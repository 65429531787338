import { FieldArray, Form, Formik } from "formik";
import "primeflex/primeflex.css";
import { Checkbox } from "primereact/checkbox";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import copiarIcone from "../../../assets/icons/copy.svg";
import AlertMenssage from "../../../components/AlertMenssage";
import ButtonSave from "../../../components/ButtonSave";
import ConfirmationDialog from "../../../components/dialogs/confirmation-dialog";
import DropdownInput from "../../../components/DropdownInput";
import EnderecoForm, {
  enderecoFields,
} from "../../../components/form/EnderecoForm";
import TelefoneForm, {
  telefoneFields,
} from "../../../components/form/PhoneForm";
import ImageeInputPreview from "../../../components/ImageInputPreview";
import ProporcaoImagemDialogo from "../../../components/ProporcaoImagemDialogo";
import InputCnpj from "../../../components/InputCnpj";
import ExpositorMenu from "../../../components/ExpositorMenu";
import TextInput from "../../../components/TextInput";
import AlertService from "../../../service/alert.service";
import Authentication from "../../../service/authentication.service";
import ExpositoresService from "../../../service/expositores.service";
import ModalidadeExpositoresService from "../../../service/modalidade-expositores.service";
import productTypeService from "../../../service/product-type-service";
import SegmentosService from "../../../service/segmentos.service";
import imageProfileDefault from "../../../utils/image-profile-default";
import { userHasAccess, userProfiles } from "../../../utils/permission-user";
import { removerAcentos } from "../../../utils/remover-caracteres-especiais";
import unMask from "../../../utils/unmask";
import { expositorSchema } from "../../../validations/expositores.validation";
import "./style.css";
import "./style.scss";
import QueryOptions from "../../../utils/query-options";

export default function ExpositoresForm() {
  const history = useHistory();
  const [disabledExpositor, setDisabledExpositor] = useState();
  const [action, setAction] = useState("edit");
  const [segmentosOptions, setSegmentosOptions] = useState([]);
  const [uriLink, setUriLink] = useState();
  const [loadingInfo, setLoadingInfo] = useState(true);

  const [loading, setLoading] = useState(false);
  const [disabledButton, setDisableButton] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);

  const [usuarioExpositor, setUsuarioExpositor] = useState(false);

  const animatedComponents = makeAnimated();
  const [displayConfirmationDialogEdit, setDisplayConfirmationDialogEdit] =
    useState(false);

  const [expositor, setExpositor] = useState();
  const [modalidadeExpositor, setModalidadeExpositor] = useState([]);
  const [imageFile, setImageFile] = useState();
  const [imageProfile, setImageProfile] = useState(imageProfileDefault());
  const [time] = useState(new Date().getTime());

  const [modalidade, setModalidade] = useState("");
  const { id } = useParams();
  const _expositoresService = new ExpositoresService();

  useEffect(
    (_expositorService = new ExpositoresService()) => {
      const baseUrl = process.env.REACT_APP_API_URL;
      function getImageProfile(expositor) {
        return expositor.logotipoFileName
          ? `${baseUrl}expositores/${expositor.id}/logotipos/display?${time}`
          : imageProfileDefault();
      }
      async function getExpositor() {
        const _expositoresService = new ExpositoresService();
        const expositor = await _expositoresService.read(id);

        const imageProfile = getImageProfile(expositor);
        if (expositor.disabled === true) {
          setDisabledExpositor(true);
        } else {
          setDisabledExpositor(false);
        }

        if (!expositor.adresses.length) {
          expositor.adresses.push(enderecoFields);
        }

        if (!expositor.phones.length) {
          expositor.phones.push(telefoneFields);
        }

        if (expositor.idModalidade === 2) {
          setModalidade(2);
        }

        if (userHasAccess([userProfiles.expositor])) {
          setUsuarioExpositor(true);
        }

        if (!expositor.hasOwnProperty("cnpj")) {
          expositor.cnpj = "";
        }

        const redesSociais = [];
        if (expositor.redesSociais?.length) {
          expositor.redesSociais.forEach((item) => {
            if (item.id_rede_social_tipo === 1) {
              redesSociais[0] = item;
            } else if (item.id_rede_social_tipo === 2) {
              redesSociais[1] = item;
            } else if (item.id_rede_social_tipo === 3) {
              redesSociais[2] = item;
            } else if (item.id_rede_social_tipo === 4) {
              redesSociais[3] = item;
            } else if (item.id_rede_social_tipo === 5) {
              redesSociais[4] = item;
            } else if (item.id_rede_social_tipo === 6) {
              redesSociais[5] = item;
            }
          });
        }

        setExpositor({ ...expositor, redesSociais: redesSociais });
        setImageProfile(imageProfile);
        setLoadingInfo(false);
      }

      async function init() {
        const _modalidadeExpositoresService =
          new ModalidadeExpositoresService();
        const modalidadeDoExpositor =
          await _modalidadeExpositoresService.list();
        setModalidadeExpositor(modalidadeDoExpositor);

        const queryOptions = new QueryOptions([["page", 0]]);

        const _segmentosService = new SegmentosService();
        const response = await _segmentosService.list(queryOptions, true);
        setSegmentosOptions(response);

        const _productTypeService = new productTypeService();
        const productTypeResponse = await _productTypeService.list(
          queryOptions,
          true
        );
        setProductOptions(productTypeResponse);

        if (id) {
          getExpositor();
          const copyText = document.getElementById("copyText")?.value;
          setUriLink(copyText);
        } else {
          setAction("create");
          setExpositor({
            nomeFantasia: "",
            razaoSocial: "",
            email: "",
            site: "",
            inscEstadual: "",
            cnpj: "",
            uriUnico: "",
            idsProdutoTipo: [],
            adresses: [enderecoFields],
            phones: [telefoneFields],
            segmentos: [],
            idModalidade: "",
            numeroEstande: "",
            redesSociais: [
              {
                link: "",
                id_rede_social_tipo: 1,
              },
              {
                link: "",
                id_rede_social_tipo: 2,
              },
              {
                link: "",
                id_rede_social_tipo: 3,
              },
              {
                link: "",
                id_rede_social_tipo: 4,
              },
            ],
          });
          setLoadingInfo(false);
        }
      }
      init();
    },
    [id, uriLink, time]
  );

  async function createExpositores(values) {
    setLoading(true);

    const data = { ...values };

    const telefonesValidosUsuario = data.phones.filter(
      (item) => unMask(item.ddi) && unMask(item.ddd) && unMask(item.phone)
    );
    data.phones = telefonesValidosUsuario;

    const enderecosValidosUsuarios = data.adresses.filter(
      (item) => item.address
    );
    data.adresses = enderecosValidosUsuarios;

    if (action === "create") {
      var indexRedesSociais = 0;
      values.redesSociais.forEach((item) => {
        if (item.link === "") {
          values.redesSociais.splice(indexRedesSociais);
        }

        indexRedesSociais++;
      });

      await _expositoresService
        .create(data)
        .then(async (response) => {
          history.push("/expositores?acao=criado");
          const data = response;

          if (imageFile) {
            await _expositoresService.uploadImage(imageFile, data.id);
          }
        })
        .catch(({ response }) => {
          AlertService.warn(response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await _expositoresService
        .update(data)
        .then(() => {
          AlertService.success("Expositor atualizado com sucesso");
        })
        .catch(({ response }) => AlertService.warn(response.data.msg))
        .finally(() => {
          setLoading(false);
        });

      if (imageFile) {
        await _expositoresService.uploadImage(imageFile, values.id);
      }

      if (action === "profile") {
        Authentication._user = expositor;
      }
    }
  }

  async function disableUser() {
    if (!disabledExpositor) {
      await _expositoresService
        .update({ ...expositor, disabled: true })
        .then(() => {
          setDisplayConfirmationDialogEdit(false);
          window.location.reload();
        })
        .catch(({ response }) => {
          AlertService.warn(response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await _expositoresService
        .update({ ...expositor, disabled: false })
        .then(() => {
          setDisplayConfirmationDialogEdit(false);
          window.location.reload();
        })
        .catch(({ response }) => {
          AlertService.warn(response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  async function uriChangesEvent(event) {
    const uriUnico = event.target.value;
    _expositoresService.validaUri(uriUnico, id).then((data) => {
      if (history.location.pathname === `/expositores/editar/${id}`) {
        if (!data.disponível) {
          document.getElementById("errorUriMessage").style.display = "block";
          setDisableButton(true);
        } else {
          document.getElementById("errorUriMessage").style.display = "none";
          setDisableButton(false);
        }
      }
    });
  }

  async function handleDelete() {
    await _expositoresService.delete(id);
    history.push("/expositores?acao=deletado");
    setDisplayConfirmationDialog(false);
  }

  function copyText() {
    const x = document.getElementById("copyText");
    x.select();
    document.execCommand("copy");
  }

  function handleChangeImageFile(imageFile) {
    setImageFile(imageFile);
  }

  return (
    <div className="p-grid">
      <ConfirmationDialog
        header={
          disabledExpositor
            ? "Ativar esse expositor vai lhe dar acesso à plataforma."
            : "Inativar o expositor vai deixá-lo sem acesso à plataforma."
        }
        action={
          disabledExpositor
            ? "Deseja realmente ativar esse expositor?"
            : "Deseja realmente inativar esse expositor?"
        }
        display={displayConfirmationDialogEdit}
        onClose={() => setDisplayConfirmationDialogEdit(false)}
        onConfirm={disableUser}
      />

      <ConfirmationDialog
        header="Excluir esse usuário vai apagar todas as suas informações."
        action="Excluir mesmo assim?"
        display={displayConfirmationDialog}
        onClose={() => setDisplayConfirmationDialog(false)}
        onConfirm={handleDelete}
      />

      {loadingInfo && (
        <div className="loading-info-container">
          <lottie-player
            src="https://assets4.lottiefiles.com/packages/lf20_58vkk48j.json"
            background="transparent"
            speed="1"
            style={{ width: "500px" }}
            loop
            autoplay
          />
        </div>
      )}

      <div className="p-col-12" hidden={loadingInfo}>
        {action !== "create" && (
          <ExpositorMenu activeItem={0} expositor={expositor} />
        )}

        <div className="card">
          {action === "edit" ? (
            <h1>Editar expositor</h1>
          ) : (
            <h1>Adicionar expositor</h1>
          )}
          <hr />

          {action === "edit" &&
            (disabledExpositor ? (
              <h3 style={{ color: "red" }}>Expositor Inativo</h3>
            ) : (
              <h3 style={{ color: "#009974" }}>Expositor Ativo</h3>
            ))}

          <Formik
            enableReinitialize={true}
            validationSchema={expositorSchema}
            initialValues={expositor}
            onSubmit={(values) => {
              createExpositores(values);
            }}
          >
            {(props) => {
              return (
                <Form autoComplete="off">
                  {userHasAccess([userProfiles.administrador]) && (
                    <div>
                      <div className="midia-kit">
                        <div>
                          <h3>Campos avulsos do mídia kit</h3>
                          <div>
                            <div>
                              <Checkbox
                                onChange={(e) => {
                                  props.setFieldValue(
                                    "bannerRotativos",
                                    e?.checked
                                  );
                                }}
                                checked={props.values?.bannerRotativos}
                                name="bannerRotativos"
                                inputId="bannerRotativos"
                              />
                              <label htmlFor="bannerRotativos">
                                Banner rotativos
                              </label>
                            </div>
                            <div>
                              <Checkbox
                                onChange={(e) => {
                                  props.setFieldValue(
                                    "modulosEventos",
                                    e?.checked
                                  );
                                }}
                                checked={props.values?.modulosEventos}
                                name="modulosEventos"
                                inputId="modulosEventos"
                              />
                              <label htmlFor="modulosEventos">
                                Módulos eventos
                              </label>
                            </div>

                            <div>
                              <Checkbox
                                onChange={(e) => {
                                  props.setFieldValue(
                                    "contatoDoExpositor",
                                    e?.checked
                                  );
                                }}
                                checked={props.values?.contatoDoExpositor}
                                name="contatoDoExpositor"
                                inputId="contatoDoExpositor"
                              />
                              <label htmlFor="contatoDoExpositor">
                                Contato - Plugin Whatsapp e fale com expositor
                              </label>
                            </div>
                          </div>
                          <div>
                            <Checkbox
                              onChange={(e) => {
                                props.setFieldValue(
                                  "moduloGaleriaVideos",
                                  e?.checked
                                );
                              }}
                              checked={props.values?.moduloGaleriaVideos}
                              name="moduloGaleriaVideos"
                              inputId="moduloGaleriaVideos"
                            />
                            <label htmlFor="moduloGaleriaVideos">
                              Módulo galeria de vídeos
                            </label>
                          </div>
                          <div>
                            <Checkbox
                              onChange={(e) => {
                                props.setFieldValue(
                                  "modulosCatalogos",
                                  e?.checked
                                );
                              }}
                              checked={props.values?.modulosCatalogos}
                              name="modulosCatalogos"
                              inputId="modulosCatalogos"
                            />
                            <label htmlFor="modulosCatalogos">
                              Módulos catálogos
                            </label>
                          </div>

                          <div>
                            <Checkbox
                              onChange={(e) => {
                                props.setFieldValue(
                                  "coletorDeDadosDigital",
                                  e?.checked
                                );
                              }}
                              checked={props.values?.coletorDeDadosDigital}
                              name="coletorDeDadosDigital"
                              inputId="coletorDeDadosDigital"
                            />
                            <label htmlFor="coletorDeDadosDigital">
                              Coletor de dados digital
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="p-grid">
                    <div className="p-col-12 p-md-3 p-lg-2">
                      <label>
                        CNPJ <span className="required-hash">*</span>
                      </label>
                      <InputCnpj
                        name="cnpj"
                        classes=""
                        placeholder="__.___.___/____-__"
                        className="input"
                        disabled={usuarioExpositor ? true : false}
                      />
                    </div>
                    <div className="p-col-12 p-md-3 p-lg-3">
                      <label>
                        Nome fantasia <span className="required-hash">*</span>
                      </label>
                      <TextInput
                        name="nomeFantasia"
                        placeholder="Nome fantasia"
                        className="input"
                        disabled={usuarioExpositor ? true : false}
                      />
                    </div>

                    <div className="p-col-12 p-md-4 p-lg-3">
                      <label>
                        Razão social <span className="required-hash">*</span>
                      </label>
                      <TextInput
                        name="razaoSocial"
                        placeholder="Razão social"
                        className="input"
                        disabled={usuarioExpositor ? true : false}
                      />
                    </div>

                    <div className="p-col-12 p-md-3 p-lg-2">
                      <label>Inscrição estadual</label>
                      <TextInput
                        name="inscEstadual"
                        placeholder="__________"
                        className="input"
                        disabled={usuarioExpositor ? true : false}
                      />
                    </div>

                    <div className="p-col-12 p-md-3 p-lg-2">
                      <label>
                        Email <span className="required-hash">*</span>
                      </label>
                      <TextInput
                        name="email"
                        type="email"
                        placeholder="__________"
                        className="input"
                      />
                    </div>

                    <div className="p-col-12 p-md-3 p-lg-2">
                      <label>Site</label>
                      <TextInput
                        name="site"
                        placeholder="__________"
                        className="input"
                        disabled={usuarioExpositor ? true : false}
                      />
                    </div>

                    <div className="p-col-12 p-md-3 p-lg-2">
                      <label>
                        Modalidade <span className="required-hash">*</span>
                      </label>
                      <DropdownInput
                        value={props.values?.idModalidade}
                        placeholder="Selecione"
                        name="idModalidade"
                        options={modalidadeExpositor}
                        onChange={(e) => {
                          props.setFieldValue(
                            "idModalidade",
                            e.value,
                            setModalidade(e.value)
                          );
                        }}
                        disabled={usuarioExpositor ? true : false}
                      />
                    </div>

                    {modalidade !== 2 && (
                      <div className="p-col-12 p-md-3 p-lg-2">
                        <label>Número do estande</label>
                        <TextInput
                          placeholder="Número do estande"
                          name="numeroEstande"
                          className="input"
                          disabled={usuarioExpositor ? true : false}
                        />
                      </div>
                    )}

                    <div className="p-col-12 p-lg-4 p-md-4 p-sm-6">
                      <label>Tipos de Produtos</label>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        name="idsProdutoTipo"
                        options={productOptions}
                        placeholder="Selecione"
                        value={props.values?.idsProdutoTipo}
                        onChange={(event) =>
                          props.setFieldValue("idsProdutoTipo", event)
                        }
                      />
                    </div>

                    <div className="p-col-12 p-lg-6 p-md-4 p-sm-6">
                      <label>Segmentos</label>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        name="segmentos"
                        options={segmentosOptions}
                        placeholder="Selecione"
                        value={props.values?.segmentos}
                        onChange={(event) =>
                          props.setFieldValue("segmentos", event)
                        }
                      />
                    </div>

                    <div className="p-col-12 p-md-3 p-lg-2">
                      <label>
                        URI único <span className="required-hash">*</span>
                      </label>
                      <TextInput
                        name="uriUnico"
                        onBlur={uriChangesEvent}
                        className="input"
                        disabled={usuarioExpositor ? true : false}
                        values={props?.values?.uriUnico}
                        onChange={(e) => {
                          const uriLimpa = removerAcentos(e.target.value);
                          props.setFieldValue("uriUnico", uriLimpa);
                        }}
                      />
                    </div>

                    <div className="p-col-12 p-md-3 p-lg-10">
                      <span>Ver página</span>
                      <br />
                      <div className="copy">
                        <a
                          href={uriLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ width: "600px" }}
                        >
                          <input
                            value={`${process.env.REACT_APP_PLATAFORMA_URL}expositor/${props.values?.uriUnico}`}
                            id="copyText"
                            name="copyText"
                          />
                        </a>
                        <div className="tooltip">
                          <span className="tooltip-itens">
                            <span className="tooltiptext">
                              Clique para copiar
                            </span>

                            <img
                              src={copiarIcone}
                              alt="Icone de copiar"
                              onClick={copyText}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="p-grid p-col-12 p-md-3 p-lg-12">
                      <div className="box">
                        <div className="label-logo">
                          <label>Logo da empresa</label>
                        </div>
                        <div className="flex-user-form">
                          <div className="flex-box-profile">
                            <div className="box-profile">
                              <ImageeInputPreview
                                image={imageProfile}
                                changeImage={(e) => handleChangeImageFile(e)}
                                height={250}
                                width={250}
                                tamanhoEspecifico={true}
                              />
                            </div>
                            <div className="label-logo">
                              <span>
                                <ProporcaoImagemDialogo
                                  isJustDesktop={true}
                                  widthDesktop={250}
                                  heightDesktop={250}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr style={{ width: "100%", opacity: "30%" }} />

                    <div className="flex-adress-and-phone">
                      <div className="adress-field">
                        <div className="p-grid">
                          <div
                            className="p-col-12"
                            style={{ marginLeft: "4px" }}
                          >
                            <h2>Endereço</h2>
                          </div>
                          <FieldArray
                            name="adresses"
                            render={(arrayHelpers) => (
                              <div
                                className="p-col-12"
                                style={{ marginLeft: "4px" }}
                              >
                                {props?.values?.adresses?.map(
                                  (address, index) => (
                                    <div key={index}>
                                      {/* <AddressForm
                                        arrayHelpers={arrayHelpers}
                                        index={index}
                                        adresses={props.values.adresses}
                                        formikProps={props}
                                      /> */}
                                      <EnderecoForm
                                        form={props}
                                        index={index}
                                        arrayHelpers={arrayHelpers}
                                        address={address}
                                        requiredHashInFields={
                                          props.errors?.adresses ? true : false
                                        }
                                      />
                                    </div>
                                  )
                                )}
                                <div
                                  className="add-item"
                                  onClick={() =>
                                    arrayHelpers.insert(
                                      props?.values?.adresses?.length + 1,
                                      enderecoFields
                                    )
                                  }
                                >
                                  Adicionar um novo endereço
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="phone-field">
                        <div className="p-grid">
                          <div className="p-col-12">
                            <h2>Contatos</h2>
                          </div>
                          <FieldArray
                            name="phones"
                            render={(arrayHelpers) => (
                              <div className="p-col-12">
                                {props?.values?.phones?.map((phone, index) => (
                                  <div key={index}>
                                    <TelefoneForm
                                      parent="phones."
                                      arrayHelpers={arrayHelpers}
                                      index={index}
                                      phones={props.values.phones}
                                      formikProps={props}
                                      requiredHashInFields={
                                        props.errors?.phones ? true : false
                                      }
                                    />
                                  </div>
                                ))}
                                <div
                                  className="add-item"
                                  onClick={() =>
                                    arrayHelpers.insert(
                                      props?.values?.phones?.length + 1,
                                      telefoneFields
                                    )
                                  }
                                >
                                  Adicionar um novo telefone
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div style={{ width: "100%", paddingLeft: "10px" }}>
                      <hr></hr>
                      <h2>Redes Sociais</h2>

                      <div className="p-grid p-col-12">
                        <div className="p-col-2 p-md-2 p-lg-2">
                          <div
                            className="p-col-12 p-md-12 p-lg-12"
                            style={{
                              paddingLeft: "0px",
                            }}
                          >
                            <label>Link do facebook</label>
                            <TextInput
                              name="redesSociais[0].link"
                              placeholder="https://www.facebook.com"
                              className="input"
                              onChange={(event) => {
                                props.setFieldValue(
                                  "redesSociais[0].id_rede_social_tipo",
                                  1
                                );

                                props.setFieldValue(
                                  "redesSociais[0].link",
                                  event.target.value
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div className="p-col-2 p-md-2 p-lg-2">
                          <div className="p-col-12 p-md-12 p-lg-12">
                            <label>Link do instagram</label>
                            <TextInput
                              name="redesSociais[1].link"
                              placeholder="https://www.instagram.com"
                              className="input"
                              onChange={(event) => {
                                props.setFieldValue(
                                  "redesSociais[1].id_rede_social_tipo",
                                  2
                                );

                                props.setFieldValue(
                                  "redesSociais[1].link",
                                  event.target.value
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div className="p-col-2 p-md-2 p-lg-2">
                          <div className="p-col-12 p-md-12 p-lg-12">
                            <label>Link do pinterest</label>
                            <TextInput
                              name="redesSociais[2].link"
                              placeholder="https://pinterest.com"
                              className="input"
                              onChange={(event) => {
                                props.setFieldValue(
                                  "redesSociais[2].id_rede_social_tipo",
                                  3
                                );

                                props.setFieldValue(
                                  "redesSociais[2].link",
                                  event.target.value
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div className="p-col-2 p-md-2 p-lg-2">
                          <div className="p-col-12 p-md-12 p-lg-12">
                            <label>Link do youtube</label>
                            <TextInput
                              name="redesSociais[3].link"
                              placeholder="https://www.youtube.com"
                              className="input"
                              onChange={(event) => {
                                props.setFieldValue(
                                  "redesSociais[3].id_rede_social_tipo",
                                  4
                                );

                                props.setFieldValue(
                                  "redesSociais[3].link",
                                  event.target.value
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div className="p-col-2 p-md-2 p-lg-2">
                          <div className="p-col-12 p-md-12 p-lg-12">
                            <label>Link do linkedin</label>
                            <TextInput
                              name="redesSociais[5].link"
                              placeholder="https://br.linkedin.com/"
                              className="input"
                              onChange={(event) => {
                                props.setFieldValue(
                                  "redesSociais[5].id_rede_social_tipo",
                                  6
                                );

                                props.setFieldValue(
                                  "redesSociais[5].link",
                                  event.target.value
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div className="p-col-2 p-md-2 p-lg-2">
                          {props.values?.contatoDoExpositor && (
                            <div className="p-col-12 p-md-12 p-lg-12">
                              <label>Whatsapp (DDI+DDD+número)</label>
                              <TextInput
                                name="redesSociais[4].link"
                                placeholder="DDI+DDD+número"
                                maxLength="15"
                                className="input"
                                onChange={(event) => {
                                  props.setFieldValue(
                                    "redesSociais[4].id_rede_social_tipo",
                                    5
                                  );

                                  props.setFieldValue(
                                    "redesSociais[4].link",
                                    event.target.value
                                  );
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="btns-inline">
                    <div className="btn-medium">
                      <ButtonSave
                        label={action === "create" ? "Salvar" : "Atualizar"}
                        disabled={disabledButton ? true : false}
                        loading={loading}
                        className="button-save"
                        type="submit"
                        id="saveValuesButton"
                        onClick={() => {
                          if (Object.keys(props.errors).length) {
                            AlertService.warn(
                              "Verifique se todos os campos obrigatórios foram preenchidos corretamente"
                            );
                          }
                        }}
                      />
                    </div>

                    {action === "edit" &&
                      userHasAccess([userProfiles.administrador]) && (
                        <div
                          className="btns-inline"
                          style={{ justifyContent: "space-evenly" }}
                        >
                          <div className="btn-medium">
                            <button
                              className="button-disable"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setDisplayConfirmationDialogEdit(true);
                              }}
                            >
                              {disabledExpositor ? "Ativar" : "Desativar"}
                            </button>
                          </div>

                          <div className="btn-medium">
                            <button
                              className="button-delete"
                              onClick={(e) => {
                                e.preventDefault();
                                setDisplayConfirmationDialog(true);
                              }}
                            >
                              Excluir
                            </button>
                          </div>
                        </div>
                      )}

                    {userHasAccess([userProfiles.administrador]) && (
                      <div className="btn-medium">
                        <button
                          className="button-cancel"
                          onClick={() => {
                            history.push(`/expositores`);
                          }}
                        >
                          Voltar
                        </button>
                      </div>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
          <AlertMenssage />
          <div className="error-container">
            <p id="errorUriMessage">
              {" Esse URI único já está sendo utilizado "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
