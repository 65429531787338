export default class ExpositorEstatisticaLanguageSerializer {
  fromJson(json) {
    const expositorEstatisticaLanguage = {};
    Object.assign(
      expositorEstatisticaLanguage,
      json.descricao && { descricao: json.descricao },
      json.id_idioma && { id_idioma: json.id_idioma },
      json.titulo && { titulo: json.titulo },
      json.titulo_grupo && { titulo_grupo: json.titulo_grupo },
      json.id_expositor_estatistica && {
        id_expositor_estatistica: json.id_expositor_estatistica,
      },
      json.id_expositor_estatistica_idioma && {
        id_expositor_estatistica_idioma: json.id_expositor_estatistica_idioma,
      }
    );

    return expositorEstatisticaLanguage;
  }

  toJson(expositor) {
    const expositorEstatisticaLanguage = {};

    Object.assign(
      expositorEstatisticaLanguage,
      expositor.titulo && {
        titulo: expositor.titulo,
      },
      expositor.idIdioma && {
        id_idioma: expositor.idIdioma,
      }
    );
    return expositorEstatisticaLanguage;
  }
}
