/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import AlertMenssage from "../../components/AlertMenssage";
import ButtonSave from "../../components/ButtonSave";
import CustonCheckbox from "../../components/CustonCheckbox";
import ImageeInputPreview from "../../components/ImageInputPreview";
import InputPercent from "../../components/InputPercent";
import TextInput from "../../components/TextInput";
import TextareaInput from "../../components/TextareaInput";
import ConfirmationDialog from "../../components/dialogs/confirmation-dialog";
import AlertService from "../../service/alert.service";
import Authentication from "../../service/authentication.service";
import CursoInstrutorService from "../../service/curso-instrutor.service";
import CursoService from "../../service/curso.service";
import ExpositoresService from "../../service/expositores.service";
import LanguagesService from "../../service/language.service";
import UserProfileService from "../../service/user-profile.service";
import imageIconDefault from "../../utils/image-icon-default";
import QueryOptions from "../../utils/query-options";
import {
  cursoSchema,
  failedValidation,
} from "../../validations/curso.validation";
import CalendarDateInput from "../../components/CalendarDateInput";

export default function CursoForm() {
  const _cursoService = new CursoService();
  const _expositoresService = new ExpositoresService();
  const _instrutorService = new CursoInstrutorService();
  const _perfilService = new UserProfileService();

  const { id: idCurso } = useParams();
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cursoDisabled, setCursoDisabled] = useState(false);
  const animatedComponents = makeAnimated();
  const [expositoresOpcoes, setExpositoresOpcoes] = useState([]);
  const [instrutoresOpcoes, setInstrutoresOpcoes] = useState([]);
  const [perfilOpcoes, setPerfilOpcoes] = useState([]);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [displayDisabledDialog, setDisplayDisabledDialog] = useState(false);
  const [imagemCurso, setImagemCurso] = useState([]);
  const [isChangePhoto, setIsChangePhoto] = useState(false);
  const [imageFile, setImageFile] = useState([]);

  // Idiomas
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(1);

  // Formulário
  const formIdiomaField = {
    titulo: "",
    descricao: "",
    idIdioma: "",
  };

  const [valuesForm, setValuesForm] = useState({
    cargaHoraria: "",
    porcentagemConclusao: "",
    instrutor: "",
    expositor: "",
    usuario: Authentication._user.name,
    dataCriacao: format(new Date(), "dd/MM/yyyy"),
    idiomas: [formIdiomaField],
    destaque: false,
    perfil: [],
    dateStart: "",
    dateEnd: "",
  });

  const mountLanguage = async () => {
    const _languagesService = new LanguagesService();
    const languages = await _languagesService.list();

    let idiomas = [];
    let imagens = [];
    languages.forEach((element) => {
      idiomas.push({
        ...formIdiomaField,
        idIdioma: element.id,
      });

      imagens.push(imageIconDefault());
    });

    setValuesForm({
      ...valuesForm,
      idiomas,
    });

    setImagemCurso([...imagens]);

    setLanguages(languages);
    return languages;
  };

  const listExpositores = async () => {
    const data = await _expositoresService.list(
      new QueryOptions([
        ["per_page", 10000],
        ["fields", "id_expositor,nome_fantasia"],
      ])
    );

    setExpositoresOpcoes(data);
  };

  const listInstrutores = async () => {
    const data = await _instrutorService.list(
      new QueryOptions([
        ["per_page", 10000],
        ["fields", "id_curso_instrutor,nome_instrutor"],
      ])
    );

    setInstrutoresOpcoes(data);
  };

  const listPerfis = async () => {
    const data = await _perfilService.list(
      new QueryOptions([["per_page", 10000]])
    );

    const perfilFilter = []
    data.forEach((item) => {
      if (item.id > 3) {
         perfilFilter.push({ ...item });
      }
    });

    setPerfilOpcoes(perfilFilter);
  };

  const getCurso = async (languages) => {
    const curso = await _cursoService.read(idCurso);

    let idiomas = [];
    languages.forEach((element) => {
      idiomas.push({
        ...formIdiomaField,
        idIdioma: element.id,
      });
    });

    const idIdiomas = [];
    curso.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

    const idiomasFaltantes = [];
    idiomas.forEach((item) => {
      if (!idIdiomas.includes(item.idIdioma)) {
        idiomasFaltantes.push(item);
      }
    });

    const allLanguages = [...curso.idiomas, ...idiomasFaltantes].sort(
      (v1, v2) => v1.idIdioma - v2.idIdioma
    );

    const imagens = [];
    allLanguages.forEach((idioma, index) => {
      if (idioma.idCursoIdioma && idioma.imageFilename) {
        imagens[index] = _cursoService.linkImage(idioma.idCursoIdioma);
      } else {
        imagens[index] = imageIconDefault();
      }
    });

    setImagemCurso([...imagens]);

    setCursoDisabled(curso.disabled);

    setValuesForm({ ...curso, idiomas: allLanguages });
  };

  useEffect(() => {
    setIsEdit(!!idCurso);

    const init = async () => {
      listExpositores();
      listInstrutores();
      listPerfis();
      const languages = await mountLanguage();

      if (idCurso) {
        await getCurso(languages);
      }
    };

    init();
  }, []);

  async function handleDelete() {
    await _cursoService.delete(idCurso);
    history.push(`/cursos/lista?acao=deletado`);
  }

  async function handleDisabled() {
    const isDisabled = !cursoDisabled;

    const data = {
      id: idCurso,
      disabled: isDisabled,
    };

    await _cursoService.update(data);

    setCursoDisabled(isDisabled);
    setDisplayDisabledDialog(false);
    AlertService.success(`Curso ${isDisabled ? "desativado" : "ativado"}`);
  }

  const changePhoto = (value, index) => {
    const imageFileAux = [...imageFile];
    imageFileAux[index] = value;
    setImageFile([...imageFileAux]);
    setIsChangePhoto(true);
  };

  const updatePhoto = async (idiomas) => {
    if (isChangePhoto) {
      imageFile.forEach(async (imagem, index) => {
        if (imagem) {
          await _cursoService.uploadImagens(
            imageFile[index],
            idiomas[index].idCursoIdioma
          );
        }
      });
    }
  };

  const onSubmit = async (values) => {
    setLoading(true);

    const data = { ...values };
    data.idiomas = data.idiomas.filter(
      (idioma) => idioma.titulo && idioma.descricao
    );

    if (isEdit) {
      // Atualizar
      try {
        data.disabled = cursoDisabled;
        await _cursoService.update(data);
        await updatePhoto(values.idiomas);
        AlertService.success("Curso atualizado");
      } catch ({ response }) {
        AlertService.warn(response.data.msg);
      } finally {
        setLoading(false);
        setIsChangePhoto(false);
      }
    } else {
      // Criar
      try {
        const curso = await _cursoService.create(data);
        await updatePhoto(curso.idiomas);
        history.push(`/cursos/lista?acao=criado`);
      } catch ({ response }) {
        AlertService.warn(response.data.msg);
      } finally {
        setIsChangePhoto(false);
      }
    }
  };

  return (
    <>
      <ConfirmationDialog
        header="Atenção!"
        action="Apagar este curso?"
        display={displayDeleteDialog}
        onClose={() => setDisplayDeleteDialog(false)}
        onConfirm={handleDelete}
      />

      <ConfirmationDialog
        header="Atenção!"
        action={`${cursoDisabled ? "Ativar" : "Desativar"}  este curso?`}
        display={displayDisabledDialog}
        onClose={() => setDisplayDisabledDialog(false)}
        onConfirm={handleDisabled}
      />
      <div className="card">
        <h1 className="title-page">
          {isEdit ? "Editar" : "Adicionar"} curso
          {cursoDisabled ? (
            <>
              {" "}
              - <span className="text-desativado">Desativado</span>
            </>
          ) : null}
        </h1>

        <hr className="hr-header" />

        <Formik
          enableReinitialize={true}
          validationSchema={cursoSchema}
          initialValues={valuesForm}
          onSubmit={onSubmit}
        >
          {(props) => {
            return (
              <Form autoComplete="off">
                <div
                  className="wrapper-form"
                  style={{ paddingLeft: "0.5em", maxWidth: "820px" }}
                >
                  <div className="p-grid">
                    {languages.map((item, index) => (
                      <div key={index} className="language-dashboard">
                        <button
                          className={
                            item.id === currentLanguage
                              ? "p-button-dash"
                              : "p-button-teste"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentLanguage(item.id);
                          }}
                        >
                          {item.nome}
                        </button>
                      </div>
                    ))}
                  </div>

                  <div>
                    <FieldArray
                      name="idiomas"
                      render={(arrayHelpers) => (
                        <div className="p-grid">
                          {props?.values?.idiomas?.map((item, index) => (
                            <div
                              className="p-col-12"
                              key={index}
                              hidden={!(item.idIdioma === currentLanguage)}
                            >
                              <TextInput
                                name={`idiomas[${index}].titulo`}
                                className="input"
                                label="Título"
                                quantidadeCaracteres={50}
                                maxLength={50}
                                required={
                                  index === 0 || (item.descricao && index > 0)
                                }
                              />
                              <TextareaInput
                                name={`idiomas[${index}].descricao`}
                                classes="descricao-publicidade"
                                className="input"
                                label="Descrição"
                                required={
                                  index === 0 || (item.titulo && index > 0)
                                }
                              />

                              <div className="box" style={{ marginLeft: 2 }}>
                                <div className="flex-box-profile">
                                  <div className="box-profile">
                                    <ImageeInputPreview
                                      image={imagemCurso[index]}
                                      changeImage={(e) => changePhoto(e, index)}
                                    />
                                  </div>

                                  {/* {displayMudarFoto && (
                                    <div className="remover-imagem-container">
                                      <h4
                                        onClick={() => {
                                          // setDisplayConfirmationRemoverFoto(true);
                                        }}
                                      >
                                        Remover foto atual
                                      </h4>
                                    </div>
                                  )} */}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    />

                    <hr style={{ opacity: "30%" }} />

                    <div className="p-grid">
                      <div className="p-col-12">
                        <CustonCheckbox name="destaque">
                          Destaque (listados no topo da página)
                        </CustonCheckbox>
                      </div>
                      <div className="p-col-6">
                        <label>
                          Instrutor <span className="required-hash">*</span>
                        </label>
                        <Select
                          components={animatedComponents}
                          name="instrutor"
                          options={instrutoresOpcoes}
                          placeholder="Selecione"
                          value={props.values?.instrutor}
                          onChange={(event) =>
                            props.setFieldValue("instrutor", event)
                          }
                        />

                        <ErrorMessage name="instrutor">
                          {(msg) => <div className="error-input">{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div className="p-col-6">
                        <label>
                          Expositor <span className="required-hash">*</span>
                        </label>
                        <Select
                          components={animatedComponents}
                          name="expositor"
                          options={expositoresOpcoes}
                          placeholder="Selecione"
                          value={props.values?.expositor}
                          onChange={(event) =>
                            props.setFieldValue("expositor", event)
                          }
                        />

                        <ErrorMessage name="expositor">
                          {(msg) => <div className="error-input">{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <TextInput
                        classes="p-col-4"
                        name="cargaHoraria"
                        className="input"
                        label="Carga horária (minutos)"
                        type="number"
                        required
                      />

                      <InputPercent
                        classes="p-col-4"
                        name="porcentagemConclusao"
                        className="input"
                        label="Porcentagem para conclusão"
                        required
                      />

                      <TextInput
                        classes="p-col-4"
                        name="dataCriacao"
                        className="input"
                        label="Data de criação"
                        disabled
                      />

                      <div className="p-col-12">
                        <label>
                          Perfil <span className="required-hash">*</span>
                        </label>
                        <Select
                          isMulti
                          components={animatedComponents}
                          name="profile"
                          options={perfilOpcoes}
                          placeholder="Selecione"
                          value={props.values?.perfil}
                          onChange={(event) =>
                            props.setFieldValue("perfil", event)
                          }
                        />

                        <ErrorMessage name="expositor">
                          {(msg) => <div className="error-input">{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>

                    <div className="p-grid">
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        className="p-col-6"
                      >
                        <label>Data disponibilidade</label>
                        <CalendarDateInput
                          name="dateStart"
                          className="input"
                          selected={props.values?.dateStart}
                          onChange={(e) => {
                            props.setFieldValue("dateStart", e);
                          }}
                          maxDate={props.values?.dateEnd}
                          minDate={new Date()}
                        />
                      </div>

                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        className="p-col-6"
                      >
                        <label>Data limite para conclusão</label>
                        <CalendarDateInput
                          name="dateEnd"
                          className="input"
                          selected={props.values?.dateEnd}
                          onChange={(e) => {
                            props.setFieldValue("dateEnd", e);
                          }}
                          minDate={props.values?.dateStart}
                        />
                      </div>
                    </div>

                    <div className="p-grid">
                      <TextInput
                        classes="p-col-12"
                        name="usuario"
                        className="input"
                        label="Criado por"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="btns-inline">
                    <div className="btn-medium">
                      <ButtonSave
                        label={isEdit ? "Atualizar" : "Adicionar"}
                        type="submit"
                        loading={loading}
                        onClick={() => {
                          if (!failedValidation) {
                            AlertService.warn(
                              "Verifique se os campos obrigatórios de todos os idiomas foram preenchidos"
                            );
                          }
                        }}
                      />
                    </div>
                    {isEdit ? (
                      <>
                        <div className="btn-medium">
                          <button
                            className="button-disable"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setDisplayDisabledDialog(true);
                            }}
                          >
                            {cursoDisabled ? "Ativar" : "Desativar"}
                          </button>
                        </div>
                        <div className="btn-medium">
                          <div
                            className="btns-inline"
                            style={{ justifyContent: "space-evenly" }}
                          >
                            <button
                              className="button-delete"
                              styles="p-button-secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                setDisplayDeleteDialog(true);
                              }}
                            >
                              Excluir
                            </button>
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className="btn-medium">
                      <button
                        className="button-voltar"
                        onClick={() => {
                          history.push(`/cursos/lista`);
                        }}
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>

        <AlertMenssage />
      </div>
    </>
  );
}
