import { add } from "date-fns";
import Authentication from "../service/authentication.service";

export function fromDateGmt(date) {
  if (Authentication._user) {
    const fusoHorario = 3;

    date = new Date(date.substring(0, date.length - 4));
    date = add(date, { hours: 0 - fusoHorario });

    return date;
  }

  return new Date(date);
}

export function toDateGMT(date) {
  return date.toISOString().replace("T", " ").substring(0, 16);
}
