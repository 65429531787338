import api from "./api.service";
import CoreApiService from "./core-api.service";
import ProductsSerializer from "./serializers/products.serializer";

export default class ProductService extends CoreApiService {
  constructor() {
    super("expositores", "produtos", new ProductsSerializer());
  }

  async deletarTodosProdutos(id) {
    const response = await api.delete(
      `${this.parentEndpoint}/${id}/${this.endpoint}`
    );
    return response;
  }
}
