import { format } from "date-fns";
import CursoModuloAulaIdiomaSerializer from "./curso-modulo-aula-idioma.serializer";

export default class CursoModuloAulaSerializer {
  constructor() {
    this.cursoAulaIdiomaSerializaer = new CursoModuloAulaIdiomaSerializer();
  }

  fromJson(json) {
    const aula = {};

    json = json.data || json;

    Object.assign(
      aula,
      json.id_curso_aula && { id: json.id_curso_aula },
      json.data_criacao && {
        dataCriacao: format(new Date(json.data_criacao), "dd/MM/yyyy"),
      },
      json.duracao && { duracao: json.duracao },
      // json.modulo && { modulo: json.modulo},
      json.hasOwnProperty("disabled") && { disabled: json.disabled },
      json.idiomas
        ? {
            idiomas: json.idiomas.map((item) =>
              this.cursoAulaIdiomaSerializaer.fromJson(item)
            ),
          }
        : { idiomas: [] }
    );

    return aula;
  }

  toJson(aula) {
    const aulaToJson = {};

    Object.assign(
      aulaToJson,
      aula.idCursoModulo && { id_curso_modulo: aula.idCursoModulo },
      aula.duracao && { duracao: aula.duracao },
      aula.hasOwnProperty("disabled") && { disabled: aula.disabled },
      aula.idiomas && {
        idiomas: aula?.idiomas?.map((item) =>
          this.cursoAulaIdiomaSerializaer.toJson(item)
        ),
      }
    );

    return aulaToJson;
  }
}
