export default class CursoIdiomaSerializer {
  fromJson(json) {
    const cursoIdioma = {
      idCursoIdioma: json.id_curso_idioma,
      titulo: json.titulo,
      descricao: json.descricao,
      imageFilename: json.image_filename,
      idIdioma: json.idioma.id_idioma,
    };

    return cursoIdioma;
  }

  toJson(cursoIdioma) {
    return {
      id_curso_idioma: cursoIdioma.idCursoIdioma,
      titulo: cursoIdioma.titulo,
      descricao: cursoIdioma.descricao,
      id_idioma: cursoIdioma.idIdioma,
    };
  }
}
