import React from "react";
import { useField } from "formik";
import { Checkbox } from "primereact/checkbox";

import "./styles.scss";

export default function CustonCheckbox({ children, ...props }) {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <div className="custon-checkbox">
      <label className="checkbox">
        <Checkbox {...field} {...props}></Checkbox>
        <span className="text">{children}</span>
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
}
