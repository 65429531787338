import React, { useEffect, useState } from "react";
import QueryOptions from "../../utils/query-options";
import EditionService from "../../service/edition.service";
import ListTableLazy from "../../components/ListTableLazy";
import queryString from "query-string";
import AlertService from "../../service/alert.service";
import AlertMenssage from "../../components/AlertMenssage";

export default function Edition({ location }) {
  const [edition, setEdition] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);
  const [first, setFirst] = useState(0);
  const [busca, setBusca] = useState("");
  const [limparFiltros, setLimparFiltros] = useState(false);

  const columns = [
    { field: "nome", header: "Nome" },
    { field: "dataInicioStr", header: "Data de início" },
    { field: "dataFimStr", header: "Data de fim" },
    { field: `statusStr`, header: "Status" },
  ];

  useEffect(() => {
    async function listEdition() {
      const _editionService = new EditionService();
      const queryOptions = new QueryOptions([
        ["fields", "nome, data_fim, data_inicio, id_edicao, disabled"],
      ]);

      if (busca.length) {
        queryOptions.queryKvs.push(["query", busca]);
        setLimparFiltros(true);
      } else {
        setLimparFiltros(false);
      }

      setLoading(true);
      const data = await _editionService.list(queryOptions, true);

      setLoading(false);
      setMeta(data.meta);
      setEdition(data.items);
    }

    listEdition();

  }, [page, busca]);

  useEffect(() => {
    const queryStringObj = queryString.parse(location.search);

    if (queryStringObj) {
      if (queryStringObj.acao === "criado") {
        AlertService.success("Edição adicionada com sucesso");
      } else if (queryStringObj.acao === "deletado") {
        AlertService.success("Edição deletada com sucesso");
      }
    }
  }, [location]);

  
  function handleChangePage(event) {
    setFirst(event.first);
    setPage(event.page + 1);
  }

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <div className="p-grid">
              <div className="p-col-9 p-lg-9 p-md-9 p-sm-9">
                <h1 className="h1-container">Edições</h1>
              </div>

              <div
                className={
                  busca.length
                    ? "p-col-12 p-lg-2 p-md-2 p-sm-2"
                    : "p-col-12 p-lg-3 p-md-3 p-sm-3"
                }
              >
                <div className="text-input-filtragem">
                  <input
                    type="text"
                    value={busca}
                    onChange={(ev) => setBusca(ev.target.value)}
                    placeholder="Busca por nome"
                  />
                </div>
              </div>

              {limparFiltros && (
                <div className="p-col-12 p-lg-1 p-md-1 p-sm-1">
                  <button
                    onClick={() => {
                      setBusca("");
                      setLimparFiltros(false);
                    }}
                    className="limpar-filtros-button"
                  >
                    <span>Limpar filtros</span>
                  </button>
                </div>
              )}
            </div>

            <hr className="hr-header" />
            <AlertMenssage />

            <ListTableLazy
              cols={columns}
              values={edition}
              firstIdx={first}
              meta={meta}
              loading={loading}
              endpoint="edicoes"
              onChangePage={handleChangePage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
