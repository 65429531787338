/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import CalendarDateInput from "../../components/CalendarDateInput";
import CursoService from "../../service/curso.service";

import makeAnimated from "react-select/animated";
import ButtonSave from "../../components/ButtonSave";
import LoadDialog from "../../components/dialogs/load-dialog";
import AlertService from "../../service/alert.service";
import ExpositoresService from "../../service/expositores.service";
import UserProfileService from "../../service/user-profile.service";
import QueryOptions from "../../utils/query-options";
import "./styles.scss";
import AlertMenssage from "../../components/AlertMenssage";

export default function CursoRelatorio() {
  const [loading, setLoading] = useState(false);
  const [displayLoadDialog, setDisplayLoadDialog] = useState(false);
  const animatedComponents = makeAnimated();

  // Opções
  const [cursoOpcoes, setCursoOpcoes] = useState([]);
  const [perfilOpcoes, setPerfilOpcoes] = useState([]);
  const [expositoresOpcoes, setExpositoresOpcoes] = useState([]);

  // Services
  const _expositoresService = new ExpositoresService();
  const _cursoService = new CursoService();
  const _perfilService = new UserProfileService();

  const statusOpcoes = [
    { value: 0, label: "Todos" },
    { value: 1, label: "Concluído" },
    { value: 2, label: "Em andamento" },
  ];

  const valuesForm = {
    expositores: [],
    cursos: [],
    perfil: [],
    status: statusOpcoes[0],
    data_inicio: "",
    data_fim: "",
  };

  useEffect(() => {
    const listExpositores = async () => {
      const data = await _expositoresService.list(
        new QueryOptions([
          ["per_page", 10000],
          ["fields", "id_expositor,nome_fantasia"],
        ])
      );

      setExpositoresOpcoes(data);
    };

    const listCursos = async () => {
      const cursos = await _cursoService.list();
      setCursoOpcoes(cursos);
    };

    const listPerfis = async () => {
      const data = await _perfilService.list(
        new QueryOptions([["per_page", 10000]])
      );

      const perfilFilter = [];
      data.forEach((item) => {
        if (item.id > 3) {
          perfilFilter.push({ ...item });
        }
      });

      setPerfilOpcoes(perfilFilter);
    };

    async function init() {
      setLoading(true);

      listExpositores();
      listCursos();
      listPerfis();

      setLoading(false);
    }

    init();
  }, []);

  const onSubmit = async (values) => {
    setDisplayLoadDialog(true);

    const ids_perfis = [];
    if (values.perfil.length) {
      values.perfil.forEach((item) => ids_perfis.push(item.id));
    }

    const data = {};

    Object.assign(
      data,
      values.expositores.length && {
        ids_expositores: values.expositores.map((item) => item.value),
      },
      values.cursos.length && {
        ids_cursos: values.cursos.map((item) => item.value),
      },
      values.perfil.length && {
        ids_perfis: values.perfil.map((item) => item.id),
      },
      values.status > -1 && { status: values.status },
      values.data_inicio && { data_inicio: values.data_inicio },
      values.data_fim && { data_fim: values.data_fim }
    );

    try {
      setLoading(true);
      setDisplayLoadDialog(true);
      const response = await _cursoService.relatorio(data);

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const downloadUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "relatorio.xlsx";
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (response) {
      AlertService.warn("Sem resultados para o filtro selecionado");
    } finally {
      setLoading(false);
      setDisplayLoadDialog(false);
    }
  };

  return (
    <>
      <LoadDialog
        header="Gerando relatório"
        display={displayLoadDialog}
        onClose={() => setDisplayLoadDialog(false)}
        loading={true}
      />
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <div className="p-grid">
              <div className="p-col-8 p-lg-8 p-md-8 p-sm-5">
                <h1 className="h1-container">Relatório</h1>
              </div>
            </div>

            <hr className="hr-header" />

            <Formik
              enableReinitialize={true}
              initialValues={valuesForm}
              onSubmit={onSubmit}
            >
              {(props) => {
                return (
                  <Form autoComplete="off">
                    <div
                      className="wrapper-form"
                      style={{ paddingLeft: "0.5em", maxWidth: "820px" }}
                    >
                      <div className="p-grid">
                        <div className="p-col-12">
                          <label>Expositores</label>
                          <Select
                            isMulti
                            components={animatedComponents}
                            name="profile"
                            options={expositoresOpcoes}
                            placeholder="Selecione"
                            value={props.values?.expositores}
                            onChange={(event) => {
                              props.setFieldValue("expositores", event);
                            }}
                          />

                          <ErrorMessage name="expositor">
                            {(msg) => <div className="error-input">{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div className="p-col-12">
                          <label>Cursos</label>
                          <Select
                            isMulti
                            components={animatedComponents}
                            name="profile"
                            options={cursoOpcoes}
                            placeholder="Selecione"
                            value={props.values?.cursos}
                            onChange={(event) =>
                              props.setFieldValue("cursos", event)
                            }
                          />

                          <ErrorMessage name="expositor">
                            {(msg) => <div className="error-input">{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div className="p-col-12">
                          <label>Perfil</label>
                          <Select
                            isMulti
                            components={animatedComponents}
                            name="perfil"
                            options={perfilOpcoes}
                            placeholder="Selecione"
                            value={props.values?.perfil}
                            onChange={(event) =>
                              props.setFieldValue("perfil", event)
                            }
                          />

                          <ErrorMessage name="expositor">
                            {(msg) => <div className="error-input">{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div className="p-col-3">
                          <label>Status</label>
                          <Select
                            components={animatedComponents}
                            name="status"
                            options={statusOpcoes}
                            placeholder="Selecione"
                            value={props.values?.status}
                            onChange={(event) =>
                              props.setFieldValue("status", event)
                            }
                          />

                          <ErrorMessage name="expositor">
                            {(msg) => <div className="error-input">{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                          className="p-col-12 p-md-3 p-lg-3"
                        >
                          <label>
                            Data de início{" "}
                            <span className="required-hash">*</span>
                          </label>
                          <CalendarDateInput
                            name="data_inicio"
                            className="input"
                            selected={props.values?.data_inicio}
                            onChange={(e) => {
                              props.setFieldValue("data_inicio", e);
                            }}
                            maxDate={props.values?.data_fim}
                          />
                        </div>

                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                          className="p-col-12 p-md-3 p-lg-3"
                        >
                          <label>
                            Data de fim <span className="required-hash">*</span>
                          </label>
                          <CalendarDateInput
                            name="data_fim"
                            className="input"
                            selected={props.values?.data_fim}
                            onChange={(e) => {
                              props.setFieldValue("data_fim", e);
                            }}
                            minDate={props.values?.data_inicio}
                          />
                        </div>
                      </div>

                      <div className="btns-inline">
                        <div className="btn-medium">
                          <ButtonSave
                            label="Gerar relatório"
                            type="submit"
                            loading={loading}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>

            <AlertMenssage />
          </div>
        </div>
      </div>
    </>
  );
}
