import BannerExpositorLanguageSerializer from "./banner-expositor.language.serializer";

export default class BannerExpositorSerializer {
  constructor() {
    this.bannerExpositorLanguageSerializer =
      new BannerExpositorLanguageSerializer();
  }

  fromJson(json) {
    const bannerExpositorFromJson = {};

    Object.assign(
      bannerExpositorFromJson,
      json.titulo && { titulo: json.titulo },
      json.idiomas && {
        idiomas: json.idiomas.map((item) =>
          this.bannerExpositorLanguageSerializer.fromJson(item)
        ),
      },
      json.id_expositor_banner && { id: json.id_expositor_banner },
      json.disabled && { disabled: json.disabled }
    );

    return bannerExpositorFromJson;
  }

  toJson(bannerExpositor) {
    const bannerExpositorToJson = {};

    Object.assign(
      bannerExpositorToJson,
      bannerExpositor.edicaoAtual && { id_edicao: bannerExpositor.edicaoAtual },
      bannerExpositor.titulo && { titulo: bannerExpositor.titulo },
      bannerExpositor.idiomas && {
        idiomas: bannerExpositor.idiomas.map((item) =>
          this.bannerExpositorLanguageSerializer.toJson(item)
        ),
      }
    );

    if (bannerExpositor.disabled) {
      bannerExpositorToJson.disabled = true;
    } else {
      bannerExpositorToJson.disabled = false;
    }

    return bannerExpositorToJson;
  }

  toFormData(file) {
    let formData = new FormData();
    formData.append("file", file[0]);

    return formData;
  }
}
