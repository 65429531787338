import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import CampoCustomizadoItem from "../../../service/campo-customizado-item.service";
import CampoCustomizado from "../../../service/campo-customizado.service";

import "./styles.scss";

export default function CampoCustomizadoItens() {
  const { id } = useParams();
  const [camposCustomizados, setCamposCustomizados] = useState([]);
  const [itens, setItens] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function init() {
      const _campoCustomizadoService = new CampoCustomizado();
      const campoCustomizadoResponse = await _campoCustomizadoService.list();

      campoCustomizadoResponse.forEach((campo) => {
        if (campo.idCampoCustomizado === parseInt(id)) {
          setCamposCustomizados(campo);
        }
      });

      const _campoCustomizadoItemService = new CampoCustomizadoItem();
      const campoCustomizadoItemResponse =
        await _campoCustomizadoItemService.listSub(id);
      setItens(campoCustomizadoItemResponse);
    }

    init();
  }, [id]);

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <div className="p-grid">
            <div className="p-col-9 p-lg-9 p-md-9 p-sm-9">
              <h1 className="h1-container">{camposCustomizados?.campo}</h1>
            </div>
          </div>
          <hr className="hr-header" />

          <div className="p-grid">
            {itens.map((item) => (
              <div
                key={item.idCampoItemCustomizado}
                className="p-col-12 p-lg-12 p-md-12 p-sm-12 campo-item-card-container"
                onClick={() =>
                  history.push(
                    `/campos-customizados/itens/${item.idCampoItemCustomizado}`
                  )
                }
              >
                <h2>{item.idiomas[0].item}</h2>
              </div>
            ))}

            <div className="btns-inline">
              <div className="btn-medium">
                <button
                  className="button-cancel"
                  onClick={() => {
                    history.push(`/campos-customizados`);
                  }}
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
