import * as Yup from "yup";
import * as messages from "./messages";

export let failedValidation = false;

export const cursoModuloSchema = Yup.object().shape({
  idiomas: Yup.array().of(
    Yup.object().shape({
      nome: Yup.string().test(
        "nome",
        messages.REQUIRED,
        function (value) {
          if (this && this.parent) {
            if (this.parent.idIdioma === 1) {
              failedValidation = value;
              return value;
            } else if (!this.parent.nome && this.parent.link) {
              failedValidation = false;
              return false;
            }
          }
          return true;
        }
      ),
    })
  ),
});
