import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AlertMenssage from "../../components/AlertMenssage";
import ExpositorMenu from "../../components/ExpositorMenu";
import ListTableLazy from "../../components/ListTableLazy";
import AlertService from "../../service/alert.service";
import EditionService from "../../service/edition.service";
import ExpositoresService from "../../service/expositores.service";
import ModeracaoVideosService from "../../service/video-moderation.service";
import QueryOptions from "../../utils/query-options";

export default function ExhibitorVideos({ location }) {
  const { idExpositor } = useParams();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);
  const [first, setFirst] = useState(0);
  const [expositor, setExpositor] = useState();

  const columns = [
    {
      field: `idiomas.${[0]}.titulo`,
      header: "Titulo",
    },
    {
      field: `idiomas.${[0]}.descricao`,
      header: "Descricão",
    },
    {
      field: `idiomas.${[0]}.link`,
      header: "Link",
    },
  ];

  useEffect(() => {
    async function listVideoModeration() {
      const _videoModerationService = new ModeracaoVideosService();
      const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);

      const _edicaoService = new EditionService();
      const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
        queryOptionsEdicaoAtiva
      );

      setLoading(true);

      const queryOptions = new QueryOptions([
        ["fields", "id_moderacao_video, id_expositor, idiomas"],
        ["page", page],
        ["edicao", edicaoAtual?.id],
      ]);

      const data = await _videoModerationService.listSub(
        idExpositor,
        queryOptions,
        true
      );

      const queryStringObj = queryString.parse(location.search);

      if (queryStringObj) {
        if (queryStringObj.acao === "criado") {
          AlertService.success("Vídeo adicionado com sucesso");
        } else if (queryStringObj.acao === "deletado") {
          AlertService.success("Vídeo deletado com sucecsso");
        }
      }

      const _expositoresService = new ExpositoresService();
      const expositor = await _expositoresService.read(idExpositor);
      setExpositor(expositor);

      setLoading(false);
      setVideos(data.items);
      setMeta(data.meta);
    }
    listVideoModeration();
  }, [page, idExpositor, location]);

  function handleChangePage(event) {
    setFirst(event.first);
    setPage(event.page + 1);
  }

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <ExpositorMenu activeItem={4} expositor={expositor} />

        <div className="card">
          <h1>Vídeos</h1>
          <hr classname="hr-header" />
          <AlertMenssage />
          <ListTableLazy
            cols={columns}
            values={videos}
            setValues={setVideos}
            firstIdx={first}
            meta={meta}
            loading={loading}
            endpoint="videos"
            deleteAllEndPoint="moderacao-videos"
            parentEndPoint="expositores"
            parentId={idExpositor}
            onChangePage={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
}
