import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import AlertMenssage from "../../../components/AlertMenssage";
import ButtonSave from "../../../components/ButtonSave";
import ConfirmationDialog from "../../../components/dialogs/confirmation-dialog";
import ExpositorMenu from "../../../components/ExpositorMenu";
import ProporcaoImagemDialogo from "../../../components/ProporcaoImagemDialogo";
import TextInput from "../../../components/TextInput";
import AlertService from "../../../service/alert.service";
import avertisingdAreaService from "../../../service/avertisingd-area.service";
import BannerExpositorService from "../../../service/banner-expositor.service";
import EditionService from "../../../service/edition.service";
import ExpositoresService from "../../../service/expositores.service";
import LanguagesService from "../../../service/language.service";
import QueryOptions from "../../../utils/query-options";
import { bannerValidation } from "../../../validations/banner.validation";
import AdvertisingExpositorImage from "../BannerExpositorImage/index";

export const bannerIdiomas = {};

export default function BannerExpositorForm() {
  const { id } = useParams();
  const { idExpositor } = useParams();
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState("edit");
  const [currentLanguage, setCurrentLanguage] = useState(1);
  const [languages, setLanguages] = useState([]);
  const history = useHistory();
  const [disabledAdvertising, setDisabledAdvertising] = useState();
  const [expositor, setExpositor] = useState();
  const [loadingInfo, setLoadingInfo] = useState(true);

  const [, setAvertisingdArea] = useState([]);
  const [bannerValues, setBannerValues] = useState({
    titulo: "",
    idiomas: [bannerIdiomas],
  });
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);
  const [displayConfirmationDialogEdit, setDisplayConfirmationDialogEdit] =
    useState(false);

  const _bannersExpositorService = new BannerExpositorService();

  useEffect(
    (_bannersExpositorService = new BannerExpositorService(), banner) => {
      async function getBannerValues(languages) {
        setAction("edit");
        const banner = await _bannersExpositorService.readBannerExpositor(id);

        let idiomas = [];
        languages.forEach((element) => {
          idiomas.push({
            ...bannerIdiomas,
            idIdioma: element.id,
          });
        });

        const idIdiomas = [];
        banner.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));
        const idiomasFaltantes = [];
        idiomas.forEach((item) => {
          if (!idIdiomas.includes(item.idIdioma)) {
            idiomasFaltantes.push(item);
          }
        });

        const allLanguages = [...banner.idiomas, ...idiomasFaltantes].sort(
          (v1, v2) => v1.idIdioma - v2.idIdioma
        );
        setBannerValues({ ...banner, idiomas: allLanguages });

        if (banner.disabled === true) {
          setDisabledAdvertising(true);
        } else {
          setDisabledAdvertising(false);
        }
        setLoadingInfo(false);
      }

      async function init() {
        const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
        const _edicaoService = new EditionService();
        const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
          queryOptionsEdicaoAtiva
        );

        const _expositoresService = new ExpositoresService();
        const expositor = await _expositoresService.read(idExpositor);
        setExpositor(expositor);

        const _languagesService = new LanguagesService();
        const languages = await _languagesService.list();

        let idiomas = [];
        languages.forEach((element) => {
          idiomas.push({
            ...bannerIdiomas,
            idIdioma: element.id,
          });
        });
        setBannerValues({
          ...bannerValues,
          idiomas,
          edicaoAtual: edicaoAtual?.id,
        });
        setLanguages(languages);

        const _avertisingdAreaService = new avertisingdAreaService();
        const response = await _avertisingdAreaService.list();
        setAvertisingdArea(response);

        if (id) {
          await getBannerValues(languages);
        } else {
          setAction("create");
          setLoadingInfo(false);
        }
      }
      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, bannerIdiomas]
  );

  async function createBannerValues(values) {
    if (values.idiomas) {
      values.idiomas.forEach((item) => {
        if (item.link) {
          if (item.link.includes("https://") || item.link.includes("http://")) {
            return;
          } else {
            item.link = `https://${item.link}`;
          }
        }
      });
    }

    values.parentId = idExpositor;

    setLoading(true);
    if (action === "create") {
      const data = { ...values };

      await _bannersExpositorService
        .createSub(data)
        .then((response) => {
          history.push(
            `/expositores/${idExpositor}/banner-expositor/editar/${response.id}`
          );
        })
        .catch(({ response }) => {
          AlertService.warn(response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const data = { ...values };

      await _bannersExpositorService
        .updateBannerExpositor(data)
        .then((response) => {
          let idiomas = [];
          languages.forEach((element) => {
            idiomas.push({
              ...bannerIdiomas,
              idIdioma: element.id,
            });
          });

          const idIdiomas = [];
          response.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

          const idiomasFaltantes = [];
          idiomas.forEach((item) => {
            if (!idIdiomas.includes(item.idIdioma)) {
              idiomasFaltantes.push(item);
            }
          });

          const allLanguages = [...response.idiomas, ...idiomasFaltantes].sort(
            (v1, v2) => v1.idIdioma - v2.idIdioma
          );

          setBannerValues({ ...response, idiomas: allLanguages });

          AlertService.success("Banner atualizado com sucesso");
        })
        .catch(({ response }) => AlertService.warn(response.data.msg))
        .finally(() => {
          setLoading(false);
        });
    }
  }

  async function disableBanner() {
    if (!disabledAdvertising) {
      await _bannersExpositorService
        .updateBannerExpositor({ ...bannerValues, disabled: true })
        .then(() => {
          setDisplayConfirmationDialogEdit(false);
          window.location.reload();
        })
        .catch(({ response }) => {
          AlertService.warn(response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await _bannersExpositorService
        .updateBannerExpositor({ ...bannerValues, disabled: false })
        .then(() => {
          setDisplayConfirmationDialogEdit(false);
          window.location.reload();
        })
        .catch(({ response }) => {
          AlertService.warn(response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function selectLanguage(item) {
    setCurrentLanguage(item.id);
  }

  async function handleDelete() {
    await _bannersExpositorService.deleteBannerExpositor(id);
    history.push(
      `/expositores/editar/${idExpositor}/banner-expositor?acao=deletado`
    );
    setDisplayConfirmationDialog(false);
  }

  return (
    <>
      <ConfirmationDialog
        header={
          disabledAdvertising
            ? "Ativar esse banner o tornará visível nas respectivas seções da plataforma Expo Revestir"
            : "Inativar esse banner fará com que ela não apareça em nenhuma seção da plataforma Expo Revestir"
        }
        action={
          disabledAdvertising
            ? "Deseja realmente ativar esse banner?"
            : "Deseja realmente inativar esse banner?"
        }
        display={displayConfirmationDialogEdit}
        onClose={() => setDisplayConfirmationDialogEdit(false)}
        onConfirm={disableBanner}
      />

      <div className="p-grid">
        <ConfirmationDialog
          header="Excluir esse item vai apagar todas as informações"
          action="Excluir mesmo assim?"
          display={displayConfirmationDialog}
          onClose={() => setDisplayConfirmationDialog(false)}
          onConfirm={handleDelete}
        />

        {loadingInfo && (
          <div className="loading-info-container">
            <lottie-player
              src="https://assets4.lottiefiles.com/packages/lf20_58vkk48j.json"
              background="transparent"
              speed="1"
              style={{ width: "500px" }}
              loop
              autoplay
            />
          </div>
        )}

        <div className="p-col-12" hidden={loadingInfo}>
          <ExpositorMenu activeItem={6} expositor={expositor} />

          <div className="card">
            <h1 className="title-page">Banner</h1>
            <hr classname="hr-header" />
            {action === "edit" &&
              (disabledAdvertising ? (
                <h3 style={{ color: "red" }}>Banner Inativo</h3>
              ) : (
                <h3 style={{ color: "#009974" }}>Banner Ativo</h3>
              ))}
            <Formik
              enableReinitialize={true}
              validationSchema={bannerValidation}
              initialValues={bannerValues}
              onSubmit={(values) => {
                createBannerValues(values);
              }}
            >
              {(props) => {
                return (
                  <Form autoComplete="off">
                    <div
                      className="wrapper-form"
                      style={{ paddingLeft: "0.5em" }}
                    >
                      <div className="p-grid">
                        {languages.map((item, index) => (
                          <div key={index} className="language-dashboard">
                            <button
                              className={
                                item.id === currentLanguage
                                  ? "p-button-dash"
                                  : "p-button-teste"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                selectLanguage(item);
                              }}
                            >
                              {item.nome}
                            </button>
                          </div>
                        ))}
                      </div>

                      <div className="p-grid">
                        <FieldArray
                          name="idiomas"
                          render={(arrayHelpers) => (
                            <div className="p-grid p-col-12 p-md-7 p-lg-7 ">
                              <div className="p-col-12">
                                <TextInput
                                  name="titulo"
                                  className="input"
                                  label="Título"
                                  quantidadeCaracteres={50}
                                  maxLength={50}
                                  required={true}
                                />
                              </div>

                              {props?.values?.idiomas?.map((item, index) => (
                                <div
                                  className="p-col-12"
                                  key={index}
                                  hidden={!(item.idIdioma === currentLanguage)}
                                >
                                  <TextInput
                                    name={`idiomas[${index}].link`}
                                    className="input"
                                    label="Link"
                                  />

                                  <div className="p-grid ">
                                    {props.values.id && (
                                      <>
                                        <div className="p-col-12 p-md-6 p-lg-6">
                                          <div>
                                            <p>
                                              <ProporcaoImagemDialogo
                                                isJustDesktop={true}
                                                widthDesktop={1920}
                                                heightDesktop={620}
                                              />
                                            </p>
                                          </div>
                                          <AdvertisingExpositorImage
                                            languages={languages}
                                            idExpositorBannerIdioma={
                                              item.idExpositorBannerIdioma
                                            }
                                            chave={"imageFilename"}
                                            form={props}
                                            index={index}
                                            width={1920}
                                            height={620}
                                          />
                                        </div>
                                        <div className="p-col-12 p-md-6 p-lg-6">
                                          <div>
                                            <p>
                                              <ProporcaoImagemDialogo
                                                isJustMobile={true}
                                                widthMobile={428}
                                                heightMobile={625}
                                              />
                                            </p>
                                          </div>
                                          <AdvertisingExpositorImage
                                            languages={languages}
                                            idExpositorBannerIdioma={
                                              item.idExpositorBannerIdioma
                                            }
                                            form={props}
                                            index={index}
                                            mobile={true}
                                            chave={"imageFilenameMobile"}
                                            width={428}
                                            height={625}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="btns-inline">
                      <div className="btn-medium">
                        <ButtonSave
                          label={
                            action === "create"
                              ? "Enviar uma imagem"
                              : "Atualizar"
                          }
                          loading={loading}
                          type="submit"
                          onClick={() => {
                            if (Object.keys(props.errors).length) {
                              AlertService.warn(
                                "Verifique se todos os campos obrigatórios foram preenchidos corretamente"
                              );
                            }
                          }}
                        />
                      </div>

                      <div className="btn-medium">
                        {action === "edit" && (
                          <div
                            className="btns-inline"
                            style={{ justifyContent: "space-evenly" }}
                          >
                            <div className="btn-medium">
                              <button
                                className="button-disable"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setDisplayConfirmationDialogEdit(true);
                                }}
                              >
                                {disabledAdvertising ? "Ativar" : "Desativar"}
                              </button>
                            </div>

                            <button
                              className="button-delete"
                              styles="p-button-secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                setDisplayConfirmationDialog(true);
                              }}
                            >
                              Excluir
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="btn-medium">
                        <button
                          className="button-cancel"
                          onClick={() => {
                            history.push(
                              `/expositores/editar/${idExpositor}/banner-expositor`
                            );
                          }}
                        >
                          Voltar
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <AlertMenssage />
          </div>
        </div>
      </div>
    </>
  );
}
