import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import Imagem from "../assets/icons/questionmark.svg";

export default function ModalExplicativo({
  header,
  content,
  imagemExplicativa,
  imagemExplicativaAlt,
}) {
  const [displayBasic, setDisplayBasic] = useState(false);

  return (
    <>
      <div>
        <img
          alt="Ícone para abrir um modal explicativo"
          src={Imagem}
          onClick={() => {
            setDisplayBasic(true);
          }}
          className="icone-modal-explicativo"
        />
      </div>

      <Dialog
        header={header}
        visible={displayBasic}
        style={{ width: "50vw", backgroundColor: "#f8eae5" }}
        onHide={() => {
          setDisplayBasic(false);
        }}
      >
        <p>{content}</p>

        {imagemExplicativa && (
          <div style={{ margin: "0 auto" }}>
            <div style={{ textAlign: "center" }}>
              <img src={imagemExplicativa} alt={imagemExplicativaAlt}></img>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
}
