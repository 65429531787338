import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import AlertMenssage from "../../../components/AlertMenssage";
import ButtonSave from "../../../components/ButtonSave";
import ConfirmationDialog from "../../../components/dialogs/confirmation-dialog";
import EnderecoForm, {
  enderecoFields,
} from "../../../components/form/EnderecoForm";
import SexoService from "../../../service/sexo.service";
import TelefoneForm, {
  telefoneFields,
} from "../../../components/form/PhoneForm";
import ImageInputPreview from "../../../components/ImageInputPreview";
import InputCpf from "../../../components/InputCpf";
import TextInput from "../../../components/TextInput";
import AlertService from "../../../service/alert.service";
import Authentication from "../../../service/authentication.service";
import CargoService from "../../../service/cargo.service";
import ExpositoresService from "../../../service/expositores.service";
import PerfilEmpresaService from "../../../service/perfil-empresa.service";
import UserProfileService from "../../../service/user-profile.service";
import UserService from "../../../service/user.service";
import { AppContext } from "../../../store/index";
import imageProfileDefault from "../../../utils/image-profile-default";
import { userHasAccess, userProfiles } from "../../../utils/permission-user";
import { userUpdateSchema } from "../../../validations/user-update.validation";
import { userSchema } from "../../../validations/user.validation";
import UsuarioMenu from "../UsuarioMenu";
import unMask from "../../../utils/unmask";
import QueryOptions from "../../../utils/query-options";
import "./styles.scss";
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from "react-maskedinput";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

export default function UserForm() {
  registerLocale("ptBR", ptBR);

  const { id } = useParams();
  const match = useRouteMatch();
  const [time] = useState(new Date().getTime());
  const [disabledUser, setDisabledUser] = useState();
  const [formularioUsuario, setFormularioUsuario] = useState();
  const [imageProfile, setImageProfile] = useState(imageProfileDefault());
  const [optionsProfile, setOptionsProfile] = useState([]);
  const [opcoesCargo, setOpcoesCargo] = useState([]);
  const [mostrarValidacaoSenha, setMostrarValidacaoSenha] = useState(false);
  const [opcoesGenero, setOpcoesGenero] = useState([]);
  const [expositor, setExpositor] = useState([]);
  const animatedComponents = makeAnimated();
  const [imageFile, setImageFile] = useState();
  const [action, setAction] = useState("edit");
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [opcoesPerfisEmpresa, setOpcoesPerfisEmpresa] = useState();
  const [loading, setLoading] = useState(false);
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);
  const [displayConfirmationDialogEdit, setDisplayConfirmationDialogEdit] =
    useState(false);
  const [displayMudarFoto, setDisplayMudarFoto] = useState(false);
  const history = useHistory();
  const _userService = new UserService();
  const [isPerfil, setIsPerfil] = useState(false);
  const { user } = useContext(AppContext);
  const [displayConfirmationRemoverFoto, setDisplayConfirmationRemoverFoto] =
    useState(false);

  const [validationPass, setValidationPass] = useState({
    numero: false,
    letraMinuscula: false,
    letraMaiuscula: false,
    caracterEspecial: false,
    length: false,
  });

  useEffect(
    (_userService = new UserService()) => {
      const baseUrl = process.env.REACT_APP_API_URL;
      function getImageProfile(user) {
        if (user.imageFileName) {
          setDisplayMudarFoto(true);
        }

        return user.imageFileName
          ? `${baseUrl}usuarios/${user.id}/display?${time}`
          : imageProfileDefault();
      }

      async function getUser(expositores) {
        const user = await _userService.read(id);
        const imageProfile = getImageProfile(user);
        user.profile = user.profile?.value;

        if (Authentication._user.id === id) {
          setIsPerfil(true);
        } else {
          setIsPerfil(false);
        }

        if (user.disabled === true) {
          setDisabledUser(true);
        } else {
          setDisabledUser(false);
        }

        if (!user.adresses.length) {
          user.adresses.push(enderecoFields);
        }

        if (!user.phones.length) {
          user.phones.push(telefoneFields);
        }

        if (user.expositores && user.expositores.length) {
          user.expositores = expositores.filter((item) =>
            user.expositores.includes(item.id)
          );
        }

        setFormularioUsuario(user);
        setImageProfile(imageProfile);
      }

      async function getProfile() {
        const user = await _userService.getProfile();
        const imageProfile = getImageProfile(user);
        setFormularioUsuario(user);
        setAction("perfil");
        setImageProfile(imageProfile);
      }

      async function init() {
        const _cargoService = new CargoService();
        const cargoResponse = await _cargoService.list();
        setOpcoesCargo(cargoResponse);

        const _sexoService = new SexoService();
        const sexoResponse = await _sexoService.list();
        setOpcoesGenero(sexoResponse);

        const _perfilEmpresaService = new PerfilEmpresaService();
        const perfilEmpresaResponse = await _perfilEmpresaService.list();
        setOpcoesPerfisEmpresa(perfilEmpresaResponse);

        if (userHasAccess([userProfiles.administrador])) {
          const _userProfileService = new UserProfileService();
          setOptionsProfile(await _userProfileService.list());
        } else if (userHasAccess([userProfiles.expositor])) {
          const _userProfileService = new UserProfileService();
          const listagem = await _userProfileService.list();
          const listagemFiltrada = listagem[1];
          setOptionsProfile([listagemFiltrada]);

          document.getElementById("input-perfil").style.display = "none";
        }

        const _expositoresService = new ExpositoresService();
        const expositorResponse = await _expositoresService.list(
          new QueryOptions([["per_page", 10000]])
        );
        setExpositor(expositorResponse);

        if (id) {
          getUser(expositorResponse);
        } else if (match.path.includes("perfil")) {
          getProfile();
        } else {
          setFormularioUsuario({
            name: "",
            cpf: "",
            email: "",
            confEmail: "",
            password: "",
            confirmPassword: "",
            phones: [telefoneFields],
            adresses: [enderecoFields],
            expositores: [],
            profile: "",
            perfilEmpresa: "",
            sexo: "",
            cargo: "",
            dataNascimento: "",
          });

          setAction("create");
        }
        setLoadingInfo(false);
      }

      init();
    },
    [id, match.path, disabledUser, time]
  );

  async function onSubmit(values) {
    setLoading(true);

    const data = { ...values };

    const telefonesValidosUsuario = data.phones.filter(
      (item) => unMask(item.ddi) && unMask(item.ddd) && unMask(item.phone)
    );
    data.phones = telefonesValidosUsuario;

    const enderecosValidosUsuarios = data.adresses.filter(
      (item) => item.address
    );
    data.adresses = enderecosValidosUsuarios;

    if (action === "create") {
      if (userHasAccess([userProfiles.expositor])) {
        data.profile = {
          id: 2,
        };
        data.expositores = {
          value: user.expositores.value,
        };

        await _userService
          .create(data)
          .then(() => {
            history.push("/usuarios?acao=criado");
          })
          .catch(({ response }) => {
            AlertService.warn(response.data.msg);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        await _userService
          .create(data)
          .then(() => {
            history.push("/usuarios?acao=criado");
          })
          .catch(({ response }) => {
            AlertService.warn(response.data.msg);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      let usuario = null;

      await _userService
        .update(data)
        .then((response) => {
          usuario = response;
        })
        .catch(({ response }) => {
          AlertService.warn(response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });

      if (usuario) {
        if (imageFile) {
          await _userService.uploadImage(imageFile, values.id);
        }

        if (action === "profile") {
          Authentication._user = usuario;
        }

        AlertService.success("Usuário atualizado");
      }
    }
  }

  async function disableUser() {
    await _userService
      .update({ id, disabled: !disabledUser })
      .then(() => {
        setDisplayConfirmationDialogEdit(false);
        window.location.reload();
      })
      .catch(({ response }) => {
        AlertService.warn(response.data.msg);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function removerFotoPerfil() {
    await _userService.removerImagemPerfil(id).then(() => {
      setDisplayConfirmationRemoverFoto(false);
      setImageProfile(imageProfileDefault());
    });
  }

  function handleChangeImageFile(imageFile) {
    setImageFile(imageFile);
  }

  async function handleDelete() {
    await _userService.delete(id);
    history.push("/usuarios?acao=deletado");
    setDisplayConfirmationDialog(false);
  }

  var permissionEdit = true;

  function validPass(event) {
    const value = event.target.value;

    const numero = /^(?=.*\d)/.test(value);
    const letraMinuscula = /(?=.*[a-z])/.test(value);
    const letraMaiuscula = /(?=.*[A-Z])/.test(value);
    const caracterEspecial = /(?=.*[!@#$%<^&*?=])/.test(value);
    const length = value.length >= 6;

    setValidationPass({
      numero,
      letraMinuscula,
      letraMaiuscula,
      caracterEspecial,
      length,
    });
  }

  return (
    <>
      <ConfirmationDialog
        header={
          disabledUser
            ? "Ativar o usuário vai permitir seu acesso à plataforma."
            : "Inativar o usuário vai deixá-lo sem acesso à plataforma."
        }
        action={
          disabledUser
            ? "Deseja realmente ativar esse usuário?"
            : "Deseja realmente desativar esse usuário?"
        }
        display={displayConfirmationDialogEdit}
        onClose={() => setDisplayConfirmationDialogEdit(false)}
        onConfirm={disableUser}
      />

      <ConfirmationDialog
        header="Excluir esse usuário vai apagar todas as suas informações."
        action="Excluir mesmo assim?"
        display={displayConfirmationDialog}
        onClose={() => setDisplayConfirmationDialog(false)}
        onConfirm={handleDelete}
      />

      <ConfirmationDialog
        header="A foto será removida de forma instanânea. Essa ação não pode ser desfeita"
        action="Excluir mesmo assim?"
        display={displayConfirmationRemoverFoto}
        onClose={() => setDisplayConfirmationRemoverFoto(false)}
        onConfirm={removerFotoPerfil}
      />

      <div id="page-profile" className="p-grid">
        {loadingInfo && (
          <div className="loading-info-container">
            <lottie-player
              src="https://assets4.lottiefiles.com/packages/lf20_58vkk48j.json"
              background="transparent"
              speed="1"
              style={{ width: "500px" }}
              loop
              autoplay
            />
          </div>
        )}

        <div className="p-col-12" hidden={loadingInfo}>
          <UsuarioMenu activeItem={0} />
          <div className="card">
            <h1 className="title-page">
              {action === "edit"
                ? [isPerfil ? "Editar perfil" : "Editar usuário"]
                : "Adicionar usuário"}
            </h1>
            <hr />
            <Formik
              enableReinitialize={true}
              initialValues={formularioUsuario}
              validationSchema={
                action === "create" ? userSchema : userUpdateSchema
              }
              onSubmit={(values) => {
                onSubmit(values);
              }}
            >
              {(props) => {
                return (
                  <Form className="form" autoComplete="off">
                    <div className="box">
                      <div className="flex-user-form">
                        <div className="flex-box-profile">
                          <div className="box-profile">
                            <ImageInputPreview
                              image={imageProfile}
                              changeImage={(e) => handleChangeImageFile(e)}
                            />
                          </div>

                          {displayMudarFoto && (
                            <div className="remover-imagem-container">
                              <h4
                                onClick={() => {
                                  setDisplayConfirmationRemoverFoto(true);
                                }}
                              >
                                Remover foto atual
                              </h4>
                            </div>
                          )}
                        </div>

                        <div className="flex-user-info">
                          <div className="p-col-12 p-lg-12 title-name">
                            {action === "edit" &&
                              (disabledUser ? (
                                <h3 style={{ color: "red" }}>
                                  Usuário Inativo
                                </h3>
                              ) : (
                                <h3 style={{ color: "#009974" }}>
                                  Usuário Ativo
                                </h3>
                              ))}

                            {!id && action !== "edit" && <h3>Usuário</h3>}
                          </div>

                          <div className="p-grid">
                            <div
                              className="p-col-12 p-md-6 p-lg-2"
                              id="input-perfil"
                            >
                              <label>
                                Perfil <span className="required-hash">*</span>
                              </label>
                              <Dropdown
                                disabled={
                                  Authentication._user.id === id ? true : false
                                }
                                value={props.values?.profile}
                                className="full"
                                name="profile"
                                options={optionsProfile}
                                onChange={(e) => {
                                  props.setFieldValue("profile", e.value);
                                }}
                              />
                              <ErrorMessage name="profile">
                                {(msg) => (
                                  <div className="error-input">{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                            <div className="p-col-12 p-md-6 p-lg-2">
                              <label>
                                CPF <span className="required-hash">*</span>
                              </label>

                              <InputCpf
                                placeholder="___.___.___-__"
                                name="cpf"
                                disabled={!permissionEdit}
                                className="input"
                              />
                            </div>

                            <div className="p-col-12 p-md-12 p-lg-8">
                              <label>
                                Nome Completo{" "}
                                <span className="required-hash">*</span>
                              </label>
                              <TextInput
                                name="name"
                                placeholder="Nome completo"
                                disabled={!permissionEdit}
                                className="input"
                              />
                            </div>
                          </div>

                          <div className="p-grid">
                            <div className="p-col-12 p-md-12 p-lg-3">
                              <label>
                                E-mail <span className="required-hash">*</span>
                              </label>
                              <TextInput
                                name="email"
                                placeholder="E-mail"
                                autoComplete="234"
                                disabled={!permissionEdit}
                                className="input"
                              />
                            </div>

                            <div className="p-col-12 p-md-6 p-lg-3">
                              <label>
                                Confirme o e-mail{" "}
                                <span className="required-hash">*</span>
                              </label>
                              <TextInput
                                placeholder="Confirmar e-mail"
                                name="confEmail"
                                disabled={!permissionEdit}
                                className="input"
                              />
                            </div>

                            <div className="p-col-12 p-lg-3 p-md-3 p-sm-6">
                              <label>
                                Cargo{" "}
                                {props.values?.profile?.id === 3 && (
                                  <span className="required-hash">*</span>
                                )}
                              </label>
                              <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                name="cargo"
                                options={opcoesCargo}
                                placeholder="Selecione"
                                value={props.values?.cargo}
                                onChange={(event) =>
                                  props.setFieldValue("cargo", event)
                                }
                              />
                              <ErrorMessage name="cargo">
                                {(msg) => (
                                  <div className="error-input">{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>

                            <div className="p-col-12 p-lg-3 p-md-3 p-sm-6">
                              <label>
                                Perfil da empresa
                                {props.values?.profile?.id === 3 && (
                                  <span className="required-hash">*</span>
                                )}
                              </label>

                              <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                name="perfilEmpresa"
                                options={opcoesPerfisEmpresa}
                                placeholder="Selecione"
                                value={props.values?.perfilEmpresa}
                                onChange={(event) =>
                                  props.setFieldValue("perfilEmpresa", event)
                                }
                              />
                              <ErrorMessage name="perfilEmpresa">
                                {(msg) => (
                                  <div className="error-input">{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>

                            <div className="p-col-12 p-lg-3 p-md-3 p-sm-6">
                              <label>
                                Gênero{" "}
                                {props.values?.profile?.id === 3 && (
                                  <span className="required-hash">*</span>
                                )}
                              </label>
                              <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                name="sexo"
                                options={opcoesGenero}
                                placeholder="Selecione"
                                value={props.values?.sexo}
                                onChange={(event) =>
                                  props.setFieldValue("sexo", event)
                                }
                              />
                              <ErrorMessage name="sexo">
                                {(msg) => (
                                  <div className="error-input">{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              className="p-col-12 p-md-4 p-lg-2"
                            >
                              <label>
                                Data de nascimento
                                {props.values?.profile?.id === 3 && (
                                  <span className="required-hash">*</span>
                                )}
                              </label>

                              <DatePicker
                                name="dataNascimento"
                                locale="ptBR"
                                customInput={
                                  <MaskedInput
                                    mask="11/11/1111"
                                    placeholder="dd/MM/yyyy"
                                  />
                                }
                                onChange={(e) => {
                                  props.setFieldValue("dataNascimento", e);
                                }}
                                selected={props.values?.dataNascimento}
                                className="input"
                                dateFormat="dd/MM/yyyy"
                              />

                              <ErrorMessage name="dataNascimento">
                                {(msg) => (
                                  <div className="error-input">{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>

                            {props?.values?.profile?.id !== 3 && (
                              <>
                                <div className="p-col-12 p-md-6 p-lg-3">
                                  <label>
                                    Senha{" "}
                                    {action === "create" && (
                                      <span className="required-hash">*</span>
                                    )}
                                  </label>
                                  <TextInput
                                    name="password"
                                    placeholder="Senha"
                                    type="password"
                                    onKeyUp={validPass}
                                    autoComplete="new-password"
                                    disabled={!permissionEdit}
                                    className="input"
                                    onFocus={() =>
                                      setMostrarValidacaoSenha(true)
                                    }
                                    onBlur={(e) => {
                                      setMostrarValidacaoSenha(false);
                                      props.handleBlur(e);
                                    }}
                                  />

                                  {mostrarValidacaoSenha && (
                                    <div className="info-pass">
                                      <div className="title-list">
                                        <strong>Senha deve conter:</strong>
                                      </div>
                                      <div>
                                        <div
                                          className={
                                            validationPass.numero
                                              ? "check-success"
                                              : undefined
                                          }
                                        >
                                          <div className="check">
                                            {!validationPass.numero && (
                                              <span className="check-text">
                                                -
                                              </span>
                                            )}
                                            {validationPass.numero && (
                                              <i className="pi pi-check"></i>
                                            )}
                                          </div>
                                          Mínimo 1 número
                                        </div>
                                        <div
                                          className={
                                            validationPass.letraMinuscula &&
                                            "check-success"
                                          }
                                        >
                                          <div className="check">
                                            {!validationPass.letraMinuscula && (
                                              <span className="check-text">
                                                -
                                              </span>
                                            )}
                                            {validationPass.letraMinuscula && (
                                              <i className="pi pi-check"></i>
                                            )}
                                          </div>
                                          Mínimo 1 letra minúscula
                                        </div>
                                        <div
                                          className={
                                            validationPass.letraMaiuscula &&
                                            "check-success"
                                          }
                                        >
                                          <div className="check">
                                            {!validationPass.letraMaiuscula && (
                                              <span className="check-text">
                                                -
                                              </span>
                                            )}
                                            {validationPass.letraMaiuscula && (
                                              <i className="pi pi-check"></i>
                                            )}
                                          </div>
                                          Mínimo 1 letra maiúscula
                                        </div>
                                        <div
                                          className={
                                            validationPass.caracterEspecial &&
                                            "check-success"
                                          }
                                        >
                                          <div className="check">
                                            {!validationPass.caracterEspecial && (
                                              <span className="check-text">
                                                -
                                              </span>
                                            )}
                                            {validationPass.caracterEspecial && (
                                              <i className="pi pi-check"></i>
                                            )}
                                          </div>
                                          Mínimo 1 caractere especial{" "}
                                          {"(!@#$%<^&*?=)"}
                                        </div>
                                        <div
                                          className={
                                            validationPass.length &&
                                            "check-success"
                                          }
                                        >
                                          <div className="check">
                                            {!validationPass.length && (
                                              <span className="check-text">
                                                -
                                              </span>
                                            )}
                                            {validationPass.length && (
                                              <i className="pi pi-check"></i>
                                            )}
                                          </div>
                                          Mínimo 6 caracteres
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="p-col-12 p-md-6 p-lg-3">
                                  <label>
                                    Confirme a senha
                                    {action === "create" && (
                                      <span className="required-hash">*</span>
                                    )}{" "}
                                  </label>
                                  <TextInput
                                    placeholder="Confirmar senha"
                                    name="confirmPassword"
                                    type="password"
                                    disabled={!permissionEdit}
                                    className="input"
                                  />
                                </div>
                              </>
                            )}

                            {action === "edit" && (
                              <div className="p-col-12 p-lg-3 p-md-4 p-sm-6">
                                <label>Nacionalidade</label>
                                <TextInput
                                  type="text"
                                  name="nacionalidade"
                                  className="input"
                                  disabled={true}
                                />
                              </div>
                            )}

                            {props.values?.profile?.id === 2 &&
                              userHasAccess([userProfiles.administrador]) && (
                                <div className="p-col-12 p-lg-3 p-md-4 p-sm-6">
                                  <label>
                                    Expositor{" "}
                                    <span className="required-hash">*</span>
                                  </label>
                                  <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    name="expositores"
                                    options={expositor}
                                    placeholder="Selecione"
                                    value={props.values?.expositores}
                                    onChange={(event) =>
                                      props.setFieldValue("expositores", event)
                                    }
                                  />

                                  <ErrorMessage name="expositores">
                                    {(msg) => (
                                      <div className="error-input">{msg}</div>
                                    )}
                                  </ErrorMessage>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <hr style={{ opacity: "30%", marginTop: "28px" }} />
                      <div className="flex-adress-and-phone">
                        <div className="adress-field">
                          <div className="p-grid">
                            <div className="p-col-12">
                              <h2>Endereço</h2>
                            </div>
                            <FieldArray
                              name="adresses"
                              render={(arrayHelpers) => (
                                <div className="p-col-12">
                                  {props?.values?.adresses?.map(
                                    (address, index) => (
                                      <div key={index}>
                                        <EnderecoForm
                                          form={props}
                                          index={index}
                                          arrayHelpers={arrayHelpers}
                                          address={address}
                                          requiredHashInFields={
                                            props.errors?.adresses
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    )
                                  )}
                                  <div
                                    className="add-item"
                                    onClick={() =>
                                      arrayHelpers.insert(
                                        props?.values?.adresses?.length + 1,
                                        enderecoFields
                                      )
                                    }
                                  >
                                    Adicionar um novo endereço
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>

                        <div className="phone-field">
                          <div className="p-grid">
                            <div className="p-col-12">
                              <h2>Contatos</h2>
                            </div>
                            <FieldArray
                              name="phones"
                              render={(arrayHelpers) => (
                                <div className="p-col-12">
                                  {props?.values?.phones?.map(
                                    (phone, index) => (
                                      <div key={index}>
                                        <TelefoneForm
                                          parent="phones."
                                          arrayHelpers={arrayHelpers}
                                          index={index}
                                          phones={props.values.phones}
                                          formikProps={props}
                                          requiredHashInFields={
                                            props.errors?.phones ? true : false
                                          }
                                        />
                                      </div>
                                    )
                                  )}
                                  <div
                                    className="add-item"
                                    onClick={() =>
                                      arrayHelpers.insert(
                                        props?.values?.phones?.length + 1,
                                        telefoneFields
                                      )
                                    }
                                  >
                                    Adicionar um novo telefone
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="btns-inline">
                      {userHasAccess([userProfiles.expositor]) ? (
                        <>
                          {action === "create" && (
                            <div className="btn-medium">
                              <ButtonSave
                                label={
                                  action === "create"
                                    ? "Adicionar"
                                    : "Atualizar"
                                }
                                loading={loading ? 1 : 0}
                                type="submit"
                              />
                            </div>
                          )}

                          {Authentication._user.id === id && (
                            <div className="btn-medium">
                              <ButtonSave
                                label={
                                  action === "create"
                                    ? "Adicionar"
                                    : "Atualizar"
                                }
                                loading={loading ? 1 : 0}
                                type="submit"
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="btn-medium">
                          <ButtonSave
                            label={
                              action === "create" ? "Adicionar" : "Atualizar"
                            }
                            loading={loading ? 1 : 0}
                            type="submit"
                            onClick={() => {
                              if (Object.keys(props.errors).length) {
                                AlertService.warn(
                                  "Verifique se todos os campos obrigatórios foram preenchidos corretamente"
                                );
                              }
                            }}
                          />
                        </div>
                      )}

                      {Authentication._user.id !== id && action === "edit" && (
                        <div className="">
                          {userHasAccess([userProfiles.administrador]) && (
                            <div className="btn-medium">
                              <button
                                className="button-disable"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setDisplayConfirmationDialogEdit(true);
                                }}
                              >
                                {disabledUser ? "Ativar" : "Desativar"}
                              </button>
                            </div>
                          )}

                          {userHasAccess([userProfiles.administrador]) && (
                            <div className="btn-medium">
                              <button
                                className="button-delete"
                                loading={loading ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setDisplayConfirmationDialog(true);
                                }}
                              >
                                Excluir
                              </button>
                            </div>
                          )}
                        </div>
                      )}

                      <div>
                        <button
                          className="button-cancel"
                          onClick={() => {
                            history.push(`/usuarios`);
                          }}
                        >
                          Voltar
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <AlertMenssage />
          </div>
        </div>
      </div>
    </>
  );
}
