import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AlertMenssage from "../../components/AlertMenssage";
import ButtonSave from "../../components/ButtonSave";
import ExpositorMenu from "../../components/ExpositorMenu";
import {
  arrayParaAtualizar,
  NumerosNaoMentem,
} from "../../components/NumerosNaoMentem";
import TextareaInput from "../../components/TextareaInput";
import { VideoContainer } from "../../components/VideoSobreExpositor";
import AlertService from "../../service/alert.service";
import ExpositoresEstatisticasService from "../../service/expositor-estatistica.service";
import ExpositoresService from "../../service/expositores.service";
import LanguagesService from "../../service/language.service";
import { SobreExpositorSchema } from "../../validations/sobre-expositor.validation";
import { estatisticas } from "./estatisticas";
import "./style.scss";

const sobreLanguageField = {
  descricao: "",
};

export default function SobreExpositor() {
  const { idExpositor } = useParams();
  const [loading, setLoading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(1);
  const [languages, setLanguages] = useState([]);
  const [existeEstatistica, setExisteEstisticas] = useState(null);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [expositor, setExpositor] = useState();

  const [sobreValues, setSobreValues] = useState({
    idiomas: [sobreLanguageField],
    estatisticas: estatisticas,
  });

  const _sobreExpositorService = new ExpositoresService();
  const _expositoresEstatisticaService = new ExpositoresEstatisticasService();

  useEffect(
    (_avertisingdService = new ExpositoresService(), avertisingd) => {
      async function getSobreExpositorValues(languages) {
        const sobreExpositor = await _sobreExpositorService.read(idExpositor);

        const estatisticasExpositor =
          await _expositoresEstatisticaService.readSub(idExpositor);

        let idiomas = [];
        languages.forEach((element) => {
          idiomas.push({
            ...sobreLanguageField,
            idIdioma: element.id,
          });
        });

        const idIdiomas = [];
        sobreExpositor.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

        const idiomasFaltantes = [];
        idiomas.forEach((item) => {
          if (!idIdiomas.includes(item.idIdioma)) {
            idiomasFaltantes.push(item);
          }
        });

        const allLanguages = [
          ...sobreExpositor.idiomas,
          ...idiomasFaltantes,
        ].sort((v1, v2) => v1.idIdioma - v2.idIdioma);

        const idioma = currentLanguage - 1;
        if (estatisticasExpositor.estatisticas[idioma]) {
          setExisteEstisticas(true);
          setSobreValues({
            ...sobreExpositor,
            idiomas: allLanguages,
            estatisticas: estatisticasExpositor.estatisticas,
          });
        } else {
          setSobreValues({
            ...sobreExpositor,
            idiomas: allLanguages,
            estatisticas: estatisticas,
          });
        }
      }
      async function init() {
        const _expositoresService = new ExpositoresService();
        const expositor = await _expositoresService.read(idExpositor);
        setExpositor(expositor);

        const _languagesService = new LanguagesService();
        const languages = await _languagesService.list();

        let idiomas = [];
        languages.forEach((element) => {
          idiomas.push({
            ...sobreLanguageField,
            idIdioma: element.id,
          });
        });

        setSobreValues({ ...sobreLanguageField, idiomas });

        setLanguages(languages);
        await getSobreExpositorValues(languages);
        setLoadingInfo(false);
      }
      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idExpositor, sobreLanguageField]
  );

  async function createExpositorValues(values) {
    setLoading(true);
    values.id = idExpositor;
    const data = { ...values };
    data.idiomas = data.idiomas.filter((idioma) => idioma.descricao);

    await _sobreExpositorService
      .update(data)
      .then(async (response) => {
        values.parentId = idExpositor;

        let idiomas = [];
        languages.forEach((element) => {
          idiomas.push({
            ...sobreLanguageField,
            idIdioma: element.id,
          });
        });

        const idIdiomas = [];
        response.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

        const idiomasFaltantes = [];
        idiomas.forEach((item) => {
          if (!idIdiomas.includes(item.idIdioma)) {
            idiomasFaltantes.push(item);
          }
        });

        if (!existeEstatistica) {
          let existeTituloGrupo = true;
          let existemTitulos = true;
          if (values.estatisticas[0].idiomas[0].titulo === "") {
            existeTituloGrupo = false;
          }

          values.estatisticas.forEach((item) => {
            if (item.idiomas[0].titulo === "") {
              existemTitulos = false;
            }
          });

          if (existemTitulos && existeTituloGrupo) {
            await _expositoresEstatisticaService
              .createSub(values)
              .then(async () => {
                setExisteEstisticas(true);
                window.location.reload();
              });
          } else if (!existeTituloGrupo && !existeTituloGrupo) {
            AlertService.warn(
              "As informações de estatísticas não foram salvas. O campo está vazio ou está faltando preencher o campo de idiomas obrigatórios (português)"
            );
          }
        } else {
          arrayParaAtualizar.forEach(async (item) => {
            const id = item[0].id_expositor_estatistica;

            item.forEach((item) => {
              delete item.id_expositor_estatistica;
            });

            const idiomas = item;

            await _expositoresEstatisticaService.editarExpositorEstatistica({
              idiomas,
              id,
            });
          });
        }

        //setSobreValues({ ...response, idiomas: allLanguages });
      })
      .finally(() => {
        setLoading(false);
        AlertService.success(
          "Informações sobre o expositor atualizada com sucesso"
        );
      });
  }

  function selectLanguage(item) {
    setCurrentLanguage(item.id);
  }

  return (
    <>
      {loadingInfo && (
        <div className="loading-info-container">
          <lottie-player
            src="https://assets4.lottiefiles.com/packages/lf20_58vkk48j.json"
            background="transparent"
            speed="1"
            style={{ width: "500px" }}
            loop
            autoplay
          />
        </div>
      )}

      <div className="p-grid">
        <div className="p-col-12" hidden={loadingInfo}>
          <ExpositorMenu activeItem={2} expositor={expositor} />

          <div className="card">
            <h1 className="title-page">{`${sobreValues?.nomeFantasia}`}</h1>
            <hr classname="hr-header" />

            <Formik
              enableReinitialize={true}
              validationSchema={SobreExpositorSchema}
              initialValues={sobreValues}
              onSubmit={(values) => {
                createExpositorValues(values);
              }}
            >
              {(props) => {
                return (
                  <Form autoComplete="off">
                    <div
                      className="wrapper-form"
                      style={{ paddingLeft: "0.5em" }}
                    >
                      <div className="p-grid">
                        {languages.map((item, index) => (
                          <div key={index} className="language-dashboard">
                            <button
                              className={
                                item.id === currentLanguage
                                  ? "p-button-dash"
                                  : "p-button-teste"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                selectLanguage(item);
                              }}
                            >
                              {item.nome}
                            </button>
                          </div>
                        ))}
                      </div>

                      <div className="p-grid">
                        <FieldArray
                          name="idiomas"
                          render={(arrayHelpers) => (
                            <>
                              <div className="p-grid p-col-12 p-md-12 p-lg-12">
                                {props?.values?.idiomas?.map((item, index) => (
                                  <div
                                    className="p-col-12 p-md-12 p-lg-12"
                                    key={index}
                                    hidden={
                                      !(item.idIdioma === currentLanguage)
                                    }
                                  >
                                    <label style={{ fontSize: "18px" }}>
                                      Descrição sobre o expositor
                                    </label>
                                    <TextareaInput
                                      name={`idiomas[${index}].descricao`}
                                      classes="descricao-publicidade"
                                      className="input"
                                      style={{
                                        minHeight: "200px",
                                        marginTop: "10px",
                                        fontSize: "16px",
                                        padding: "10px",
                                      }}
                                      maxLength={360}
                                    />

                                    <div style={{ marginTop: "50px" }}>
                                      <VideoContainer
                                        index={index}
                                        formValue={props.values}
                                        existeEstatistica={existeEstatistica}
                                      />
                                    </div>

                                    <div style={{ marginTop: "50px" }}>
                                      <NumerosNaoMentem
                                        index={index}
                                        formValue={props.values}
                                        existeEstatistica={existeEstatistica}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        />
                      </div>
                    </div>

                    <div className="btns-inline">
                      <div className="btn-medium">
                        <ButtonSave
                          label={!existeEstatistica ? "Salvar" : "Atualizar"}
                          loading={loading}
                          type="submit"
                        />
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <AlertMenssage />
          </div>
        </div>
      </div>
    </>
  );
}
