/* eslint-disable react-hooks/exhaustive-deps */
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import AlertMenssage from "../../components/AlertMenssage";
import ButtonSave from "../../components/ButtonSave";
import AlertService from "../../service/alert.service";
import CursoModuloAulaService from "../../service/curso-modulo-aula.service";
import QueryOptions from "../../utils/query-options";
import "./styles.scss";
import CursoService from "../../service/curso.service";
import BreadCrumb from "../../components/BreadCrumb";

export default function CursoModuloAulaList({ location }) {
  const { idCursoModulo } = useParams();
  const history = useHistory();
  const [breadCrumbItems, setBreadCrumbItems] = useState([]);

  // Paginação
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);
  const [first, setFirst] = useState(1);
  const [last, setLast] = useState(0);

  const [loading, setLoading] = useState(false);
  const [aulas, setAulas] = useState([]);

  // Services
  const _cursoModuloAulaService = new CursoModuloAulaService();
  const _cursoService = new CursoService();

  const getBreadCrumb = async () => {
    const data = await _cursoService.getBreadCrumb(
      "id_curso_modulo",
      idCursoModulo
    );

    const items = [
      {
        label: "Cursos:",
        description: data.curso.nome,
        to: "/cursos/lista",
      },
      {
        label: "Módulos:",
        description: data.modulo.nome,
        to: `/cursos/modulos/${data.curso.id}`,
      },
      {
        description: "Aulas",
      },
    ];

    setBreadCrumbItems(items);
  };

  useEffect(() => {
    async function init() {
      setLoading(true);
      getBreadCrumb();

      const queryOptions = new QueryOptions([
        ["id_curso_modulo", idCursoModulo],
        ["page", page],
      ]);
      const data = await _cursoModuloAulaService.list(queryOptions, true);

      setAulas(data.items);
      setMeta(data.meta);
      setLast(data.items.length);
      setLoading(false);
    }

    init();
  }, [page]);

  useEffect(() => {
    const queryStringObj = queryString.parse(location.search);

    if (queryStringObj) {
      if (queryStringObj.acao === "criado") {
        AlertService.success("Aula adicionado");
      } else if (queryStringObj.acao === "deletado") {
        AlertService.success("Aula deletado");
      }
    }
  }, [location]);

  const handleChangePage = (event) => {
    let lastAux = event.first + aulas.length;
    if (lastAux > meta.totalItems) {
      lastAux = meta.totalItems;
    }

    setFirst(event.first + 1);
    setLast(lastAux);
    setPage(event.page + 1);
  };

  const statusBodyTable = (event) => {
    return (
      <span className={`status ${event.disabled ? "desativado" : "ativado"}`}>
        {event.disabled ? "Desativado" : "Ativado"}
      </span>
    );
  };

  const actionBodyTable = (event) => {
    return (
      <div className="icons">
        <i
          className="pi pi-pencil"
          title="Editar"
          onClick={() =>
            history.push(
              `/cursos/modulos/${idCursoModulo}/aulas/editar/${event.id}`
            )
          }
        ></i>
      </div>
    );
  };

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <div className="p-grid">
            <div className="p-col-8 p-lg-8 p-md-8 p-sm-5">
              <h1 className="h1-container">Aulas</h1>
            </div>
          </div>

          <hr className="hr-header" />

          <BreadCrumb items={breadCrumbItems} />

          <div className="datatable-paginator-demo" style={{marginTop: 15}}>
            <div className="btn-add">
              <ButtonSave
                label="Adicionar"
                onClick={() =>
                  history.push(
                    `/cursos/modulos/${idCursoModulo}/aulas/adicionar`
                  )
                }
              />
            </div>

            <div className="alert-menssage">
              <AlertMenssage to="top" />
            </div>
            <div className="content-section implementation">
              <DataTable
                value={aulas}
                lazy
                first={first}
                rows={meta.perPage}
                paginator={meta.totalPages > 1}
                loading={loading}
                totalRecords={meta.totalItems}
                onPage={handleChangePage}
                emptyMessage="Sem registros"
                currentPageReportTemplate={`${first} a ${last} de ${meta.totalItems}`}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                dataKey="id"
              >
                <Column
                  field={`idiomas.${[0]}.titulo`}
                  header="Título"
                  headerStyle={{ width: "10rem" }}
                ></Column>
                <Column
                  field={`idiomas.${[0]}.instrutor`}
                  header="Instrutor"
                  headerStyle={{ width: "8rem" }}
                ></Column>
                <Column
                  field="dataCriacao"
                  header="Data criação"
                  headerStyle={{ width: "8rem" }}
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  headerStyle={{ width: "8rem" }}
                  body={statusBodyTable}
                ></Column>
                <Column
                  field="actions"
                  header="Ações"
                  headerStyle={{ width: "4rem" }}
                  body={actionBodyTable}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
