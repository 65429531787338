import EventLanguageSerializer from "./event-language.serializer";
import { format } from "date-fns";
import EditionSerializer from "./edition.serializer";
import EventLogosSerializer from "./event-logos.serializer";
import { fromDateGmt, toDateGMT } from "../../utils/format-date";

export default class EventSerializer {
  constructor() {
    this.eventLanguageSerializer = new EventLanguageSerializer();
    this.editionSerializer = new EditionSerializer();
    this.eventLogosSerializer = new EventLogosSerializer();
  }

  fromJson(json) {
    const event = {};

    Object.assign(
      event,
      json.id_expositor && { idExpositor: json.id_expositor },
      json.id_evento && { id: json.id_evento },
      json.uri_unico && { uriUnico: json.uri_unico },
      { eventoExterno: json.evento_externo },
      { eventoDisponivel: json.evento_disponivel },
      { eventoAnfacer: json.evento_anfacer },
      { eventoPatrocinadoHome: json.evento_patrocinado_home },
      json.url_chat && { urlChat: json.url_chat },
      json.nome_palestrante && { palestrante: json.nome_palestrante },
      json.id_evento_categoria && {
        idEventoCategoria: json.id_evento_categoria,
      },
      json.filename && { filename: json.filename },
      json.url_video && { urlVideo: json.url_video },
      json.data_inicio && {
        dataInicio: fromDateGmt(json.data_inicio),
        dataInicioStr: format(fromDateGmt(json.data_inicio), "dd/MM/yyyy"),
        dataInicioHoraStr: format(fromDateGmt(json.data_inicio), "HH:mm"),
        dataInicioDiaEHoraStr: format(
          fromDateGmt(json.data_inicio),
          "dd/MM/yyyy - HH:mm"
        ),
      },
      json.data_fim && {
        dataFim: fromDateGmt(json.data_fim),
        dataFimStr: format(fromDateGmt(json.data_fim), "dd/MM/yyyy"),
        dataFimHoraStr: format(fromDateGmt(json.data_fim), "HH:mm"),
        dataFimDiaEHoraStr: format(
          fromDateGmt(json.data_fim),
          "dd/MM/yyyy - HH:mm"
        ),
      },
      json.idiomas
        ? {
            idiomas: json.idiomas.map((item) =>
              this.eventLanguageSerializer.fromJson(item)
            ),
          }
        : { idiomas: [] },
      json.idiomas && { id: json.idiomas[0]?.id_evento },
      json.destaque && { destaque: json.destaque },
      json.id_edicao && { idEdicao: json.id_edicao }
    );

    if (json.id_evento_categoria === 1) {
      delete event.id_expositor;
      event.eventoOficial = true;
      event.eventoCategoria = "Evento oficial";
      if (json.destaque) {
        event.eventoDestaqueStr = "✓";
      }
    } else if (json.id_evento_categoria === 2) {
      delete event.id_expositor;
      event.eventoParceiro = true;
      event.eventoCategoria = "Evento parceiro";
      if (json.evento_patrocinado_home) {
        event.eventoPatrocinadoHomeStr = "✓";
      }
    } else if (json.id_evento_categoria === 3) {
      event.eventoExpositor = true;
      event.eventoCategoria = "Evento expositor";
      if (json.evento_patrocinado_home) {
        event.eventoPatrocinadoHomeStr = "✓";
      }
    }

    return event;
  }

  toJson(event) {
    const eventToJson = {};

    Object.assign(
      eventToJson,

      { uri_unico: event.uriUnico },
      event.dataInicio && { data_inicio: toDateGMT(event.dataInicio) },
      event.dataFim && { data_fim: toDateGMT(event.dataFim) },
      event.expositores && { id_expositor: event.expositores.id },
      event.idEdicao && { id_edicao: event.idEdicao },
      { url_chat: event.urlChat },

      event.urlVideo ? { url_video: event.urlVideo } : { url_video: null },

      event.palestrante && { nome_palestrante: event.palestrante },
      event.idiomas && {
        idiomas: event.idiomas.map((item) =>
          this.eventLanguageSerializer.toJson(item)
        ),
      },

      event.idExpositor && { id_expositor: event.idExpositor },

      event.destaque ? { destaque: true } : { destaque: false },

      event.eventoDisponivel
        ? { evento_disponivel: true }
        : { evento_disponivel: false },

      event.eventoPatrocinadoHome
        ? {
            evento_patrocinado_home: true,
          }
        : { evento_patrocinado_home: false },

      event.eventoExterno
        ? { evento_externo: true }
        : { evento_externo: false },

      event.eventoCriadoPelaAnfacer
        ? {
            evento_anfacer: true,
          }
        : { evento_anfacer: false }
    );

    if (event.eventoOficial) {
      eventToJson.id_evento_categoria = 1;
      eventToJson.id_expositor = null;
      eventToJson.evento_anfacer = true;
      eventToJson.evento_patrocinado_home = false;
      eventToJson.evento_externo = false;
    } else if (event.eventoParceiro) {
      eventToJson.id_expositor = null;
      eventToJson.id_evento_categoria = 2;
      eventToJson.destaque = false;
      eventToJson.evento_anfacer = true;
    } else if (event.eventoExpositor) {
      eventToJson.id_evento_categoria = 3;
      eventToJson.destaque = false;
    } else {
      eventToJson.id_evento_categoria = 3;
      eventToJson.evento_externo = 1;
    }

    if (event.eventoExterno) {
      eventToJson.url_chat = null;
      eventToJson.uri_unico = null;
    }

    return eventToJson;
  }

  toFormData(file) {
    let formData = new FormData();
    formData.append("files", file[0]);

    return formData;
  }
}
