import { format } from "date-fns";
import CursoIdiomaSerializer from "./curso-idioma.serializer";
import ExpositoresSerializer from "./expositores.serializer";
import CursoInstrutorSerializer from "./curso-instrutor.serializer";
import unMask from "../../utils/unmask";
import UserProfileSerializer from "./user-profile.serializer";
import { toDateGMT } from "../../utils/format-date";

export default class CursoSerializer {
  constructor() {
    this.cursoIdiomaSerializer = new CursoIdiomaSerializer();
    this.expositorSerializer = new ExpositoresSerializer();
    this.cursoInstrutorSerializer = new CursoInstrutorSerializer();
    this.usuarioPerfilSerializer = new UserProfileSerializer();
  }

  fromJson(json) {
    const curso = {};

    json = json.data || json;

    Object.assign(
      curso,
      json.id_curso && { id: json.id_curso, value: json.id_curso },
      json.data_criacao && {
        dataCriacao: format(new Date(json.data_criacao), "dd/MM/yyyy"),
      },
      json.expositor && {
        expositor: this.expositorSerializer.fromJson(json.expositor),
      },
      json.curso_instrutor && {
        instrutor: this.cursoInstrutorSerializer.fromJson(json.curso_instrutor),
      },
      json.carga_horaria && { cargaHoraria: json.carga_horaria },
      json.porcentagem_conclusao && {
        porcentagemConclusao: json.porcentagem_conclusao,
      },
      json.hasOwnProperty("disabled") && { disabled: json.disabled },
      json.idiomas
        ? {
            idiomas: json.idiomas.map((item) =>
              this.cursoIdiomaSerializer.fromJson(item)
            ),
          }
        : { idiomas: [] },
      json.hasOwnProperty("destaque") && { destaque: json.destaque },
      json.usuario_criador && { usuario: json.usuario_criador.nome },
      json.usuario_perfis && {
        perfil: json.usuario_perfis.map((item) =>
          this.usuarioPerfilSerializer.fromJson(item)
        ),
      },
      json.data_inicio && {
        dateStart: new Date(json.data_inicio),
      },
      json.data_fim && {
        dateEnd: new Date(json.data_fim),
      }
    );

    if (curso.idiomas.length) {
      curso.label = curso.idiomas[0].titulo;
    }

    return curso;
  }

  toJson(curso) {
    const cursoToJson = {};

    Object.assign(
      cursoToJson,
      curso.instrutor && { id_curso_instrutor: curso.instrutor.id },
      curso.expositor && { id_expositor: curso.expositor.id },
      curso.instrutor && { id_curso_instrutor: curso.instrutor.id },
      curso.cargaHoraria && { carga_horaria: curso.cargaHoraria },
      curso.porcentagemConclusao && {
        porcentagem_conclusao: unMask(curso.porcentagemConclusao.toString()),
      },
      curso.hasOwnProperty("disabled") && { disabled: curso.disabled },
      curso.idiomas && {
        idiomas: curso?.idiomas?.map((item) =>
          this.cursoIdiomaSerializer.toJson(item)
        ),
      },
      curso.hasOwnProperty("destaque") && { destaque: curso.destaque },
      curso.hasOwnProperty("perfil") && {
        ids_usuario_perfil: curso.perfil
          ? curso.perfil.map((item) => item.value.id)
          : [],
      },
      { data_inicio: curso.dateStart ? toDateGMT(curso.dateStart) : null },
      { data_fim: curso.dateEnd ? toDateGMT(curso.dateEnd) : null }
    );

    return cursoToJson;
  }
}
