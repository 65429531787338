import api from "./api.service";
import CoreApiService from "./core-api.service";
import AvertisingdSerializer from "./serializers/avertisingd.serializer";

export default class AvertisingdService extends CoreApiService {
  constructor() {
    super(undefined, "publicidades", new AvertisingdSerializer());
  }

  async upload(idPublicidadeIdioma, data, desktop, mobile, homeDesktop, homeMobile) {
    let query = "";

    if (desktop) {
      query = "?desktop=true";
    }

    if (mobile) {
      query = "?mobile=true";
    }

    if (homeMobile) {
      query = "?home_mobile=true";
    }

    if (homeDesktop) {
      query = "?home_desktop=true";
    }

    const formData = this.serializer.toFormData(data);

    const response = await api.put(
      `${this.endpoint}/${idPublicidadeIdioma}/upload${query}`,
      formData
    );

    if (response.data["oret"]) {
      return this.serializer.fromJson(response.data["oret"]);
    }
    return null;
  }
}
