import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import AlertMenssage from "../../components/AlertMenssage";
import ConfirmationDialog from "../../components/dialogs/confirmation-dialog";
import ExpositorMenu from "../../components/ExpositorMenu";
import ProdutosListagem from "../../components/ProdutoListagem/ProdutosListagem";
import AlertService from "../../service/alert.service";
import ExpositoresService from "../../service/expositores.service";
import ProductService from "../../service/products.service";

import "./styles.scss";

export default function Products({ location }) {
  const { idExpositor } = useParams();
  const [expositor, setExpositor] = useState();
  const [produtoListagem, setProdutoListagem] = useState([]);
  const history = useHistory();
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [loading, setLoading] = useState(false);
  const [busca, setBusca] = useState("");
  const [limparFiltros, setLimparFiltros] = useState(false);
  const [produtoListagemLength, setProdutoListagemLength] = useState();
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);

  const _produtosService = new ProductService();

  useEffect(() => {
    async function listProducts() {
      const queryStringObj = queryString.parse(location.search);

      if (queryStringObj) {
        if (queryStringObj.acao === "criado") {
          AlertService.success("Produto adicionado com sucesso");
        } else if (queryStringObj.acao === "deletado") {
          AlertService.success("Produto deletado com sucesso");
        }
      }

      const _expositoresService = new ExpositoresService();
      const expositor = await _expositoresService.read(idExpositor);
      setExpositor(expositor);
      setLoadingInfo(false);
    }
    listProducts();
  }, [idExpositor, location, busca]);

  async function deletarTodosProdutos() {
    setLoading(true);
    await _produtosService.deletarTodosProdutos(idExpositor).then(() => {
      setDisplayConfirmationDialog(false);
      setProdutoListagem([]);
    });

    setLoading(false);
  }

  return (
    <div className="p-grid">
      <ConfirmationDialog
        header="Você tem certeza de que deseja remover todos os itens?"
        action="Excluir mesmo assim?"
        display={displayConfirmationDialog}
        onClose={() => setDisplayConfirmationDialog(false)}
        onConfirm={deletarTodosProdutos}
        loading={loading}
      />

      <div className="p-col-12">
        <ExpositorMenu activeItem={1} expositor={expositor} />

        <div className="card">
          <div className="p-grid">
            <div className="p-col-9 p-lg-9 p-md-9 p-sm-9">
              <h1 className="h1-container">Produtos</h1>
              <div>
                <p>
                  Após adicionar seus produtos será possível organizar a ordem
                  em que eles aparecerão em sua página arrastando.
                </p>
                <p>
                  Para incluir cada produto será necessário informações como
                  nome, descrição, tipo e dimensões em português, inglês e
                  espanhol.
                </p>
                <p>
                  Será possível incluir uma ou mais fotos em cada produto, por
                  exemplo, uma foto de cada cor da mesma linha.
                </p>
                <p>
                  Cada foto obrigatoriamente deverá ter 1632 x 1440px, com o
                  objetivo de garantir qualidade e formato de todos os produtos
                  na plataforma.
                </p>
                <p>
                  Após adicionar seus produtos será possível organizar a ordem
                  em que eles aparecerão em sua página arrastando os blocos
                  pretos abaixo.
                </p>
              </div>
            </div>

            <div
              className={
                busca.length
                  ? "p-col-12 p-lg-2 p-md-2 p-sm-2"
                  : "p-col-12 p-lg-3 p-md-3 p-sm-3"
              }
            >
              <div className="text-input-filtragem">
                <input
                  type="text"
                  value={busca}
                  onChange={(ev) => setBusca(ev.target.value)}
                  placeholder="Busca por nome, descrição ou código"
                />
              </div>
            </div>

            {limparFiltros && (
              <div className="p-col-12 p-lg-1 p-md-1 p-sm-1">
                <button
                  onClick={() => {
                    setBusca("");
                    setLimparFiltros(false);
                  }}
                  className="limpar-filtros-button"
                >
                  <span>Limpar filtros</span>
                </button>
              </div>
            )}
          </div>

          <hr classname="hr-header" />
          <AlertMenssage />

          <div className="produto-button-container">
            <button
              className="button-save"
              onClick={() => {
                history.push(`/expositores/${idExpositor}/produtos/adicionar`);
              }}
            >
              Adicionar
            </button>

            {produtoListagemLength?.length > 0 && (
              <button
                className="button-delete"
                onClick={() => {
                  setDisplayConfirmationDialog(true);
                }}
              >
                Remover todos
              </button>
            )}
          </div>

          {loadingInfo && (
            <div className="loading-info-container">
              <lottie-player
                src="https://assets4.lottiefiles.com/packages/lf20_58vkk48j.json"
                background="transparent"
                speed="1"
                style={{ width: "500px" }}
                loop
                autoplay
              />
            </div>
          )}

          <div hidden={loadingInfo}>
            <ProdutosListagem
              busca={busca}
              setLimparFiltros={setLimparFiltros}
              produtoListagem={produtoListagem}
              setProdutoListagem={setProdutoListagem}
              setProdutoListagemLength={setProdutoListagemLength}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
