import api from "./api.service";
import CoreApiService from "./core-api.service";
import UserSerializer from "./serializers/user.serializer";

export default class UserService extends CoreApiService {
  constructor() {
    super("expositores", "usuarios", new UserSerializer());
  }

  async forgot(data) {
    const response = await api.post(`recupera-senha`, data);
    return response;
  }

  async changePassword(data) {
    const response = await api.put(`altera-senha`, data);
    return response;
  }

  async getProfile(id) {
    const response = await api.get(`${this.endpoint}/perfil`);
    const data = response.data;
    return this.serializer.fromJson(data);
  }

  async uploadImage(file, id) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await api.put(`${this.endpoint}/${id}/uploads`, formData);
    const data = response.data;
    return this.serializer.fromJson(data.oret);
  }

  async listaPorExpositor(idExpositor, queryOptions) {
    this.parentEndpoint = "expositores";
    return await this.listSub(idExpositor, queryOptions, true);
  }

  async removerImagemPerfil(id) {
    const response = await api.delete(`${this.endpoint}/${id}/imagens`);
    return response;
  }

  async teste() {
    const response = await api.get("certificado-feira/usuarios");
    const data = response.data;

    return {
      items: data.items,
      meta: {
        page: data._meta.page,
        per_page: data._meta.perPage,
        total_items: data._meta.totalItems,
        total_pages: data._meta.totalPages,
      },
    };
  }
}
