import { format } from "date-fns";
import CursoModuloIdiomaSerializer from "./curso-modulo-idioma.serialize";

export default class CursoModuloSerializer {
  constructor() {
    this.cursoModuloIdiomaSerializer = new CursoModuloIdiomaSerializer();
  }

  fromJson(json) {
    const modulo = {};

    json = json.data || json;

    Object.assign(
      modulo,
      json.id_curso_modulo && {
        id: json.id_curso_modulo,
      },
      json.data_criacao && { dataCriacao: format(new Date(json.data_criacao), 'dd/MM/yyyy')  },
      json.hasOwnProperty("disabled") && { disabled: json.disabled },
      json.idiomas
        ? {
            idiomas: json.idiomas.map((item) =>
              this.cursoModuloIdiomaSerializer.fromJson(item)
            ),
          }
        : { idiomas: [] }
    );

    return modulo;
  }

  toJson(modulo) {
    const moduloToJson = {};

    Object.assign(
      moduloToJson,
      modulo.idCurso && { id_curso: modulo.idCurso },
      modulo.hasOwnProperty("disabled") && { disabled: modulo.disabled },
      modulo.idiomas && {
        idiomas: modulo?.idiomas?.map((item) =>
          this.cursoModuloIdiomaSerializer.toJson(item)
        ),
      }
    );

    return moduloToJson;
  }
}
