import api from "./api.service";
import CoreApiService from "./core-api.service";
import CursoInstrutorSerializer from "./serializers/curso-instrutor.serializer";

export default class CursoInstrutorService extends CoreApiService {
  constructor() {
    super(undefined, "curso/instrutores", new CursoInstrutorSerializer());
  }

  linkImage(id) {
    return `${process.env.REACT_APP_API_URL}/${this.endpoint}/${id}/display`;
  }

  async uploadImage(file, id) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await api.put(`${this.endpoint}/${id}/uploads`, formData);
    const data = response.data;
    return this.serializer.fromJson(data.oret);
  }
}
