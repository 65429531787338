import CursoInstrutorIdiomaSerializer from "./curso-instrutor-idioma.serializer";

export default class CursoInstrutorSerializer {
  constructor() {
    this.cursoInstrutorIdiomaSerializer = new CursoInstrutorIdiomaSerializer();
  }

  fromJson(json) {
    const instrutor = {};

    json = json.data || json;

    Object.assign(
      instrutor,
      json.id_curso_instrutor && {
        id: json.id_curso_instrutor,
        value: json.id_curso_instrutor,
      },
      json.nome_instrutor && {
        nome: json.nome_instrutor,
        label: json.nome_instrutor,
      },
      json.image_filename && { imageFilename: json.image_filename },
      json.hasOwnProperty("disabled") && { disabled: json.disabled },
      json.idiomas
        ? {
            idiomas: json.idiomas.map((item) =>
              this.cursoInstrutorIdiomaSerializer.fromJson(item)
            ),
          }
        : { idiomas: [] }
    );

    return instrutor;
  }

  toJson(instrutor) {
    return {
      nome_instrutor: instrutor.nome,
      idiomas: instrutor?.idiomas?.map((item) =>
        this.cursoInstrutorIdiomaSerializer.toJson(item)
      ),
    };
  }
}
