export default class PhoneTypeSerializer {
  fromJson(json) {
    const phoneType = {
      id: json.id_telefone_tipo,
      type: json.tipo,
    };

    return phoneType;
  }

  toJson(phoneType) {
    return {
      id_telefone_tipo: phoneType.id,
      tipo: phoneType.type,
    };
  }
}
