/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import AlertMenssage from "../../components/AlertMenssage";
import ButtonSave from "../../components/ButtonSave";
import TextInput from "../../components/TextInput";
import ConfirmationDialog from "../../components/dialogs/confirmation-dialog";
import AlertService from "../../service/alert.service";
import CursoModuloService from "../../service/curso-modulo.service";
import LanguagesService from "../../service/language.service";
import { cursoModuloSchema } from "../../validations/curso-modulo.validation";
import CursoService from "../../service/curso.service";
import BreadCrumb from "../../components/BreadCrumb";

export default function CursoModuloForm() {
  const _cursoModuloService = new CursoModuloService();
  const _cursoService = new CursoService();

  const { idCurso, id: idCursoModulo } = useParams();
  const history = useHistory();
  const [breadCrumbItems, setBreadCrumbItems] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [moduloDisabled, setModuloDisabled] = useState(false);
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);
  const [displayDisabledDialog, setDisplayDisabledDialog] = useState(false);

  // Idiomas
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(1);

  // Formulário
  const formIdiomaField = {
    nome: "",
    idIdioma: "",
  };

  const [valuesForm, setValuesForm] = useState({
    dataCriacao: format(new Date(), "dd/MM/yyyy"),
    idiomas: [formIdiomaField],
  });

  const mountLanguage = async () => {
    const _languagesService = new LanguagesService();
    const languages = await _languagesService.list();

    let idiomas = [];
    languages.forEach((element) => {
      idiomas.push({
        ...formIdiomaField,
        idIdioma: element.id,
      });
    });

    setValuesForm({
      ...valuesForm,
      idiomas,
    });

    setLanguages(languages);
    return languages;
  };

  const getCursoModulo = async (languages) => {
    const modulo = await _cursoModuloService.read(idCursoModulo);

    let idiomas = [];
    languages.forEach((element) => {
      idiomas.push({
        ...formIdiomaField,
        idIdioma: element.id,
      });
    });

    const idIdiomas = [];
    modulo.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

    const idiomasFaltantes = [];
    idiomas.forEach((item) => {
      if (!idIdiomas.includes(item.idIdioma)) {
        idiomasFaltantes.push(item);
      }
    });

    const allLanguages = [...modulo.idiomas, ...idiomasFaltantes].sort(
      (v1, v2) => v1.idIdioma - v2.idIdioma
    );

    setModuloDisabled(modulo.disabled);

    setValuesForm({ ...modulo, idiomas: allLanguages });
  };

  const getBreadCrumb = async () => {
    let params = {};

    if (idCursoModulo) {
      params = {
        key: "id_curso_modulo",
        value: idCursoModulo,
      };
    } else {
      params = {
        key: "id_curso",
        value: idCurso,
      };
    }

    const data = await _cursoService.getBreadCrumb(params.key, params.value);

    const items = [
      {
        label: "Cursos:",
        description: data.curso.nome,
        to: "/cursos/lista",
      },
    ];

    if (idCursoModulo) {
      items.push({
        label: "Módulos:",
        description: data.modulo.nome,
        to: `/cursos/modulos/${data.curso.id}`,
      });

      items.push({
        description: "Editar",
      });
    } else {
      items.push({
        label: "Módulos",
        to: `/cursos/modulos/${data.curso.id}`,
      });

      items.push({ description: "Adcionar" });
    }

    setBreadCrumbItems(items);
  };

  useEffect(() => {
    setIsEdit(!!idCursoModulo);

    getBreadCrumb();

    const init = async () => {
      const languages = await mountLanguage();

      if (idCursoModulo) {
        await getCursoModulo(languages);
      }
    };

    init();
  }, []);

  async function handleDelete() {
    await _cursoModuloService.delete(idCursoModulo);
    history.push(`/cursos/modulos/${idCurso}?acao=deletado`);
  }

  async function handleDisabled() {
    const isDisabled = !moduloDisabled;

    const data = {
      id: idCursoModulo,
      disabled: isDisabled,
    };

    await _cursoModuloService.update(data);

    setModuloDisabled(isDisabled);
    setDisplayDisabledDialog(false);
    AlertService.success(`Módulo ${isDisabled ? "desativado" : "ativado"}`);
  }

  const onSubmit = async (values) => {
    setLoading(true);

    const data = { ...values };
    data.idiomas = data.idiomas.filter((idioma) => idioma.nome);

    if (isEdit) {
      // Atualizar
      try {
        await _cursoModuloService.update(data);
        AlertService.success("Inatrutor atualizado");
      } catch ({ response }) {
        AlertService.warn(response.data.msg);
      } finally {
        setLoading(false);
      }
    } else {
      // Criar
      data.idCurso = idCurso;

      try {
        await _cursoModuloService.create(data);
        history.push(`/cursos/modulos/${idCurso}?acao=criado`);
      } catch ({ response }) {
        AlertService.warn(response.data.msg);
      }
    }
  };

  return (
    <>
      <ConfirmationDialog
        header="Atenção!"
        action="Apagar este módulo?"
        display={displayConfirmationDialog}
        onClose={() => setDisplayConfirmationDialog(false)}
        onConfirm={handleDelete}
      />

      <ConfirmationDialog
        header="Atenção!"
        action="Desabilitar este módulo?"
        display={displayDisabledDialog}
        onClose={() => setDisplayDisabledDialog(false)}
        onConfirm={handleDisabled}
      />

      <div className="card">
        <h1 className="title-page">
          {isEdit ? "Editar" : "Adiconar"} Módulo
          {moduloDisabled ? (
            <>
              {" "}
              - <span className="text-desativado">Desativado</span>
            </>
          ) : null}
        </h1>

        <hr className="hr-header" />

        <BreadCrumb items={breadCrumbItems} />

        <Formik
          enableReinitialize={true}
          validationSchema={cursoModuloSchema}
          initialValues={valuesForm}
          onSubmit={onSubmit}
        >
          {(props) => {
            return (
              <Form autoComplete="off">
                <div
                  className="wrapper-form"
                  style={{ paddingLeft: "0.5em", maxWidth: "820px" }}
                >
                  <div className="p-grid">
                    {languages.map((item, index) => (
                      <div key={index} className="language-dashboard">
                        <button
                          className={
                            item.id === currentLanguage
                              ? "p-button-dash"
                              : "p-button-teste"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentLanguage(item.id);
                          }}
                        >
                          {item.nome}
                        </button>
                      </div>
                    ))}
                  </div>

                  <div>
                    <FieldArray
                      name="idiomas"
                      render={(arrayHelpers) => (
                        <div className="p-grid">
                          {props?.values?.idiomas?.map((item, index) => (
                            <div
                              className="p-col-12"
                              key={index}
                              hidden={!(item.idIdioma === currentLanguage)}
                            >
                              <TextInput
                                name={`idiomas[${index}].nome`}
                                className="input"
                                label="Nome"
                                quantidadeCaracteres={50}
                                maxLength={50}
                                required
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </div>

                  <div className="p-grid">
                    <TextInput
                      classes="p-col-4"
                      name="dataCriacao"
                      className="input"
                      label="Data de criação"
                      disabled
                    />
                  </div>

                  <div className="btns-inline">
                    <div className="btn-medium">
                      <ButtonSave
                        label={isEdit ? "Atualizar" : "Adicionar"}
                        type="submit"
                        loading={loading}
                      />
                    </div>
                    {isEdit ? (
                      <>
                        <div className="btn-medium">
                          <button
                            className="button-disable"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setDisplayDisabledDialog(true);
                            }}
                          >
                            {moduloDisabled ? "Ativar" : "Desativar"}
                          </button>
                        </div>
                        <div className="btn-medium">
                          <button
                            className="button-delete"
                            styles="p-button-secondary"
                            onClick={(e) => {
                              e.preventDefault();
                              setDisplayConfirmationDialog(true);
                            }}
                          >
                            Excluir
                          </button>
                        </div>
                      </>
                    ) : null}
                    <div className="btn-medium">
                      <button
                        className="button-voltar"
                        onClick={() => {
                          history.push(`/cursos/modulos/${idCurso}`);
                        }}
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>

        <AlertMenssage />
      </div>
    </>
  );
}
