import * as Yup from "yup";
import * as messages from "./messages";
import validaDigitos from "../utils/valida-digitos";
import unMask from "../utils/unmask";

export const phoneSchema = Yup.object({
  ddi: Yup.string().required(messages.REQUIRED),
  ddd: Yup.string().test("ddd", messages.REQUIRED, function (value) {
    if (validaDigitos(this.parent.ddi) && unMask(this.parent.ddi) === "55") {
      return validaDigitos(value);
    }

    return true;
  }),
  phone: Yup.string().required(messages.REQUIRED),
  type: Yup.string().required(messages.REQUIRED),
});
