import queryString from "query-string";
import React, { useEffect, useState } from "react";
import AlertMenssage from "../../components/AlertMenssage";
import ListTableLazy from "../../components/ListTableLazy";
import AlertService from "../../service/alert.service";
import ProductTypeService from "../../service/product-type.service";
import QueryOptions from "../../utils/query-options";

export default function ProdutoTipos({ location }) {
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [loading, setLoading] = useState(false);
  const [produtoTipos, setProdutoTipos] = useState([]);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);

  const columns = [{ field: `idiomas.${[0]}.tipo`, header: "Tipo do produto" }];

  useEffect(() => {
    async function listProdutoTipos() {
      setLoading(true);
      const queryStringObj = queryString.parse(location.search);

      const queryOptions = new QueryOptions([["page", page]]);

      if (queryStringObj) {
        if (queryStringObj.acao === "criado") {
          AlertService.success("Tipo de produto adicionado com sucesso");
        } else if (queryStringObj.acao === "deletado") {
          AlertService.success("Tipo de produto deletado com sucesso");
        }
      }

      const _produtoTiposService = new ProductTypeService();
      const produtoTipos = await _produtoTiposService.list(queryOptions, true);

      setProdutoTipos(produtoTipos.items);
      setMeta(produtoTipos.meta);
      setLoadingInfo(false);
      setLoading(false);
    }
    listProdutoTipos();
  }, [page, location]);

  function handleChangePage(event) {
    setFirst(event.first);
    setPage(event.page + 1);
  }

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <div className="p-grid">
            <div className="p-col-9 p-lg-9 p-md-9 p-sm-9">
              <h1 className="h1-container">Tipos de produtos</h1>
            </div>
          </div>

          <hr classname="hr-header" />
          <AlertMenssage />
          <ListTableLazy
            cols={columns}
            values={produtoTipos}
            firstIdx={first}
            meta={meta}
            loading={loading}
            endpoint="produto-tipos"
            onChangePage={handleChangePage}
          />

          {loadingInfo && (
            <div className="loading-info-container">
              <lottie-player
                src="https://assets4.lottiefiles.com/packages/lf20_58vkk48j.json"
                background="transparent"
                speed="1"
                style={{ width: "500px" }}
                loop
                autoplay
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
