import { Column, DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AlertMenssage from "../../../components/AlertMenssage";
import LogService from "../../../service/log.service";
import QueryOptions from "../../../utils/query-options";
import UsuarioMenu from "../UsuarioMenu";
import UsuarioLogFilter from "./UsuarioLogFilter";

export default function UsuarioLog() {
  const { id: idUsuario } = useParams();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});

  const [logs, setLogs] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);

  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);
  const [first, setFirst] = useState(1);
  const [last, setLast] = useState(0);

  useEffect(() => {
    async function logs() {
      setLoading(true);
      const _logService = new LogService();

      const queryOptions = new QueryOptions([["page", page]]);

      Object.keys(filter).forEach((key) => {
        queryOptions.queryKvs.push([key, filter[key]]);
      });

      const data = await _logService.listaLogs(idUsuario, queryOptions, true);
      setLogs(data.items);
      setMeta(data.meta);

      setFirst(data.meta.perPage * data.meta.page - data.meta.perPage + 1);
      let lastItem = data.meta.perPage * data.meta.page;

      if (lastItem > data.meta.totalItems) {
        lastItem = data.meta.totalItems;
      }

      setLast(lastItem);

      setLoading(false);
    }

    logs();
  }, [page, filter, idUsuario]);

  function rowExpansionTemplate(data) {
    let conteudo = "";

    if (data.conteudo) {
      conteudo = JSON.stringify(data.conteudo, undefined, 2);
      return (
        <div
          style={{ whiteSpace: "pre-wrap" }}
          dangerouslySetInnerHTML={{
            __html: conteudo
              .replaceAll("{\n", "")
              .replaceAll("}\n", "")
              .replaceAll("},\n", "")
              .replaceAll("}", "")
              .replaceAll("[", "")
              .replaceAll("]", ""),
          }}
        ></div>
      );
    }

    return "Sem conteúdo";
  }

  function onPage(event) {
    setPage(event.page + 1);
  }

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <UsuarioMenu activeItem={1} />
          <div className="card">
            <div className="header-expo">
              <h1 className="h1-container">Usuários - Logs</h1>
            </div>

            <AlertMenssage scroll={false} />

            <UsuarioLogFilter setFilter={setFilter} loading={loading} />

            <DataTable
              value={logs}
              lazy={true}
              paginator={parseInt(meta.totalItems / meta.perPage) > 1}
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="id"
              autoLayout
              first={first}
              loading={loading}
              rows={meta.perPage}
              totalRecords={meta.totalItems}
              onPage={onPage}
              currentPageReportTemplate={`${first} a ${last} de ${meta.totalItems}`}
              resizableColumns={true}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
              emptyMessage="Sem registros"
            >
              <Column expander={true} style={{ width: "3em" }} />
              <Column field="dataStr" header="Data" />
              <Column field="metodo" header="Método" />
              <Column field="endpoint" header="Tipo" />
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
}
