import * as Yup from "yup";
import * as messages from "./messages";
import "./customs.validation";

export const SegmentosSchema = Yup.object({
  idiomas: Yup.array().of(
    Yup.object().shape({
      nome: Yup.string()
        .max(50, "O máximo de caracteres permitido é 50")
        .required(messages.REQUIRED),
    })
  ),
});
