export default class SegmentosIdiomasSerializer {
  fromJson(json) {
    const segmentosIdiomas = {
      idSegmentoIdiomas: json.id_segmento_idioma,
      nome: json.nome,
      idIdioma: json.idioma.id_idioma,
    };

    return segmentosIdiomas;
  }

  toJson(segmentosIdiomas) {
    return {
      id_idioma: segmentosIdiomas.idIdioma,
      nome: segmentosIdiomas.nome,
    };
  }
}
