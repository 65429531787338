import PhoneSerializer from "./phone.serializer";
import AddressSerializer from "./address.serializer";
import UserProfileSerializer from "./user-profile.serializer";
import UserHasExpositorSerializer from "./user_has_expositor";
import unMask from "../../utils/unmask";
import maskCpfCnpj from "../../utils/mask-cpf-cnpj";
import ExpositoresSerializer from "./expositores.serializer";
import CargoSerializer from "./cargo.serializer";
import convertDate from "../../utils/convert-date";
import SexoSerializer from "./sexo.serializer";
import PerfilEmpresaSerializer from "./perfil-empresa.serializer";

export default class UserSerializer {
  constructor() {
    this.phoneSerializer = new PhoneSerializer();
    this.addressSerializer = new AddressSerializer();
    this.userProfileSerializer = new UserProfileSerializer();
    this.userHasExpositorSerializer = new UserHasExpositorSerializer();
    this.expositoresSerializer = new ExpositoresSerializer();
    this.sexoSerializer = new SexoSerializer();
    this.cargoSerializer = new CargoSerializer();
    this.perfilEmpresaSerializer = new PerfilEmpresaSerializer();
  }

  fromJson(json) {
    const user = {};

    Object.assign(
      user,
      json.id_usuario && { id: json.id_usuario },
      json.email && {
        email: json.email,
        confEmail: json.email,
      },
      json.data_nascimento && {
        dataNascimento: new Date(json.data_nascimento),
      },
      json.disabled && { disabled: json.disabled },
      json.login && { login: json.login },
      json.nome && { name: json.nome },
      json.cpf && { cpf: maskCpfCnpj(json.cpf) },
      json.image_filename && { imageFileName: json.image_filename },
      json.usuario_perfil && {
        profile: this.userProfileSerializer.fromJson(json.usuario_perfil),
      },
      json.sexo && { sexo: this.sexoSerializer.fromJson(json.sexo) },
      json.nacionalidade && { nacionalidade: json.nacionalidade },
      json.cargo && { cargo: this.cargoSerializer.fromJson(json.cargo) },
      json.perfil_empresa && {
        perfilEmpresa: this.perfilEmpresaSerializer.fromJson(
          json.perfil_empresa
        ),
      },
      json.trocar_senha && { changePassword: json.trocar_senha },
      json.senha_tentativas && { passwordAttempts: json.senha_tentativas },
      json.enderecos
        ? {
            adresses: json.enderecos.map((item) =>
              this.addressSerializer.fromJson(item)
            ),
          }
        : { adresses: [] },
      json.telefones
        ? {
            phones: json.telefones.map((item) =>
              this.phoneSerializer.fromJson(item)
            ),
          }
        : { phones: [] },
      json.expositores && {
        expositores: json.expositores.map((item) => item.id_expositor),
        expositoresNomeFantasia: json.expositores.map((item) => {
          return {
            id: item.id_expositor,
            nome: item.nome_fantasia,
          };
        }),
      },

      json.certificados
        ? {
            certificados: json.certificados.map((item) => item),
          }
        : { certificados: [] }
    );

    if (json.expositores) {
      const expositoresPermissoes = [];
      json.expositores.forEach((item) => {
        expositoresPermissoes.push({
          id: item.id_expositor,
          modulosCatalogos: item?.habilita_catalogos,
          modulosEventos: item?.habilita_eventos,
          coletorDeDadosDigital: item?.habilita_coletor_dados,
          contatoDoExpositor: item?.habilita_contato,
          moduloGaleriaVideos: item?.habilita_videos,
          bannerRotativos: item?.habilita_banners,
        });
      });

      user.expositoresPermissoes = expositoresPermissoes;
    }

    if (user.disabled) {
      user.statusStr = "Inativo";
    } else {
      user.statusStr = "Ativo";
    }

    return user;
  }

  toJson(user) {
    const userToJson = {};

    Object.assign(
      userToJson,
      user.name && { nome: user.name },
      user.cpf && { cpf: unMask(user.cpf) },
      user.email && { email: user.email },
      user.confEmail && { conf_email: user.confEmail },
      user.disabled !== undefined && { disabled: user.disabled },

      user.phones && {
        telefones: user.phones.map((item) => this.phoneSerializer.toJson(item)),
      },
      user.adresses && {
        enderecos: user.adresses.map((item) =>
          this.addressSerializer.toJson(item)
        ),
      },

      user?.perfilEmpresa && { id_perfil_empresa: user.perfilEmpresa.value },

      user?.profile && { id_usuario_perfil: user.profile.id },

      user.dataNascimento && {
        data_nascimento: convertDate(user.dataNascimento),
      },

      user.cargo
        ? { id_usuario_cargo: user.cargo.value }
        : { id_usuario_cargo: null },

      user.sexo
        ? { id_usuario_sexo: user.sexo.value }
        : { id_usuario_sexo: null }
    );

    if (user?.profile?.id === 2) {
      userToJson.expositores = user.expositores
        ? user.expositores.map((item) => {
            return { id_expositor: item.id };
          })
        : [];
    }

    if (user?.profile?.id !== 3) { 
      userToJson.senha= user?.password;
      userToJson.conf_senha = user?.confirmPassword;
    }

    return userToJson;
  }
}
