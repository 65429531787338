import React from "react";
import { useField } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import MaskedInput from "react-maskedinput";

export default function CalendarDateInput({ label, classes = "", ...props }) {
  const [field, meta] = useField(props);
  registerLocale("ptBR", ptBR);

  return (
    <div className={classes}>
      <label htmlFor={props.id || props.name}>{label}</label>
      <div className="input-block">
        <DatePicker
          locale="ptBR"
          timeFormat="p"
          timeIntervals={15}
          dateFormat="Pp"
          customInput={
            <MaskedInput
              mask="11/11/1111"
              placeholder="mm/dd/yyyy"
            />
          }
          {...field}
          {...props}
        />
      </div>
      <div className="error-input">
        {meta.touched && meta.error ? (
          <div className="error ">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
}
