export default class QueryOptions {
  constructor(_queryKvs = []) {
    this.queryKvs = _queryKvs;
  }

  toQueryMap(queryKvs) {
    return new Map([...this.queryKvs, ...queryKvs]);
  }

  toQueryString(queryKvs = []) {
    let queryString = "";
    this.toQueryMap(queryKvs).forEach((value, key) => {
      queryString = queryString.concat(`${key}=${value}&`);
    });

    return queryString.substring(0, queryString.length - 1);
  }
}
