import ExpositorEstatisticaLanguageSerializer from "./expositores-estatisticas-language.serializer";

export default class ExpositoresEstatisticasIdiomasSerializer {
  constructor() {
    this.expositorEstatisticaLanguageSerializer =
      new ExpositorEstatisticaLanguageSerializer();
  }
  fromJson(json) {
    const expositoresEstatisticasLanguage = {};
    Object.assign(
      expositoresEstatisticasLanguage,
      json.idiomas && {
        idiomas: json.idiomas.map((item) =>
          this.expositorEstatisticaLanguageSerializer.fromJson(item)
        ),
      },
      json.id_expositor_estatistica && {
        id_expositor_estatistica: json.id_expositor_estatistica,
      }
    );

    return expositoresEstatisticasLanguage;
  }

  toJson(expositor) {
    const expositoresEstatisticasLanguageToJson = {};

    Object.assign(
      expositoresEstatisticasLanguageToJson,
      expositor.titulo && {
        titulo: expositor.titulo,
      },
      expositor.idIdioma && {
        id_idioma: expositor.idIdioma,
      }
    );

    return expositoresEstatisticasLanguageToJson;
  }
}
