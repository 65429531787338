export default class SexoIdiomasSerializer {
  fromJson(json) {
    const sexoIdiomasSerializer = {};

    Object.assign(
      sexoIdiomasSerializer,
      json.id_usuario_sexo && {
        idUsuarioSexo: json.id_usuario_sexo,
        value: json.id_usuario_sexo,
      },
      json.sexo && { sexo: json.sexo, label: json.sexo }
    );

    return sexoIdiomasSerializer;
  }
}
