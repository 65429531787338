/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import AlertMenssage from "../../../components/AlertMenssage";
import ButtonSave from "../../../components/ButtonSave";
import { Checkbox } from "primereact/checkbox";
import ConfirmationDialog from "../../../components/dialogs/confirmation-dialog";
import TextareaInput from "../../../components/TextareaInput";
import TextInput from "../../../components/TextInput";
import EventService from "../../../service/event.service";
import LanguagesService from "../../../service/language.service";
import { languageFields } from "../../Products/ProductsForm/idioma";
import CalendarInput from "../../../components/CalendarInput";
import AlertService from "../../../service/alert.service";
import queryString from "query-string";
import explicacaoUri from "../../../assets/images/explicacaoUri.png";
import explicacaoUrlChat from "../../../assets/images/explicacaoUrlChat.png";
import explicacaoUrlVideo from "../../../assets/images/explicacaoUrlVideo.png";
import {
  eventsSchema,
  failedValidationEvent,
} from "../../../validations/event.validation";
import DropdownInput from "../../../components/DropdownInput";
import EditionService from "../../../service/edition.service";
import EventsImages from "../EventImage";
import { format } from "date-fns";
import ModalEventosDestaques from "../../../components/ModalEventosDestaques";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ExpositoresService from "../../../service/expositores.service";
import TextInputDuvidas from "../../../components/TextInputDuvidas";
import { Button } from "primereact/button";
import EventLogoImage from "../EventLogoImage";
import "./style.scss";
import EventoImagemService from "../../../service/evento-imagem.service";
import QueryOptions from "../../../utils/query-options";
import { removerAcentos } from "../../../utils/remover-caracteres-especiais";
import ProporcaoImagemDialogo from "../../../components/ProporcaoImagemDialogo";
import { verificarUrlVimeo } from "../../../utils/verificar-vimeo-embed";
import { verificarUrlYoutube } from "../../../utils/verificar-youtube-embed";
import EventoImagensBanners from "../EventoImagensBanners";

export const languageEventFields = {
  titulo: "",
  descricao: "",
  idIdioma: "",
};

export const idiomaImagemBanners = {
  titulo: "",
  link: "",
  idIdioma: "",
};

export default function EventForm({ location }) {
  var quantidadeEventosDestaquesDoDia = 2;

  const [modalAberto, setModalAberto] = useState(false);
  const [eventoConfirmado, setEventoConfirmado] = useState(null);
  const { id } = useParams();
  const [action, setAction] = useState("create");
  const [currentLanguage, setCurrentLanguage] = useState(1);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dataInicioVerificar, setDataInicioVerificar] = useState();
  const [eventosDestaquesDoDia, setEventosDestaquesDoDia] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [expositores, setExpositores] = useState([]);
  const [editions, setEditions] = useState([]);
  const [chatAoVivo, setChatAoVivo] = useState(false);
  const [eventoExterno, setEventoExterno] = useState(false);
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);
  const [displayConfirmationDialogLogo, setDisplayConfirmationDialogLogo] =
    useState(false);
  const [displayConfirmationDialogBanner, setDisplayConfirmationDialogBanner] =
    useState(false);
  const [event, setEvent] = useState();
  const [loadingInfo, setLoadingInfo] = useState(true);

  const animatedComponents = makeAnimated();
  const [itemParaExcluir, setItemParaExcluir] = useState();
  const [arquivosImagens, setArquivosImagens] = useState([]);
  const [atualizarImagens, setAtualizarImagens] = useState(
    new Date().getTime()
  );
  const [flagUrlVideoInvalido, setFlagUrlVideoInvalido] = useState(false);

  const _eventoImagemService = new EventoImagemService();
  const _eventService = new EventService();
  const _expositorService = new ExpositoresService();

  useEffect(
    (event) => {
      async function getEvent(languages) {
        setAction("edit");
        const event = await _eventService.read(id);
        const eventoLogos = await _eventService.getLogo(parseInt(id));
        const eventoBanner = await _eventService.getBanner(id);

        if (eventoLogos?.logos.length) {
          const logos = [];
          eventoLogos.logos.forEach((item) => {
            logos.push({ id: item.id_evento_logotipo });
          });

          setArquivosImagens(logos);
        }

        if (event.hasOwnProperty("idExpositor")) {
          const expositor = await _expositorService.read(event.idExpositor);
          event.expositores = expositor;
        }

        if (event.urlChat !== "" || event.urlChat !== undefined) {
          setChatAoVivo(true);
        }
        if (event.uriUnico !== "" || event.uriUnico !== undefined) {
          setEventoExterno(true);
        }

        let idiomas = [];
        languages.forEach((element) => {
          idiomas.push({
            ...languageFields,
            idIdioma: element.id,
          });
        });

        const idIdiomas = [];
        event.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

        const idiomasFaltantes = [];
        idiomas.forEach((item) => {
          if (!idIdiomas.includes(item.idIdioma)) {
            idiomasFaltantes.push(item);
          }
        });

        const allLanguages = [...event.idiomas, ...idiomasFaltantes].sort(
          (v1, v2) => v1.idIdioma - v2.idIdioma
        );

        setDataInicioVerificar(event.dataInicioStr);

        let eventoLogosValidas = [];
        if (eventoLogos.logos?.length) {
          eventoLogosValidas = eventoLogos.logos;
        } else {
          eventoLogosValidas.push({ logos: [{ titulo: "" }] });
        }

        let eventosBannerValidos = [];
        if (eventoBanner.length) {
          eventosBannerValidos = eventoBanner;
        } else {
          eventosBannerValidos.push({
            titulo: "",
            idiomas: [
              { idIdioma: 1, link: "" },
              { idIdioma: 2, link: "" },
              { idIdioma: 3, link: "" },
            ],
          });
        }

        setEvent({
          ...event,
          idiomas: allLanguages,
          logos: eventoLogosValidas,
          banners: eventosBannerValidos,
        });

        setLoadingInfo(false);
      }

      async function init() {
        const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
        const _edicaoService = new EditionService();
        const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
          queryOptionsEdicaoAtiva
        );

        const _languagesService = new LanguagesService();
        const languages = await _languagesService.list();

        const _expositoresService = new ExpositoresService();
        const expositores = await _expositoresService.list(
          new QueryOptions([["per_page", 10000]])
        );
        setExpositores(expositores);

        let idiomas = [];
        languages.forEach((element) => {
          idiomas.push({
            ...languageEventFields,
            idIdioma: element.id,
          });
        });

        const eventFields = {
          uriUnico: "",
          dataInicio: "",
          dataFim: "",
          edicaoAtual: edicaoAtual?.id,
          urlVideo: "",
          destaque: false,
          eventosParceiros: false,
          idiomas: [languageEventFields],
          eventoDisponivel: "",
          logos: [{ titulo: "" }],
          banners: [
            {
              titulo: "",
              idiomas: [
                { idIdioma: 1, link: "" },
                { idIdioma: 2, link: "" },
                { idIdioma: 3, link: "" },
              ],
            },
          ],
        };

        const _editionService = new EditionService();
        const editions = await _editionService.list();
        setEditions(editions);

        editions.forEach((element) => {
          if (!element.disabled) {
            eventFields.idEdicao = element?.value;
          }
        });

        setEvent({ ...eventFields, idiomas });
        setLanguages(languages);

        const queryStringObj = queryString.parse(location.search);

        if (queryStringObj) {
          if (queryStringObj.acao === "criado") {
            AlertService.success("Evento adicionado com sucesso");
          } else if (queryStringObj.acao === "deletado") {
            AlertService.success("Evento deletado com sucecsso");
          }
        }

        if (id) {
          getEvent(languages);
        } else {
          setAction("create");
          setLoadingInfo(false);
        }
      }
      init();
    },
    [id]
  );

  async function createEvent(values) {
    if (values.banners) {
      values.banners.forEach((item) => {
        item.idiomas.forEach((item) => {
          if (item.link) {
            if (
              item.link.includes("https://") ||
              item.link.includes("http://")
            ) {
              return;
            } else {
              item.link = `https://${item.link}`;
            }
          }
        });
      });
    }

    setLoading(true);
    const data = { ...values };
    data.idiomas = data.idiomas.filter(
      (idioma) => idioma.titulo && idioma.descricao
    );
    data.eventoCriadoPelaAnfacer = true;

    if (action === "create") {
      if (
        values.eventosDestaquesMesmoDia?.length >=
          quantidadeEventosDestaquesDoDia &&
        !eventoConfirmado
      ) {
        setModalAberto(true);
      } else if (
        values.eventosDestaquesMesmoDia.length <=
          quantidadeEventosDestaquesDoDia &&
        !eventoConfirmado
      ) {
        await _eventService
          .create(data)
          .then(async (response) => {
            values.logos.forEach(async (item, index) => {
              item.parentId = response.id;
              const logo = await _eventService.criarLogo(item);
              const imagem = arquivosImagens.find(
                (item) => item?.index === index
              );

              if (imagem) {
                await _eventoImagemService.EventoLogoImagem(
                  logo.id_evento_logotipo,
                  imagem.file
                );

                setAtualizarImagens(new Date().getTime());
              }
            });

            values.banners.forEach(async (item, index) => {
              item.parentId = response.id;
              await _eventService.criarBanner(item);
            });

            history.push(`/eventos/editar/${response.id}?acao=criado`);
          })
          .catch(({ response }) => {
            AlertService.warn(response.data.msg);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      if (
        values.eventosDestaquesMesmoDia?.length >=
          quantidadeEventosDestaquesDoDia &&
        !eventoConfirmado
      ) {
        setModalAberto(true);
      } else {
        await _eventService
          .update(data)
          .then(async (response) => {
            values.logos.forEach(async (item, index) => {
              if (item?.id_evento_logotipo) {
                const logo = await _eventService.editarLogo(item);
                const imagem = arquivosImagens.find(
                  (item) => item.id === logo.id_evento_logotipo
                );

                if (imagem) {
                  await _eventoImagemService.EventoLogoImagem(
                    logo.id_evento_logotipo,
                    imagem.file
                  );

                  setAtualizarImagens(new Date().getTime());
                }
              } else {
                item.parentId = id;
                const logo = await _eventService.criarLogo(item);
                const imagem = arquivosImagens.find(
                  (item) => item?.index === index
                );

                if (imagem) {
                  await _eventoImagemService.EventoLogoImagem(
                    logo.id_evento_logotipo,
                    imagem.file
                  );

                  setAtualizarImagens(new Date().getTime());
                }
              }
            });

            values.banners.forEach(async (item, index) => {
              if (item.idEventoBanner) {
                await _eventService.editarBanner(item);
              } else {
                item.parentId = response.id;
                await _eventService.criarBanner(item);
              }
            });

            let idiomas = [];
            languages.forEach((element) => {
              idiomas.push({
                ...languageEventFields,
                idIdioma: element.id,
              });
            });

            const idIdiomas = [];
            response.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

            const idiomasFaltantes = [];
            idiomas.forEach((item) => {
              if (!idIdiomas.includes(item.idIdioma)) {
                idiomasFaltantes.push(item);
              }
            });

            const allLanguages = [
              ...response.idiomas,
              ...idiomasFaltantes,
            ].sort((v1, v2) => v1.idIdioma - v2.idIdioma);

            const eventoLogos = await _eventService.getLogo(id);
            let eventoLogosValidas = [];
            if (eventoLogos.logos?.length) {
              eventoLogosValidas = eventoLogos.logos;
            } else {
              eventoLogosValidas.push({ logos: [{ titulo: "" }] });
            }

            const eventoBanner = await _eventService.getBanner(id);
            let eventosBannerValidos = [];
            if (eventoBanner.length) {
              eventosBannerValidos = eventoBanner;
            } else {
              eventosBannerValidos.push({
                titulo: "",
                idiomas: [
                  { idIdioma: 1, link: "" },
                  { idIdioma: 2, link: "" },
                  { idIdioma: 3, link: "" },
                ],
              });
            }

            setEvent({
              ...response,
              idiomas: allLanguages,
              logos: eventoLogosValidas,
              banners: eventosBannerValidos,
            });

            AlertService.success("Evento atualizado com sucesso");
          })
          .catch(({ response }) => {
            AlertService.warn(response.data.msg);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
    setLoading(false);
  }

  async function handleDelete() {
    await _eventService.delete(id);
    history.push(`/eventos-oficiais?acao=deletado`);
    setDisplayConfirmationDialog(false);
  }

  async function handleDeleteLogo() {
    await _eventService
      .deleteLogo(itemParaExcluir.item.id_evento_logotipo)
      .then(() => {
        itemParaExcluir.arrayHelpers.remove(itemParaExcluir.index);
        setDisplayConfirmationDialogLogo(false);
      });
  }

  async function handleDeleteBanner() {
    await _eventService
      .deleteBanner(itemParaExcluir.item.idEventoBanner)
      .then(() => {
        itemParaExcluir.arrayHelpers.remove(itemParaExcluir.index);
        setDisplayConfirmationDialogBanner(false);
      });
  }

  function selectLanguage(item) {
    setCurrentLanguage(item.id);
  }

  function checarUrlEvento(props) {
    if (props.values?.urlVideo && !props.values?.eventoExterno) {
      if (verificarUrlVimeo(props.values?.urlVideo)) {
        setFlagUrlVideoInvalido(false);
      } else if (verificarUrlYoutube(props.values?.urlVideo)) {
        setFlagUrlVideoInvalido(false);
      } else {
        setFlagUrlVideoInvalido(true);
      }
    } else {
      setFlagUrlVideoInvalido(false);
    }
  }

  function verificarIdiomaSelecionadoBanners(banner) {
    const bannerIdiomaAtual = banner.idiomas.filter(
      (item) => item.idIdioma !== currentLanguage
    );

    if (bannerIdiomaAtual[0].idIdioma === currentLanguage) {
      return false;
    }
  }

  return (
    <div className="p-grid">
      <ConfirmationDialog
        header="Excluir esse item vai apagar todas as informações"
        action="Excluir mesmo assim?"
        display={displayConfirmationDialog}
        onClose={() => setDisplayConfirmationDialog(false)}
        onConfirm={handleDelete}
      />

      {loadingInfo && (
        <div className="loading-info-container">
          <lottie-player
            src="https://assets4.lottiefiles.com/packages/lf20_58vkk48j.json"
            background="transparent"
            speed="1"
            style={{ width: "500px" }}
            loop
            autoplay
          />
        </div>
      )}
      <div className="p-col-12" hidden={loadingInfo}>
        <div className="card">
          <Formik
            enableReinitialize={true}
            validationSchema={eventsSchema}
            initialValues={event}
            onSubmit={async (values) => {
              setLoading(true);
              if (!flagUrlVideoInvalido) {
                values.dataInicioStr = dataInicioVerificar;

                const eventosOrdenadosEmDataEHora = await _eventService.list();

                const eventosDestaquesMesmoDia = [];

                eventosOrdenadosEmDataEHora.forEach((evento) => {
                  if (
                    evento.dataInicioStr === values.dataInicioStr &&
                    evento.destaque === true &&
                    values.destaque
                  ) {
                    eventosDestaquesMesmoDia.push(evento);
                  }
                });

                const eventosDestaquesAparecendoNaHome = [];

                for (
                  let index = 0;
                  index !== quantidadeEventosDestaquesDoDia;
                  index++
                ) {
                  eventosDestaquesMesmoDia[index] &&
                    (eventosDestaquesAparecendoNaHome[index] =
                      eventosDestaquesMesmoDia[index]);
                }

                values.eventosDestaquesMesmoDia =
                  eventosDestaquesAparecendoNaHome;
                setEventosDestaquesDoDia(eventosDestaquesAparecendoNaHome);

                if (eventoConfirmado === false) {
                  setEventoConfirmado(null);
                  return;
                }

                createEvent(values);
              }
            }}
          >
            {(props) => {
              return (
                <Form autoComplete="off">
                  <h1>{action === "create" ? "Adicionar" : "Editar evento"}</h1>

                  <hr className="hr-header" />
                  <h3>Informe o tipo do evento</h3>

                  <div className="p-grid p-col-12">
                    <div className="p-col-12 p-md-3 p-lg-2 campo-input">
                      <label>Evento oficial </label>
                      <Checkbox
                        onChange={(e) => {
                          props.setFieldValue("eventoOficial", e?.checked);
                          props.setFieldValue("uriUnico", "");

                          if (props.values?.eventoParceiro) {
                            props.setFieldValue("eventoParceiro", !e.checked);
                          }

                          if (props.values?.eventoExpositor) {
                            props.setFieldValue("eventoExpositor", !e.checked);
                          }
                          setEventoExterno(false);
                        }}
                        checked={props.values?.eventoOficial}
                        name="eventoOficial"
                      />
                    </div>

                    <ModalEventosDestaques
                      modalAberto={modalAberto}
                      setModalAberto={setModalAberto}
                      setEventoConfirmado={setEventoConfirmado}
                      eventoConfirmado={eventoConfirmado}
                      formValues={props.values}
                      languages={languages}
                      setEvent={setEvent}
                      setLoading={setLoading}
                      action={action}
                      eventosDestaquesDoDia={eventosDestaquesDoDia}
                      quantidadeEventosDestaquesDoDia={
                        quantidadeEventosDestaquesDoDia
                      }
                    />

                    <div className="p-col-12 p-md-3 p-lg-2 campo-input">
                      <label>Evento parceiro </label>
                      <Checkbox
                        onChange={(e) => {
                          props.setFieldValue("eventoParceiro", e.checked);

                          if (props.values.eventoOficial) {
                            props.setFieldValue("eventoOficial", !e.checked);
                          }

                          if (props.values.eventoExpositor) {
                            props.setFieldValue("eventoExpositor", !e.checked);
                          }

                          setEventoExterno(true);
                        }}
                        checked={props.values?.eventoParceiro}
                        name="eventoParceiro"
                      />
                    </div>

                    <div className="p-col-12 p-md-3 p-lg-2 campo-input">
                      <label>Evento expositor </label>
                      <Checkbox
                        onChange={(e) => {
                          props.setFieldValue("eventoExpositor", e.checked);

                          if (props.values.eventoOficial) {
                            props.setFieldValue("eventoOficial", !e.checked);
                          }

                          if (props.values.eventoParceiro) {
                            props.setFieldValue("eventoParceiro", !e.checked);
                          }

                          setEventoExterno(true);
                        }}
                        checked={props.values?.eventoExpositor}
                        name="eventoExpositor"
                      />
                    </div>
                  </div>

                  {(props.values?.eventoOficial ||
                    props.values?.eventoParceiro ||
                    props.values?.eventoExpositor) && (
                    <>
                      <div className="p-grid">
                        {languages.map((item, index) => (
                          <div key={index}>
                            <button
                              className={
                                item.id === currentLanguage
                                  ? "p-button-dash"
                                  : "p-button-teste"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                selectLanguage(item);
                              }}
                            >
                              {item.nome}
                            </button>
                          </div>
                        ))}
                      </div>

                      <div className="p-grid">
                        <FieldArray
                          name="idiomas"
                          render={(arrayHelpers) => (
                            <div className="p-col-12 campos-inputs-idiomas">
                              {props?.values?.idiomas?.map((item, index) => (
                                <div
                                  key={index}
                                  hidden={!(item.idIdioma === currentLanguage)}
                                  style={{ marginLeft: "-5px" }}
                                >
                                  <TextInput
                                    name={`idiomas[${index}].titulo`}
                                    classes="p-col-12 p-md-6 p-lg-10"
                                    className="input"
                                    label="Título"
                                    required={
                                      index === 0 ||
                                      (item.descricao && index > 0)
                                    }
                                    maxLength={50}
                                    quantidadeCaracteres={50}
                                  />

                                  <TextareaInput
                                    name={`idiomas[${index}].descricao`}
                                    classes="p-col-12 p-md-6 p-lg-10"
                                    className="input"
                                    label="Descrição"
                                    required={
                                      index === 0 || (item.titulo && index > 0)
                                    }
                                    maxLength={160}
                                    quantidadeCaracteres={160}
                                  />

                                  <TextareaInput
                                    name={`idiomas[${index}].textoDescritivo`}
                                    classes="p-col-12 p-md-6 p-lg-10"
                                    className="input"
                                    label="Texto descritivo"
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        />

                        <div className="p-col-12 p-md-4 p-lg-2 ">
                          <label>
                            Data de início
                            <span className="required-hash">*</span>
                          </label>
                          <CalendarInput
                            name="dataInicio"
                            className="input"
                            selected={props.values?.dataInicio}
                            onChange={(e) => {
                              if (e) {
                                setDataInicioVerificar(format(e, "dd/MM/yyyy"));
                              }
                              props.setFieldValue("dataInicio", e);
                            }}
                            maxDate={props.values?.dataFim}
                          />
                        </div>

                        <div className="p-col-12 p-md-4 p-lg-2 datas-containers">
                          <label>
                            Data de fim <span className="required-hash">*</span>
                          </label>
                          <CalendarInput
                            name="dataFim"
                            className="input"
                            selected={props.values?.dataFim}
                            onChange={(e) => {
                              props.setFieldValue("dataFim", e);
                            }}
                            minDate={props.values?.dataInicio}
                          />
                        </div>

                        <div className="p-col-12 p-md-4 p-lg-2 datas-containers">
                          <label>
                            Edição <span className="required-hash">*</span>
                          </label>
                          <DropdownInput
                            name="idEdicao"
                            options={editions}
                            value={props.values?.idEdicao}
                          />
                        </div>

                        <div className="p-col-12 p-md-4 p-lg-2 datas-containers">
                          <label>Palestrante(s)</label>
                          <TextInput
                            name="palestrante"
                            className="input"
                            placeholder="José da Silva, Carlos Jean"
                          />
                        </div>

                        {props.values.eventoExpositor && (
                          <div className="p-col-12 p-lg-2 p-md-4 p-sm-6">
                            <label>Expositor</label>
                            <Select
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              name="expositores"
                              options={expositores}
                              placeholder="Selecione"
                              value={props.values?.expositores}
                              onChange={(event) =>
                                props.setFieldValue("expositores", event)
                              }
                            />
                          </div>
                        )}

                        {props.values.eventoOficial && props.values.dataInicio && (
                          <div className="p-col-12 p-md-3 p-lg-12 campo-input">
                            <label>Esse evento é o destaque do dia? </label>
                            <Checkbox
                              disalbed={props.values.dataInicio}
                              onChange={async (e) => {
                                props.setFieldValue("destaque", e.checked);

                                const eventosOrdenadosEmDataEHora =
                                  await _eventService.list();

                                const eventosDestaquesMesmoDia = [];

                                eventosOrdenadosEmDataEHora.forEach(
                                  (evento) => {
                                    evento.dataInicioStrNewValue = format(
                                      new Date(props.values.dataInicio),
                                      "dd/MM/yyyy"
                                    );
                                    if (
                                      (evento.dataInicioStr ===
                                        evento.dataInicioStrNewValue &&
                                        evento.destaque) === true
                                    ) {
                                      eventosDestaquesMesmoDia.push(evento);
                                    }
                                  }
                                );

                                const eventosDestaquesAparecendoNaHome = [];

                                for (
                                  let index = 0;
                                  index !== quantidadeEventosDestaquesDoDia;
                                  index++
                                ) {
                                  eventosDestaquesMesmoDia[index] &&
                                    (eventosDestaquesAparecendoNaHome[index] =
                                      eventosDestaquesMesmoDia[index]);
                                }
                                setEventosDestaquesDoDia(
                                  eventosDestaquesAparecendoNaHome
                                );

                                eventosDestaquesAparecendoNaHome.forEach(
                                  (evento) => {
                                    if (evento.id === parseInt(id)) {
                                      setModalAberto(true);
                                    }
                                  }
                                );
                              }}
                              checked={props.values?.destaque}
                              name="destaque"
                            />
                          </div>
                        )}

                        {props.values.eventoOficial &&
                          !props.values.dataInicio && (
                            <div className="p-col-12 p-md-12 p-lg-12 campo-input">
                              <label>
                                Informe a data inicial do evento para liberar a
                                verificação de evento destaque do dia.{" "}
                              </label>
                            </div>
                          )}

                        {!props.values.eventoOficial && (
                          <div className="p-col-12 p-md-3 p-lg-8  campo-input">
                            <Checkbox
                              onChange={(e) =>
                                props.setFieldValue(
                                  "eventoPatrocinadoHome",
                                  e.checked
                                )
                              }
                              checked={props.values?.eventoPatrocinadoHome}
                              name="eventoPatrocinadoHome"
                            />
                            <label>
                              Evento patrocinado (aparecerá na home){" "}
                            </label>
                          </div>
                        )}

                        {!props.values.eventoOficial && (
                          <div className="p-col-12 p-md-3 p-lg-12  campo-input">
                            <Checkbox
                              onChange={(e) => {
                                props.setFieldValue("eventoExterno", e.checked);
                                props.setFieldValue("uriUnico", "");
                              }}
                              checked={props.values?.eventoExterno}
                              name="eventoExterno"
                            />
                            <label>
                              Esse evento é um evento externo (fora da
                              plataforma){" "}
                            </label>
                          </div>
                        )}

                        {(!eventoExterno || !props.values?.eventoExterno) && (
                          <div className="p-col-12 p-md-4 p-lg-3 datas-containers">
                            <TextInputDuvidas
                              label="Endereço único do evento"
                              name="uriUnico"
                              className="input"
                              header="Endereço único do evento"
                              content="O endereço único do evento é o item que aparecerá na url do evento. Os usuários acessarão o evento por esse endereço único e ele é sempre o último item na url. É possível vê-lo destacado em vermelho logo abaixo no exemplo."
                              imagemExplicativa={explicacaoUri}
                              imagemExplicativaAlt={
                                'Uma imagem informando que um endereço único é a última parte de um URL, por exemplo: "https://google.com/esse-é-o-endereco-unico"'
                              }
                              values={props?.values?.uriUnico}
                              required={true}
                              onChange={(e) => {
                                const uriLimpa = removerAcentos(e.target.value);
                                props.setFieldValue("uriUnico", uriLimpa);
                              }}
                            />
                          </div>
                        )}

                        {(!eventoExterno || !props.values?.eventoExterno) && (
                          <div className="p-col-12 p-md-12 p-lg-12 campo-input">
                            <Checkbox
                              onChange={() => {
                                setChatAoVivo(!chatAoVivo);
                                props.setFieldValue("urlChat", null);
                              }}
                              checked={chatAoVivo}
                            />
                            <label> Esse evento terá chat ao vivo</label>
                          </div>
                        )}

                        <div className="p-col-12 p-md-4 p-lg-5 datas-containers">
                          <TextInputDuvidas
                            label={
                              !eventoExterno || !props.values?.eventoExterno
                                ? "Url do iframe do vídeo"
                                : "Url do evento"
                            }
                            name="urlVideo"
                            className="input"
                            header="Url do vídeo do vimeo"
                            content="Esse é um código exclusivo de um determinado evento. Esse código pode ser conseguido no momento em que o evento é criado"
                            imagemExplicativa={explicacaoUrlVideo}
                            onBlur={() => {
                              checarUrlEvento(props);
                            }}
                          />
                          {flagUrlVideoInvalido && (
                            <div>
                              <p className="error-input">
                                Ops! O link desse iframe parece inválido
                              </p>
                            </div>
                          )}
                        </div>

                        {chatAoVivo &&
                          (!eventoExterno || !props.values?.eventoExterno) && (
                            <div className="p-col-12 p-md-4 p-lg-5 datas-containers">
                              <TextInputDuvidas
                                label="Url do iframe do chat "
                                name="urlChat"
                                className="input"
                                header="Url do chat ao vivo do vimeo"
                                content="Esse é um código exclusivo de um determinado chat ao vivo de um evento. Esse código pode ser conseguido no momento em que o evento é criado"
                                imagemExplicativa={explicacaoUrlChat}
                              />
                            </div>
                          )}

                        <div className="p-col-12 p-md-12 p-lg-12 campo-input">
                          <Checkbox
                            onChange={() => {
                              props.setFieldValue(
                                "eventoDisponivel",
                                !props.values?.eventoDisponivel
                              );
                            }}
                            checked={props.values?.eventoDisponivel}
                          />
                          <label>
                            Ficará disponível após a transmissão datada
                          </label>
                        </div>

                        {action === "edit" && (
                          <div className="p-col-12 p-md-4 p-lg-12">
                            <h3>Imagem do evento</h3>
                            <ProporcaoImagemDialogo
                              isJustDesktop={true}
                              widthDesktop={420}
                              heightDesktop={300}
                            />
                            <hr />
                            <EventsImages
                              languages={languages}
                              width={420}
                              height={300}
                              form={props}
                            />
                          </div>
                        )}

                        {!loading ? (
                          <>
                            {(!eventoExterno ||
                              !props.values?.eventoExterno) && (
                              <div className="p-col-12 p-md-12 p-lg-12">
                                <h3>Logos do evento</h3>
                                <hr />
                                <FieldArray
                                  name="logos"
                                  render={(arrayHelpers) => (
                                    <>
                                      <div className="p-grid">
                                        <div className="imagem-container">
                                          {props?.values?.logos?.map(
                                            (item, index) => (
                                              <div className="card-container-imagem p-col-6">
                                                <div className="p-grid">
                                                  <TextInput
                                                    placeholder="Insira o título da logo aqui"
                                                    label={`Título da logo ${
                                                      index + 1
                                                    }`}
                                                    name={`logos[${index}].titulo`}
                                                    classes="p-col-11"
                                                    className="input"
                                                  />

                                                  <div
                                                    className="p-col-1"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <i
                                                      className="trashIcon"
                                                      onClick={() => {
                                                        if (
                                                          item?.id_evento_logotipo
                                                        ) {
                                                          setItemParaExcluir({
                                                            item: item,
                                                            index: index,
                                                            arrayHelpers:
                                                              arrayHelpers,
                                                          });
                                                          setDisplayConfirmationDialogLogo(
                                                            true
                                                          );
                                                        } else {
                                                          arrayHelpers.remove(
                                                            index
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <img
                                                        src={require("../../../assets/icons/deleteIconColor.svg")}
                                                        alt="Remover Logo"
                                                      />
                                                    </i>
                                                  </div>

                                                  <div className="p-col-12 p-md-4 p-lg-12">
                                                    <div>
                                                      <ProporcaoImagemDialogo
                                                        desktopEMobile={true}
                                                        desktopEMobileWidth={
                                                          250
                                                        }
                                                        desktopEMobileHeight={
                                                          250
                                                        }
                                                      />
                                                    </div>

                                                    <EventLogoImage
                                                      index={index}
                                                      arquivos={arquivosImagens}
                                                      setArquivos={
                                                        setArquivosImagens
                                                      }
                                                      idEventoLogotipo={
                                                        item?.id_evento_logotipo
                                                      }
                                                      atualizarImagens={
                                                        atualizarImagens
                                                      }
                                                      form={props}
                                                      item={item}
                                                      width={250}
                                                      height={250}
                                                    />
                                                  </div>
                                                </div>

                                                <ConfirmationDialog
                                                  header="Excluir essa logo vai apagar as informações dela no portal de administração e na plataforma"
                                                  action="Excluir mesmo assim?"
                                                  display={
                                                    displayConfirmationDialogLogo
                                                  }
                                                  onClose={() =>
                                                    setDisplayConfirmationDialogLogo(
                                                      false
                                                    )
                                                  }
                                                  onConfirm={() => {
                                                    handleDeleteLogo(
                                                      itemParaExcluir
                                                    );
                                                  }}
                                                />
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>

                                      <Button
                                        label="Nova logo"
                                        type="button"
                                        onClick={() => {
                                          arrayHelpers.insert(
                                            props?.values?.logos?.length + 1,
                                            { titulo: "", filename: "" }
                                          );
                                        }}
                                      />
                                    </>
                                  )}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="loading-info-container">
                              <lottie-player
                                src="https://assets9.lottiefiles.com/packages/lf20_rwq6ciql.json"
                                background="transparent"
                                speed="1"
                                style={{ width: "120px", height: "120px" }}
                                loop
                                autoplay
                              />
                            </div>
                          </>
                        )}
                      </div>

                      {(!eventoExterno || !props.values?.eventoExterno) && (
                        <div className="p-col-12 p-md-12 p-lg-12">
                          {!loading ? (
                            <div>
                              <h3>Banners do evento</h3>
                              <hr />
                              <div className="card-container-imagem p-col-6">
                                <div className="p-grid">
                                  {languages.map((item, index) => (
                                    <div key={index}>
                                      <button
                                        className={
                                          item.id === currentLanguage
                                            ? "p-button-dash"
                                            : "p-button-teste"
                                        }
                                        onClick={(e) => {
                                          e.preventDefault();
                                          selectLanguage(item);
                                        }}
                                      >
                                        {item.nome}
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <FieldArray
                                name="banners"
                                render={(arrayHelpers) => (
                                  <>
                                    <div className="p-grid">
                                      <div className="imagem-container">
                                        {props.values?.banners?.map(
                                          (banner, index) => (
                                            <div className="card-container-imagem p-col-6">
                                              <div
                                                key={index}
                                                hidden={verificarIdiomaSelecionadoBanners(
                                                  banner
                                                )}
                                                style={{ marginLeft: "-5px" }}
                                              >
                                                <TextInput
                                                  name={`banners[${index}].titulo`}
                                                  classes="p-col-12 p-md-12 p-lg-12"
                                                  className="input"
                                                  label="Título"
                                                />

                                                <TextInput
                                                  name={`banners[${index}].idiomas[${
                                                    currentLanguage - 1
                                                  }].link`}
                                                  classes="p-col-12 p-md-12 p-lg-12"
                                                  className="input"
                                                  label="Link"
                                                />

                                                {props.values?.banners[index]
                                                  .idEventoBanner && (
                                                  <h5>{`Quantidade de cliques: ${banner.quantidadeCliques}`}</h5>
                                                )}

                                                {props.values?.banners[index]
                                                  .idEventoBanner && (
                                                  <>
                                                    <div className="p-grid">
                                                      <div className="p-md-6 p-lg-6 p-sm-12">
                                                        <div>
                                                          <ProporcaoImagemDialogo
                                                            isJustDesktop={true}
                                                            widthDesktop={1192}
                                                            heightDesktop={260}
                                                          />
                                                        </div>
                                                        <EventoImagensBanners
                                                          languages={languages}
                                                          bannerEventoIdioma={banner.idiomas.filter(
                                                            (item) =>
                                                              item.idIdioma ===
                                                              currentLanguage
                                                          )}
                                                          chave={
                                                            "imageFilename"
                                                          }
                                                          form={props}
                                                          index={index}
                                                          desktop={true}
                                                          width={1192}
                                                          height={260}
                                                        />
                                                      </div>

                                                      <div className=" p-md-12 p-lg-6 p-sm-12">
                                                        <div>
                                                          <ProporcaoImagemDialogo
                                                            isJustMobile={true}
                                                            widthMobile={300}
                                                            heightMobile={300}
                                                          />
                                                        </div>

                                                        <EventoImagensBanners
                                                          languages={languages}
                                                          bannerEventoIdioma={banner.idiomas.filter(
                                                            (item) =>
                                                              item.idIdioma ===
                                                              currentLanguage
                                                          )}
                                                          form={props}
                                                          index={index}
                                                          mobile={true}
                                                          chave={
                                                            "imageFilenameMobile"
                                                          }
                                                          width={300}
                                                          height={300}
                                                        />
                                                      </div>
                                                    </div>
                                                    <ConfirmationDialog
                                                      header="Excluir esse banner vai apagar as informações dela no portal de administração e na plataforma"
                                                      action="Excluir mesmo assim?"
                                                      display={
                                                        displayConfirmationDialogBanner
                                                      }
                                                      onClose={() =>
                                                        setDisplayConfirmationDialogBanner(
                                                          false
                                                        )
                                                      }
                                                      onConfirm={() => {
                                                        handleDeleteBanner(
                                                          itemParaExcluir
                                                        );
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </div>
                                              <i
                                                className="trashIcon"
                                                onClick={() => {
                                                  if (banner.idEventoBanner) {
                                                    setItemParaExcluir({
                                                      item: banner,
                                                      index: index,
                                                      arrayHelpers:
                                                        arrayHelpers,
                                                    });
                                                    setDisplayConfirmationDialogBanner(
                                                      true
                                                    );
                                                  } else {
                                                    arrayHelpers.remove(index);
                                                  }
                                                }}
                                              >
                                                <img
                                                  src={require("../../../assets/icons/deleteIconColor.svg")}
                                                  alt="Remover Logo"
                                                />
                                              </i>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>

                                    <div className="button-novo-item">
                                      <Button
                                        label="Novo banner"
                                        type="button"
                                        onClick={() => {
                                          arrayHelpers.insert(
                                            props?.values?.banners?.length + 1,
                                            {
                                              titulo: "",
                                              idiomas: [
                                                { idIdioma: 1, link: "" },
                                                { idIdioma: 2, link: "" },
                                                { idIdioma: 3, link: "" },
                                              ],
                                            }
                                          );
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                              />
                            </div>
                          ) : (
                            <>
                              <div className="loading-info-container">
                                <lottie-player
                                  src="https://assets4.lottiefiles.com/packages/lf20_58vkk48j.json"
                                  background="transparent"
                                  speed="1"
                                  style={{ width: "500px" }}
                                  loop
                                  autoplay
                                />
                              </div>
                            </>
                          )}
                        </div>
                      )}

                      <div
                        className="btns-inline"
                        style={{ marginTop: "14px" }}
                      >
                        <div className="btn-medium">
                          <ButtonSave
                            label={
                              action === "create" ? "Adicionar" : "Atualizar"
                            }
                            loading={loading}
                            type="submit"
                            onClick={() => {
                              if (action === "create") {
                                if (!failedValidationEvent) {
                                  AlertService.warn(
                                    "Verifique se os campos obrigatórios de todos os idiomas foram preenchidos"
                                  );
                                }
                              }

                              if (Object.keys(props.errors).length) {
                                AlertService.warn(
                                  "Verifique se todos os campos obrigatórios foram preenchidos corretamente"
                                );
                              }
                            }}
                          />
                        </div>

                        <div className="btn-medium">
                          {action === "edit" && (
                            <button
                              className="button-delete"
                              label="Excluir"
                              styles="p-button-secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                setDisplayConfirmationDialog(true);
                              }}
                            >
                              Excluir
                            </button>
                          )}
                        </div>

                        <div className="btn-medium">
                          <button
                            className="button-cancel"
                            onClick={() => {
                              history.push(`/eventos-oficiais`);
                            }}
                          >
                            Voltar
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </Form>
              );
            }}
          </Formik>
          <AlertMenssage />
        </div>
      </div>
    </div>
  );
}
