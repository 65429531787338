import ProductsLanguagesImagesSerializer from "./products-languages-images.serializer";

export default class EventoImagemSerializer {
  constructor() {
    this.productsLanguageImages = new ProductsLanguagesImagesSerializer();
  }

  fromJson(json) {
    const eventoImagem = {};

    Object.assign(
      eventoImagem,
      json.id_produto_imagem && { id: json.id_produto_imagem },
      json.id_produto && { idProduto: json.id_produto },
      json.image_filename && { imageFile: json.image_filename },
      json.idiomas
        ? {
            idiomas: json.idiomas.map((item) =>
              this.productsLanguageImages.fromJson(item)
            ),
          }
        : { idiomas: [] }
    );

    if (eventoImagem?.idiomas.length) {
      eventoImagem.nome = eventoImagem.idiomas[0].nome;
      eventoImagem.descricao = eventoImagem.idiomas[0].descricao;
    }

    return eventoImagem;
  }

  toJson(eventoImagem) {
    const eventoImagemToJson = {};

    Object.assign(
      eventoImagemToJson,
      eventoImagem.idiomas && {
        idiomas: eventoImagem.idiomas.map((item) =>
          this.productsLanguageImages.toJson(item)
        ),
      },
      eventoImagem.imageFile && { filename: eventoImagem.imageFile }
    );

    return eventoImagemToJson;
  }

  toFormData(file) {

    let formData = new FormData();
    formData.append("file", file);

    return formData;
  }
}
