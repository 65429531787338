import languagesSerializer from "./languages.serializer";
import AvertisingdLanguageSerializer from "./avertisingd-language.serializer";
import { format } from "date-fns";
import { fromDateGmt, toDateGMT } from "../../utils/format-date";

export default class AvertisingdSerializer {
  constructor() {
    this.languageSerializer = new languagesSerializer();
    this.avertisingdLanguageSerializer = new AvertisingdLanguageSerializer();
  }

  fromJson(json) {
    const avertisingdLanguageSerializer = {
      quantidadeCliques: json.quantidade_cliques,
    };
    Object.assign(
      avertisingdLanguageSerializer,
      json.id_publicidade && { id: json.id_publicidade },
      json.areas && {
        idsAreas: json.areas,
      },
      json.disabled && { disabled: json.disabled },
      json.id_edicao && { idEdicao: json.id_edicao },
      json.idiomas
        ? {
            idiomas: json.idiomas.map((item) =>
              this.avertisingdLanguageSerializer.fromJson(item)
            ),
          }
        : { idiomas: [] },
      json.data_inicio && {
        dataInicio: fromDateGmt(json.data_inicio),
        dataInicioStr: format(fromDateGmt(json.data_inicio), "dd/MM/yyyy"),
      },
      json.data_fim && {
        dataFim: fromDateGmt(json.data_fim),
        dataFimStr: format(fromDateGmt(json.data_fim), "dd/MM/yyyy"),
      }
    );

    if (avertisingdLanguageSerializer.disabled) {
      avertisingdLanguageSerializer.statusStr = "Inativo";
    } else {
      avertisingdLanguageSerializer.statusStr = "Ativo";
    }

    return avertisingdLanguageSerializer;
  }

  toJson(avertisingd) {
    const avertising = {};

    Object.assign(
      avertising,
      avertisingd.idsAreas && {
        ids_areas: avertisingd.idsAreas.map((item) => item.value),
      },
      avertisingd.edicaoAtual && { id_edicao: avertisingd.edicaoAtual },
      avertisingd.idiomas && {
        idiomas: avertisingd.idiomas.map((item) =>
          this.avertisingdLanguageSerializer.toJson(item)
        ),
      },
      avertisingd.dataInicio && {
        data_inicio: toDateGMT(avertisingd.dataInicio),
      },
      avertisingd.dataFim && { data_fim: toDateGMT(avertisingd.dataFim) },
      avertisingd.disabled ? { disabled: true } : { disabled: false }
    );

    return avertising;
  }

  toFormData(file) {
    let formData = new FormData();
    formData.append("file", file[0]);

    return formData;
  }
}
