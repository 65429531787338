import PerfilEmpresaIdiomasSerializer from "./perfil-empresas-idiomas.serializer";

export default class PerfilEmpresaSerializer {
  constructor() {
    this.perfilEmpresaIdiomasSerializer = new PerfilEmpresaIdiomasSerializer();
  }

  fromJson(json) {
    const perfilEmpresa = {
      idiomas: json.idiomas.map((item) =>
        this.perfilEmpresaIdiomasSerializer.fromJson(item)
      ),
    };

    return perfilEmpresa.idiomas[0];
  }
}
