import React from "react";
import { useField } from "formik";

export default function InputCnpj({ label, classes = "", ...props }) {
  const [field, meta] = useField(props);

  let value = field.value;
  if (field.value) {
    value = field.value.replace(/[^0-9]/g, "");

    value = value.replace(/^(\d{2})(\d)/, "$1.$2");
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
    value = value.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return (
    <div className={classes}>
      <label htmlFor={props.id || props.name}>{label}</label>
      <div className="input-block">
        <input
          className="input-text"
          {...field}
          {...props}
          value={value}
          maxLength="18"
        />
        {props.loading && (
          <i className="pi pi-spin pi-spinner input-loading"></i>
        )}
      </div>
      <div className="error-input">
        {meta.touched && meta.error ? (
          <div className="error ">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
}
