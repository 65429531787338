import React, { useRef, useState, useEffect } from "react";
import AlertDialog from "../../components/dialogs/alert-dialog";

import "./styles.scss";

export default function ImageeInputPreview(props) {
  const [image, setImage] = useState("");
  const fileInput = useRef(null);
  const viewImage = useRef(null);
  const [displayDialog, setDisplayDialog] = useState(false);

  useEffect(() => {
    if (props.image) {
      setImage(props.image);
    }
  }, [props.image]);

  async function handleFileChange(event) {
    if (props.tamanhoEspecifico) {
      const files = event.target.files || event.dataTransfer.files;
      const promises = [];
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const promise = new Promise((resolve, reject) => {
          const image = new Image();
          let url = "";
          image.onload = function () {
            file.width = image.width;
            file.height = image.height;
            resolve(file);
          };
          url = URL.createObjectURL(file);
          image.src = url;
        });
        promises.push(promise);
      }

      const data = await Promise.all(promises);

      if (props?.width && props?.height) {
        if (data[0].width === props.width && data[0].height === props.height) {
          setImage(URL.createObjectURL(data[0]));
          props.changeImage(data[0]);
        } else {
          setDisplayDialog(true);
          setImage(props.image);
        }

        return data;
      }
    } else {
      const files = event.target.files;
      if (files.length) {
        const file = event.target.files[0];
        setImage(URL.createObjectURL(file));
        props.changeImage(file);
      }
    }
  }

  return (
    <div className="image-input-preview">
      <AlertDialog
        display={displayDialog}
        textList={[
          "Dimensão da imagem precisa ser:",
          `Largura ${props?.width}px`,
          `Altura ${props?.height}px`,
        ]}
        onClose={() => setDisplayDialog(false)}
      />
      <div className="photo">
        <input
          type="file"
          accept="image/*"
          ref={fileInput}
          onChange={handleFileChange}
        />
        <img
          src={image}
          className="image-profile"
          alt="Perfil"
          ref={viewImage}
        ></img>
        <div className="icon" onClick={(e) => fileInput.current.click()}>
          <img src={require("../../assets/icons/cameraIcon.svg")} alt=""></img>
        </div>
      </div>
    </div>
  );
}
