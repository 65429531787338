import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

AlertDialog.propTypes = {
  display: PropTypes.bool.isRequired,
  onClose: PropTypes.any.isRequired,
  textList: PropTypes.array,
};

export default function AlertDialog({
  display,
  onClose,
  textList,
  title = "Atenção!",
}) {
  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Ok"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        />
      </div>
    );
  };

  let texts = [];
  for (let t of textList) {
    texts.push(<p>{t}</p>);
  }

  return (
    <>
      <Dialog
        header={title}
        visible={display}
        dismissableMask={true}
        style={{ width: "360px" }}
        onHide={() => onClose()}
        footer={renderFooter()}
        closable={false}
      >
        <div style={{ padding: "10px 0" }}>{texts}</div>
      </Dialog>
    </>
  );
}
