export const CALENDAR_PT = {
  firstDayOfWeek: 1,
  dayNames: [
    "domingo",
    "segunda",
    "terça",
    "quarta",
    "quinta",
    "sexta",
    "sábado",
  ],
  dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
  dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
  monthNames: [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ],
  monthNamesShort: [
    "jan",
    "fev",
    "mar",
    "abr",
    "mai",
    "jun",
    "jul",
    "ago",
    "set",
    "out",
    "nov",
    "dez",
  ],
  today: "Hoje",
  clear: "Limpar",
  dateFormat: "dd/mm/yy",
  weekHeader: "Se",
};

export const METODOS = ["Leitura", "Criação", "Atualização", "Remoção"];

export const ENDPOINTS = [
  "Retorna listas dos catálogos de um determinado expositor",
  "Retorna o catálogo registrado",
  "Cria um registro de catálogo",
  "Atualiza dados de um catálogo",
  "Remove um catálogo",
  "Realiza upload de arquivo para um catálogo",
  "Retorna a imagem de um catálogo",
  "Retorna o arquivo de um catálogo",
  "Retorna lista de cidades de um estado",
  "Cria um registro de cidade atrelado a um estado",
  "Atualiza dados de uma cidade",
  "Remove uma cidade",
  "Retorna lista de edições",
  "Retorna os dados de uma edição",
  "Cria um registro de edição",
  "Atualiza os dados de uma edição",
  "Remove uma edição",
  "Retorna lista de estados",
  "Retorna lista de eventos",
  "Retorna os dados de um evento",
  "Cria um registro de evento",
  "Atualiza dados de um evento",
  "Realiza upload de arquivo para um evento",
  "Retorna a imagem de um evento",
  "Remove um evento",
  "Retorna lista das modalidades de expositor",
  "Cria um registro da modalidade do expositor",
  "Atualiza dados de uma modalidade do expositor",
  "Remove uma modalidade de expositor",
  "Retorna lista de expositores",
  "Retorna dados de um expositor",
  "Cria um registro de expositor",
  "Atualiza dados de um expositor",
  "Realiza upload de arquivo para um expositor",
  "Realiza upload do logotipo de um expositor",
  "Retorna a imagem do logotipo de um expositor",
  "Retorna a imagem de um expositor",
  "Remove um Expositor",
  "Retorna lista de idiomas",
  "Cria um registro de idiomas",
  "Atualiza dados de um idioma",
  "Remove um Idioma",
  "Retorna lista de mapas",
  "Retorna os dados de um mapa",
  "Cria um registro de mapa",
  "Atualiza dados de um mapa",
  "Remove um mapa",
  "Realiza upload de arquivo de um mapa",
  "Retorna a imagem de um mapa",
  "Retorna lista de moderação dos vídeos de um expositor",
  "Retorna os dados de uma moderação de vídeo",
  "Cria um registro de moderação do vídeo",
  "Atualiza dados de uma moderação de vídeo",
  "Remove uma moderação de vídeo",
  "Retorna lista de módulos registrados (Seções que representam grupos da aplicação)",
  "Cria um registro de módulo (Seção referente a algum grupo da aplicação)",
  "Atualiza dados de um módulo (Seção referente a algum grupo da aplicação)",
  "Remove um módulo (Seção referente a algum grupo da aplicação)",
  "Retorna lista de permissões de acesso por usuário",
  "Atualiza dados de permissão de acesso do usuário",
  "Retorna lista tipos de permissão",
  "Retorna os dados das imagens de um produto",
  "Retorna os dados de uma imagem do produto",
  "Cria um registro de ProdutoImagem",
  "Atualiza dados de um ProdutoImagem",
  "Realiza upload de arquivo para um ProdutoImagem",
  "Retorna a imagem de um ProdutoImagem",
  "Remove um ProdutoImagem",
  "Realiza upload de vários arquivos",
  "Retorna lista dos produtos de um determinado expositor",
  "Retorna os dados de um produto",
  "Cria um registro de produto",
  "Atualiza dados de um produto",
  "Remove um produto",
  "Retorna listas de ProdutoTipos",
  "Retorna os dados de um ProdutoTipo",
  "Cria um registro de ProdutoTipo",
  "Atualiza dados de um ProdutoTipo",
  "Remove um tipo de produto",
  "Retorna listas das áreas para publicidades",
  "Cria um registro da área da publicidade",
  "Atualiza dados da área da publicidade",
  "Remove uma área de publicidade",
  "Retorna lista de publicidades",
  "Retorna os dados de uma publicidade",
  "Cria um registro de publicidade",
  "Atualiza dados de uma publicidade",
  "Realiza upload de arquivo para uma publicidade",
  "Retorna a imagem de uma publicidade",
  "Remove uma publicidade",
  "Troca a senha de um usuário e envia para seu e-mail",
  "Atualiza a senha de um usuário",
  "Retorna lista de segmentos",
  "Retorna segmento pelo ID",
  "Cria um registro de segmento",
  "Atualiza dados de um segmento",
  "Remove um segmento",
  "Retorna lista de tipos de telefone",
  "Cria um registro de tipo de telefone",
  "Atualiza dados de um tipo de telefone",
  "Remove um tipo de telefone",
  "Retorna lista de telefones de um usuário",
  "Retorna dados de um telefone",
  "Cria um registro de telefone para um usuário",
  "Atualiza dados de um telefone",
  "Retorna lista perfis de usuários",
  "Retorna lista de usuários",
  "Retorna dados de um usuário por ID",
  "Retorna dados dos usuários pertencentes à um expositor",
  "Cria um registro de usuário",
  "Atualiza dados de um usuário",
  "Realiza upload de arquivo de imagem para um usuário",
  "Retorna a imagem do Perfil de um usuário",
  "Remove um Usuário",
];
