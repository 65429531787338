import ExpositorEstatistica from "./expositor-estatistica-idiomas-serializer";

export default class ExpositoresEstatisticasSerializer {
  constructor() {
    this.expositorEstatistica = new ExpositorEstatistica();
  }

  fromJson(json) {
    const expositorEstatistica = {};
    Object.assign(
      expositorEstatistica,
      json.items && {
        estatisticas: json.items.map((item) =>
          this.expositorEstatistica.fromJson(item)
        ),
      },
      json.titulo_grupo && { titulo_grupo: json.titulo_grupo }
    );

    return expositorEstatistica;
  }

  toJson(expositor) {
    const expositoresEstatiticasToJson = {};

    Object.assign(
      expositoresEstatiticasToJson,
      expositor.estatisticas && {
        estatisticas: expositor.estatisticas,
      },
      expositor.idiomas && {
        idiomas: expositor.idiomas,
      },
      expositor.arrayParaAtualizar && {
        idiomas: this.expositorEstatistica.toJson(expositor),
      }
    );

    return expositoresEstatiticasToJson;
  }
}
