import React from "react";
import { useField } from "formik";
import InputMask from "react-input-mask";

export default function TextInput({
  label,
  classes = "",
  required,
  quantidadeCaracteres,
  ...props
}) {
  const [field, meta] = useField(props);

  return (
    <div className={classes}>
      <label htmlFor={props.id || props.name}>
        {label}{" "}
        {quantidadeCaracteres && (
          <span className="quantidade-caracteres">
            ({quantidadeCaracteres} caracteres)
          </span>
        )}
        {required && <span className="required-hash"> *</span>}
      </label>

      <div className="input-block">
        {props.iconleft && (
          <img className="input-icon" src={props.iconleft} alt="teste" />
        )}

        {!props.mask ? (
          <input className="input-text" {...field} {...props} />
        ) : (
          <InputMask
            className="input-text"
            {...field}
            {...props}
            alwaysShowMask={true}
          />
        )}
        {props.loading && (
          <i className="pi pi-spin pi-spinner input-loading"></i>
        )}
      </div>
      <div className="error-input">
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
}
