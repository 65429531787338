export default class ReportSerializer {
  toJson(report) {
    const reportToJson = {};

    Object.assign(
      reportToJson,
      report.requester && { id_usuario: report.requester },
      report.company && { id_empresa: report.company.id },
      report.accountingGrid && { id_grade_contabil: report.accountingGrid },
      report.nf_fatura !== null &&
        report.nf_fatura !== "" &&
        report.nf_fatura !== undefined && { nf_fatura: report.nf_fatura },
      report.dateStart && {
        datas_entrada: report.dateEnd
          ? [new Date(report.dateStart), new Date(report.dateEnd)]
          : [new Date(report.dateStart)],
      },
      report.expirationDateStart && {
        datas_vencimento: report.expirationDateEnd
          ? [
              new Date(report.expirationDateStart),
              new Date(report.expirationDateEnd),
            ]
          : [new Date(report.expirationDateStart)],
      }
    );

    return reportToJson;
  }
}
