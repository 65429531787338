/* eslint-disable react-hooks/exhaustive-deps */
import { editor } from "@overlapmedia/imagemapper";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import AlertMenssage from "../../../components/AlertMenssage";
import BoxUploadNote from "../../../components/BoxUploadNote";
import ButtonSave from "../../../components/ButtonSave";
import ConfirmationDialog from "../../../components/dialogs/confirmation-dialog";
import AlertService from "../../../service/alert.service";
import EditionService from "../../../service/edition.service";
import ExpositoresService from "../../../service/expositores.service";
import MapaInterativoService from "../../../service/mapa-interativo.service";
import QueryOptions from "../../../utils/query-options";
import "./styles.scss";

export default function MapainterativoForm() {
  const { id: idMapa } = useParams();
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);
  const [data, setData] = useState();
  const [mapa, setMapa] = useState();
  const [expositores, setExpositores] = useState();
  const [edicoes, setEdicoes] = useState();
  const [file, setFile] = useState();
  const [edicaoSelected, setEdicaoSelected] = useState(6);
  const [nome, setNome] = useState("");
  const [slug, setSlug] = useState("");
  const [action, setAction] = useState("create");
  const history = useHistory();
  const [naturalWidth, setNaturalWidth] = useState(0);
  const [naturalHeight, setNaturalHeight] = useState(0);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [imagemTrocada, setImagemTrocada] = useState(false);

  const _mapaService = new MapaInterativoService();

  const tipos = {
    rect: "Retângulo",
    ellipse: "Elipse",
    polygon: "Polígono",
  };

  useEffect(() => {
    if (file) {
      limpaImagemCarregadaTela();
      const src = URL.createObjectURL(file[0]);
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setNaturalWidth(img.naturalWidth);
        setNaturalHeight(img.naturalHeight);

        inicializar(undefined, src);
      };
    }
  }, [file]);

  function limpaImagemCarregadaTela() {
    const imagens = document.getElementsByTagName("image");
    if (imagens.length) {
      for (let i = 0; i < imagens.length; i++) {
        imagens[i].remove();
      }
    }
  }

  useEffect(() => {
    async function listExpositores() {
      const queryOptions = new QueryOptions([
        ["fields", "nome_fantasia, id_expositor, numero_estande"],
        ["per_page", 10000],
      ]);

      const _expositorService = new ExpositoresService();
      let expositoresData = await _expositorService.list(queryOptions);

      expositoresData.forEach(
        (item) =>
          (item.label = `${item.label} | Estande: ${item.numeroEstande}`)
      );

      setExpositores(expositoresData);
    }

    async function listEdicoes() {
      const queryOptions = new QueryOptions([
        ["fields", "id_edicao,nome"],
        ["per_page", 10000],
      ]);
      const _editionService = new EditionService();
      const edicoesData = await _editionService.list(queryOptions);
      setEdicoes(edicoesData);
    }

    async function init() {
      await listExpositores();
      await listEdicoes();

      const _editionService = new EditionService();
      const edicaoDoMomento = await _editionService.list();
      edicaoDoMomento.forEach((element) => {
        if (!element.disabled) {
          setEdicaoSelected(element.value);
        }
      });

      if (idMapa) {
        setAction("edit");
        const _mapaService = new MapaInterativoService();
        const response = await _mapaService.read(idMapa);
        setEdicaoSelected(response.idEdicao);
        setSlug(response.slug);
        setNome(response.nome || "");

        let src = undefined;
        if (response.filename) {
          src = `${process.env.REACT_APP_API_URL}mapas/${idMapa}/display`;
        }

        const img = new Image();
        img.src = src;

        img.onload = () => {
          setNaturalWidth(img.naturalWidth);
          setNaturalHeight(img.naturalHeight);

          inicializar(response, src);
        };
        setLoadingInfo(false);
      }
      setLoadingInfo(false);
    }
    init();
  }, [idMapa]);

  function getDataMap() {
    const aux = JSON.parse(mapa.export(false));

    if (!data) {
      aux.components[0].index = 0;
      setData(aux);
    } else {
      const dataAux = { ...data };
      aux.components.forEach((item) => {
        const index = dataAux.components.findIndex(
          (element) => element.id === item.id
        );

        if (index >= 0) {
          dataAux.components[index] = { ...dataAux.components[index], ...item };
        } else {
          item.index = dataAux.components.length;
          dataAux.components.unshift(item);
        }

        dataAux.idCounter = aux.idCounter;
      });

      setData({ ...dataAux });
    }
  }

  function inicializar(values = null, imageSrc = null) {
    if (!mapa || imageSrc) {
      const myEditor = editor("mapa-interativo");
      myEditor.loadImage(imageSrc);

      myEditor.selectMode();

      if (values && values.json) {
        const mapaJson = JSON.parse(values.json);
        myEditor.import(JSON.stringify(mapaJson.mapa));

        setData(mapaJson.mapa);
      }

      setMapa(myEditor);
    }
  }

  function onChangeExpositor(id, value) {
    const components = [...data.components];
    const index = components.findIndex((item) => item.id === id);
    if (index > -1) {
      components[index].value = value;
      setData({ ...data, components: [...components] });
    }
  }

  function onSelectItem(event) {
    mapa.selectMode();
    const id = event.value;
    const components = [...data.components];
    components.forEach((item) => (item.checked = false));
    const index = components.findIndex((item) => item.id === id);
    if (index > -1) {
      components[index].checked = id;
      setData({ ...data, components: [...components] });
      mapa.selectComponent(id);
    }
  }

  function onRemoverItem(id) {
    const aux = [...data.components];
    mapa.removeComponent(id);

    const mapaObj = JSON.parse(mapa.export(false));
    mapaObj.components.map((item, index) => {
      const itemAux = aux.find((element) => element.id === item.id);
      return (item.value = itemAux.value);
    });

    mapaObj.components.reverse();
    setData(mapaObj);
  }

  function gerarCodigoImageMap() {
    const idsMapa = [];
    let html = '<map id="map-area" name="image-map">';

    data.components.forEach((item) => {
      let type = "";
      let coords = [];

      if (item.type === "polygon") {
        type = "poly";
        item.data.forEach((coord) => {
          coords.push(coord.x);
          coords.push(coord.y);
        });
      } else {
        coords = [
          item.data.x,
          item.data.y,
          item.data.x + item.data.width,
          item.data.y + item.data.height,
        ];

        if (item.type === "rect") {
          type = "rect";
        } else {
          type = "circle";
        }
      }

      html += `<area alt="" title="" data-expositor="${item.value
        }" coords="${coords.join(",")}" shape="${type}" />`;

      idsMapa.push({ id_mapa: item.id, idExpositor: item.value });
    });

    html += "</map>";

    return { html, idsMapa };
  }

  async function onSubmit() {
    if (!data || !edicaoSelected) {
      AlertService.warn("Preencha todos os campos para continuar.");
    } else if (data) {
      let possuiRelacaoMapaExpositor = true;
      data.components.forEach((item) => {
        if (!item.value || item.value === "") {
          possuiRelacaoMapaExpositor = false;
        }
      });

      if (!possuiRelacaoMapaExpositor) {
        AlertService.warn("Indique a relação do Mapa com Expositores.");
      } else {
        // Sucesso
        const objMapa = gerarCodigoImageMap();
        const values = {
          nome,
          html: objMapa.html,
          idEdicao: edicaoSelected,
          json: JSON.stringify({
            mapa: data,
            mapaExpositor: objMapa.idsMapa,
          }),
        };

        if (idMapa) {
          values.id = idMapa;
          await _mapaService
            .update(values)
            .then(async () => {
              if (file) {
                await _mapaService.upload(idMapa, file);
                setFile(null);
                setImagemTrocada(false);
              }

              AlertService.success("Mapa atualizado com sucesso");
            })
            .catch(({ response }) => {
              AlertService.warn(response.data.msg);
            });
        } else {
          await _mapaService
            .create(values)
            .then(async (response) => {
              await _mapaService.upload(response.id, file);
              history.push(`/mapas-interativos?acao=crimouseupado`);
            })
            .catch(({ response }) => {
              AlertService.warn(response.data.msg);
            });
        }
      }
    }
  }

  async function handleDelete() {
    const _mapaService = new MapaInterativoService();
    await _mapaService.delete(idMapa);
    history.push("/mapas-interativos?acao=deletado");
    setDisplayConfirmationDialog(false);
  }

  function copiarIframe() {
    var iframe = `<iframe
        width="100%"
        height="100%"
        frameborder="0"
        src="${process.env.REACT_APP_PLATAFORMA_URL}mapa/${slug}/nome"
        title="Expo Revestir"
      ></iframe>`;
    navigator.clipboard.writeText(iframe).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  // function ordernarItems(obj, chave) {
  //   return obj.sort(function (a, b) {
  //     return a[chave] < b[chave] ? -1 : a[chave] > b[chave] ? 1 : 0;
  //   });
  // }

  function handleTrocarImagem() {
    document.getElementById("input-imagem").click();
    setImagemTrocada(true);
  }

  function handleCarregarImagemSalva() {
    limpaImagemCarregadaTela();
    const src = `${process.env.REACT_APP_API_URL
      }mapas/${idMapa}/display?time=${new Date().getTime()}`;

    const img = new Image();
    img.src = src;

    img.onload = () => {
      setNaturalWidth(img.naturalWidth);
      setNaturalHeight(img.naturalHeight);

      inicializar(undefined, src);
      setImagemTrocada(false);
      setFile(null);
    };
  }

  return (
    <>
      <ConfirmationDialog
        header="Excluir esse usuário vai apagar todas as suas informações."
        action="Excluir mesmo assim?"
        display={displayConfirmationDialog}
        onClose={() => setDisplayConfirmationDialog(false)}
        onConfirm={handleDelete}
      />

      {loadingInfo && (
        <div className="loading-info-container">
          <lottie-player
            src="https://assets4.lottiefiles.com/packages/lf20_58vkk48j.json"
            background="transparent"
            speed="1"
            style={{ width: "500px" }}
            loop
            autoplay
          />
        </div>
      )}

      <div id="page-mapa" className="p-grid">
        <div className="p-col-12" hidden={loadingInfo}>
          <div className="card">
            <h1>Mapa</h1>
            <hr className="hr-header" />
            <div
              className="box p-grid"
              style={{ display: file || idMapa ? "none" : "unset" }}
            >
              <div className="p-col-12 p-lg-6">
                <label style={{ fontSize: 18 }}>
                  Imagem <span className="required-hash">*</span>
                </label>
                <BoxUploadNote
                  setFile={setFile}
                  file={file}
                  isList={false}
                  isObject={true}
                  acceptList="image/jpeg"
                  preview={true}
                />
                <small>Tamanho ideal da imagem 1680x1080</small>
              </div>
            </div>
            <div hidden={!file && !idMapa}>
              <div className="acoes">
                <Button
                  label="Retângulo"
                  className="p-button-raised p-button-rounded"
                  onClick={() => mapa.rect()}
                />

                <Button
                  label="Polígono"
                  onClick={() => mapa.polygon()}
                  className="p-button-raised p-button-rounded"
                />
              </div>

              <div id="mapa">
                <svg
                  onMouseUp={() => getDataMap()}
                  id="mapa-interativo"
                  style={{
                    width: `${naturalWidth}px`,
                    height: `${naturalHeight}px`,
                    border: "1px dashed #ccc",
                    background: "#fafafa",
                  }}
                ></svg>
              </div>
            </div>
            <div className="acoes-imagem">
              {(idMapa || file) && (
                <div onClick={handleTrocarImagem}>Trocar imagem</div>
              )}
              {idMapa && imagemTrocada && file && (
                <div onClick={handleCarregarImagemSalva}>
                  Voltar para imagem salva
                </div>
              )}
            </div>
            {data?.components?.map((item, index) => (
              <div className="p-grid acoes-image">
                <div className="p-col-12 p-lg-1">
                  <RadioButton
                    value={item.id}
                    name="item"
                    checked={item?.checked === item.id}
                    onChange={onSelectItem}
                  />
                </div>

                <div className="p-col-12 p-lg-1">{tipos[item.type]}</div>

                <div className="p-col-12 p-lg-5">
                  <label>Expositor</label>
                  <Dropdown
                    filter={true}
                    filterPlaceholder="Pesquisar"
                    placeholder="Selecione"
                    options={expositores}
                    json
                    className="full"
                    value={item?.value}
                    onChange={(event) =>
                      onChangeExpositor(item.id, event.value)
                    }
                  />
                </div>

                <div className="p-col-12 p-lg-3 ">
                  <i
                    className="pi pi-trash m-t-20"
                    onClick={() => onRemoverItem(item.id)}
                  ></i>
                </div>
              </div>
            ))}
            <div className="p-grid">
              <div className="p-col-12 p-lg-4">
                <label>
                  Nome <span className="required-hash">*</span>
                </label>
                <input
                  value={nome}
                  onChange={({ target }) => setNome(target.value)}
                  className="input"
                />
              </div>
              <div className="p-col-12 p-lg-2">
                <label>
                  Edição <span className="required-hash">*</span>
                </label>
                <Dropdown
                  filter={true}
                  filterPlaceholder="Pesquisar"
                  placeholder="Selecione"
                  options={edicoes}
                  json
                  className="full"
                  value={edicaoSelected}
                  onChange={(e) => setEdicaoSelected(e.value)}
                />
              </div>
            </div>
            <div className="pointer" onClick={copiarIframe}>
              Copiar iframe
            </div>
            <div className="btns-inline">
              <div className="btn-medium" style={{ marginTop: "16px" }}>
                <ButtonSave
                  label={action === "edit" ? "Atualizar" : "Salvar"}
                  onClick={onSubmit}
                />

                {action === "edit" && (
                  <button
                    className="button-delete"
                    style={{ marginLeft: "20px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setDisplayConfirmationDialog(true);
                    }}
                  >
                    Excluir
                  </button>
                )}

                <button
                  style={{ marginLeft: "20px" }}
                  className="button-cancel"
                  onClick={() => {
                    history.push(`/mapas-interativos`);
                  }}
                >
                  Voltar
                </button>
              </div>
            </div>
            <AlertMenssage />
          </div>
        </div>
      </div>
    </>
  );
}
