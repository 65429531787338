import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import ButtonSave from "../../../components/ButtonSave";

import EmpresaCatalogosService from "../../../service/empresa-catalogos.service";
import { useParams } from "react-router";
import "./style.scss";

export default function CatalogoArquivo({ setArquivo, arquivo }) {
  const { id } = useParams();
  const [time, setTime] = useState(new Date().getTime());

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  async function onUpload() {
    const _catalogoService = new EmpresaCatalogosService();
    setLoading(true);
    await _catalogoService.upload(id, files).then(() => {
      setArquivo(true);
      setTime(new Date().getTime());
    });

    setFiles([]);
    setLoading(false);
  }

  return (
    <div>
      <section className="container">
        <div
          className="box-upload"
          {...getRootProps({ className: "dropzone" })}
        >
          <input {...getInputProps()} />

          {!files.length ? (
            <>"Arraste e solte o arquivo aqui ou clique para selecionar."</>
          ) : (
            <>{files[0].path}</>
          )}
        </div>

        <aside style={thumbsContainer}>{}</aside>

        {arquivo && (
          <div className="col-md-8">
            <iframe
              src={`${process.env.REACT_APP_API_URL}catalogos/${id}/arquivos/display?${time}`}
              width="100%"
              height="780"
              title="pdf"
            />
          </div>
        )}

        <ButtonSave
          label="Adicionar Arquivo"
          loading={loading}
          disabled={!files.length}
          onClick={onUpload}
        />
      </section>
    </div>
  );
}
