/* eslint-disable react-hooks/exhaustive-deps */
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CertificadosService from "../../service/certificado.service";
import QueryOptions from "../../utils/query-options";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AlertMenssage from "../../components/AlertMenssage";
import ButtonSave from "../../components/ButtonSave";
import ConfirmationDialog from "../../components/dialogs/confirmation-dialog";
import LoadDialog from "../../components/dialogs/load-dialog";
import AlertService from "../../service/alert.service";
import "./style.scss";

export default function Certificados() {
  const history = useHistory();
  // Paginação
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);
  const [first, setFirst] = useState(1);
  const [last, setLast] = useState(0);

  // Busca
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(null);
  const [queryArray, setQueryArray] = useState([]);

  // Options
  const optionsStatus = [
    { label: "Emitido / Enviado", value: 1 },
    { label: "Emitido", value: 2 },
    { label: "Pendente", value: 3 },
    { label: "Processando", value: 4 },
    { label: "Erro", value: 5 },
    { label: "Não Solicitado", value: 6 },
  ];

  const [loading, setLoading] = useState(false);
  const [loadingLottie, setLoadingLottie] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [certificados, setCertificados] = useState([]);

  const animatedComponents = makeAnimated();

  // Dialogs
  const [displayDialogEmitirCertificado, setDisplayDialogEmitirCertificado] =
    useState(false);
  const [displayLoadDialog, setDisplayLoadDialog] = useState(false);

  // Services
  const _certificadoService = new CertificadosService();

  // Actions
  const [actionDownloadLoading, setActionDownloadLoading] = useState();
  const [actionSendLoading, setActionSendLoading] = useState();

  const actionBodyTable = (event) => {
    return (
      <div className="icons">
        <i
          className={`pi ${
            actionDownloadLoading === event.idUser
              ? "pi-spin pi-spinner"
              : "pi-download"
          }`}
          title="Baixar certificado"
          onClick={() => certificadoDownload(event)}
        ></i>
        <i
          className={`pi ${
            actionSendLoading === event.idUser
              ? "pi-spin pi-spinner"
              : "pi pi-envelope"
          }`}
          title="Enviar certificado"
          onClick={() => certificadoEnviarEmail(event)}
        ></i>
        <i
          className="pi pi-user"
          title="Abrir usuário"
          onClick={() => history.push(`/usuarios/editar/${event.idUser}`)}
        ></i>
      </div>
    );
  };

  const statusBodyTable = (event) => {
    const status = event.idStatus - 1;
    const statusClass = [
      "emitido-enviado",
      "enviado",
      "pendente",
      "processando",
      "erro",
      "nao-solicitado",
    ];

    return (
      <span
        className={`status ${statusClass[status]}`}
        title={event.erro ? event.erro : ""}
      >
        {optionsStatus[status].label}
      </span>
    );
  };

  const certificadoDownload = async (data) => {
    try {
      setActionDownloadLoading(data.idUser);
      await _certificadoService.certificadoDownload(data.idUser, data.name);

      if ([5, 6].includes(data.idStatus)) {
        data.idStatus = 2;
      }
    } catch {
      if ([5, 6].includes(data.idStatus)) {
        data.idStatus = 5;
      }
    } finally {
      setActionDownloadLoading(null);
    }
  };

  const certificadoEnviarEmail = async (data) => {
    try {
      setActionSendLoading(data.idUser);
      const response = await _certificadoService.certificadoEnviarEmail(
        data.idUser
      );

      if (response.success) {
        data.idStatus = 1;
        AlertService.success(response.msg);
      } else {
        data.idStatus = 5;
        AlertService.warn(response.msg);
      }
    } catch {
      AlertService.error("Ocorreu um erro. Tente novamente.");
    } finally {
      setActionSendLoading(null);
    }
  };

  useEffect(() => {
    async function init() {
      setLoading(true);
      const queryOptions = new QueryOptions([["page", page]]);

      if (queryArray.length) {
        queryOptions.queryKvs.push(...queryArray);
      }

      const data = await _certificadoService.getUsers(queryOptions, true);

      setCertificados(data.items);
      setMeta(data.meta);
      setLast(data.items.length);
      setLoading(false);
      setLoadingLottie(false);
    }

    init();
  }, [page, queryArray]);

  const handleChangePage = (event) => {
    let lastAux = event.first + certificados.length;
    if (lastAux > meta.totalItems) {
      lastAux = meta.totalItems;
    }

    setFirst(event.first + 1);
    setLast(lastAux);
    setPage(event.page + 1);
  };

  const onFilter = () => {
    const queryAux = [];

    if (search !== "") {
      queryAux.push(["query", search]);
    }

    if (status !== null && status?.value > 0) {
      queryAux.push(["status", status.value]);
    }

    setQueryArray(queryAux);
  };

  const cleanFilter = () => {
    setSearch("");
    setStatus(0);
    setQueryArray([]);
  };

  const emitirEnviarCertificados = async () => {
    try {
      setDisplayDialogEmitirCertificado(false);
      setDisplayLoadDialog(true);

      const data = selectedRows.map((item) => item.idUser);
      const response = await _certificadoService.generateSendCertificado(data);

      const certificadosAux = [...certificados];

      certificadosAux.forEach((item) => {
        const data = response.find(
          (element) => element.íd_usuario === item.idUser
        );
        if (data) {
          item.idStatus = data.id_certificado_feira_status;

          if (data.erro) {
            item.erro = data.erro;
          }
        }
      });
    } catch {
      AlertService.error("Ocorreu um erro. Tente novamente.");
    } finally {
      setDisplayLoadDialog(false);
    }
  };

  return (
    <>
      <ConfirmationDialog
        header="Deseja gerar e enviar os certificados por e-mail?"
        display={displayDialogEmitirCertificado}
        onClose={() => setDisplayDialogEmitirCertificado(false)}
        onConfirm={emitirEnviarCertificados}
        action="Ao confirmar, os usuários selecionados receberão por e-mail seus certificados. O processo ocorrerá em segundo plano e pode levar algum tempo. Agradecemos sua paciência."
      />

      <LoadDialog
        header="Gerando e Enviando E-mails"
        display={displayLoadDialog}
        onClose={() => setDisplayLoadDialog(false)}
        loading={true}
      />
      <div id="page-certificados" className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <h1 className="h1-container">Certificados</h1>

            <hr className="hr-header" />

            <div className="header-actions">
              <div className="container-pesquisa">
                <div className="titleFilter">Filtro:</div>
                <div className="text-input-filtragem container-usuario-filtragem">
                  <input
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Busca por nome, email ou CPF"
                  />
                </div>

                <Select
                  closeMenuOnSelect={true}
                  value={status}
                  onChange={setStatus}
                  components={animatedComponents}
                  options={optionsStatus}
                  placeholder="Status"
                />
                <button className="btn-filtragem" onClick={onFilter}>
                  Buscar
                </button>

                <button
                  onClick={cleanFilter}
                  className="limpar-filtros-button"
                  disabled={!queryArray.length}
                >
                  <span>Limpar filtros</span>
                </button>
              </div>

              <ButtonSave
                className="btn-filtragem"
                type="submit"
                disabled={!selectedRows.length}
                onClick={() => setDisplayDialogEmitirCertificado(true)}
                label="Emitir e enviar certificados"
              ></ButtonSave>
            </div>

            <div className="datatable-paginator-demo">
              <div className="alert-menssage">
                <AlertMenssage to="top" />
              </div>
              <div className="content-section implementation">
                <DataTable
                  value={certificados}
                  lazy
                  first={first}
                  rows={meta.perPage}
                  paginator={meta.totalPages > 1}
                  loading={loading}
                  totalRecords={meta.totalItems}
                  onPage={handleChangePage}
                  emptyMessage="Sem registros"
                  currentPageReportTemplate={`${first} a ${last} de ${meta.totalItems}`}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                  selectionMode={"checkbox"}
                  selection={selectedRows}
                  onSelectionChange={(e) => {
                    if (e.value !== undefined) {
                      setSelectedRows(e.value);
                    }
                  }}
                  dataKey="idUser"
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3rem" }}
                  ></Column>
                  <Column field="name" header="Participante"></Column>
                  <Column field="email" header="E-mail"></Column>
                  <Column
                    field="cpf"
                    header="CPF"
                    headerStyle={{ width: "10rem" }}
                  ></Column>
                  <Column
                    field="status"
                    header="Status"
                    headerStyle={{ width: "10rem" }}
                    body={statusBodyTable}
                  ></Column>
                  <Column
                    field="actions"
                    header="Ações"
                    headerStyle={{ width: "8rem" }}
                    body={actionBodyTable}
                  ></Column>
                </DataTable>

                {loadingLottie && (
                  <div className="loading-info-container">
                    <lottie-player
                      src="https://assets4.lottiefiles.com/packages/lf20_58vkk48j.json"
                      background="transparent"
                      speed="1"
                      style={{ width: "500px" }}
                      loop
                      autoplay
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
