import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import ButtonSave from "../../../components/ButtonSave";
import { Dialog } from "primereact/dialog";
import ImageDefault from "../../../components/ImageDefault";

import EventoImagemService from "../../../service/evento-imagem.service";
import { useParams } from "react-router";
import "./style.scss";
import AlertDialog from "../../../components/dialogs/alert-dialog";

export default function AdvertisingImage({ width, height, form }) {
  const { id } = useParams();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState(new Date().getTime());
  const [displayDialogImagem, setDisplayDialogImagem] = useState(false);

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 150,
    height: 150,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    getFilesFromEvent: async (event) => {
      const files = event.target.files || event.dataTransfer.files;
      const promises = [];
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const promise = new Promise((resolve, reject) => {
          const image = new Image();
          let url = "";
          image.onload = function () {
            file.width = image.width;
            file.height = image.height;
            resolve(file);
          };
          url = URL.createObjectURL(file);
          image.src = url;
        });
        promises.push(promise);
      }

      const data = await Promise.all(promises);

      if (width && height) {
        if (data[0].width === width && data[0].height === height) {
          setFiles(
            data.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
        } else {
          setDisplayDialogImagem(true);
        }
      } else {
        setFiles(
          data.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      }

      return data;
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="upload" />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  async function onUpload() {
    const _eventoService = new EventoImagemService();
    setLoading(true);
    await _eventoService.upload(id, files[0]).then(() => {
      form.setFieldValue(`filename`, files[0].name);
    });
    setTime(new Date().getTime());

    setFiles([]);
    setLoading(false);
  }

  return (
    <div>
      <Dialog
        header="Editar"
        visible={displayDialog}
        style={{ width: "50vw" }}
        onHide={() => setDisplayDialog(false)}
        dismissableMask
      ></Dialog>

      <AlertDialog
        display={displayDialogImagem}
        textList={[
          "Dimensão da imagem precisa ser:",
          `Largura: ${width}px`,
          `Altura: ${height}px`,
        ]}
        onClose={() => setDisplayDialogImagem(false)}
      />

      <section className="container">
        <div
          className="box-upload"
          {...getRootProps({ className: "dropzone" })}
        >
          <input {...getInputProps()} />
          <p>Arraste e solte a imagem aqui ou clique para selecionar.</p>
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>

        {files.length ? (
          <ButtonSave
            label="Adicionar Imagens"
            loading={loading}
            disabled={!files.length}
            onClick={onUpload}
          />
        ) : null}

        {form?.values?.filename && (
          <div
            className="m-t-20"
            id="image-card"
            style={{ display: "flex", gap: 20, flexWrap: "wrap" }}
          >
            <div className="image-card" style={{ marginBottom: "16px" }}>
              <div className="image">
                <ImageDefault
                  urlImage={`${process.env.REACT_APP_API_URL}eventos/${id}/display?${time}`}
                />
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
