import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";

import Authentication from "../service/authentication.service";

import AuthLayout from "../pages/layout/Auth";
import App from "../pages/layout/Full";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  const user = Authentication._user;
  const signed = Authentication.isAuthenticated();
  const changePassword = user && user.changePassword;

  if (user && user.profile.id === 3) {
    Authentication.logout();
    return <Redirect to="/entrar" />;
  }

  if (!signed && isPrivate) {
    return <Redirect to="/entrar" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/" />;
  }

  if (changePassword || rest.path === "/mudar-senha") {
    if (changePassword === undefined) {
      return <Redirect to="/" />;
    }

    if (changePassword && rest.path !== "/mudar-senha") {
      return <Redirect to="/mudar-senha" />;
    }
  }

  let Layout = App;
  if (!signed || changePassword) {
    Layout = AuthLayout;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
