import { format } from "date-fns";
import { fromDateGmt, toDateGMT } from "../../utils/format-date";

export default class EditionSerializer {
  fromJson(json) {
    const edition = {};

    Object.assign(
      edition,
      json.disabled && { disabled: json.disabled },
      json.disabled ? { statusStr: "Inativo" } : { statusStr: "Ativo" },
      json.id_edicao && {
        id: json.id_edicao,
        value: json.id_edicao,
        idEdicao: json.id_edicao,
      },
      json.nome && { nome: json.nome, label: json.nome },
      json.data_inicio && {
        dataInicio: fromDateGmt(json.data_inicio),
        dataInicioStr: format(fromDateGmt(json.data_inicio), "dd/MM/yyyy"),
      },
      json.data_fim && {
        dataFim: fromDateGmt(json.data_fim),
        dataFimStr: format(fromDateGmt(json.data_fim), "dd/MM/yyyy"),
      },
      json.expositores
        ? { expositores: json.expositores.map((item) => item.id_expositor) }
        : { expositores: [] },

      json.disabled
    );

    return edition;
  }

  toJson(edition) {
    const editionToJson = {};
    Object.assign(
      editionToJson,
      edition.dataFim && { data_fim: toDateGMT(edition.dataFim) },
      edition.dataInicio && { data_inicio: toDateGMT(edition.dataInicio) },
      edition.nome && { nome: edition.nome },
      edition.expositores
        ? {
            expositores: edition.expositores.map((item) => {
              return { id_expositor: item.id };
            }),
          }
        : { expositores: [] }
    );

    return editionToJson;
  }

  toFormData(file) {
    let formData = new FormData();
    formData.append("file", file[0]);

    return formData;
  }
}
