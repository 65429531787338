import api from "./api.service";
import CoreApiService from "./core-api.service";
import ExpositoresSerializer from "./serializers/expositores.serializer";

export default class ExpositoresService extends CoreApiService {
  constructor() {
    super(undefined, "expositores", new ExpositoresSerializer());
  }

  async validaUri(uri, id_expositor) {
    const response = await api.get(
      `${this.endpoint}/${id_expositor}/valida-uri/${uri}`
    );
    return response.data;
  }

  async uploadImage(file, id) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await api.put(
      `${this.endpoint}/${id}/logotipos/upload`,
      formData
    );
    const data = response.data;
    return this.serializer.fromJson(data.oret);
  }

  async deletarItems(id, deleteAllEndPoint) {
    const response = await api.delete(
      `${this.endpoint}/${id}/${deleteAllEndPoint}`
    );

    return response;
  }
}
