import maskCpfCnpj from "../../utils/mask-cpf-cnpj";

export default class CertificadoSerializer {
  fromJson(json) {
    const certificado = {};

    Object.assign(
      certificado,
      json.id_usuario && { idUser: json.id_usuario },
      json.nome && { name: json.nome },
      json.email && { email: json.email },
      json.cpf && { cpf: maskCpfCnpj(json.cpf) }
    );

    if (json?.certificado_feira.length) {
      certificado.idStatus = json.certificado_feira[0].id_certificado_feira_status;
      certificado.erro = json.certificado_feira[0]?.erro_mensagem;
    } else {
      certificado.idStatus = 6;
    }

    return certificado;
  }

  toJson(certificado) {
    const certificadoToJson = {};

    Object.assign(
      certificadoToJson,

      certificado.dataCriacao && { data_criacao: certificado.dataCriacao },
      certificado.idSegmento && { id_segmento: certificado.idSegmento }
    );
  }
}
