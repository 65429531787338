import api from "./api.service";
import CoreApiService from "./core-api.service";
import EventosBannersSerializer from "./serializers/evento-banner.serializer";
import EventoImagemSerializer from "./serializers/evento-imagem-serializer";

export default class EventoImagemService extends CoreApiService {
  constructor() {
    super("eventos", "upload", new EventoImagemSerializer());
  }

  async upload(id, data) {
    const formData = this.serializer.toFormData(data);

    const response = await api.put(
      `${this.parentEndpoint}/${id}/upload`,
      formData
    );

    if (response.data["oret"]) {
      return this.serializer.fromJson(response.data["oret"]);
    }

    return null;
  }

  async EventoLogoImagem(id, data) {
    this.parentEndpoint = "eventos";

    const formData = this.serializer.toFormData(data);

    const response = await api.put(
      `${this.parentEndpoint}/logotipos/${id}/upload`,
      formData
    );

    if (response.data["oret"]) {
      return this.serializer.fromJson(response.data["oret"]);
    }

    return null;
  }

  async eventoBannerImagem(idEventoBannerIdioma, data, desktop, mobile) {
    this.serializer = new EventosBannersSerializer()

    let query = "";

    if (desktop) {
      query = "?desktop=true";
    }

    if (mobile) {
      query = "?mobile=true";
    }

    const formData = this.serializer.toFormData(data[0]);

    const response = await api.put(
      `${this.parentEndpoint}/banners/${idEventoBannerIdioma}/upload${query}`,
      formData
    );

    if (response.data["oret"]) {
      return this.serializer.fromJson(response.data["oret"]);
    }
    return null;
  }
}
