import React, { useState } from "react";
import TextInput from "./TextInput";

export const arrayParaAtualizar = [];

export function NumerosNaoMentem({ index, formValue, existeEstatistica }) {
  const [disabled, setDisabled] = useState(false);
  const [box1Existe, setBox1Existe] = useState(false);
  const [box2Existe, setBox2Existe] = useState(false);
  const [box3Existe, setBox3Existe] = useState(false);
  const [box4Existe, setBox4Existe] = useState(false);

  async function trocarValor(e, numeroDoBox, tituloGrupo, tipo) {
    if (tituloGrupo) {
      formValue.estatisticas[0].idiomas[index].id_idioma = index + 1;
      formValue.estatisticas[0].idiomas[index].titulo = e.target.value;
      formValue.estatisticas[0].titulo_grupo = true;

      arrayParaAtualizar[numeroDoBox] =
        formValue.estatisticas[numeroDoBox].idiomas;
    } else {
      if (tipo === "titulo") {
        if (formValue.estatisticas[numeroDoBox].idiomas[index]) {
          formValue.estatisticas[numeroDoBox].idiomas[index].titulo =
            e.target.value;
          formValue.estatisticas[numeroDoBox].idiomas[index].id_idioma =
            index + 1;

          arrayParaAtualizar[numeroDoBox] =
            formValue.estatisticas[numeroDoBox].idiomas;
        }
      } else if (tipo === "descricao") {
        formValue.estatisticas[numeroDoBox].idiomas[index].descricao =
          e.target.value;
        formValue.estatisticas[numeroDoBox].idiomas[index].id_idioma =
          index + 1;

        arrayParaAtualizar[numeroDoBox] =
          formValue.estatisticas[numeroDoBox].idiomas;
      }
    }
    setDisabled(false);
  }

  return (
    <div
      className="numeros-nao-mentem"
      style={{
        width: "100%",
        padding: "20px 0",
        backgroundColor: "#F87FB4",
      }}
    >
      <div>
        <div>
          <div className="numeros-nao-mentem-h1">
            <TextInput
              placeholder="Você pode alterar esse título"
              name={`estatisticas[0].idiomas[${index}].titulo`}
              classes="descricao-publicidade"
              className="input"
              maxlength="27"
              style={{
                textAlign: "center",
                fontSize: "1.5em",
                lineHeight: "140%",
                backgroundColor: "transparent",
                border: "none",
                paddingBottom: "2rem",
              }}
              onKeyUp={(e) => {
                trocarValor(e, 0, true, undefined);
              }}
            />
          </div>

          <div
            className="box-container"
            style={{
              width: "90%",
              margin: "auto",
              color: "white",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <div
              className="box"
              style={{
                width: "301px",
                backgroundColor: "black",
              }}
            >
              <h1>
                <TextInput
                  name={`estatisticas[1].idiomas[${index}].titulo`}
                  classes="descricao-publicidade"
                  placeholder="1M"
                  maxlength={5}
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    fontFamily: "Roboto Slab, serif",
                    fontSize: "24px",
                    lineHeight: "140%",
                    color: "white",
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  className="input"
                  disabled={box1Existe && disabled}
                  onKeyUp={(e) => {
                    if (!box1Existe) {
                      setDisabled(true);
                      setBox1Existe(true);
                      trocarValor(e, 1, false, "titulo");
                    } else {
                      trocarValor(e, 1, false, "titulo");
                    }
                  }}
                />
              </h1>
              <TextInput
                name={`estatisticas[1].idiomas[${index}].descricao`}
                placeholder="Clientes satisfeitos"
                classes="descricao-publicidade"
                maxlength={24}
                style={{
                  fontFamily: "Raleway",
                  fontSize: "1em",
                  lineHeight: "140%",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "transparent",
                  border: "none",
                }}
                className="input"
                disabled={box1Existe && disabled}
                onKeyUp={(e) => {
                  if (!box1Existe) {
                    setDisabled(true);
                    setBox1Existe(true);
                    trocarValor(e, 1, false, "descricao");
                  } else {
                    trocarValor(e, 1, false, "descricao");
                  }
                }}
              />
            </div>

            <div
              className="box"
              style={{
                width: "301px",
                backgroundColor: "black",
              }}
            >
              <TextInput
                name={`estatisticas[2].idiomas[${index}].titulo`}
                classes="descricao-publicidade"
                placeholder="600k"
                className="input"
                disabled={box2Existe && disabled}
                maxlength={5}
                style={{
                  textAlign: "center",
                  padding: "10px",
                  fontFamily: "Roboto Slab, serif",
                  fontSize: "24px",
                  lineHeight: "140%",
                  color: "white",
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onKeyUp={(e) => {
                  if (!box2Existe) {
                    setDisabled(true);
                    setBox2Existe(true);
                    trocarValor(e, 2, false, "titulo");
                  } else {
                    trocarValor(e, 2, false, "titulo");
                  }
                }}
              />
              <TextInput
                name={`estatisticas[2].idiomas[${index}].descricao`}
                classes="descricao-publicidade"
                placeholder="Seguidores nas redes sociais"
                maxlength={24}
                style={{
                  fontFamily: "Raleway",
                  fontSize: "1em",
                  lineHeight: "140%",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "transparent",
                  border: "none",
                }}
                className="input"
                disabled={box2Existe && disabled}
                onKeyUp={(e) => {
                  if (!box2Existe) {
                    setDisabled(true);
                    setBox2Existe(true);
                    trocarValor(e, 2, false, "descricao");
                  } else {
                    trocarValor(e, 2, false, "descricao");
                  }
                }}
              />
            </div>

            <div
              className="box"
              style={{
                width: "301px",
                backgroundColor: "black",
              }}
            >
              <TextInput
                name={`estatisticas[3].idiomas[${index}].titulo`}
                placeholder="21"
                maxlength={5}
                classes="descricao-publicidade"
                style={{
                  textAlign: "center",
                  padding: "10px",
                  fontFamily: "Roboto Slab, serif",
                  fontSize: "24px",
                  lineHeight: "140%",
                  color: "white",
                  backgroundColor: "transparent",
                  border: "none",
                }}
                className="input"
                disabled={box3Existe && disabled}
                onKeyUp={(e) => {
                  if (!box3Existe) {
                    setDisabled(true);
                    setBox3Existe(true);
                    trocarValor(e, 3, false, "titulo");
                  } else {
                    trocarValor(e, 3, false, "titulo");
                  }
                }}
              />
              <TextInput
                name={`estatisticas[3].idiomas[${index}].descricao`}
                placeholder="Quantidade de prêmios"
                classes="descricao-publicidade"
                maxlength={24}
                style={{
                  fontFamily: "Raleway",
                  fontSize: "1em",
                  lineHeight: "140%",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "transparent",
                  border: "none",
                }}
                className="input"
                disabled={box3Existe && disabled}
                onKeyUp={(e) => {
                  if (!box3Existe) {
                    setDisabled(true);
                    setBox3Existe(true);
                    trocarValor(e, 3, false, "descricao");
                  } else {
                    trocarValor(e, 3, false, "descricao");
                  }
                }}
              />
            </div>

            <div
              className="box"
              style={{
                width: "301px",
                backgroundColor: "black",
              }}
            >
              <TextInput
                name={`estatisticas[4].idiomas[${index}].titulo`}
                placeholder="856k"
                classes="descricao-publicidade"
                maxlength={5}
                style={{
                  textAlign: "center",
                  padding: "10px",
                  fontFamily: "Roboto Slab, serif",
                  fontSize: "24px",
                  lineHeight: "140%",
                  color: "white",
                  backgroundColor: "transparent",
                  border: "none",
                }}
                className="input"
                disabled={box4Existe && disabled}
                onKeyUp={(e) => {
                  if (!box4Existe) {
                    setDisabled(true);
                    setBox4Existe(true);
                    trocarValor(e, 4, false, "titulo");
                  } else {
                    trocarValor(e, 4, false, "titulo");
                  }
                }}
              />
              <TextInput
                name={`estatisticas[4].idiomas[${index}].descricao`}
                classes="descricao-publicidade"
                placeholder="Número de vendas"
                maxlength={24}
                style={{
                  fontFamily: "Raleway",
                  fontSize: "1em",
                  lineHeight: "140%",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "transparent",
                  border: "none",
                }}
                className="input"
                disabled={box4Existe && disabled ? true : false}
                onKeyUp={(e) => {
                  if (!box4Existe) {
                    setDisabled(true);
                    setBox4Existe(true);
                    trocarValor(e, 4, false, "descricao");
                  } else {
                    trocarValor(e, 4, false, "descricao");
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
