import React from "react";

export default function VimeoPlayer({ videoLink }) {
  let video = "";

  if (videoLink) {
    video = videoLink.split("/").pop();
  }

  return (
    <div className="vimeo-player-wrapper">
      <iframe
        id="vimeo-player"
        src={`https://player.vimeo.com/video/${video}`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title="Vimeo Video"
      ></iframe>
    </div>
  );
}
