/* eslint-disable no-undef */
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import ButtonSave from "../../../components/ButtonSave";
import TextInput from "../../../components/TextInput";
import LanguagesService from "../../../service/language.service";
import { useHistory, useParams } from "react-router";
import CalendarInput from "../../../components/CalendarInput";
import AvertisingdService from "../../../service/avertisingd.service";
import avertisingdAreaService from "../../../service/avertisingd-area.service";
import Select from "react-select";
import { avertisingdLanguageField } from "./idioma";
import AlertService from "../../../service/alert.service";
import AlertMenssage from "../../../components/AlertMenssage";
import makeAnimated from "react-select/animated";
import ConfirmationDialog from "../../../components/dialogs/confirmation-dialog";
import {
  avertisingdSchema,
  failedValidation,
} from "../../../validations/avertisingd-validation";
import AdvertisingImage from "../AdvertisingImage";
import TextareaInput from "../../../components/TextareaInput";
import EditionService from "../../../service/edition.service";
import QueryOptions from "../../../utils/query-options";
import ProporcaoImagemDialogo from "../../../components/ProporcaoImagemDialogo";

export default function AverertisingForm() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState("edit");
  const [currentLanguage, setCurrentLanguage] = useState(1);
  const [languages, setLanguages] = useState([]);
  const history = useHistory();
  const [disabledAdvertising, setDisabledAdvertising] = useState();
  const [todasAsAreasDesbloqueadas, setTodasAsAreasDesbloqueadas] = useState();
  const [soExisteBannerNaHome, setSoExisteBannerNaHome] = useState();
  const animatedComponents = makeAnimated();
  const [avertisingdArea, setAvertisingdArea] = useState([]);
  const [avertisingdValues, setAvertisingdValues] = useState({
    dataInicio: "",
    dataFim: "",
    idsAreas: "",
    disabled: "",
    idiomas: [avertisingdLanguageField],
  });
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);
  const [displayConfirmationDialogEdit, setDisplayConfirmationDialogEdit] =
    useState(false);

  const _avertisingdService = new AvertisingdService();
  useEffect(
    (_avertisingdService = new AvertisingdService(), avertisingd) => {
      async function getAvertisingdValues(languages) {
        setAction("edit");
        const avertisingd = await _avertisingdService.read(id);

        let existeBannerNaHome = false;
        let existeBannersGerais = false;
        for (let index = 0; index < avertisingd.idsAreas.length; index++) {
          if (avertisingd.idsAreas[index].id_publicidade_area === 1) {
            existeBannerNaHome = true;
          } else if (avertisingd.idsAreas[index].id_publicidade_area !== 1) {
            existeBannersGerais = true;
          }
        }

        if (existeBannerNaHome && existeBannersGerais) {
          setTodasAsAreasDesbloqueadas(true);
          setSoExisteBannerNaHome(null);
        } else if (existeBannerNaHome && !todasAsAreasDesbloqueadas) {
          setSoExisteBannerNaHome(true);
          setTodasAsAreasDesbloqueadas(false);
        } else {
          setSoExisteBannerNaHome(false);
          setTodasAsAreasDesbloqueadas(false);
        }

        let idiomas = [];
        languages.forEach((element) => {
          idiomas.push({
            ...avertisingdLanguageField,
            idIdioma: element.id,
          });
        });

        const idIdiomas = [];
        avertisingd.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

        const idiomasFaltantes = [];
        idiomas.forEach((item) => {
          if (!idIdiomas.includes(item.idIdioma)) {
            idiomasFaltantes.push(item);
          }
        });

        const allLanguages = [...avertisingd.idiomas, ...idiomasFaltantes].sort(
          (v1, v2) => v1.idIdioma - v2.idIdioma
        );

        const areas = [];
        avertisingd.idsAreas.forEach((item) => {
          item.label = item.area;
          item.value = item.id_publicidade_area;

          areas.push(item);
        });

        setAvertisingdValues({
          ...avertisingd,
          idiomas: allLanguages,
          idsAreas: areas,
        });

        if (avertisingd.disabled === true) {
          setDisabledAdvertising(true);
        } else {
          setDisabledAdvertising(false);
        }
      }

      async function init() {
        const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
        const _edicaoService = new EditionService();
        const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
          queryOptionsEdicaoAtiva
        );

        const _languagesService = new LanguagesService();
        const languages = await _languagesService.list();

        let idiomas = [];
        languages.forEach((element) => {
          idiomas.push({
            ...avertisingdLanguageField,
            idIdioma: element.id,
          });
        });

        setAvertisingdValues({
          ...avertisingdValues,
          idiomas,
          edicaoAtual: edicaoAtual?.id,
        });
        
        setLanguages(languages);

        const _avertisingdAreaService = new avertisingdAreaService();
        const response = await _avertisingdAreaService.list();
        setAvertisingdArea(response);

        if (id) {
          await getAvertisingdValues(languages);
        } else {
          setAction("create");
        }
      }
      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, avertisingdLanguageField]
  );

  async function createAdvertisingValues(values) {
    if (values.idiomas) {
      values.idiomas.forEach((item) => {
        if (item.link) {
          if (item.link.includes("https://") || item.link.includes("http://")) {
            return;
          } else {
            item.link = `https://${item.link}`;
          }
        }
      });
    }

    let existeBannerNaHome = false;
    let existeBannersGerais = false;
    for (let index = 0; index < values.idsAreas.length; index++) {
      if (
        values.idsAreas[index].id === 1 ||
        values.idsAreas[index].value === 1
      ) {
        existeBannerNaHome = true;
      } else if (
        values.idsAreas[index].id !== 1 ||
        values.idsAreas[index].value !== 1
      ) {
        existeBannersGerais = true;
      }
    }
    if (existeBannerNaHome && existeBannersGerais) {
      setTodasAsAreasDesbloqueadas(true);
      setSoExisteBannerNaHome(null);
    } else if (existeBannerNaHome && !todasAsAreasDesbloqueadas) {
      setSoExisteBannerNaHome(true);
      setTodasAsAreasDesbloqueadas(false);
    } else {
      setSoExisteBannerNaHome(false);
      setTodasAsAreasDesbloqueadas(false);
    }

    setLoading(true);
    if (action === "create") {
      const data = { ...values };
      data.idiomas = data.idiomas.filter((idioma) => idioma.titulo);

      await _avertisingdService
        .create(data)
        .then((response) => {
          history.push(`/publicidades/editar/${response.id}`);
        })
        .catch(({ response }) => {
          AlertService.warn(response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const data = { ...values };
      data.idiomas = data.idiomas.filter((idioma) => idioma.titulo);

      await _avertisingdService
        .update(data)
        .then((response) => {
          let idiomas = [];
          languages.forEach((element) => {
            idiomas.push({
              ...avertisingdLanguageField,
              idIdioma: element.id,
            });
          });

          const idIdiomas = [];
          response.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

          const idiomasFaltantes = [];
          idiomas.forEach((item) => {
            if (!idIdiomas.includes(item.idIdioma)) {
              idiomasFaltantes.push(item);
            }
          });

          const allLanguages = [...response.idiomas, ...idiomasFaltantes].sort(
            (v1, v2) => v1.idIdioma - v2.idIdioma
          );

          const areas = [];
          response.idsAreas.forEach((item) => {
            item.label = item.area;
            item.value = item.id_publicidade_area;

            areas.push(item);
          });

          setAvertisingdValues({
            ...response,
            idiomas: allLanguages,
            idsAreas: areas,
          });

          AlertService.success("Publicidade atualizada");
        })
        .catch(({ response }) => AlertService.warn(response.data.msg))
        .finally(() => {
          setLoading(false);
        });
    }
  }

  async function disableAdvertising() {
    if (!disabledAdvertising) {
      const data = avertisingdValues;
      data.idiomas = data.idiomas.filter(
        (idioma) => idioma.titulo && idioma.link
      );
      await _avertisingdService
        .update({ ...avertisingdValues, disabled: true })
        .then(() => {
          setDisplayConfirmationDialogEdit(false);
          window.location.reload();
        })
        .catch(({ response }) => {
          AlertService.warn(response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const data = avertisingdValues;
      data.idiomas = data.idiomas.filter(
        (idioma) => idioma.titulo && idioma.link
      );
      await _avertisingdService
        .update({ ...avertisingdValues, disabled: false })
        .then(() => {
          setDisplayConfirmationDialogEdit(false);
          window.location.reload();
        })
        .catch(({ response }) => {
          AlertService.warn(response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function selectLanguage(item) {
    setCurrentLanguage(item.id);
  }

  async function handleDelete() {
    await _avertisingdService.delete(id);
    history.push(`/publicidades?acao=deletado`);
    setDisplayConfirmationDialog(false);
  }

  return (
    <>
      <ConfirmationDialog
        header={
          disabledAdvertising
            ? "Ativar a publicidade a tornará visível nas respectivas seções do portal Expo Revestir"
            : "Inativar a publicidade fará com que ela não apareça em nenhuma seção do portal Expo Revestir"
        }
        action={
          disabledAdvertising
            ? "Deseja realmente ativar essa publicidade?"
            : "Deseja realmente inativar essa publicidade?"
        }
        display={displayConfirmationDialogEdit}
        onClose={() => setDisplayConfirmationDialogEdit(false)}
        onConfirm={disableAdvertising}
      />

      <div className="p-grid">
        <ConfirmationDialog
          header="Excluir esse item vai apagar todas as informações"
          action="Excluir mesmo assim?"
          display={displayConfirmationDialog}
          onClose={() => setDisplayConfirmationDialog(false)}
          onConfirm={handleDelete}
        />
        <div className="p-col-12">
          <div className="card">
            <h1 className="title-page">Publicidade</h1>
            <hr classname="hr-header" />

            <div
              style={{
                display: "flex",
                width: "800px",
                justifyContent: "space-between",
              }}
            >
              <div>
                {action === "edit" &&
                  (disabledAdvertising ? (
                    <h3 style={{ color: "red" }}>Publicidade Inativa</h3>
                  ) : (
                    <h3 style={{ color: "#009974" }}>Publicidade Ativa</h3>
                  ))}
              </div>
              <div>
                {avertisingdValues.quantidadeCliques > 1 && (
                  <h3>
                    Foram realizados {avertisingdValues.quantidadeCliques}{" "}
                    cliques
                  </h3>
                )}

                {avertisingdValues.quantidadeCliques === 1 && (
                  <h3> Houve apenas 1 clique</h3>
                )}

                {avertisingdValues.quantidadeCliques === 0 && (
                  <h3> Não foi realizado nenhum clique</h3>
                )}
              </div>
            </div>

            <Formik
              enableReinitialize={true}
              validationSchema={avertisingdSchema}
              initialValues={avertisingdValues}
              onSubmit={(values) => {
                createAdvertisingValues(values);
              }}
            >
              {(props) => {
                return (
                  <Form autoComplete="off">
                    <div
                      className="wrapper-form"
                      style={{ paddingLeft: "0.5em", maxWidth: "820px" }}
                    >
                      <div className="p-grid">
                        {languages.map((item, index) => (
                          <div key={index} className="language-dashboard">
                            <button
                              className={
                                item.id === currentLanguage
                                  ? "p-button-dash"
                                  : "p-button-teste"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                selectLanguage(item);
                              }}
                            >
                              {item.nome}
                            </button>
                          </div>
                        ))}
                      </div>

                      <div className="p-grid">
                        <FieldArray
                          name="idiomas"
                          render={(arrayHelpers) => (
                            <div className="p-grid ">
                              {props?.values?.idiomas?.map((item, index) => (
                                <div
                                  className="p-col-12"
                                  key={index}
                                  hidden={!(item.idIdioma === currentLanguage)}
                                >
                                  <TextInput
                                    name={`idiomas[${index}].titulo`}
                                    className="input"
                                    label="Título"
                                    quantidadeCaracteres={50}
                                    maxLength={50}
                                    required={
                                      index === 0 || (item.link && index > 0)
                                    }
                                  />

                                  <label>Descrição</label>
                                  <TextareaInput
                                    name={`idiomas[${index}].descricao`}
                                    classes="descricao-publicidade"
                                    className="input"
                                  />

                                  <TextInput
                                    name={`idiomas[${index}].link`}
                                    className="input"
                                    label="Link"
                                  />
                                  <div className="p-grid">
                                    {item.idPublicidadeIdioma && (
                                      <>
                                        {soExisteBannerNaHome === false && (
                                          <>
                                            <div className="p-col-12 p-md-6 p-lg-6">
                                              <div>
                                                <ProporcaoImagemDialogo
                                                  isJustDesktop={true}
                                                  widthDesktop={1920}
                                                  heightDesktop={620}
                                                />
                                              </div>

                                              <AdvertisingImage
                                                languages={languages}
                                                idPublicidadeIdioma={
                                                  item.idPublicidadeIdioma
                                                }
                                                chave={"imageFilename"}
                                                form={props}
                                                index={index}
                                                desktop={true}
                                                width={1920}
                                                height={620}
                                              />
                                            </div>
                                            <div className="p-col-12 p-md-6 p-lg-6">
                                              <div>
                                                <p>
                                                  <ProporcaoImagemDialogo
                                                    isJustMobile={true}
                                                    widthMobile={428}
                                                    heightMobile={625}
                                                  />
                                                </p>
                                              </div>
                                              <AdvertisingImage
                                                languages={languages}
                                                idPublicidadeIdioma={
                                                  item.idPublicidadeIdioma
                                                }
                                                form={props}
                                                index={index}
                                                mobile={true}
                                                chave={"imageFilenameMobile"}
                                                width={428}
                                                height={625}
                                              />
                                            </div>
                                          </>
                                        )}

                                        {soExisteBannerNaHome && (
                                          <>
                                            <div className="p-col-12 p-md-12 p-lg-12">
                                              <hr />
                                              <h1>
                                                Imagens para os banners da home
                                              </h1>
                                            </div>

                                            <div className="p-col-12 p-md-6 p-lg-6">
                                              <div>
                                                <p>
                                                  Tamanho da imagem para banners
                                                  da home desktop: 1920x720px
                                                </p>
                                              </div>
                                              <AdvertisingImage
                                                languages={languages}
                                                idPublicidadeIdioma={
                                                  item.idPublicidadeIdioma
                                                }
                                                chave={"filenameHome"}
                                                form={props}
                                                index={index}
                                                filenameHome={true}
                                                width={1920}
                                                height={720}
                                              />
                                            </div>

                                            <div className="p-col-12 p-md-6 p-lg-6">
                                              <div>
                                                <p>
                                                  Tamanho da imagem para banners
                                                  da home mobile: 428x725px
                                                </p>
                                              </div>
                                              <AdvertisingImage
                                                languages={languages}
                                                idPublicidadeIdioma={
                                                  item.idPublicidadeIdioma
                                                }
                                                form={props}
                                                index={index}
                                                filenameHomeMobile={true}
                                                chave={"filenameHomeMobile"}
                                                width={428}
                                                height={725}
                                              />
                                            </div>
                                          </>
                                        )}

                                        {todasAsAreasDesbloqueadas && (
                                          <>
                                            <div className="p-col-12 p-md-6 p-lg-6">
                                              <div>
                                                <p>
                                                  Tamanho da imagem para
                                                  desktop: 1920x620px
                                                </p>
                                              </div>
                                              <AdvertisingImage
                                                languages={languages}
                                                idPublicidadeIdioma={
                                                  item.idPublicidadeIdioma
                                                }
                                                chave={"imageFilename"}
                                                form={props}
                                                index={index}
                                                desktop={true}
                                                width={1920}
                                                height={620}
                                              />
                                            </div>
                                            <div className="p-col-12 p-md-6 p-lg-6">
                                              <div>
                                                <p>
                                                  Tamanho da imagem para mobile:
                                                  428x625px
                                                </p>
                                              </div>
                                              <AdvertisingImage
                                                languages={languages}
                                                idPublicidadeIdioma={
                                                  item.idPublicidadeIdioma
                                                }
                                                form={props}
                                                index={index}
                                                mobile={true}
                                                chave={"imageFilenameMobile"}
                                                width={428}
                                                height={625}
                                              />
                                            </div>

                                            <div className="p-col-12 p-md-12 p-lg-12">
                                              <hr />
                                              <h1>
                                                Imagens para os banners da home
                                              </h1>
                                            </div>

                                            <div className="p-col-12 p-md-6 p-lg-6">
                                              <div>
                                                <p>
                                                  Tamanho da imagem para banners
                                                  da home desktop: 1920x720px
                                                </p>
                                              </div>
                                              <AdvertisingImage
                                                languages={languages}
                                                idPublicidadeIdioma={
                                                  item.idPublicidadeIdioma
                                                }
                                                chave={"filenameHome"}
                                                form={props}
                                                index={index}
                                                filenameHome={true}
                                                width={1920}
                                                height={720}
                                              />
                                            </div>

                                            <div className="p-col-12 p-md-6 p-lg-6">
                                              <div>
                                                <p>
                                                  Tamanho da imagem para banners
                                                  da home mobile: 428x725px
                                                </p>
                                              </div>
                                              <AdvertisingImage
                                                languages={languages}
                                                idPublicidadeIdioma={
                                                  item.idPublicidadeIdioma
                                                }
                                                form={props}
                                                index={index}
                                                filenameHomeMobile={true}
                                                chave={"filenameHomeMobile"}
                                                width={428}
                                                height={725}
                                              />
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              ))}

                              <div className="p-col-12 p-md-8 p-lg-12">
                                <label>
                                  Area <span className="required-hash">*</span>
                                </label>
                                <Select
                                  isMulti
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  name="idsAreas"
                                  options={avertisingdArea}
                                  placeholder="Selecione"
                                  value={props.values?.idsAreas}
                                  onChange={(event) =>
                                    props.setFieldValue("idsAreas", event)
                                  }
                                />
                                <ErrorMessage name="idsAreas">
                                  {(msg) => (
                                    <div className="error-input">{msg}</div>
                                  )}
                                </ErrorMessage>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                                className="p-col-12 p-md-4 p-lg-3"
                              >
                                <label>
                                  Data do início
                                  <span className="required-hash">*</span>
                                </label>
                                <CalendarInput
                                  name="dataInicio"
                                  className="input"
                                  selected={props.values?.dataInicio}
                                  onChange={(e) => {
                                    props.setFieldValue("dataInicio", e);
                                  }}
                                  maxDate={props.values?.dataFim}
                                />
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                                className="p-col-12 p-md-4 p-lg-3"
                              >
                                <label>
                                  Data do final
                                  <span className="required-hash">*</span>
                                </label>
                                <CalendarInput
                                  name="dataFim"
                                  className="input"
                                  selected={props.values?.dataFim}
                                  onChange={(e) => {
                                    props.setFieldValue("dataFim", e);
                                  }}
                                  minDate={props.values?.dataInicio}
                                />
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="btns-inline">
                      <div className="btn-medium">
                        <ButtonSave
                          label={action === "create" ? "Salvar" : "Atualizar"}
                          loading={loading}
                          type="submit"
                          onClick={() => {
                            if (!failedValidation) {
                              AlertService.warn(
                                "Verifique se os campos obrigatórios de todos os idiomas foram preenchidos"
                              );
                            }
                            if (Object.keys(props.errors).length) {
                              AlertService.warn(
                                "Verifique se todos os campos obrigatórios foram preenchidos corretamente"
                              );
                            }
                          }}
                        />
                      </div>

                      <div className="btn-medium">
                        {action === "edit" && (
                          <div
                            className="btns-inline"
                            style={{ justifyContent: "space-evenly" }}
                          >
                            <div className="btn-medium">
                              <button
                                className="button-disable"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setDisplayConfirmationDialogEdit(true);
                                }}
                              >
                                {disabledAdvertising ? "Ativar" : "Desativar"}
                              </button>
                            </div>

                            <button
                              className="button-delete"
                              styles="p-button-secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                setDisplayConfirmationDialog(true);
                              }}
                            >
                              Excluir
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="btn-medium">
                        <button
                          className="button-cancel"
                          onClick={() => {
                            history.push(`/publicidades`);
                          }}
                        >
                          Voltar
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <AlertMenssage />
          </div>
        </div>
      </div>
    </>
  );
}
