import React, { useEffect, useState } from "react";
import ListTableLazy from "../../components/ListTableLazy";
import { useParams } from "react-router";
import AlertMenssage from "../../components/AlertMenssage";
import ExpositorMenu from "../../components/ExpositorMenu";
import BannerExpositorService from "../../service/banner-expositor.service";
import queryString from "query-string";
import AlertService from "../../service/alert.service";
import ExpositoresService from "../../service/expositores.service";
import QueryOptions from "../../utils/query-options";
import EditionService from "../../service/edition.service";

export default function BannerExpositor({ location }) {
  const { idExpositor, id } = useParams();
  const [bannerValues, setBannerValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);
  const [first, setFirst] = useState(0);
  const [expositor, setExpositor] = useState();

  const columns = [
    {
      field: `titulo`,
      header: "Banners",
    },
  ];

  useEffect(() => {
    async function listProducts() {
      const _edicaoService = new EditionService();

      const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
      const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
        queryOptionsEdicaoAtiva
      );
      const _bannersExpositorService = new BannerExpositorService();
      setLoading(true);

      const queryOptions = new QueryOptions([
        ["page", page],
        ["edicao", edicaoAtual?.id],
      ]);

      const data = await _bannersExpositorService.listSub(
        idExpositor,
        queryOptions,
        true
      );

      const queryStringObj = queryString.parse(location.search);

      if (queryStringObj) {
        if (queryStringObj.acao === "criado") {
          AlertService.success("Publicidade adicionada com sucesso");
        } else if (queryStringObj.acao === "deletado") {
          AlertService.success("Banner deletado com sucesso");
        }
      }

      const _expositoresService = new ExpositoresService();
      const expositor = await _expositoresService.read(idExpositor);
      setExpositor(expositor);

      setLoading(false);
      setBannerValues(data.items);
      setMeta(data.meta);
    }
    listProducts();
  }, [page, idExpositor, location, id]);

  function handleChangePage(event) {
    setFirst(event.first);
    setPage(event.page + 1);
  }

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <ExpositorMenu activeItem={6} expositor={expositor} />

        <div className="card">
          <h1>Banners</h1>
          <hr className="hr-header" />
          <AlertMenssage />
          <ListTableLazy
            cols={columns}
            values={bannerValues}
            setValues={setBannerValues}
            firstIdx={first}
            meta={meta}
            loading={loading}
            endpoint="banner-expositor"
            deleteAllEndPoint="banners"
            parentEndPoint="expositores"
            parentId={idExpositor}
            onChangePage={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
}
