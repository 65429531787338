import api from "./api.service";
import CoreApiService from "./core-api.service";
import CampoCustomizadoItemSerializer from "./serializers/campo-customizado-item.serializer";

export default class CampoCustomizadoItem extends CoreApiService {
  constructor() {
    super("campos_customizados", "itens", new CampoCustomizadoItemSerializer());
  }

  async readCampo(parentId, queryOptions = null, isListView = null) {
    const response = await api.get(
      `${this.parentEndpoint}/${this.endpoint}/${parentId}?${
        (queryOptions && queryOptions.toQueryString()) || ""
      }`
    );

    const data = response.data;
    return this.serializer.fromJson(data);
  }

  async updateCampo(item) {
    const response = await api.put(
      `${this.parentEndpoint}/${this.endpoint}`,
      this.serializer.toJson(item)
    );

    const data = response.data;

    return data;
  }
}
