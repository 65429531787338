import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./styles.scss";

// Exemplo
// [
//     {
//         label: 'Curso',
//         description: '',
//         to: '/cursos/aulas'
//     }
// ]

export default function BreadCrumb({ items }) {
  const history = useHistory();

  return (
    <div id="breadcrumb">
      {items.map((item, index) => (
        <span key={`breadcrumb-${index}`}>
          {item.label ? <span className="link" onClick={() => history.push(item.to)}>
            {item.label}{" "}
          </span> : null}
          {item.description}
          {items.length > index + 1 ? <strong>{" > "}</strong> : ""}
        </span>
      ))}
    </div>
  );
}
