import maskCpfCnpj from "../../utils/mask-cpf-cnpj";
import unMask from "../../utils/unmask";
import PhoneSerializer from "./phone.serializer";
import AddressSerializer from "./address.serializer";
import ProductsTypeSerializer from "./product-type-serializer";
import SegmentosTypeSerializer from "./segmentos-type.serializer";
import ModalidadeExpositoresSerializer from "./modalidade-expositores.serializer";
import ExpositoreslanguageSerializer from "./expositores-language.serializer";
import RedesSociaisTiposSerializer from "./redes-sociais-tipos.serializer";

export default class ExpositoresSerializer {
  constructor() {
    this.expositoresEstatisticasSerializer =
      this.expositoreslanguageSerializer = new ExpositoreslanguageSerializer();
    this.phoneSerializer = new PhoneSerializer();
    this.addressSerializer = new AddressSerializer();
    this.redesSociaisTiposSerializer = new RedesSociaisTiposSerializer();
    this.segmentosTypeSerializer = new SegmentosTypeSerializer();
    this.productsTypeSerializer = new ProductsTypeSerializer();
    this.modalidadeExpositoresSerializer =
      new ModalidadeExpositoresSerializer();
  }

  fromJson(json) {
    const expositores = {};
    Object.assign(
      expositores,
      json.logotipo_filename && { logotipoFileName: json.logotipo_filename },
      json.id_expositor && { id: json.id_expositor, value: json.id_expositor },
      json.email && { email: json.email },
      json.site && { site: json.site },
      json.razao_social && {
        razaoSocial: json.razao_social,
        label: json.razao_social,
      },
      json.id_expositor && {
        items: {
          value: json.id_expositor,
          label: json.razao_social,
          id_expositor: json.id_expositor,
        },
      },
      json.redes_sociais && {
        redesSociais: json.redes_sociais.map((item) =>
          this.redesSociaisTiposSerializer.fromJson(item)
        ),
      },
      json.image_filename && { imageFileName: json.image_filename },
      json.uri_unico && { uriUnico: json.uri_unico },
      json.nome_fantasia && { nomeFantasia: json.nome_fantasia },
      json.cnpj && { cnpj: maskCpfCnpj(json.cnpj) },
      json.insc_estadual && { inscEstadual: json.insc_estadual },
      json.disabled && { disabled: json.disabled },
      json.id_expositor && { value: json.id_expositor },
      json.nome_fantasia && { label: json.nome_fantasia },
      json.enderecos
        ? {
            adresses: json.enderecos.map((item) =>
              this.addressSerializer.fromJson(item)
            ),
          }
        : { adresses: [] },
      json.telefones
        ? {
            phones: json.telefones.map((item) =>
              this.phoneSerializer.fromJson(item)
            ),
          }
        : { phones: [] },
      json.segmentos && {
        segmentos: json.segmentos.map((item) =>
          this.segmentosTypeSerializer.fromJson(item)
        ),
      },

      json.idiomas && {
        idiomas: json.idiomas.map((item) =>
          this.expositoreslanguageSerializer.fromJson(item)
        ),
      },

      json.produto_tipos && {
        idsProdutoTipo: json.produto_tipos.map((item) =>
          this.productsTypeSerializer.fromJson(item)
        ),
      },

      json.id_expositor_modalidade && {
        idModalidade: json.id_expositor_modalidade,
      },
      json.numero_estande && { numeroEstande: json.numero_estande },

      json.habilita_banners && {
        bannerRotativos: json.habilita_banners,
      },

      json.habilita_catalogos && {
        modulosCatalogos: json.habilita_catalogos,
      },

      json.habilita_eventos && {
        modulosEventos: json.habilita_eventos,
      },

      json.habilita_coletor_dados && {
        coletorDeDadosDigital: json.habilita_coletor_dados,
      },

      json.habilita_contato && {
        contatoDoExpositor: json.habilita_contato,
      },

      json.habilita_videos && {
        moduloGaleriaVideos: json.habilita_videos,
      }
    );

    if (!json.razao_social && json.nome_fantasia) {
      expositores.label = json.nome_fantasia;
    }

    if (expositores.disabled) {
      expositores.statusStr = "Inativo";
    } else {
      expositores.statusStr = "Ativo";
    }

    return expositores;
  }

  toJson(expositor) {
    const expositorToJson = {
      habilita_banners: expositor.bannerRotativos,
      habilita_catalogos: expositor.modulosCatalogos,
      habilita_eventos: expositor.modulosEventos,
      habilita_coletor_dados: expositor.coletorDeDadosDigital,
      habilita_contato: expositor.contatoDoExpositor,
      habilita_videos: expositor.moduloGaleriaVideos,
    };

    Object.assign(
      expositorToJson,
      expositor.razaoSocial && { razao_social: expositor.razaoSocial },
      expositor.nomeFantasia && { nome_fantasia: expositor.nomeFantasia },
      expositor.email && { email: expositor.email },
      expositor.site && { site: expositor.site },
      expositor.imageFileName && { image_filename: expositor.imageFileName },
      expositor.uriUnico && { uri_unico: expositor.uriUnico },
      expositor.cnpj && { cnpj: unMask(expositor.cnpj) },
      expositor.inscEstadual && { insc_estadual: expositor.inscEstadual },
      expositor.adresses && {
        enderecos: expositor.adresses.map((item) =>
          this.addressSerializer.toJson(item)
        ),
      },
      expositor.idiomas && {
        idiomas: expositor.idiomas.map((item) =>
          this.expositoreslanguageSerializer.toJson(item)
        ),
      },
      expositor.phones && {
        telefones: expositor.phones.map((item) =>
          this.phoneSerializer.toJson(item)
        ),
      },
      expositor.segmentos && {
        ids_segmento: expositor.segmentos
          ? expositor.segmentos.map((item) => item.value)
          : [],
      },
      expositor.idsProdutoTipo && {
        ids_produto_tipo: expositor.idsProdutoTipo
          ? expositor.idsProdutoTipo.map((item) => item.value)
          : [],
      },
      expositor.idModalidade && {
        id_expositor_modalidade: expositor.idModalidade,
      },

      expositor.disabled !== undefined && { disabled: expositor.disabled },

      expositor.numeroEstande && { numero_estande: expositor.numeroEstande }
    );

    if (expositor.redesSociais) {
      const redesSociais = expositor.redesSociais;
      const redesSociaisValidas = [];

      redesSociais.forEach((item) => {
        if (item !== undefined) {
          if (item.link !== "") {
            delete item.label;
            delete item.value;
            redesSociaisValidas.push(item);
          }
        }
      });

      expositorToJson.redes_sociais = redesSociaisValidas.map((item) => item);
    }

    return expositorToJson;
  }
}
