import api from "./api.service";
import CoreApiService from "./core-api.service";
import CursoSerializer from "./serializers/curso.serializer";

export default class CursoService extends CoreApiService {
  constructor() {
    super(undefined, "cursos", new CursoSerializer());
  }

  async getBreadCrumb(key, value) {
    const response = await api.get(
      `${this.endpoint}/breadcrumb?${key.toString()}=${value.toString()}`
    );
    return response.data;
  }

  linkImage(idCursoIdioma) {
    return `${process.env.REACT_APP_API_URL}/${this.endpoint}/idiomas/${idCursoIdioma}/display`;
  }

  async uploadImagens(file, idCursoIdioma) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await api.put(
      `${this.endpoint}/idiomas/${idCursoIdioma}/uploads`,
      formData
    );
    const data = response.data;
    return data;
  }

  async relatorio(data) {
    return await api.post(`${this.endpoint}/relatorios`, data, { responseType: 'blob' });
  }
}
