import * as Yup from "yup";
import * as messages from "./messages";
import unMask from "../utils/unmask";

export let failedValidation = false;

export const cursoSchema = Yup.object().shape({
  cargaHoraria: Yup.string().required(messages.REQUIRED).nullable(),
  porcentagemConclusao: Yup.string()
    .required(messages.REQUIRED)
    .test("porcentagemConclusao", "Porcentagem deve estar entre 1% e 100%.", (value) => {
      if (value) {
      const number = parseFloat(unMask(value));
        if (number < 1 || number > 100) {
          return false;
        }
      }

      return true;
    })
    .nullable(),
  instrutor: Yup.string().required(messages.REQUIRED).nullable(),
  expositor: Yup.string().required(messages.REQUIRED).nullable(),
  idiomas: Yup.array().of(
    Yup.object().shape({
      titulo: Yup.string().test("titulo", messages.REQUIRED, function (value) {
        if (this && this.parent) {
          if (this.parent.idIdioma === 1) {
            failedValidation = value;
            return value;
          } else if (!this.parent.titulo && this.parent.descricao) {
            failedValidation = false;
            return false;
          }
        }
        return true;
      }),
      descricao: Yup.string().test(
        "descricao",
        messages.REQUIRED,
        function (value) {
          if (this && this.parent) {
            if (this.parent.idIdioma === 1) {
              failedValidation = value;
              return value;
            } else if (this.parent.titulo && !this.parent.descricao) {
              failedValidation = false;
              return false;
            }
          }
          return true;
        }
      ),
    })
  ),
});
