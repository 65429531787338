export default class CursoModuloIdiomaSerializer {
  fromJson(json) {
    const moduloIdiomas = {
      idIdioma: json.idioma.id_idioma,
      nome: json.nome_modulo,
    };

    return moduloIdiomas;
  }

  toJson(moduloIdiomas) {
    return {
      id_idioma: moduloIdiomas.idIdioma,
      nome_modulo: moduloIdiomas.nome,
    };
  }
}
