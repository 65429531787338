/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import AlertMenssage from "../../components/AlertMenssage";
import ButtonSave from "../../components/ButtonSave";
import TextInput from "../../components/TextInput";
import TextareaInput from "../../components/TextareaInput";
import ConfirmationDialog from "../../components/dialogs/confirmation-dialog";
import AlertService from "../../service/alert.service";
import CursoModuloAulaService from "../../service/curso-modulo-aula.service";
import LanguagesService from "../../service/language.service";

import BreadCrumb from "../../components/BreadCrumb";
import VimeoPlayer from "../../components/VimeoPlayer";
import CursoService from "../../service/curso.service";
import {
  cursoModuloAulaSchema,
  failedValidation,
} from "../../validations/curso-modulo-aula.validation";

export default function CursoModuloAulaForm() {
  const _cursoAulaService = new CursoModuloAulaService();
  const _cursoService = new CursoService();
  const [breadCrumbItems, setBreadCrumbItems] = useState([]);

  const { idCursoModulo, id: idCursoAula } = useParams();
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cursoAulaDisabled, setCursoAulaDisabled] = useState(false);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [displayDisabledDialog, setDisplayDisabledDialog] = useState(false);

  // Idiomas
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(1);

  // Formulário
  const formIdiomaField = {
    titulo: "",
    descricao: "",
    instrutor: "",
    linkVideo: "",
    idIdioma: "",
  };

  const [valuesForm, setValuesForm] = useState({
    duracao: "",
    porcentagemConclusao: "",
    dataCriacao: format(new Date(), "dd/MM/yyyy"),
    idiomas: [formIdiomaField],
  });

  const mountLanguage = async () => {
    const _languagesService = new LanguagesService();
    const languages = await _languagesService.list();

    let idiomas = [];
    languages.forEach((element) => {
      idiomas.push({
        ...formIdiomaField,
        idIdioma: element.id,
      });
    });

    setValuesForm({
      ...valuesForm,
      idiomas,
    });

    setLanguages(languages);
    return languages;
  };

  const getCursoAula = async (languages) => {
    const cursoAula = await _cursoAulaService.read(idCursoAula);

    let idiomas = [];
    languages.forEach((element) => {
      idiomas.push({
        ...formIdiomaField,
        idIdioma: element.id,
      });
    });

    const idIdiomas = [];
    cursoAula.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

    const idiomasFaltantes = [];
    idiomas.forEach((item) => {
      if (!idIdiomas.includes(item.idIdioma)) {
        idiomasFaltantes.push(item);
      }
    });

    const allLanguages = [...cursoAula.idiomas, ...idiomasFaltantes].sort(
      (v1, v2) => v1.idIdioma - v2.idIdioma
    );

    setCursoAulaDisabled(cursoAula.disabled);

    setValuesForm({ ...cursoAula, idiomas: allLanguages });
  };

  const getBreadCrumb = async () => {
    let params = {};

    if (idCursoAula) {
      params = {
        key: "id_curso_aula",
        value: idCursoAula,
      };
    } else {
      params = {
        key: "id_curso_modulo",
        value: idCursoModulo,
      };
    }

    const data = await _cursoService.getBreadCrumb(params.key, params.value);

    const items = [
      {
        label: "Cursos:",
        description: data.curso.nome,
        to: "/cursos/lista",
      },
    ];

    if (idCursoAula) {
      items.push({
        label: "Módulos:",
        description: data.modulo.nome,
        to: `/cursos/modulos/${data.curso.id}`,
      });

      items.push({
        label: "Aulas:",
        description: data.aula.nome,
        to: `/cursos/modulos/${data.modulo.id}/aulas`,
      });

      items.push({
        description: "Editar",
      });
    } else {
      items.push({
        label: "Módulos:",
        description: data.modulo.nome,
        to: `/cursos/modulos/${data.curso.id}`,
      });

      items.push({
        label: "Aulas",
        to: `/cursos/modulos/${data.modulo.id}/aulas`,
      });

      items.push({ description: "Adicionar" });
    }

    setBreadCrumbItems(items);
  };

  useEffect(() => {
    setIsEdit(!!idCursoAula);

    const init = async () => {
      getBreadCrumb();
      const languages = await mountLanguage();

      if (idCursoAula) {
        await getCursoAula(languages);
      }
    };

    init();
  }, []);

  async function handleDelete() {
    await _cursoAulaService.delete(idCursoAula);
    history.push(`/cursos/modulos/${idCursoModulo}/aulas`);
  }

  async function handleDisabled() {
    const isDisabled = !cursoAulaDisabled;

    const data = {
      id: idCursoAula,
      disabled: isDisabled,
    };

    await _cursoAulaService.update(data);

    setCursoAulaDisabled(isDisabled);
    setDisplayDisabledDialog(false);
    AlertService.success(`Aula ${isDisabled ? "desativado" : "ativado"}`);
  }

  const onSubmit = async (values) => {
    setLoading(true);

    const data = { ...values };
    data.idiomas = data.idiomas.filter(
      (idioma) => idioma.titulo && idioma.descricao && idioma.linkVideo
    );

    if (isEdit) {
      // Atualizar
      try {
        await _cursoAulaService.update(data);
        AlertService.success("Curso atualizado");
      } catch ({ response }) {
        AlertService.warn(response.data.msg);
      } finally {
        setLoading(false);
      }
    } else {
      // Criar
      try {
        data.idCursoModulo = idCursoModulo;
        await _cursoAulaService.create(data);
        history.push(`/cursos/modulos/${idCursoModulo}/aulas?acao=criado`);
      } catch ({ response }) {
        AlertService.warn(response.data.msg);
      }
    }
  };

  const updateTempoAula = (form) => {
    const handleScriptLoad = () => {
      const iframe = document.getElementById("vimeo-player");
      const player = new window.Vimeo.Player(iframe);

      form.setFieldValue("duracao", "");

      player.getDuration().then(function (duration) {
        form.setFieldValue("duracao", duration);
      });

      script.removeEventListener("load", handleScriptLoad);
      document.body.removeChild(script);
    };

    const script = document.createElement("script");
    script.src = "https://player.vimeo.com/api/player.js";
    script.async = true;

    script.addEventListener("load", handleScriptLoad);
    document.body.appendChild(script);
  };

  return (
    <>
      <ConfirmationDialog
        header="Atenção!"
        action="Apagar esta aula?"
        display={displayDeleteDialog}
        onClose={() => setDisplayDeleteDialog(false)}
        onConfirm={handleDelete}
      />

      <ConfirmationDialog
        header="Atenção!"
        action="Desabilitar esta aula?"
        display={displayDisabledDialog}
        onClose={() => setDisplayDisabledDialog(false)}
        onConfirm={handleDisabled}
      />
      <div className="card">
        <h1 className="title-page">
          {isEdit ? "Editar" : "Adiconar"} Aula
          {cursoAulaDisabled ? (
            <>
              {" "}
              - <span className="text-desativado">Desativado</span>
            </>
          ) : null}
        </h1>

        <hr className="hr-header" />

        <BreadCrumb items={breadCrumbItems} />

        <div className="action-list"></div>

        <Formik
          enableReinitialize={true}
          validationSchema={cursoModuloAulaSchema}
          initialValues={valuesForm}
          onSubmit={onSubmit}
        >
          {(props) => {
            return (
              <Form autoComplete="off">
                <div
                  className="wrapper-form"
                  style={{ paddingLeft: "0.5em", maxWidth: "820px" }}
                >
                  <div className="p-grid">
                    {languages.map((item, index) => (
                      <div key={index} className="language-dashboard">
                        <button
                          className={
                            item.id === currentLanguage
                              ? "p-button-dash"
                              : "p-button-teste"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentLanguage(item.id);
                          }}
                        >
                          {item.nome}
                        </button>
                      </div>
                    ))}
                  </div>

                  <div>
                    <FieldArray
                      name="idiomas"
                      render={(arrayHelpers) => (
                        <div className="p-grid">
                          {props?.values?.idiomas?.map((item, index) => (
                            <div
                              className="p-col-12"
                              key={index}
                              hidden={!(item.idIdioma === currentLanguage)}
                            >
                              <TextInput
                                name={`idiomas[${index}].titulo`}
                                className="input"
                                label="Título"
                                quantidadeCaracteres={50}
                                maxLength={50}
                                required={
                                  index === 0 ||
                                  ((item.descricao || item.linkVideo) &&
                                    index > 0)
                                }
                              />

                              <TextareaInput
                                name={`idiomas[${index}].descricao`}
                                classes="descricao-publicidade"
                                className="input"
                                label="Descrição"
                                required={
                                  index === 0 ||
                                  ((item.titulo || item.linkVideo) && index > 0)
                                }
                              />

                              <TextInput
                                name={`idiomas[${index}].instrutor`}
                                className="input"
                                label="Instrutor"
                                quantidadeCaracteres={50}
                                maxLength={50}
                              />

                              <TextInput
                                name={`idiomas[${index}].linkVideo`}
                                className="input"
                                label="Vídeo"
                                required={
                                  index === 0 ||
                                  ((item.titulo || item.descricao) && index > 0)
                                }
                                onBlur={() => updateTempoAula(props)}
                              />

                              {item.linkVideo ? <VimeoPlayer videoLink={item.linkVideo} />: null}
                            </div>
                          ))}
                        </div>
                      )}
                    />

                    <hr style={{ opacity: "30%" }} />

                    <div className="p-grid">
                      <TextInput
                        classes="p-col-4"
                        name="duracao"
                        className="input"
                        label="Duração (segundos)"
                        required
                      />
                      <TextInput
                        classes="p-col-4"
                        name="dataCriacao"
                        className="input"
                        label="Data de criação"
                        disabled
                      />
                    </div>
                  </div>

                  <div className="btns-inline">
                    <div className="btn-medium">
                      <ButtonSave
                        label={isEdit ? "Atualizar" : "Adicionar"}
                        type="submit"
                        loading={loading}
                        onClick={() => {
                          if (!failedValidation) {
                            AlertService.warn(
                              "Verifique se os campos obrigatórios de todos os idiomas foram preenchidos"
                            );
                          }
                        }}
                      />
                    </div>
                    {isEdit ? (
                      <>
                        <div className="btn-medium">
                          <button
                            className="button-disable"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setDisplayDisabledDialog(true);
                            }}
                          >
                            {cursoAulaDisabled ? "Ativar" : "Desativar"}
                          </button>
                        </div>
                        <div className="btn-medium">
                          <div
                            className="btns-inline"
                            style={{ justifyContent: "space-evenly" }}
                          >
                            <button
                              className="button-delete"
                              styles="p-button-secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                setDisplayDeleteDialog(true);
                              }}
                            >
                              Excluir
                            </button>
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div className="btn-medium">
                      <button
                        className="button-voltar"
                        onClick={() => {
                          history.push(
                            `/cursos/modulos/${idCursoModulo}/aulas`
                          );
                        }}
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>

        <AlertMenssage />
      </div>
    </>
  );
}
