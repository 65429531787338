import api from "./api.service";
import UserSerializer from "./serializers/user.serializer";

export const TOKEN_KEY = "token";
export const TOKEN_EXPIRATION = "tokenExpiration";
export const USER_LOGGED = "userLogged";
const LOGGED_USER_URL = "logged-user";

export default class Authentication {
  constructor() {
    this.user = null;
    this.userSerializer = new UserSerializer();
  }

  static get _user() {
    return this.user;
  }

  static set _user(value) {
    this.user = value;
  }

  static getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  async singIn(email, password) {
    const res = await api.post(
      "tokens",
      {},
      {
        headers: {
          Authorization: "Basic " + btoa(`${email}:${password}`),
        },
      }
    );

    localStorage.setItem(TOKEN_KEY, res.data.token);
    localStorage.setItem(TOKEN_EXPIRATION, res.data.token_expiration);
    Authentication._user = this.userSerializer.fromJson(res.data.usuario);
  }

  static isAuthenticated() {
    const token = this.getToken();
    const strTokenExpiration = this.getTokenExpiration();
    if (!strTokenExpiration || !token) {
      this.logout();
      return false;
    }

    const tokenExpiration = new Date(strTokenExpiration);

    if (new Date().getTime() > tokenExpiration.getTime()) {
      this.logout();
      return false;
    }

    return true;
  }

  static logout() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_EXPIRATION);
    this._user = null;
  }
  static userLogged() {
    return localStorage.getItem(USER_LOGGED);
  }

  static getTokenExpiration() {
    return localStorage.getItem(TOKEN_EXPIRATION);
  }

  async retrieveLoggedUser() {
    const response = await api.get(LOGGED_USER_URL);
    Authentication._user = this.userSerializer.fromJson(response.data);
    return Authentication._user;
  }
}
