import React from "react";
import PropTypes from "prop-types";
import { TabMenu } from "primereact/tabmenu";
import { useHistory, useParams } from "react-router-dom";
import { userHasAccess, userProfiles } from "../../utils/permission-user";

UsuarioMenu.propTypes = {
  activeItem: PropTypes.any,
};

export default function UsuarioMenu({ activeItem }) {
  const history = useHistory();
  const { id: idUsuario } = useParams();
  const items = [{ label: "Dados", action: "" }];

  if (userHasAccess([userProfiles.administrador])) {
    items.push({ label: "Logs", action: `logs` });
  }

  function handleChange({ value }) {
    if (value.action === "logs") {
      history.push(`/usuarios/${idUsuario}/logs`);
    } else {
      history.push(`/usuarios/editar/${idUsuario}`);
    }
  }

  return (
    <TabMenu
      model={items}
      activeItem={items[activeItem]}
      onTabChange={(e) => {
        handleChange(e);
      }}
    />
  );
}
