import api from "./api.service";
import CoreApiService from "./core-api.service";
import EditionSerializer from "./serializers/edition.serializer";

export default class EditionService extends CoreApiService {
  constructor() {
    super(undefined, "edicoes", new EditionSerializer());
  }

  async carregarEdicaoAtual(queryOptions) {
    const edicoes = await this.list(queryOptions);

    if (edicoes.length) {
      const edicao = edicoes[edicoes.length - 1];
      return edicao;
    }
  }

  async ativarEdicao(item, isFormDate = false) {
    const response = await api.put(
      `${this.endpoint}/${item}/ativar`,
      isFormDate
        ? this.serializer.toFormData(item)
        : this.serializer.toJson(item)
    );

    const data = response.data;
    return this.serializer.fromJson(data.oret);
  }

  async duplicarEdicao(item, isFormDate = false) {
    const response = await api.post(
      `${this.endpoint}/${item}/duplicar`,
      isFormDate
        ? this.serializer.toFormData(item)
        : this.serializer.toJson(item)
    );

    const data = response.data;
    return data;
  }

  async carregarEdicaoAtiva() {
    this.parentEndpoint = "edicoes";
    this.endpoint = "ativa";

    const response = await api.get(`${this.parentEndpoint}/${this.endpoint}`);

    return response.data;
  }
}
