export default class CatalogoSerializer {
  fromJson(json) {
    const empresaCatalogos = {};

    Object.assign(
      empresaCatalogos,
      json.id_empresa && { idEmpresa: json.id_empresa },
      json.id_catalogo && { id: json.id_catalogo },
      json.idioma && { idIdioma: json.idioma.id_idioma },
      json.idioma && { idiomaNome: json.idioma.nome },
      json.image_filename && { imageFilename: json.image_filename },
      json.titulo && { titulo: json.titulo },
      json.arquivo_filename && { arquivoFilename: json.arquivo_filename }
    );

    return empresaCatalogos;
  }

  toJson(empresaCatalogo) {
    return {
      id_idioma: empresaCatalogo.idIdioma,
      titulo: empresaCatalogo.titulo,
      id_edicao: empresaCatalogo.edicaoAtual,
    };
  }

  toFormData(file) {
    let formData = new FormData();
    formData.append("file", file[0]);

    return formData;
  }
}
