import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ExpositorMenu from "../../components/ExpositorMenu";
import ListTableLazy from "../../components/ListTableLazy";
import EditionService from "../../service/edition.service";
import EmpresaCatalogosService from "../../service/empresa-catalogos.service";
import ExpositoresService from "../../service/expositores.service";
import QueryOptions from "../../utils/query-options";

export default function Catalogos() {
  const { idExpositor } = useParams();
  const [catalogo, setCatalogo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);
  const [first, setFirst] = useState(0);
  const [expositor, setExpositor] = useState();

  const columns = [
    {
      field: `titulo`,
      header: "Título",
    },
    {
      field: `idiomaNome`,
      header: "Idioma",
    },
  ];

  useEffect(() => {
    async function listCatalogo() {
      const _catalogosService = new EmpresaCatalogosService();
      const _edicaoService = new EditionService();

      const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);

      const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
        queryOptionsEdicaoAtiva
      );

      setLoading(true);

      const _expositoresService = new ExpositoresService();
      const expositor = await _expositoresService.read(idExpositor);
      setExpositor(expositor);

      const queryOptions = new QueryOptions([
        ["fields", "idioma, id_idioma, titulo, id_catalogo"],
        ["page", page],
        ["edicao", edicaoAtual?.id],
      ]);

      const data = await _catalogosService.listSub(
        idExpositor,
        queryOptions,
        true
      );
      setLoading(false);
      setCatalogo(data.items);
      setMeta(data.meta);
    }
    listCatalogo();
  }, [page, idExpositor]);

  function handleChangePage(event) {
    setFirst(event.first);
    setPage(event.page + 1);
  }
  return (
    <div className="p-grid">
      <div className="p-col-12">
        <ExpositorMenu activeItem={5} expositor={expositor} />

        <div className="card">
          <h1>Catálogos</h1>
          <hr classname="hr-header" />
          <ListTableLazy
            cols={columns}
            values={catalogo}
            setValues={setCatalogo}
            firstIdx={first}
            meta={meta}
            loading={loading}
            endpoint="catalogos"
            deleteAllEndPoint="catalogos"
            parentEndPoint="expositores"
            parentId={idExpositor}
            onChangePage={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
}
