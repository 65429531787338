import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

ConfirmationDialog.propTypes = {
  action: PropTypes.string.isRequired,
  display: PropTypes.bool.isRequired,
  onClose: PropTypes.any.isRequired,
  onConfirm: PropTypes.any.isRequired,
};

export default function ConfirmationDialog({
  action,
  display,
  onClose,
  onConfirm,
  header,
  loading,
}) {
  const renderFooter = () => {
    return (
      <div style={{ display: "flex", marginLeft: "12px" }}>
        <Button
          label="Não"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
          disabled={loading ? true : false}
          className="p-button-secondary"
        />

        <Button
          label="Sim"
          icon={loading ? "pi pi-spin pi-spinner" : null}
          disabled={loading ? true : false}
          onClick={onConfirm}
        />
      </div>
    );
  };

  return (
    <>
      <Dialog
        header={header}
        visible={display}
        dismissableMask={true}
        style={{ width: "300px" }}
        onHide={() => onClose()}
        footer={renderFooter()}
        closable={false}
      >
        <div style={{ padding: "10px 0" }}>{action}</div>
      </Dialog>
    </>
  );
}
