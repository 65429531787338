import React from "react";
import { useField } from "formik";
import { Dropdown } from "primereact/dropdown";

export default function DropdownInput({
  label,
  classes = "",
  required,
  ...props
}) {
  const [field, meta] = useField(props);

  return (
    <div className={classes}>
      <label htmlFor={props.id || props.name}>
        {label} {required && <span className="required-hash"> *</span>}
      </label>
      <div className="input-block">
        <Dropdown className="full" {...field} {...props} />
        {props.loading && (
          <i className="pi pi-spin pi-spinner input-loading select"></i>
        )}
      </div>
      <div className="error-input">
        {meta.touched && meta.error ? (
          <div className="error ">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
}
