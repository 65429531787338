import CargoIdiomasSerializer from "./cargo-idiomas.serializer";

export default class CargoSerializer {
  constructor() {
    this.cargoIdiomasSerializer = new CargoIdiomasSerializer();
  }

  fromJson(json) {
    const cargo = {
      idiomas: json.idiomas.map((item) =>
        this.cargoIdiomasSerializer.fromJson(item)
      ),
    };

    return cargo.idiomas[0];
  }

}
