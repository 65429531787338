import { format } from "date-fns";

export default function convertDate(date) {
  if (typeof date !== "string") {
    return format(date, format(new Date(date), "yyyy-MM-dd HH:mm"));
  }

  const hours = "21:59";
  const values = date.split("/")
  return `${values[2]}/${
    values[1]
  }/${values[0]} ${hours}`;
}
