import VideoModerationLanguages from "./videos-moderation-language.serializer";

export default class ModerationVideoSerializer {
  constructor() {
    this.videosModerationLanguages = new VideoModerationLanguages();
  }

  fromJson(json) {
    const moderationVideos = {};

    Object.assign(
      moderationVideos,
      json.id_moderacao_video && { id: json.id_moderacao_video },
      json.id_expositor && { idExpositor: json.id_expositor },
      json.idiomas
        ? {
            idiomas: json.idiomas.map((item) =>
              this.videosModerationLanguages.fromJson(item)
            ),
          }
        : { idiomas: [] }
    );

    return moderationVideos;
  }

  toJson(moderacaoVideoIdiomas) {
    return {
      idiomas: moderacaoVideoIdiomas.idiomas.map((item) =>
        this.videosModerationLanguages.toJson(item)
      ),
      id_edicao: moderacaoVideoIdiomas.edicaoAtual,
    };
  }
}
