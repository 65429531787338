/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AlertService from "../../service/alert.service";
import CityService from "../../service/city.service";
import FetchAddressService from "../../service/fetch-address.service";
import StateService from "../../service/state.service";
import DropdownInput from "../DropdownInput";
import TextInput from "../TextInput";
import PaisService from "../../service/pais.service";
import Select from "react-select";
import QueryOptions from "../../utils/query-options";
import { ErrorMessage } from "formik";

export const enderecoFields = {
  foreignCity: "",
  foreignState: "",
  idCountry: "",
  idState: "",
  idCity: "",
  address: "",
  neighborhood: "",
  number: "",
  complement: "",
  zipCode: "",
};

export default function EnderecoForm({
  form,
  address,
  arrayHelpers,
  index,
  requiredHashInFields,
  disabledForm = false,
}) {
  const [estados, setEstados] = useState([]);
  const [paises, setPaises] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [loading, setLoading] = useState(false);

  const _fetchAddressService = new FetchAddressService();

  async function listaCidades(id) {
    const _cidadeService = new CityService();
    let data = await _cidadeService.listSub(id);
    return data;
  }

  async function listaEstados(pais = false) {
    const _estadoService = new StateService();
    let data = await _estadoService.list(
      pais
        ? new QueryOptions([
            ["id_pais", pais.id],
            ["brasil", "brasil"],
          ])
        : null
    );

    if (form.values.adresses[index].idState) {
      const estado = data.find(
        (item) => item.id === form.values.adresses[index].idState
      );

      if (estado) {
        form.setFieldValue(`adresses.${index}.idState`, estado);
      }
    }

    setEstados([...data]);
    return data;
  }

  async function onTrocaPais(pais) {
    await listaEstados(pais);
    setCidades([]);

    if (pais.id !== 1) {
      form.setFieldValue(`adresses.${index}.zipCode`, "");
      form.setFieldValue(`adresses.${index}.idState`, "");
      form.setFieldValue(`adresses.${index}.idCity`, "");
      form.setFieldValue(`adresses.${index}.cidade`, "");
      form.setFieldValue(`adresses.${index}.uf`, null);

      let paisSelecionado = paises.find((item) => item.id === pais.id);

      form.setFieldValue(`adresses.${index}.pais`, paisSelecionado.nome);
      form.setFieldValue(`adresses.${index}.id`, paisSelecionado);
      form.setFieldValue(`adresses.${index}.idCity`, paisSelecionado);
      form.setFieldValue(`adresses.${index}.idState`, paisSelecionado);
    } else {
      form.setFieldValue(`adresses.${index}.id`, "");
      form.setFieldValue(`adresses.${index}.idCity`, "");
      form.setFieldValue(`adresses.${index}.foreignCity`, "");
      form.setFieldValue(`adresses.${index}.foreignState`, "");
    }
  }

  async function onAtualizaCidades(id) {
    const data = await listaCidades(id);
    setCidades(data);
  }

  async function enderecoPorCep(cep) {
    try {
      setLoading(true);
      const cepQuery = await _fetchAddressService.queryCep(cep);

      if (!cepQuery.erro) {
        const estadoFilter = estados.find(
          (item) => item.label.toLowerCase() === cepQuery.uf.toLowerCase()
        );

        if (estadoFilter) {
          form.setFieldValue(`adresses.${index}.idState`, estadoFilter.id);
          const cidadeData = await listaCidades(estadoFilter.id);
          setCidades(cidadeData);
          const cidadeFilter = cidadeData.find(
            (item) => item.label === cepQuery.localidade
          );
          if (cidadeFilter) {
            form.setFieldValue(`adresses.${index}.idCity`, cidadeFilter.id);

            form.setFieldValue(
              `adresses.${index}.address`,
              cepQuery.logradouro
            );
            form.setFieldValue(
              `adresses.${index}.neighborhood`,
              cepQuery.bairro
            );
          }
        }
      }
    } catch {
      AlertService.warn("Erro ao buscar CEP");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function listaPaises() {
      const _paisesService = new PaisService();

      let data = await _paisesService.list();
      setPaises(data);

      return data;
    }

    async function listaEstados() {
      if (!localStorage.getItem("token")) {
        const _estadoService = new StateService();
        let data = await _estadoService.list();

        setEstados(data);
        return data;
      } else {
        const _estadoService = new StateService();
        let data = await _estadoService.list();
        setEstados(data);
        return data;
      }
    }

    async function init() {
      const paises = await listaPaises();

      if (form.values?.adresses[index]?.idCountry) {
        const pais = paises.find(
          (item) => item.id === form.values?.adresses[index]?.idCountry.id
        );
        form.setFieldValue(`adresses.${index}.idCountry`, pais);

        await listaEstados(pais);

        if (form.values?.adresses[index]?.idState) {
          const cidadeData = await listaCidades(
            form.values?.adresses[index]?.idState
          );
          setCidades(cidadeData);

          if (form.values?.adresses[index]?.idCity) {
            const cidade = cidadeData.find(
              (item) => item.id === form.values?.adresses[index]?.idCity
            );
            form.setFieldValue(`adresses.${index}.idCity`, cidade.id);
          }
        }
      }
    }

    init();
  }, [index]);

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12 p-lg-3 p-md-5 p-sm-5">
          <label>
            País{" "}
            {requiredHashInFields && <span className="required-hash">*</span>}
          </label>
          <Select
            label="Pais"
            name={`adresses.${index}.idCountry`}
            obrigatorio={true}
            options={paises}
            placeholder="Selecione"
            onChange={(e) => {
              form.setFieldValue(`adresses[${index}].idCountry`, e);
              onTrocaPais(e);
            }}
            value={form.values?.adresses[index]?.idCountry}
          />
          <ErrorMessage name={`adresses.${index}.idCountry`}>
            {(msg) => <div className="error-input">{msg}</div>}
          </ErrorMessage>
        </div>

        {form?.values?.adresses[index]?.idCountry?.id === 1 && (
          <div className="p-col-12 p-lg-2 p-md-2 p-sm-5">
            <label>CEP</label>
            <TextInput
              name={`adresses.${index}.zipCode`}
              onBlur={(e) => enderecoPorCep(e.target.value)}
              mask="99999-999"
              disabled={form.values?.adresses[index]?.idCountry.id !== 1}
              className="input"
              loading={loading}
            />
          </div>
        )}

        {form?.values?.adresses[index]?.idCountry?.id === 1 ? (
          <div className="p-col-12 p-lg-2 p-md-5 p-sm-4">
            <label>
              Estado{" "}
              {requiredHashInFields && <span className="required-hash">*</span>}
            </label>
            <DropdownInput
              placeholder="Selecione"
              name={`adresses.${index}.idState`}
              options={estados}
              onChange={(e) => {
                form.setFieldValue(`adresses.${index}.idState`, e.value);
                onAtualizaCidades(e.value);
              }}
              loading={loading}
            />
          </div>
        ) : (
          <div className="p-col-12 p-lg-2 p-md-5 p-sm-4">
            <TextInput
              label={"Estado"}
              name={`adresses.${index}.foreignState`}
              required={requiredHashInFields}
              className="input"
              loading={loading}
            />
          </div>
        )}

        {form.values?.adresses[index]?.idCountry?.id === 1 ? (
          <div className="p-col-12 p-lg-4 p-md-4 p-sm-7">
            <label>
              Cidade{" "}
              {requiredHashInFields && <span className="required-hash">*</span>}
            </label>
            <DropdownInput
              placeholder="Selecione"
              name={`adresses.${index}.idCity`}
              options={cidades}
              loading={loading}
            />
          </div>
        ) : (
          <div className="p-col-12 p-lg-2 p-md-5 p-sm-4">
            <TextInput
              label={"Cidade"}
              name={`adresses.${index}.foreignCity`}
              required={requiredHashInFields}
              className="input"
              loading={loading}
            />
          </div>
        )}

        <div className="p-col-12 p-lg-4 p-md-6 p-sm-6">
          <label>
            Logradouro{" "}
            {requiredHashInFields && <span className="required-hash">*</span>}
          </label>
          <TextInput
            placeholder="Avenida Paulista"
            name={`adresses.${index}.address`}
            className="input"
            loading={loading}
            // disabled={this.state.loadingCep || this.props.disabledForm}
          />
        </div>

        <div className="p-col-12 p-lg-2 p-md-3 p-sm-6">
          <label>
            Número{" "}
            {requiredHashInFields && <span className="required-hash">*</span>}
          </label>
          <TextInput
            className="input"
            name={`adresses.${index}.number`}
            disabled={disabledForm}
          />
        </div>

        <div className="p-col-12 p-lg-4 p-md-3 p-sm-6">
          <label>Complemento</label>
          <TextInput
            className="input"
            name={`adresses.${index}.complement`}
            disabled={disabledForm}
          />
        </div>

        <div className="p-col-12 p-lg-5 p-md-3 p-sm-6">
          <label>
            Bairro{" "}
            {requiredHashInFields && <span className="required-hash">*</span>}
          </label>
          <TextInput
            className="input"
            name={`adresses.${index}.neighborhood`}
            disabled={disabledForm}
            loading={loading}
          />
        </div>

        {index > 0 ? (
          <div
            className="p-col-12 p-lg-1 p-md-1 p-sm-6 icon-input-spacedeb"
            style={{ paddingTop: 30 }}
          >
            <i className="trashIcon" onClick={() => arrayHelpers.remove(index)}>
              <img
                src={require("../../assets/icons/deleteIconColor.svg")}
                alt="Imagem de um lixeiro; clique nessa imagem para excluir o endereço"
              />
            </i>
          </div>
        ) : null}
      </div>
    </>
  );
}
