import api from "./api.service";
import CoreApiService from "./core-api.service";
import LogSerializer from "./serializers/log.serializer";

export default class LogService extends CoreApiService {
  constructor() {
    super(undefined, "logs", new LogSerializer());
  }

  async listaLogs(idUsuario, queryOptions = null, isListView = null) {
    const response = await api.get(
      `${this.endpoint}/${idUsuario}?${
        (queryOptions && queryOptions.toQueryString()) || ""
      }`
    );
    const data = response.data;

    if (data.items && isListView) {
      return this.convertData(data, data._meta);
    } else {
      return this.convertData(data);
    }
  }

  async logFiltros() {
    const response = await api.get(`${this.endpoint}/filtros`);

    const data = response.data;
    return data;
  }
}
