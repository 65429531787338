/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Formik } from "formik";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import AlertMenssage from "../../../components/AlertMenssage";
import ButtonSave from "../../../components/ButtonSave";
import ConfirmationDialog from "../../../components/dialogs/confirmation-dialog";
import DropdownInput from "../../../components/DropdownInput";
import ExpositorMenu from "../../../components/ExpositorMenu";
import ProporcaoImagemDialogo from "../../../components/ProporcaoImagemDialogo";
import TextInput from "../../../components/TextInput";
import AlertService from "../../../service/alert.service";
import EditionService from "../../../service/edition.service";
import {
  default as EmpresaCatalogoService,
  default as EmpresaCatalogosService,
} from "../../../service/empresa-catalogos.service";
import ExpositoresService from "../../../service/expositores.service";
import LanguagesService from "../../../service/language.service";
import QueryOptions from "../../../utils/query-options";
import { eventsSchema } from "../../../validations/catalogo.validation";
import CatalogoImages from "../../Catalogo/CatalogosImages";
import CatalogoArquivo from "../CatalogosArquivo/index";
import "./style.scss";

export const catalogoFields = {
  titulo: "",
  idIdioma: "",
};

export default function CatalogoForm({ location }) {
  const { idExpositor, id } = useParams();
  const [action, setAction] = useState("create");
  const [arquivo, setArquivo] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);
  const [expositor, setExpositor] = useState();
  const [loadingInfo, setLoadingInfo] = useState(true);

  const [catalogo, setCatalogo] = useState();
  const _catalogoService = new EmpresaCatalogoService();

  useEffect(
    (catalogo) => {
      async function getCatalogo() {
        const _catalogoService = new EmpresaCatalogosService();
        const response = await _catalogoService.read(id);
        setCatalogo(response);

        if (response.arquivoFilename) {
          setArquivo(true);
        }
        setLoadingInfo(false);
      }

      async function init() {
        const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
        const _edicaoService = new EditionService();
        const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
          queryOptionsEdicaoAtiva
        );

        const _expositoresService = new ExpositoresService();
        const expositor = await _expositoresService.read(idExpositor);
        setExpositor(expositor);

        const _languagesService = new LanguagesService();
        const languages = await _languagesService.list();
        setLanguages(languages);

        const catalogoFields = {
          titulo: "",
          idIdioma: "",
          edicaoAtual: edicaoAtual?.id,
        };

        const queryStringObj = queryString.parse(location.search);

        if (queryStringObj) {
          if (queryStringObj.acao === "criado") {
            AlertService.success("Evento adicionado com sucesso");
          } else if (queryStringObj.acao === "deletado") {
            AlertService.success("Evento deletado com sucecsso");
          }
        }
        if (id) {
          setAction("edit");
          getCatalogo();
        } else {
          setCatalogo(catalogoFields);
          setLoadingInfo(false);
        }
      }
      init();
    },
    [id, arquivo]
  );

  async function createCatalogo(values) {
    setLoading(true);
    const data = { ...values };

    if (action === "create") {
      data.parentId = idExpositor;

      await _catalogoService
        .createSub(data)
        .then((response) => {
          setAction("edit");
          history.push(
            `/expositores/${idExpositor}/catalogos/editar/${response.id}`
          );
        })
        .catch(({ response }) => {
          AlertService.warn(response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await _catalogoService
        .update(data)
        .then(() => {
          AlertService.success("Catálogo atualizado com sucesso");
        })
        .catch(({ response }) => AlertService.warn(response.data.msg))
        .finally(() => {
          setLoading(false);
        });
    }
  }

  async function handleDelete() {
    await _catalogoService.delete(id);
    history.push(`/expositores/editar/${idExpositor}/catalogos?acao=deletado`);
    setDisplayConfirmationDialog(false);
  }

  return (
    <div className="p-grid">
      <ConfirmationDialog
        header="Remover esse item vai apagar todas as informações"
        action="Remover mesmo assim?"
        display={displayConfirmationDialog}
        onClose={() => setDisplayConfirmationDialog(false)}
        onConfirm={handleDelete}
      />

      {loadingInfo && (
        <div className="loading-info-container">
          <lottie-player
            src="https://assets4.lottiefiles.com/packages/lf20_58vkk48j.json"
            background="transparent"
            speed="1"
            style={{ width: "500px" }}
            loop
            autoplay
          />
        </div>
      )}

      <div className="p-col-12" hidden={loadingInfo}>
        <ExpositorMenu activeItem={5} expositor={expositor} />

        <div className="card">
          <Formik
            enableReinitialize={true}
            validationSchema={eventsSchema}
            initialValues={catalogo}
            onSubmit={(values) => {
              createCatalogo(values);
            }}
          >
            {(props) => {
              return (
                <Form autoComplete="off">
                  <h1>
                    {action === "create"
                      ? "Adicionar um novo catálogo"
                      : "Editar catálogo"}
                  </h1>

                  <hr className="hr-header" />

                  <div className="p-grid">
                    <TextInput
                      placeholder="Titulo"
                      label="Titulo"
                      name={`titulo`}
                      classes="p-col-12 p-md-6 p-lg-6"
                      className="input"
                      quantidadeCaracteres={50}
                      maxLength={50}
                      required={true}
                    />

                    <DropdownInput
                      placeholder="Selecionar"
                      label="Idioma"
                      required={true}
                      name="idIdioma"
                      classes="p-col-12 p-md-4 p-lg-2"
                      options={languages}
                    />

                    <div
                      className="p-col-12 p-md-3 p-lg-8"
                      style={{ marginBottom: "12px" }}
                    ></div>

                    {action === "edit" && (
                      <div className="p-col-12 p-md-4 p-lg-8">
                        <h3>Imagem de capa</h3>

                        <hr />
                        <div>
                          <p>
                            <ProporcaoImagemDialogo
                              desktopEMobile={true}
                              desktopEMobileWidth={250}
                              desktopEMobileHeight={250}
                            />
                          </p>
                        </div>

                        <CatalogoImages
                          width={250}
                          height={250}
                          form={props}
                          imagem={props.values?.imageFileName}
                        />
                      </div>
                    )}
                    {action === "edit" && (
                      <div className="p-col-12 p-md-4 p-lg-8">
                        <h3>Arquivo</h3>
                        <hr />
                        <CatalogoArquivo
                          setArquivo={setArquivo}
                          arquivo={arquivo}
                        />
                        {arquivo && (
                          <a
                            href={`${process.env.REACT_APP_API_URL}catalogos/${id}/arquivos/display`}
                            target="_blank"
                            rel="noreferrer noopener"
                            class="pdf-file"
                          >
                            Baixar PDF
                          </a>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="btns-inline" style={{ marginTop: "14px" }}>
                    <div className="btn-medium">
                      <ButtonSave
                        label={
                          action === "create"
                            ? "Selecionar arquivo"
                            : "Atualizar"
                        }
                        loading={loading}
                        type="submit"
                        onClick={() => {
                          if (Object.keys(props.errors).length) {
                            AlertService.warn(
                              "Verifique se todos os campos obrigatórios foram preenchidos corretamente"
                            );
                          }
                        }}
                      />
                    </div>

                    <div className="btn-medium">
                      {action === "edit" && (
                        <button
                          className="button-delete"
                          styles="p-button-secondary"
                          onClick={(e) => {
                            e.preventDefault();
                            setDisplayConfirmationDialog(true);
                          }}
                        >
                          Excluir
                        </button>
                      )}
                    </div>

                    <div className="btn-medium">
                      <button
                        className="button-cancel"
                        onClick={() => {
                          history.push(
                            `/expositores/editar/${idExpositor}/catalogos`
                          );
                        }}
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <AlertMenssage />
        </div>
      </div>
    </div>
  );
}
