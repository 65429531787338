import * as Yup from "yup";
import * as messages from "./messages";
import "./customs.validation";
import { addressSchema } from "./address.validation";
import { phoneSchema } from "./phone.validation";

export const expositorSchema = Yup.object().shape({
  uriUnico: Yup.string().required(messages.REQUIRED),

  nomeFantasia: Yup.string().required(messages.REQUIRED),
  razaoSocial: Yup.string().required(messages.REQUIRED),
  inscEstadual: Yup.string().max(
    15,
    "O número máximo permitido para inscrição estadual é 15"
  ),
  cnpj: Yup.string().cnpj().required(messages.CNPJ),
  phones: Yup.array().of(phoneSchema),

  adresses: Yup.array().of(addressSchema),
  idModalidade: Yup.number().required(messages.REQUIRED),
});
