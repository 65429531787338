/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import AlertMenssage from "../../../../components/AlertMenssage";
import ButtonSave from "../../../../components/ButtonSave";
import TextareaInput from "../../../../components/TextareaInput";
import TextInput from "../../../../components/TextInput";
import AlertService from "../../../../service/alert.service";
import CampoCustomizadoItem from "../../../../service/campo-customizado-item.service";
import LanguagesService from "../../../../service/language.service";
import { campoCustomizadoIdiomas } from "../idioma";

export default function HomeForm() {
  const [idiomas, setIdiomas] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(1);
  const [campoCustomizado, setCampoCustomizado] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const _campoCustomizadoItemService = new CampoCustomizadoItem();

  useEffect(() => {
    async function getCampoCustomizadoValues(idiomas) {
      const campoCustomizado = await _campoCustomizadoItemService.listSub(2);

      campoCustomizado.forEach((element) => {
        let idiomasArray = [];
        idiomas.forEach((element) => {
          idiomasArray.push({
            ...campoCustomizadoIdiomas,
            idIdioma: element.id,
          });
        });

        const idIdiomas = [];
        element.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

        const idiomasFaltantes = [];
        idiomasArray.forEach((item) => {
          if (!idIdiomas.includes(item.idIdioma)) {
            idiomasFaltantes.push(item);
          }
        });

        const allLanguages = [...element.idiomas, ...idiomasFaltantes].sort(
          (v1, v2) => v1.idIdioma - v2.idIdioma
        );

        element.idiomas = allLanguages;
      });

      setCampoCustomizado({ dados: campoCustomizado });
    }

    async function init() {
      const _idiomaService = new LanguagesService();
      const idiomaResponse = await _idiomaService.list();

      let idiomasArray = [];
      idiomas.forEach((element) => {
        idiomasArray.push({
          ...campoCustomizadoIdiomas,
          idIdioma: element.id,
        });
      });

      setCampoCustomizado({
        ...campoCustomizado,
        idiomasArray,
      });
      setIdiomas(idiomaResponse);

      await getCampoCustomizadoValues(idiomaResponse);
    }

    init();
  }, []);

  function selectLanguage(item) {
    setCurrentLanguage(item.id);
  }

  async function onSubmitCampoCustom(values) {
    const data = { ...values };
    setLoading(true);

    await _campoCustomizadoItemService
      .updateCampo(data)
      .then((response) => {
        setLoading(false);
        AlertService.success("Campo atualizado com sucesso");
      })
      .catch(({ response }) => {
        AlertService.warn(response.data.msg);
      });
  }

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <div className="p-grid">
            <div className="p-col-12 p-lg-12 p-md-12 p-sm-12">
              <h1 className="h1-container">Home</h1>

              <hr className="hr-header" />
            </div>
          </div>

          <Formik
            enableReinitialize={true}
            initialValues={campoCustomizado}
            onSubmit={(values) => {
              onSubmitCampoCustom(values);
            }}
          >
            {(props) => {
              return (
                <Form autoComplete="off">
                  <div className="language-dashboard-container">
                    {idiomas.map((item, index) => (
                      <div key={index} className="language-dashboard">
                        <button
                          className={
                            item.id === currentLanguage
                              ? "p-button-dash"
                              : "p-button-teste"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            selectLanguage(item);
                          }}
                        >
                          {item.nome}
                        </button>
                      </div>
                    ))}
                  </div>

                  <FieldArray
                    name="idiomas"
                    render={(arrayHelpers) => (
                      <div className="p-grid">
                        {props?.values?.dados?.map((item, index) => (
                          <div
                            className="p-lg-8 p-md-12 p-sm-12"
                            key={index}
                            hidden={
                              !(
                                item?.idiomas[index]?.idIdioma ===
                                currentLanguage
                              )
                            }
                          >
                            <div className="p-lg-12 p-md-12 p-sm-12">
                              <h2>1 Sessão</h2>

                              <TextInput
                                name={`dados[0].idiomas[${index}].item`}
                                className="input"
                                maxLength={30}
                                quantidadeCaracteres={30}
                              />

                              <TextInput
                                name={`dados[1].idiomas[${index}].item`}
                                className="input"
                                maxLength={30}
                                quantidadeCaracteres={30}
                              />

                              <TextareaInput
                                name={`dados[2].idiomas[${index}].item`}
                                className="input"
                              />

                              <TextareaInput
                                name={`dados[3].idiomas[${index}].item`}
                                className="input"
                              />

                              <h4>Cartão 1</h4>
                              <TextInput
                                name={`dados[4].idiomas[${index}].item`}
                                className="input"
                                maxLength={60}
                                quantidadeCaracteres={60}
                              />

                              <TextareaInput
                                name={`dados[5].idiomas[${index}].item`}
                                className="input"
                              />

                              <h4>Cartão 2</h4>

                              <TextInput
                                name={`dados[6].idiomas[${index}].item`}
                                className="input"
                                maxLength={60}
                                quantidadeCaracteres={60}
                              />

                              <TextareaInput
                                name={`dados[7].idiomas[${index}].item`}
                                className="input"
                              />

                              <h4>Cartão 3</h4>

                              <TextInput
                                name={`dados[8].idiomas[${index}].item`}
                                className="input"
                                maxLength={60}
                                quantidadeCaracteres={60}
                              />
                              <TextareaInput
                                name={`dados[9].idiomas[${index}].item`}
                                className="input"
                              />

                              <hr />
                              <h2>2 Sessão</h2>

                              <TextInput
                                name={`dados[10].idiomas[${index}].item`}
                                className="input"
                                maxLength={55}
                                quantidadeCaracteres={55}
                              />

                              <TextInput
                                name={`dados[11].idiomas[${index}].item`}
                                className="input"
                                maxLength={28}
                                quantidadeCaracteres={28}
                              />

                              <hr />
                              <h2>3 Sessão</h2>

                              <TextInput
                                name={`dados[12].idiomas[${index}].item`}
                                className="input"
                                maxLength={30}
                                quantidadeCaracteres={30}
                              />

                              <TextInput
                                name={`dados[13].idiomas[${index}].item`}
                                className="input"
                                maxLength={43}
                                quantidadeCaracteres={43}
                              />

                              <hr />
                              <h2>4 Sessão</h2>

                              <TextInput
                                name={`dados[14].idiomas[${index}].item`}
                                className="input"
                              />

                              <TextareaInput
                                name={`dados[15].idiomas[${index}].item`}
                                className="input"
                              />

                              <TextareaInput
                                name={`dados[16].idiomas[${index}].item`}
                                className="input"
                              />

                              <TextareaInput
                                name={`dados[17].idiomas[${index}].item`}
                                className="input"
                              />

                              <TextareaInput
                                name={`dados[18].idiomas[${index}].item`}
                                className="input"
                              />

                              <TextInput
                                name={`dados[19].idiomas[${index}].item`}
                                className="input"
                                maxLength={30}
                                quantidadeCaracteres={30}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  />

                  <div className="btns-inline" style={{ marginTop: "14px" }}>
                    <div className="btn-medium">
                      <ButtonSave
                        label="Salvar"
                        loading={loading}
                        type="submit"
                        onClick={() => {
                          if (Object.keys(props.errors).length) {
                            AlertService.warn(
                              "Verifique se todos os campos obrigatórios foram preenchidos corretamente"
                            );
                          }
                        }}
                      />
                    </div>

                    <div className="btn-medium">
                      <button
                        className="button-cancel"
                        onClick={() => {
                          history.push("/campos-customizados");
                        }}
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <AlertMenssage />
        </div>
      </div>
    </div>
  );
}
