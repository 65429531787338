export default class AddressTypeSerializer {
  fromJson(json) {
    const addressType = {
      id: json.id_endereco_tipo,
      type: json.tipo,
    };

    return addressType;
  }

  toJson(addressType) {
    return {
      id_endereco_tipo: addressType.id,
      tipo: addressType.type,
    };
  }
}
