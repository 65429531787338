/* eslint-disable react-hooks/exhaustive-deps */
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import AlertMenssage from "../../components/AlertMenssage";
import ListTableLazy from "../../components/ListTableLazy";
import AlertService from "../../service/alert.service";
import QueryOptions from "../../utils/query-options";
import CursoInstrutorService from "../../service/curso-instrutor.service";

export default function CursoInstrutorList({ location }) {
  const [avertisingdValues, setAvertisingdValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);
  const [first, setFirst] = useState(0);

  const columns = [
    {
      field: "nome",
      header: "Nome",
    },
    {
      field: `idiomas.${[0]}.biografia`,
      header: "Biografia",
    },
  ];

  useEffect(() => {
    async function init() {
      const _instrutorService = new CursoInstrutorService();

      setLoading(true);

      const queryOptions = new QueryOptions([["page", page]]);
      const data = await _instrutorService.list(queryOptions, true);

      setLoading(false);
      setAvertisingdValues(data.items);
      setMeta(data.meta);
    }

    init();
  }, [page]);

  useEffect(() => {
    const queryStringObj = queryString.parse(location.search);

    if (queryStringObj) {
      if (queryStringObj.acao === "criado") {
        AlertService.success("Instrutor adicionado");
      } else if (queryStringObj.acao === "deletado") {
        AlertService.success("Instrutor deletado");
      }
    }
  }, [location]);

  function handleChangePage(event) {
    setFirst(event.first);
    setPage(event.page + 1);
  }

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <div className="p-grid">
            <div className="p-col-9 p-lg-9 p-md-9 p-sm-9">
              <h1 className="h1-container">Instrutores</h1>
            </div>
          </div>

          <hr className="hr-header" />

          <AlertMenssage />

          <ListTableLazy
            cols={columns}
            values={avertisingdValues}
            firstIdx={first}
            meta={meta}
            loading={loading}
            endpoint="cursos/instrutores"
            onChangePage={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
}
