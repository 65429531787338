/* eslint-disable react-hooks/exhaustive-deps */
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AlertMenssage from "../../../components/AlertMenssage";
import ExpositorMenu from "../../../components/ExpositorMenu";
import ListTableLazy from "../../../components/ListTableLazy";
import AlertService from "../../../service/alert.service";
import EditionService from "../../../service/edition.service";
import EventService from "../../../service/event.service";
import ExpositoresService from "../../../service/expositores.service";
import QueryOptions from "../../../utils/query-options";

export default function ExpositorEvent({ location }) {
  const { id, idExpositor } = useParams();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);
  const [first, setFirst] = useState(0);
  const [expositor, setExpositor] = useState();

  const columns = [
    { field: `idiomas.${[0]}.titulo`, header: "Título" },
    { field: `idiomas.${[0]}.descricao`, header: "Descrição" },
    { field: "dataInicioStr", header: "Data início" },
    { field: "dataFimStr", header: "Data fim" },
  ];

  useEffect(() => {
    async function listEvents() {
      const _eventService = new EventService();
      const _edicaoService = new EditionService();

      const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);

      const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
        queryOptionsEdicaoAtiva
      );

      const queryStringObj = queryString.parse(location.search);

      if (queryStringObj) {
        if (queryStringObj.acao === "criado") {
          AlertService.success("Evento adicionado com sucesso");
        } else if (queryStringObj.acao === "deletado") {
          AlertService.success("Evento deletado com sucecsso");
        }
      }

      const _expositoresService = new ExpositoresService();
      const expositor = await _expositoresService.read(idExpositor);
      setExpositor(expositor);

      const queryOptions = new QueryOptions([
        ["fields", "data_inicio, data_fim, idiomas, evento_anfacer"],
        ["id_expositor", idExpositor],
        ["page", page],
        ["paginacao", 1],
        ["edicao", edicaoAtual.id],
      ]);

      const eventosExpositor = await _eventService.list(queryOptions, true);
      setEvents(eventosExpositor.items.filter((item) => !item.eventoAnfacer));

      setLoading(false);

      setMeta(eventosExpositor.meta);
    }
    listEvents();
  }, [page, idExpositor, id]);

  function handleChangePage(event) {
    setFirst(event.first);
    setPage(event.page + 1);
  }

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <ExpositorMenu activeItem={3} expositor={expositor} />

        <div className="card">
          <h1>Eventos do Expositor</h1>

          <AlertMenssage />

          <hr className="hr-header" />
          <ListTableLazy
            cols={columns}
            values={events}
            setValues={setEvents}
            firstIdx={first}
            meta={meta}
            loading={loading}
            endpoint="eventos-expositor"
            deleteAllEndPoint="eventos"
            parentEndPoint="expositores"
            parentId={idExpositor}
            onChangePage={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
}
