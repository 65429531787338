import React from "react";
import { useField } from "formik";

export default function TextareaInput({
  label,
  classes,
  required,
  quantidadeCaracteres,
  rows=3,
  ...props
}) {
  const [field, meta] = useField(props);

  return (
    <div className={classes}>
      <label htmlFor={props.id || props.name}>
        {label}{" "}{quantidadeCaracteres && (
          <span className="quantidade-caracteres">
            ({quantidadeCaracteres} caracteres)
          </span>
        )}
        {required && <span className="required-hash"> *</span>}
      </label>
      <div className="input-block">
        <textarea className="input-text" {...field} {...props} rows={rows} />
      </div>
      <div className="error-input">
        {meta.touched && meta.error ? (
          <div className="error ">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
}
