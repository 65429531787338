import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ScrollPanel } from "primereact/scrollpanel";
import getPermissionUser from "../../utils/permission-user";
import EditionService from "../../service/edition.service";

class AppSubmenu extends Component {
  static defaultProps = {
    className: null,
    items: null,
    onMenuItemClick: null,
    root: false,
  };

  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.array,
    onMenuItemClick: PropTypes.func,
    root: PropTypes.bool,
  };

  async edicaoAtual() {
    const edicaoAtual = await this._editionService.list();
    edicaoAtual.forEach((element) => {
      if (!element.disabled) {
        this.setState({ edicaoAtiva: element?.nome });
      }
    });
  }

  constructor(props) {
    super(props);
    this.state = { activeIndex: null, edicaoAtiva: null };
    this._editionService = new EditionService();
    this.edicaoAtual();
  }

  onMenuItemClick(event, item, index) {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    if (index === this.state.activeIndex) this.setState({ activeIndex: null });
    else this.setState({ activeIndex: index });

    if (this.props.onMenuItemClick) {
      this.props.onMenuItemClick({
        originalEvent: event,
        item: item,
      });
    }
  }

  renderLinkContent(item) {
    let submenuIcon = item.items && (
      <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
    );
    let badge = item.badge && (
      <span className="menuitem-badge">{item.badge}</span>
    );

    const icon = item.iconImage ? (
      <img alt="icone" src={item.iconImage} />
    ) : (
      <i className={item.icon}></i>
    );

    return (
      <React.Fragment>
        {icon}
        <span>{item.label}</span>
        {submenuIcon}
        {badge}
      </React.Fragment>
    );
  }

  renderLink(item, i, download) {
    let content = this.renderLinkContent(item);

    if (item.to) {
      return (
        <NavLink
          activeClassName="active-route"
          to={item.to}
          onClick={(e) => this.onMenuItemClick(e, item, i)}
          exact
          target={item.target}
          style={item.label === "Edições" ? { display: "block" } : null}
        >
          {content}

          <div>
            {item.label === "Edições" && (
              <h6 style={{ margin: 0, fontWeight: "normal" }}>
                <span style={{color: "#009974", fontSize: "13.4px", margin: 0}}>Edição ativa:</span> {this.state.edicaoAtiva}
              </h6>
            )}
          </div>
        </NavLink>
      );
    } else if (item.download) {
      return <span className="menu-span">{content}</span>;
    } else {
      return (
        <a
          href={item.url}
          onClick={(e) => this.onMenuItemClick(e, item, i)}
          target={item.target}
        >
          {content}
        </a>
      );
    }
  }

  activeMenu(items, path) {
    let response = false;
    items.forEach((element) => {
      if (element.to && element.to.includes(path)) {
        response = true;
      } else if (element.items) {
        response = this.activeMenu(element.items, path);
      }
    });

    return response;
  }

  render() {
    const userPermission = getPermissionUser();

    let items =
      this.props.items &&
      this.props.items.map((item, i) => {
        let active = null;

        let path = window.location.pathname;

        if (path === "/") {
          path = this.props.items[0].to || "";
        }

        if (item.to && item.to === "/usuarios" && path.includes("perfil")) {
          active = true;
        } else if (item.to && path.includes(item.to)) {
          active = true;
        } else if (item.items) {
          active = classNames(item.badgeStyleClass, {
            "active-menuitem": this.activeMenu(item.items, path),
          });
        } 
        
        if (!active) {
          active = this.state.activeIndex === i;
        }

        let styleClass = classNames(item.badgeStyleClass, {
          "active-menuitem": active,
        });

        let show = true;
        if (item.permissions) {
          show = item.permissions.includes(userPermission);
        }

        if (show) {
          if (!item.download) {
            return (
              <li className={styleClass} key={i}>
                {item.items && this.props.root === true && (
                  <div className="arrow"></div>
                )}

                {this.renderLink(item, i)}

                <AppSubmenu
                  items={item.items}
                  onMenuItemClick={this.props.onMenuItemClick}
                />
              </li>
            );
          } else {
            return (
              <li
                className={styleClass}
                key={i}
                onClick={() => this.downloadFinancing(item.download)}
              >
                {item.items && this.props.root === true && (
                  <div className="arrow"></div>
                )}
                {this.renderLink(item, i, true)}
              </li>
            );
          }
        } else {
          return null;
        }
      });

    return items ? <ul className={this.props.className}>{items}</ul> : null;
  }
}

export class AppMenu extends Component {
  static defaultProps = {
    model: null,
    onMenuItemClick: null,
  };

  static propTypes = {
    model: PropTypes.array,
    onMenuItemClick: PropTypes.func,
  };

  render() {
    return (
      <ScrollPanel className="scroll-menu">
        <div className="layout-menu-container">
          <AppSubmenu
            items={this.props.model}
            className="layout-menu"
            onMenuItemClick={this.props.onMenuItemClick}
            root={true}
          />
        </div>
      </ScrollPanel>
    );
  }
}
