import React from "react";
import { useField } from "formik";
import InputMask from "react-input-mask";
import ModalExplicativo from "./ModalExplicativo";

export default function TextInputDuvidas({
  label,
  classes = "",
  header,
  required,
  content,
  imagemExplicativa,
  imagemExplicativaAlt,
  ...props
}) {
  const [field, meta] = useField(props);

  return (
    <div className={classes}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <label htmlFor={props.id || props.name}>
          {label} {required && <span className="required-hash"> *</span>}
        </label>

        <ModalExplicativo
          header={header}
          content={content}
          classes="p-col-2 p-md-2 p-lg-2"
          imagemExplicativa={imagemExplicativa}
          imagemExplicativaAlt={imagemExplicativaAlt}
        />
      </div>

      <div className="input-block">
        {props.iconleft && (
          <img className="input-icon" src={props.iconleft} alt="Ícone de dúvidas" />
        )}

        {!props.mask ? (
          <input className="input-text" {...field} {...props} />
        ) : (
          <InputMask
            className="input-text"
            {...field}
            {...props}
            alwaysShowMask={true}
          />
        )}
        {props.loading && (
          <i className="pi pi-spin pi-spinner input-loading"></i>
        )}
      </div>
      <div className="error-input">
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
}
