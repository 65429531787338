import api from "./api.service";
import CoreApiService from "./core-api.service";
import CatalogoSerializer from "./serializers/catalogos.serializer";

export default class EmpresaCatalogosService extends CoreApiService {
  constructor() {
    super("expositores", "catalogos", new CatalogoSerializer());
  }

  async upload(id, data) {
    const formData = this.serializer.toFormData(data);

    const response = await api.put(`${this.endpoint}/${id}/uploads`, formData);

    if (response.data["oret"]) {
      return this.serializer.fromJson(response.data["oret"]);
    }

    return null;
  }
}
