import React from "react";
import imgDefault from "../assets/images/defaultProfile.png";

export default function ImageDefault({
  urlImage,
  urlImageError = imgDefault,
  index = 0,
}) {
  function onError(e) {
    e.target.src = urlImageError;
  }

  return (
    <>
      {urlImage && (
        <img src={urlImage} onError={onError} alt="Imagem do banner"></img>
      )}
    </>
  );
}
