import * as Yup from "yup";
import * as messages from "./messages";
import "./customs.validation";
import { addressSchema } from "./address.validation";
import { phoneSchema } from "./phone.validation";

export const userSchema = Yup.object({
  profile: Yup.object().required(messages.REQUIRED),
  name: Yup.string().required(messages.REQUIRED),
  cpf: Yup.string().cpfCnpj().required(messages.REQUIRED),
  email: Yup.string().email(messages.EMAIL).required(messages.REQUIRED),

  dataNascimento: Yup.string().when("profile.id", {
    is: (idPerfil) => idPerfil === 3,
    then: Yup.string().required(messages.REQUIRED).nullable(),
  }),

  cargo: Yup.object().when("profile.id", {
    is: (idPerfil) => idPerfil === 3,
    then: Yup.object().required(messages.REQUIRED),
  }),

  sexo: Yup.object().when("profile.id", {
    is: (idPerfil) => idPerfil === 3,
    then: Yup.object().required(messages.REQUIRED),
  }),

  perfilEmpresa: Yup.object().when("profile.id", {
    is: (idPerfil) => idPerfil === 3,
    then: Yup.object().required(messages.REQUIRED),
  }),

  confEmail: Yup.string()
    .email(messages.EMAIL)
    .required(messages.REQUIRED)
    .test(
      "email-match",
      '"E-mail" e "Confirme o e-mail" devem ser iguais',
      function (value) {
        return this.parent.email === value;
      }
    ),

  password: Yup.string().when("profile.id", {
    is: (idPerfil) => idPerfil !== 3,
    then: Yup.string()
      .min(6, messages.MIN_LENGTH)
      .max(20, messages.MAX_LENGTH)
      .required(messages.REQUIRED),
  }),

  confirmPassword: Yup.string().when("profile.id", {
    is: (idPerfil) => idPerfil !== 3,
    then: Yup.string()
      .required(messages.REQUIRED)
      .test(
        "passwords-match",
        '"Senha" e "Confirme a senha" devem ser iguais',
        function (value) {
          return this.parent.password === value;
        }
      ),
  }),

  expositores: Yup.array().when("profile.id", {
    is: (idPerfil) => idPerfil === 2,
    then: Yup.array().required(messages.REQUIRED).nullable(),
  }),

  phones: Yup.array().of(phoneSchema),
  adresses: Yup.array().of(addressSchema),
});
