import CoreApiService from "./core-api.service";
import ReportSerializer from "./serializers/report.serializer";
import api from "./api.service";

export default class ReportService extends CoreApiService {
  constructor() {
    super(undefined, "relatorios", new ReportSerializer());
  }

  async payment(data, queryOptions) {
    const response = await api.post(
      `${this.endpoint}/pagamentos?${
        (queryOptions && queryOptions.toQueryString()) || ""
      }`,
      this.serializer.toJson(data)
    );

    return response;
  }

  async status() {
    const response = await api.get(`${this.endpoint}/status`);
    return response;
  }

  async download(id) {
    window.open(
      `${process.env.REACT_APP_API_URL}${this.endpoint}/${id}`,
      "_blank"
    );
  }
}
