import api from "./api.service";
import CoreApiService from "./core-api.service";
import EventLogosSerializer from "./serializers/event-logos.serializer";
import EventSerializer from "./serializers/event.serializer";
import EventosBannersSerializer from "./serializers/evento-banner.serializer";

export default class EventService extends CoreApiService {
  constructor() {
    super(undefined, "eventos", new EventSerializer());
  }

  async criarLogo(item) {
    this.parentEndpoint = "eventos";
    this.endpoint = "logotipos";
    this.serializer = new EventLogosSerializer();

    const response = await api.post(
      `${this.parentEndpoint}/${item.parentId}/${this.endpoint}`,
      this.serializer.toJson(item)
    );

    return response.data?.oret;
  }

  async editarLogo(item, id = item.id_evento_logotipo) {
    this.parentEndpoint = "logotipos";
    this.endpoint = "eventos";
    this.serializer = new EventLogosSerializer();

    const ref = id ? id : item.id;
    const response = await api.put(
      `${this.endpoint}/${this.parentEndpoint}/${ref}`,
      this.serializer.toJson(item)
    );

    return response.data?.oret;
  }

  async deleteLogo(id) {
    const response = await api.delete(`${this.endpoint}/logotipos/${id}`);
    return response;
  }

  async deletarTodosEventos() {
    const response = await api.delete(`${this.endpoint}`);
    return response;
  }

  async getLogo(id) {
    this.parentEndpoint = "eventos";
    this.endpoint = "logotipos";
    this.serializer = new EventLogosSerializer();

    const response = await api.get(
      `${this.parentEndpoint}/${id}/${this.endpoint}`
    );
    const data = response.data;
    return this.serializer.fromJson(data);
  }

  async criarBanner(item) {
    this.parentEndpoint = "eventos";
    this.serializer = new EventosBannersSerializer();

    const response = await api.post(
      `${this.parentEndpoint}/${item.parentId}/banners`,
      this.serializer.toJson(item)
    );

    return response.data?.oret;
  }

  async getBanner(id) {
    this.parentEndpoint = "eventos";
    this.serializer = new EventosBannersSerializer();

    const response = await api.get(`${this.parentEndpoint}/${id}/banners`);
    const data = response.data;

    return data.items.map((item) => this.serializer.fromJson(item));
  }

  async editarBanner(item, id = item.idEventoBanner) {
    this.parentEndpoint = "banners";
    this.endpoint = "eventos";
    this.serializer = new EventosBannersSerializer();

    const ref = id ? id : item.id;
    const response = await api.put(
      `${this.endpoint}/${this.parentEndpoint}/${ref}`,
      this.serializer.toJson(item)
    );

    return response.data?.oret;
  }

  async deleteBanner(id) {
    const response = await api.delete(`${this.endpoint}/banners/${id}`);
    return response;
  }
}
