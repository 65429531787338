import api from "./api.service";
import CoreApiService from "./core-api.service";
import ExpositoresEstatisticasSerializer from "./serializers/expositor-estatistica.serializer";

export default class ExpositoresEstatisticasService extends CoreApiService {
  constructor() {
    super(
      "expositores",
      "estatisticas",
      new ExpositoresEstatisticasSerializer()
    );
  }

  async readSub(id) {
    const response = await api.get(
      `${this.parentEndpoint}/${id}/${this.endpoint}`
    );
    const data = response.data;
    return this.serializer.fromJson(data);
  }

  async createEstatistica(item, isFormData = false) {
    await api.post(
      `${this.parentEndpoint}/${item.parentId}/${this.endpoint}`,
      isFormData
        ? this.serializer.toFormData(item)
        : this.serializer.toJson(item)
    );
  }

  async editarExpositorEstatistica(item) {
    this.parentEndpoint = undefined;
    this.endpoint = "expositor-estatisticas";
    return await this.update(item);
  }
}
