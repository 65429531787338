import ProductsImagesSerializer from "./products-images.serializer";
import ProductsLanguagesSerializer from "./products-languages.serializer";
import ProductsTypeSerializer from "./products-type-serializer";

export default class ProductsSerializer {
  constructor() {
    this.productsLanguagesSerializer = new ProductsLanguagesSerializer();
    this.productsTypeSerializer = new ProductsTypeSerializer();
    this.productImageSerializer = new ProductsImagesSerializer()
  }

  fromJson(json) {
    const product = {};
    Object.assign(
      product,
      json.id_produto && { id: json.id_produto },
      json.codigo && { codigoProduto: json.codigo },
      json.link && { linkProduto: json.link },
      json.data_criacao && { dataCriacaoProduto: json.data_criacao },
      json.dimensoes && { dimensoesProduto: json.dimensoes },
      json.disabled && { disabled: json.disabled },
      json.idiomas
        ? {
            idiomas: json.idiomas.map((item) =>
              this.productsLanguagesSerializer.fromJson(item)
            ),
          }
        : { idiomas: [] },
      json.produto_tipos && {
        idsProdutoTipo: json.produto_tipos.map((item) =>
          this.productsTypeSerializer.fromJson(item)
        ),
      },
      json.imagens && {
        imagens: json.imagens.map((item) =>
          this.productImageSerializer.fromJson(item)
        ),
      }
    );

    return product;
  }

  toJson(product) {
    return {
      codigo: product.codigoProduto,
      link: product.linkProduto,
      dimensoes: product.dimensoesProduto,
      id_edicao: product.edicaoAtual,
      idiomas: product?.idiomas?.map((item) =>
        this.productsLanguagesSerializer.toJson(item)
      ),
      ids_produto_tipo: product.idsProdutoTipo
        ? product.idsProdutoTipo.map((item) => item.value)
        : [],
      order: product.order,
    };
  }
}
