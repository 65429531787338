import React, { Component } from "react";
import classNames from "classnames";
import history from "../../service/history";
import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import ScrollToTop from "./ScrollToTop";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./../../layout/layout.scss";
import Authentication from "../../service/authentication.service";
import imageProfileDefault from "../../utils/image-profile-default";
import ReportService from "../../service/report.service";
import { userProfiles } from "../../utils/permission-user";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layoutMode: "static",
      layoutColorMode: "dark",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      imageProfile: null,
      visibleDialogReports: false,
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.auth = new Authentication();
    this.user = null;
    this.baseUrl = process.env.REACT_APP_API_URL;
    this.reportService = new ReportService();

    this.init();
  }

  async init() {
    if (!this.auth._user) {
      const user = await this.auth.retrieveLoggedUser();
      this.createMenu(user);

      if (user.changePassword) {
        history.push("/mudar-senha");
      }

      const imageProfile = user.imageFileName
        ? `${this.baseUrl}usuarios/${user.id}/display`
        : imageProfileDefault();
      this.setState({ user, imageProfile });
    }
  }

  onWrapperClick(event) {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  }

  async createMenu(user) {
    this.menu = [
      {
        label: "Dashboard",
        icon: "pi pi-fw pi-home",
        to: "/painel-controle",
      },
      { label: "Usuários", icon: "pi pi-fw pi-users", to: "/usuarios" },
      {
        label: "Expositores",
        icon: "pi pi-user",
        to: "/expositores",
      },
      {
        label: "Edições",
        icon: "pi pi-calendar",
        to: "/edicoes",
        permissions: [userProfiles.administrador],
      },
      {
        label: "Educação",
        icon: "pi pi-book",
        // to: "/cursos",
        items: [
          {
            label: "Cursos",
            icon: "pi pi-fw pi-bookmark",
            to: "/cursos/lista",
          },
          {
            label: "Instrutores",
            icon: "pi pi-fw pi-bookmark",
            to: "/cursos/instrutores",
          },
          {
            label: "Relatório",
            icon: "pi pi-fw pi-bookmark",
            to: "/cursos/relatorio",
          },
        ],
      },
      {
        label: "Publicidades",
        icon: "pi pi-images",
        to: "/publicidades",
        permissions: [userProfiles.administrador],
      },

      {
        label: "Eventos",
        icon: "pi pi-clock",
        to: "/eventos-oficiais",
        permissions: [userProfiles.administrador],
      },
      {
        label: "Mapas interativos",
        icon: "pi pi-map-marker",
        to: "/mapas-interativos",
        permissions: [userProfiles.administrador],
      },
      {
        label: "Segmentos",
        icon: "pi pi-reply",
        to: "/segmentos",
        permissions: [userProfiles.administrador],
      },
      {
        label: "Tipos de produtos",
        icon: "pi pi-tags",
        to: "/produto-tipos",
        permissions: [userProfiles.administrador],
      },
      {
        label: "Campos customizados",
        icon: "pi pi-pencil",
        to: "/campos-customizados",
        permissions: [userProfiles.administrador],
      },
      {
        label: "Certificados",
        icon: "pi pi-inbox",
        to: "/certificados",
        permissions: [userProfiles.administrador],
      },
      {
        label: "Configurações",
        icon: "pi pi-cog",
        items: [
          {
            label: "Perfil",
            icon: "pi pi-fw pi-bookmark",
            to: "/configuracoes/perfis",
          },
        ],
      },
    ];

    this.menuClick = false;
  }

  onToggleMenu(event) {
    this.menuClick = true;

    if (this.isDesktop()) {
      if (this.state.layoutMode === "overlay") {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive,
        });
      } else if (this.state.layoutMode === "static") {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive,
        });
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }

    event.preventDefault();
  }

  onSidebarClick(event) {
    this.menuClick = true;
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  }

  addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  }

  removeClass(element, className) {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  }

  handleLogout = () => {
    history.push("/sair");
  };

  async listReports() {
    // const response = await this.reportService.status();
    // this.props.alterarMinimo(response.data);
  }

  async onShowDialogReports() {
    await this.listReports();

    this.setState({
      visibleDialogReports: true,
    });
  }

  onHideDialogReports() {
    this.setState({ visibleDialogReports: false });
  }

  // async downloadReport(id) {
  //     this.reportService.download(id);
  //     const reports = this.props.reports.data.filter(item => item.id !== id);

  //     if (reports.length) {
  //         this.props.alterarMinimo(reports);
  //     } else {
  //         this.props.alterarMinimo([]);
  //         this.setState({
  //             visibleDialogReports: false
  //         })
  //     }
  // }

  render() {
    // const logo = this.state.layoutColorMode === 'dark' ? 'assets/layout/images/logo-white.svg' : 'assets/layout/images/logo.svg';

    const wrapperClass = classNames("layout-wrapper", {
      "layout-overlay": this.state.layoutMode === "overlay",
      "layout-static": this.state.layoutMode === "static",
      "layout-static-sidebar-inactive":
        this.state.staticMenuInactive && this.state.layoutMode === "static",
      "layout-overlay-sidebar-active":
        this.state.overlayMenuActive && this.state.layoutMode === "overlay",
      "layout-mobile-sidebar-active": this.state.mobileMenuActive,
    });

    const sidebarClassName = classNames("layout-sidebar", {
      "layout-sidebar-dark": this.state.layoutColorMode === "dark",
      "layout-sidebar-light": this.state.layoutColorMode === "light",
    });

    return (
      <>
        <ScrollToTop>
          <div className={wrapperClass} onClick={this.onWrapperClick}>
            <AppTopbar
              handleLogout={this.handleLogout}
              onToggleMenu={this.onToggleMenu}
              nameUser={this.state.user?.name}
              imageProfile={this.state.imageProfile}
              onShowReport={() => this.onShowDialogReports()}
              reportsCount={0}
            />

            <div
              ref={(el) => (this.sidebar = el)}
              className={sidebarClassName}
              onClick={this.onSidebarClick}
            >
              {this.state.user && (
                <AppMenu
                  model={this.menu}
                  onMenuItemClick={this.onMenuItemClick}
                />
              )}
            </div>

            <div className="layout-main">
              {this.state.user ? this.props.children : null}
            </div>

            <AppFooter />

            <div className="layout-mask"></div>
          </div>
        </ScrollToTop>
        {/* <Dialog
                    header="Relatórios"
                    visible={this.state.visibleDialogReports}
                    onHide={() => this.onHideDialogReports()}
                    style={{ minWidth: 280, maxWidth: 450 }}
                    dismissableMask={true}
                    responsive={true}
                    className="dialog"
                    modal={true}
                >
                    <div>
                        {this.props.reports.data.length && this.props.reports.data.map(item => (
                            <div key={item.id} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                                <span>{format(new Date(item.date), 'dd/MM/yyyy HH-mm')} </span>
                                -
                                <span>Relatório "Autorização Pagamento"</span>
                                -
                                {item.progress === 100 ? (
                                    <span
                                        onClick={() => this.downloadReport(item.id)}
                                        style={{
                                            color: "#00f",
                                            cursor: "pointer"
                                        }}
                                    >
                                        Baixar
                                    </span>
                                ) : (
                                        <span><strong>{item.progress}%</strong></span>
                                    )}
                            </div>
                        ))}
                    </div>
                </Dialog> */}
      </>
    );
  }
}

export default App;
