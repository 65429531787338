import Authentication from "../service/authentication.service";

export const userProfiles = {
  administrador: 1,
  expositor: 2,
  visitante: 3,
};

export function userHasAccess(profiles = []) {
  return profiles.includes(getPermissionUser());
}

export default function getPermissionUser() {
  const userProfile = Authentication._user.profile;
  if (userProfile) {
    return userProfile.id;
  }

  return 0;
}
