import * as Yup from "yup";
import * as messages from "./messages";
import { isVimeoLink } from "../utils/is-vimeo-link";

export let failedValidation = false;

export const cursoModuloAulaSchema = Yup.object().shape({
  duracao: Yup.string().required(messages.REQUIRED).nullable(),
  idiomas: Yup.array().of(
    Yup.object().shape({
      titulo: Yup.string().test("titulo", messages.REQUIRED, function (value) {
        if (this && this.parent) {
          if (this.parent.idIdioma === 1) {
            failedValidation = value;
            return value;
          } else if (!this.parent.titulo && (this.parent.descricao || this.parent.linkVideo)) {
            failedValidation = false;
            return false;
          }
        }
        return true;
      }),
      descricao: Yup.string().test(
        "descricao",
        messages.REQUIRED,
        function (value) {
          if (this && this.parent) {
            if (this.parent.idIdioma === 1) {
              failedValidation = value;
              return value;
            } else if (!this.parent.descricao && (this.parent.titulo || this.parent.linkVideo)) {
              failedValidation = false;
              return false;
            }
          }
          return true;
        }
      ),
      linkVideo: Yup.string()
        .test("linkVideo", messages.REQUIRED, function (value) {
          if (this && this.parent) {
            if (this.parent.idIdioma === 1) {
              failedValidation = value;
              return value;
            } else if (!this.parent.linkVideo && (this.parent.titulo || this.parent.descricao )) {
              failedValidation = false;
              return false;
            }
          }
          return true;
        })
        .test("is-vimeo-link", "O link deve ser do Vimeo", isVimeoLink),
    })
  ),
});
