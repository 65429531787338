import React, { useEffect, useState } from "react";
import ListTableLazy from "../../components/ListTableLazy";
import MapaInterativoService from "../../service/mapa-interativo.service";
import QueryOptions from "../../utils/query-options";
import AlertMenssage from "../../components/AlertMenssage";
import queryString from "query-string";
import AlertService from "../../service/alert.service";

export default function MapaInterativo({ location }) {
  const [mapas, setMapas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);
  const [first, setFirst] = useState(0);

  const columns = [{ field: "nome", header: "Nome" }];

  useEffect(() => {
    async function listEdition() {
      const _mapaInterativoService = new MapaInterativoService();
      const queryOptions = new QueryOptions([["fields", "id_mapa, nome"]]);

      const queryStringObj = queryString.parse(location.search);

      if (queryStringObj) {
        if (queryStringObj.acao === "criado") {
          AlertService.success("Mapa interativo adicionado com sucesso");
        } else if (queryStringObj.acao === "deletado") {
          AlertService.success("Mapa interativo deletado com sucesso");
        }
      }

      setLoading(true);
      const data = await _mapaInterativoService.list(queryOptions, true);
      setLoading(false);
      setMeta(data.meta);
      setMapas(data.items);
    }
    listEdition();
  }, [page, location]);



  function handleChangePage(event) {
    setFirst(event.first);
    setPage(event.page + 1);
  }

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <h1>Mapas Interativos</h1>
            <hr className="hr-header"/>
            <AlertMenssage />
            <ListTableLazy
              cols={columns}
              values={mapas}
              firstIdx={first}
              meta={meta}
              loading={loading}
              endpoint="mapas-interativos"
              onChangePage={handleChangePage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
