import { Message } from "primereact/message";

export default class AlertService {
  static success(menssage) {
    Message.messages.show({
      severity: "success",
      summary: "Sucesso",
      detail: menssage,
      life: 10000,
    });
  }

  static warn(menssage) {
    Message.messages.show({
      severity: "warn",
      summary: "Atenção",
      detail: menssage,
      life: 10000,
    });
  }

  static error(menssage) {
    Message.messages.show({
      severity: "error",
      summary: "Erro",
      detail: menssage,
      life: 10000,
    });
  }
}
