/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import QueryOptions from "../../utils/query-options";
import ListTableLazy from "../../components/ListTableLazy";
import { useParams } from "react-router";
import AvertisingdService from "../../service/avertisingd.service";
import queryString from "query-string";
import AlertService from "../../service/alert.service";
import AlertMenssage from "../../components/AlertMenssage";
import EditionService from "../../service/edition.service";

export default function Products({ location }) {
  const { id } = useParams();
  const [avertisingdValues, setAvertisingdValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);
  const [first, setFirst] = useState(0);
  const [busca, setBusca] = useState("");
  const [buscaUltima, setBuscaUltima] = useState("");
  const [limparFiltros, setLimparFiltros] = useState(false);

  const columns = [
    {
      field: `idiomas.${[0]}.titulo`,
      header: "Título",
    },
    {
      field: `idiomas.${[0]}.link`,
      header: "Link",
    },
    {
      field: `dataInicioStr`,
      header: "Data de início",
    },
    {
      field: `dataFimStr`,
      header: "Data de fim",
    },
    {
      field: `statusStr`,
      header: "Status",
    },
    {
      field: `quantidadeCliques`,
      header: "Quantidade de cliques",
    },
  ];

  useEffect(() => {
    async function listProducts() {
      const _avertisingdService = new AvertisingdService();
      const _edicaoService = new EditionService();

      const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);

      const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
        queryOptionsEdicaoAtiva
      );

      setLoading(true);

      const queryOptionsPublicidadesEdicao = new QueryOptions([
        ["edicao", edicaoAtual.id],
        ["page", page],
      ]);

      if (busca.length) {
        let pageAtual = 1;

        if (buscaUltima === busca) {
          pageAtual = page;
        } else {
          setBuscaUltima(busca);
        }

        queryOptionsPublicidadesEdicao.queryKvs.push(["query", busca], ["page", pageAtual]);
        setLimparFiltros(true);
      } else {
        setLimparFiltros(false);
        setBuscaUltima("");
      }

      const data = await _avertisingdService.list(
        queryOptionsPublicidadesEdicao,
        true
      );

      setLoading(false);
      setAvertisingdValues(data.items);
      setMeta(data.meta);
    }
    listProducts();
  }, [page, id, busca]);

  useEffect(() => {
    const queryStringObj = queryString.parse(location.search);

    if (queryStringObj) {
      if (queryStringObj.acao === "criado") {
        AlertService.success("Publicidade adicionada com sucesso");
      } else if (queryStringObj.acao === "deletado") {
        AlertService.success("Publicidade deletada com sucesso");
      }
    }
  }, [location]);

  function handleChangePage(event) {
    setFirst(event.first);
    setPage(event.page + 1);
  }

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <div className="p-grid">
            <div className="p-col-9 p-lg-9 p-md-9 p-sm-9">
              <h1 className="h1-container">Publicidade</h1>
            </div>

            <div
              className={
                busca.length
                  ? "p-col-12 p-lg-2 p-md-2 p-sm-2"
                  : "p-col-12 p-lg-3 p-md-3 p-sm-3"
              }
            >
              <div className="text-input-filtragem">
                <input
                  type="text"
                  value={busca}
                  onChange={(ev) => setBusca(ev.target.value)}
                  placeholder="Busca por nome ou descrição"
                />
              </div>
            </div>

            {limparFiltros && (
              <div className="p-col-12 p-lg-1 p-md-1 p-sm-1">
                <button
                  onClick={() => {
                    setBusca("");
                    setLimparFiltros(false);
                  }}
                  className="limpar-filtros-button"
                >
                  <span>Limpar filtros</span>
                </button>
              </div>
            )}
          </div>

          <hr className="hr-header" />
          <AlertMenssage />
          <ListTableLazy
            cols={columns}
            values={avertisingdValues}
            firstIdx={first}
            meta={meta}
            loading={loading}
            endpoint="publicidades"
            onChangePage={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
}
