export default class CursoInstrutorIdiomaSerializer {
  fromJson(json) {
    const instrutorIdiomas = {
      biografia: json.biografia,
      idIdioma: json.idioma.id_idioma,
    };

    return instrutorIdiomas;
  }

  toJson(instrutorIdiomas) {
    return {
      id_idioma: instrutorIdiomas.idIdioma,
      biografia: instrutorIdiomas.biografia,
    };
  }
}
