export default class CampoCustomizadoSerializer {
  fromJson(json) {
    const camposCustomizadosFromJson = {};

    Object.assign(
      camposCustomizadosFromJson,
      json.id_campo_customizado && {idCampoCustomizado: json.id_campo_customizado},
      json.campo && { campo: json.campo },
    );

    return camposCustomizadosFromJson;
  }
}
