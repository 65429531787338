export default class VideoModerationLanguages {
  fromJson(json) {
    const videoModerationLanguages = {};

    Object.assign(
      videoModerationLanguages,
      json.titulo && { titulo: json.titulo },
      json.descricao && { descricao: json.descricao },
      json.link && { link: json.link },
      json.id_idioma && { idIdioma: json.id_idioma }
    );

    return videoModerationLanguages;
  }

  toJson(videoModerationLanguages) {
    return {
      titulo: videoModerationLanguages.titulo,
      descricao: videoModerationLanguages.descricao,
      link: videoModerationLanguages.link,
      id_idioma: videoModerationLanguages.idIdioma,
    };
  }
}
