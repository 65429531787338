import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import AlertMenssage from "../../../components/AlertMenssage";
import ButtonSave from "../../../components/ButtonSave";
import ConfirmationDialog from "../../../components/dialogs/confirmation-dialog";
import TextInput from "../../../components/TextInput";
import AlertService from "../../../service/alert.service";
import LanguagesService from "../../../service/language.service";
import { languageFields } from "../../Products/ProductsForm/idioma";
import ProdutoTiposService from "../../../service/product-type.service";
import { ProdutoTiposSchema } from "../../../validations/produto-tipos.validation";

export default function ProdutoTiposForm() {
  const { id } = useParams();
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(1);
  const [action, setAction] = useState("edit");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const _produtoTiposService = new ProdutoTiposService();

  const [produtoTiposValues, setProdutoTiposValues] = useState({ tipo: "" });

  async function getProdutoTipo(languages) {
    setAction("edit");
    const produtoTipo = await _produtoTiposService.read(id);
    let idiomas = [];
    languages.forEach((element) => {
      idiomas.push({
        ...languageFields,
        idIdioma: element.id,
      });
    });

    const idIdiomas = [];
    produtoTipo.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

    const idiomasFaltantes = [];
    idiomas.forEach((item) => {
      if (!idIdiomas.includes(item.idIdioma)) {
        idiomasFaltantes.push(item);
      }
    });

    const allLanguages = [...produtoTipo.idiomas, ...idiomasFaltantes].sort(
      (v1, v2) => v1.idIdioma - v2.idIdioma
    );

    setProdutoTiposValues({ ...produtoTipo, idiomas: allLanguages });
  }

  useEffect(() => {
    async function init() {
      const _languagesService = new LanguagesService();
      const languages = await _languagesService.list();

      let idiomas = [];
      languages.forEach((element) => {
        idiomas.push({
          ...produtoTiposValues,
          idIdioma: element.id,
        });
      });
      setProdutoTiposValues({
        ...produtoTiposValues,
        idiomas,
      });
      setLanguages(languages);

      if (id) {
        getProdutoTipo(languages);
      } else {
        setAction("create");
      }
    }

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function criarProdutoTipo(values) {
    setLoading(true);

    if (action === "create") {
      await _produtoTiposService
        .create(values)
        .then(() => {
          history.push(`/produto-tipos?acao=criado`);
        })
        .catch(({ response }) => {
          AlertService.warn(response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await _produtoTiposService
        .update(values)
        .then(() => {
          AlertService.success("Tipo de produto atualizado com sucesso");
        })
        .catch(({ response }) => AlertService.warn(response.data.msg))
        .finally(() => {
          setLoading(false);
        });
    }
  }

  async function handleDelete() {
    await _produtoTiposService.delete(id);
    history.push(`/produto-tipos?acao=deletado`);
    setDisplayConfirmationDialog(false);
  }

  function selectLanguage(item) {
    setCurrentLanguage(item.id);
  }

  return (
    <>
      <div className="p-grid">
        <ConfirmationDialog
          header="Excluir esse item vai apagar todas as informações do tipo desse produto no portal administrador e na plataforma"
          action="Excluir mesmo assim?"
          display={displayConfirmationDialog}
          onClose={() => setDisplayConfirmationDialog(false)}
          onConfirm={handleDelete}
        />
        <div className="p-col-12">
          <div className="card">
            <h1 className="title-page">Tipo de produto</h1>
            <hr classname="hr-header" />
            <Formik
              enableReinitialize={true}
              validationSchema={ProdutoTiposSchema}
              initialValues={produtoTiposValues}
              onSubmit={(values) => {
                criarProdutoTipo(values);
              }}
            >
              {(props) => {
                return (
                  <Form autoComplete="off">
                    <div className="wrapper-form" style={{ maxWidth: "820px" }}>
                      <div className="p-grid">
                        {languages.map((item, index) => (
                          <div key={index} className="language-dashboard">
                            <button
                              className={
                                item.id === currentLanguage
                                  ? "p-button-dash"
                                  : "p-button-teste"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                selectLanguage(item);
                              }}
                            >
                              {item.nome}
                            </button>
                          </div>
                        ))}
                      </div>

                      <FieldArray
                        name="idiomas"
                        render={() => (
                          <div className="p-grid">
                            {props?.values?.idiomas?.map((item, index) => (
                              <div
                                className="p-col-12"
                                key={index}
                                hidden={!(item.idIdioma === currentLanguage)}
                              >
                                <TextInput
                                  name={`idiomas[${index}].tipo`}
                                  className="input"
                                  label="Tipo de produto"
                                  required={true}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      />
                    </div>

                    <div className="btns-inline">
                      <div className="btn-medium">
                        <ButtonSave
                          label={action === "create" ? "Salvar" : "Atualizar"}
                          loading={loading}
                          type="submit"
                          onClick={() => {
                            if (Object.keys(props.errors).length) {
                              AlertService.warn(
                                "Verifique se todos os campos obrigatórios foram preenchidos corretamente"
                              );
                            }
                          }}
                        />
                      </div>

                      {action === "edit" && (
                        <div className="btn-medium">
                          <div
                            className="btns-inline"
                            style={{ justifyContent: "space-evenly" }}
                          >
                            <button
                              className="button-delete"
                              styles="p-button-secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                setDisplayConfirmationDialog(true);
                              }}
                            >
                              Excluir
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="btn-medium">
                        <button
                          className="button-cancel"
                          onClick={() => {
                            history.push(`/produto-tipos`);
                          }}
                        >
                          Voltar
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <AlertMenssage />
          </div>
        </div>
      </div>
    </>
  );
}
