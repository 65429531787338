import * as Yup from "yup";
import * as messages from "./messages";

export let failedValidation = false;

export const avertisingdSchema = Yup.object().shape({
  dataInicio: Yup.string().required(messages.REQUIRED).nullable(),
  dataFim: Yup.string().required(messages.REQUIRED).nullable(),
  idsAreas: Yup.string().required(messages.REQUIRED).nullable(),
  idiomas: Yup.array().of(
    Yup.object().shape({
      titulo: Yup.string().test("titulo", messages.REQUIRED, function (value) {
        if (this && this.parent) {
          if (this.parent.idIdioma === 1) {
            failedValidation = value;
            return value;
          } else if (!this.parent.titulo && this.parent.link) {
            failedValidation = false;
            return false;
          }
        }
        return true;
      }),
    })
  ),
});
