export default class CursoModuloAulaIdiomaSerializer {
  fromJson(json) {
    const cursoModuloAulaIdioma = {};

    Object.assign(
      cursoModuloAulaIdioma,
      json.id_curso_aula_idioma && {
        idCursoAulaIdioma: json.id_curso_aula_idioma,
      },
      json.descricao && { descricao: json.descricao },
      json.titulo && { titulo: json.titulo },
      json.instrutor && { instrutor: json.instrutor },
      json.link_video && { linkVideo: json.link_video },
      json.idioma && { idIdioma: json.idioma.id_idioma }
    );

    return cursoModuloAulaIdioma;
  }

  toJson(cursoModuloAulaIdioma) {
    return {
      id_idioma: cursoModuloAulaIdioma.idIdioma,
      id_curso_aula_idioma: cursoModuloAulaIdioma.idCursoAulaIdioma,
      titulo: cursoModuloAulaIdioma.titulo,
      descricao: cursoModuloAulaIdioma.descricao,
      instrutor: cursoModuloAulaIdioma.instrutor,
      link_video: cursoModuloAulaIdioma.linkVideo,
    };
  }
}
