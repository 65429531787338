import React from "react";
import { Router } from "react-router-dom";
import Routes from "./routes";
import history from "./service/history";
import Store from "./store";
import "./App.scss";

export default function App() {
  return (
    <Store>
      <Router history={history}>
        <Routes />
      </Router>
    </Store>
  );
}
