import api from "./api.service";
import CoreApiService from "./core-api.service";
import UserProfileSerializer from "./serializers/user-profile.serializer";

export default class UserProfileService extends CoreApiService {
  constructor() {
    super(undefined, "usuarios/perfis", new UserProfileSerializer());
  }

  linkImage(idCursoIdioma) {
    return `${process.env.REACT_APP_API_URL}/${this.endpoint}/${idCursoIdioma}/display`;
  }

  async uploadImagens(file, id) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await api.put(`${this.endpoint}/${id}/uploads`, formData);

    const data = response.data;
    return data;
  }
}
