import { format } from "date-fns";

export default class LogSerializer {
  fromJson(json) {
    const log = {};

    Object.assign(
      log,
      json.id_log && { id: json.id_log },
      json.date && {
        data: new Date(json.date),
        dataStr: format(new Date(json.date), "dd/MM/yyy HH:mm:ss"),
      },
      json.data && { conteudo: json.data },
      json.user_agent && { user_agent: json.user_agent },
      json.ip && { ip: json.ip },
      json.endpoint && { endpoint: json.endpoint },
      json.method && { metodo: json.method }
    );

    return log;
  }
}
