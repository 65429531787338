import SegmentosIdiomasSerializer from "./segmento-idiomas.serializer";
export default class SegmentosTypeSerializer {
  constructor() {
    this.segmentosIdiomasSerializer = new SegmentosIdiomasSerializer();
  }

  fromJson(json) {
    const segmentosType = {};
    Object.assign(
      segmentosType,
      json.id_segmento && { value: json.id_segmento },
      json.id_segmento && { id: json.id_segmento },
      json.segmento_idiomas && { label: json.segmento_idiomas[0].nome },
      json.segmento_idiomas && {
        idiomas: json.segmento_idiomas.map((item) =>
          this.segmentosIdiomasSerializer.fromJson(item)
        ),
      }
    );

    return segmentosType;
  }

  toJson(segmentos) {
    const segmentosToJson = {};

    Object.assign(
      segmentosToJson,
      segmentos.idiomas && {
        segmento_idiomas: segmentos.idiomas.map((item) =>
          this.segmentosIdiomasSerializer.toJson(item)
        ),
      }
    );

    return segmentosToJson;
  }
}
