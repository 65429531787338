import React from "react";
import { useField } from "formik";
import NumberFormat from "react-number-format";

export default function InputPercent({
  label,
  classes = "",
  required,
  ...props
}) {
  const [field, meta] = useField(props);

  return (
    <div className={classes}>
      <label htmlFor={props.id || props.name}>
        {label}
        {required && <span className="required-hash"> *</span>}
      </label>
      <NumberFormat
        className="p-inputtext"
        decimalScale={2}
        decimalSeparator={","}
        allowNegative={false}
        placeholder="0 %"
        suffix={" %"}
        {...field}
        {...props}
      />
      <div className="error-input">
        {meta.touched && meta.error ? (
          <div className="error ">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
}
