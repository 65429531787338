/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ButtonSave from "../../components/ButtonSave";
import TextInput from "../../components/TextInput";
import TextareaInput from "../../components/TextareaInput";
import CursoInstrutorService from "../../service/curso-instrutor.service";
import LanguagesService from "../../service/language.service";
import AlertService from "../../service/alert.service";
import AlertMenssage from "../../components/AlertMenssage";
import ConfirmationDialog from "../../components/dialogs/confirmation-dialog";
import { cursoInstrutorSchema } from "../../validations/curso-instrutor.validation";
import ImageeInputPreview from "../../components/ImageInputPreview";
import imageProfileDefault from "../../utils/image-profile-default";

export default function CursoInstrutorForm() {
  const _instrutorService = new CursoInstrutorService();

  const { id: idInstrutor } = useParams();
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageProfile, setImageProfile] = useState(imageProfileDefault());
  const [isChangePhoto, setIsChangePhoto] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);

  // Idiomas
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(1);

  // Formulário
  const formIdiomaField = {
    biografia: "",
    idIdioma: "",
  };

  const [valuesForm, setValuesForm] = useState({
    nome: "",
    idiomas: [formIdiomaField],
  });

  const mountLanguage = async () => {
    const _languagesService = new LanguagesService();
    const languages = await _languagesService.list();

    let idiomas = [];
    languages.forEach((element) => {
      idiomas.push({
        ...formIdiomaField,
        idIdioma: element.id,
      });
    });

    setValuesForm({
      ...valuesForm,
      idiomas,
    });

    setLanguages(languages);
    return languages;
  };

  const getInstrutor = async (languages) => {
    const instrutor = await _instrutorService.read(idInstrutor);

    if (instrutor.imageFilename) {
      setImageProfile(_instrutorService.linkImage(idInstrutor));
    }

    let idiomas = [];
    languages.forEach((element) => {
      idiomas.push({
        ...formIdiomaField,
        idIdioma: element.id,
      });
    });

    const idIdiomas = [];
    instrutor.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

    const idiomasFaltantes = [];
    idiomas.forEach((item) => {
      if (!idIdiomas.includes(item.idIdioma)) {
        idiomasFaltantes.push(item);
      }
    });

    const allLanguages = [...instrutor.idiomas, ...idiomasFaltantes].sort(
      (v1, v2) => v1.idIdioma - v2.idIdioma
    );

    setValuesForm({ ...instrutor, idiomas: allLanguages });
  };

  useEffect(() => {
    setIsEdit(!!idInstrutor);

    const init = async () => {
      const languages = await mountLanguage();

      if (idInstrutor) {
        await getInstrutor(languages);
      }
    };

    init();
  }, []);

  async function handleDelete() {
    await _instrutorService.delete(idInstrutor);
    history.push(`/cursos/instrutores?acao=deletado`);
  }

  const updatePhoto = async (id = idInstrutor) => {
    if (isChangePhoto) {
      await _instrutorService.uploadImage(imageFile, id);
    }
  };

  const changePhoto = (value) => {
    setImageFile(value);
    setIsChangePhoto(true);
  };

  const onSubmit = async (values) => {
    setLoading(true);

    if (isEdit) {
      // Atualizar
      try {
        await _instrutorService.update(values);
        await updatePhoto();
        AlertService.success("Inatrutor atualizado");
      } catch ({ response }) {
        AlertService.warn(response.data.msg);
      } finally {
        setLoading(false);
      }
    } else {
      // Criar
      try {
        const instrutor = await _instrutorService.create(values);
        await updatePhoto(instrutor.id);
        history.push(`/cursos/instrutores?acao=criado`);
      } catch ({ response }) {
        AlertService.warn(response.data.msg);
      }
    }
  };

  return (
    <>
      <ConfirmationDialog
        header="Atenção!"
        action="Apagar este instrutor?"
        display={displayConfirmationDialog}
        onClose={() => setDisplayConfirmationDialog(false)}
        onConfirm={handleDelete}
      />
      <div className="card">
        <h1 className="title-page">
          {isEdit ? "Editar" : "Adiconar"} Instrutor
        </h1>

        <hr className="hr-header" />

        <Formik
          enableReinitialize={true}
          validationSchema={cursoInstrutorSchema}
          initialValues={valuesForm}
          onSubmit={onSubmit}
        >
          {(props) => {
            return (
              <Form autoComplete="off">
                <div
                  className="wrapper-form"
                  style={{ paddingLeft: "0.5em", maxWidth: "820px" }}
                >
                  <div className="box">
                    <div className="flex-box-profile">
                      <div className="box-profile">
                        <ImageeInputPreview
                          image={imageProfile}
                          changeImage={changePhoto}
                        />
                      </div>

                      {/* {displayMudarFoto && (
                      <div className="remover-imagem-container">
                        <h4
                          onClick={() => {
                            // setDisplayConfirmationRemoverFoto(true);
                          }}
                        >
                          Remover foto atual
                        </h4>
                      </div>
                    )} */}
                    </div>
                  </div>

                  <div className="p-grid">
                    <TextInput
                      classes="p-col-12"
                      name="nome"
                      className="input"
                      label="Nome"
                      required
                    />
                  </div>

                  <div className="p-grid">
                    {languages.map((item, index) => (
                      <div key={index} className="language-dashboard">
                        <button
                          className={
                            item.id === currentLanguage
                              ? "p-button-dash"
                              : "p-button-teste"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentLanguage(item.id);
                          }}
                        >
                          {item.nome}
                        </button>
                      </div>
                    ))}
                  </div>

                  <div>
                    <FieldArray
                      name="idiomas"
                      render={(arrayHelpers) => (
                        <div className="p-grid">
                          {props?.values?.idiomas?.map((item, index) => (
                            <div
                              className="p-col-12"
                              key={index}
                              hidden={!(item.idIdioma === currentLanguage)}
                            >
                              <label>
                                Biografia{" "}
                                <span className="required-hash">*</span>
                              </label>

                              <TextareaInput
                                name={`idiomas[${index}].biografia`}
                                classes="descricao-publicidade"
                                className="input"
                                rows={5}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </div>

                  <div className="btns-inline">
                    <div className="btn-medium">
                      <ButtonSave
                        label={isEdit ? "Atualizar" : "Adicionar"}
                        type="submit"
                        loading={loading}
                      />
                    </div>
                    <div className="btn-medium">
                      <div
                        className="btns-inline"
                        style={{ justifyContent: "space-evenly" }}
                      >
                        <button
                          className="button-delete"
                          styles="p-button-secondary"
                          onClick={(e) => {
                            e.preventDefault();
                            setDisplayConfirmationDialog(true);
                          }}
                        >
                          Excluir
                        </button>
                      </div>
                    </div>
                    <div className="btn-medium">
                      <button
                        className="button-voltar"
                        onClick={() => {
                          history.push(`/cursos/instrutores`);
                        }}
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>

        <AlertMenssage />
      </div>
    </>
  );
}
