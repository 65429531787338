export default class MapaInterativoSerializer {
  fromJson(json) {
    const map = {};

    Object.assign(
      map,
      json.id_mapa && { id: json.id_mapa },
      json.filename && { filename: json.filename },
      json.id_edicao && { idEdicao: json.id_edicao },
      json.html && { html: json.html },
      json.json && { json: json.json },
      json.nome && { nome: json.nome },
      json.slug && { slug: json.slug }
    );

    return map;
  }

  toJson(mapa) {
    const mapaJson = {};

    Object.assign(
      mapaJson,
      mapa.filename && { filename: mapa.filename },
      mapa.json && { json: mapa.json },
      mapa.html && { html: mapa.html },
      mapa.idEdicao && { id_edicao: mapa.idEdicao },
      mapa.nome && { nome: mapa.nome }
    );

    return mapaJson;
  }

  toFormData(file) {
    let formData = new FormData();
    formData.append("file", file[0]);

    return formData;
  }
}
