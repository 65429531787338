export default class UserHasExpositorSerializer {
  fromJson(json) {
    const user_has_expositor = {};
    Object.assign(
        user_has_expositor,

        json.id_expositor && {idExpositor: json.id_expositor},
        json.id_usuario && {idUsuario: json.id_usuario},

    );

    return user_has_expositor;
  }

  toJson(event) {
    return {

    };
  }

}
