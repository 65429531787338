import api from "./api.service";
import CoreApiService from "./core-api.service";
import MapaInterativoSerializer from "./serializers/mapa-interativo.serializer";

export default class MapaInterativoService extends CoreApiService {
  constructor() {
    super(undefined, "mapas", new MapaInterativoSerializer());
  }

  async upload(id, data) {
    const formData = this.serializer.toFormData(data);

    const response = await api.put(`${this.endpoint}/${id}/upload`, formData);

    if (response.data["oret"]) {
      return this.serializer.fromJson(response.data["oret"]);
    }

    return null;
  }
}
