import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import getPermissionUser, {
  userProfiles,
  userHasAccess,
} from "../utils/permission-user";
import ConfirmationDialog from "./dialogs/confirmation-dialog";
import ExpositoresService from "../service/expositores.service";
import EventService from "../service/event.service";
import ButtonDelete from "./ButtonDelete";

ListTableLazy.propTypes = {
  cols: PropTypes.array,
  firstIdx: PropTypes.any,
  meta: PropTypes.any,
  endpoint: PropTypes.any,
  values: PropTypes.any,
  loading: PropTypes.any,
  onChangePage: PropTypes.any,
  btnAddShow: PropTypes.any,
  styles: PropTypes.string,
  statusDetails: PropTypes.bool,
  onSort: PropTypes.any,
  isClickRow: PropTypes.bool,
};

export default function ListTableLazy({
  cols,
  firstIdx,
  meta,
  endpoint,
  values,
  setValues,
  loading,
  onChangePage,
  btnAddShow,
  styles,
  statusDetails = false,
  onSort,
  parentEndPoint,
  parentId,
  deleteAllEndPoint,
  btnAdicionar = true,
  isClickRow = true,
}) {
  const history = useHistory();
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const bodyStatus = (rowData) => {
    let response = "";

    if (!statusDetails) {
      const style =
        rowData.statusStr === "Ativo" ? "color-success" : "color-danger";

      response = <span className={style}>{rowData.statusStr}</span>;

      if (rowData.review) {
        response = (
          <span className="f12">
            <span className={style}>{rowData.statusStr}</span>
            <span className="color-danger">{rowData.review}</span>
          </span>
        );
      }

      return response;
    } else {
      response = (
        <span className="f12">
          {rowData.reviewText && (
            <span className="color-warn">{rowData.reviewText} </span>
          )}
          {rowData.approveText && (
            <span className="color-success">{rowData.approveText} </span>
          )}
          {rowData.rejectText && (
            <span className="color-reject">{rowData.rejectText} </span>
          )}
          {rowData.reviewFinancial &&
            [userProfiles.admin, userProfiles.financial].includes(
              getPermissionUser()
            ) && (
              <span className="color-danger">{rowData.reviewFinancial} </span>
            )}
        </span>
      );
    }

    return response;
  };

  const handleEdit = (event) => {
    if (isClickRow) {
      if (parentEndPoint) {
        history.push(
          `/${parentEndPoint}/${parentId}/${endpoint}/editar/${event.value.id}`
        );
      } else if (endpoint) {
        history.push(`/${endpoint}/editar/${event.value.id}`);
      }
    }
  };

  let dynamicColumns = cols.map((col) => {
    if (col.field !== "statusStr") {
      return <Column key={col.field} field={col.field} header={col.header} />;
    } else {
      return (
        <Column
          body={bodyStatus}
          key={col.field}
          field={col.field}
          header={col.header}
        />
      );
    }
  });

  const first = firstIdx + 1;
  let last = firstIdx + meta.perPage;
  if (last > meta.totalItems) {
    last = meta.totalItems;
  }

  function btnCreate() {
    if (parentEndPoint) {
      history.push(`/${parentEndPoint}/${parentId}/${endpoint}/adicionar`);
    } else if (endpoint) {
      history.push(`/${endpoint}/adicionar`);
    }
  }

  function renderizarBotao() {
    if (
      userHasAccess([userProfiles.expositor]) &&
      window.location.pathname !== "/usuarios" &&
      window.location.pathname !== "/expositores"
    ) {
      return true;
    } else if (userHasAccess([userProfiles.administrador])) {
      return true;
    }
  }

  function renderizarBotaoDeletarTudo() {
    const localizacaoAtual = window.location.pathname;
    const localizacaoExpositor = `/expositores/editar/${parentId}`;

    if (
      userHasAccess([userProfiles.administrador]) &&
      (localizacaoAtual === `${localizacaoExpositor}/eventos-expositor` ||
        localizacaoAtual === `/expositores/${parentId}/videos` ||
        localizacaoAtual === `${localizacaoExpositor}/catalogos` ||
        localizacaoAtual === "/eventos-oficiais" ||
        localizacaoAtual === `${localizacaoExpositor}/banner-expositor`) &&
      values.length
    ) {
      return true;
    }
  }

  async function deletarTodosItens() {
    setButtonLoading(true);
    const localizacaoAtual = window.location.pathname;

    const _expositorService = new ExpositoresService();
    const _eventService = new EventService();

    if (localizacaoAtual !== "/eventos-oficiais") {
      await _expositorService
        .deletarItems(parentId, deleteAllEndPoint)
        .then(() => {
          setDisplayConfirmationDialog(false);
          setValues([]);
        });
    } else {
      await _eventService.deletarTodosEventos().then(() => {
        setDisplayConfirmationDialog(false);
        setValues([]);
      });
    }

    setButtonLoading(false);
  }

  return (
    <>
      <div className="action-list">
        {renderizarBotao() && btnAdicionar && (
          <Button
            label="Adicionar"
            className="button-save"
            onClick={btnCreate}
          />
        )}

        {renderizarBotaoDeletarTudo() && (
          <ButtonDelete
            className="button-delete"
            onClick={() => {
              setDisplayConfirmationDialog(true);
            }}
            label="Remover todos"
          />
        )}
      </div>

      <ConfirmationDialog
        header="Você tem certeza de que deseja remover todos os itens?"
        display={displayConfirmationDialog}
        onClose={() => setDisplayConfirmationDialog(false)}
        onConfirm={deletarTodosItens}
        loading={buttonLoading}
      />

      <div className="datatable-paginator-demo">
        <div className="content-section implementation">
          <DataTable
            className={styles || ""}
            value={values}
            selectionMode="single"
            paginator={meta.totalPages > 1}
            lazy={true}
            rows={meta.perPage}
            first={first}
            totalRecords={meta.totalItems}
            onPage={(e) => onChangePage(e)}
            resizableColumns={true}
            onSelectionChange={handleEdit}
            emptyMessage="Sem registros"
            loading={loading}
            currentPageReportTemplate={`${first} a ${last} de ${meta.totalItems}`}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
            autoLayout
          >
            {dynamicColumns}
          </DataTable>
        </div>
      </div>
    </>
  );
}
