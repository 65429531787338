export const estatisticas = [
  {
    idiomas: [{ titulo: "" }],
  },
  {
    idiomas: [{ titulo: "" }],
  },
  {
    idiomas: [{ titulo: "" }],
  },
  {
    idiomas: [{ titulo: "" }],
  },
  {
    idiomas: [{ titulo: "" }],
  },
];
