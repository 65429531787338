import EventoBannerIdiomaSerializer from "./evento-banner-idioma.serializer";

export default class EventoBannerSerializer {
  constructor() {
    this.eventoBannerIdiomaSerializer = new EventoBannerIdiomaSerializer();
  }

  fromJson(json) {
    const eventoBannerSerializer = {};
    Object.assign(
      eventoBannerSerializer,
      json.titulo && {
        titulo: json.titulo,
      },
      json.filename && {
        filename: json.filename,
      },
      json.id_evento_banner && {
        idEventoBanner: json.id_evento_banner,
      },
      json.idiomas && {
        idiomas: json.idiomas.map((item) =>
          this.eventoBannerIdiomaSerializer.fromJson(item)
        ),
      },
      {
        quantidadeCliques: json.quantidade_cliques,
      }
    );

    return eventoBannerSerializer;
  }

  toJson(eventoBannerSerializer) {
    const eventoBannerSerializerToJson = {};

    Object.assign(
      eventoBannerSerializerToJson,
      {
        titulo: eventoBannerSerializer.titulo,
      },
      eventoBannerSerializer.idiomas && {
        idiomas: eventoBannerSerializer.idiomas.map((item) =>
          this.eventoBannerIdiomaSerializer.toJson(item)
        ),
      }
    );

    return eventoBannerSerializerToJson;
  }

  toFormData(file) {
    let formData = new FormData();
    formData.append("file", file);

    return formData;
  }
}
