import * as Yup from "yup";
import * as messages from "./messages";

export let failedValidationEventExpositor = false;

export const eventoSchemaExpositor = Yup.object().shape({
  dataInicio: Yup.string().required(messages.REQUIRED).nullable(),
  dataFim: Yup.string().required(messages.REQUIRED).nullable(),
  idEdicao: Yup.string().required(messages.REQUIRED),
  urlVideo: Yup.string().required(messages.REQUIRED),

  idiomas: Yup.array().of(
    Yup.object().shape({
      titulo: Yup.string()
        .min(5, "O número mínimo permitido para o título é de 5")
        .max(50, "O número máximo permitido para o título é de 50")
        .test("titulo", messages.REQUIRED, function (value) {
          if (this && this.parent) {
            if (this.parent.idIdioma === 1) {
              failedValidationEventExpositor = value;
              return value;
            } else if (!this.parent.titulo && this.parent.descricao) {
              failedValidationEventExpositor = false;
              return false;
            }
          }
          return true;
        }),
      descricao: Yup.string()
        .max(160, "A descrição não pode passar de 160 caracteres")
        .test("descricao", messages.REQUIRED, function (value) {
          if (this && this.parent) {
            if (this.parent.idIdioma === 1) {
              return value;
            } else if (this.parent.titulo && !this.parent.descricao) {
              failedValidationEventExpositor = false;
              return false;
            }
          }
          return true;
        }),
    })
  ),
});
