import api from "./api.service";
import CoreApiService from "./core-api.service";
import CertificadoSerializer from "./serializers/certificado.serializer";

export default class CertificadosService extends CoreApiService {
  constructor() {
    super(undefined, "certificado-feira", new CertificadoSerializer());
  }

  async getUsers(queryOptions) {
    this.endpoint = `${this.endpoint}/usuarios`;
    return await this.list(queryOptions, true);
  }

  async certificadoDownload(idUser, name) {
    try {
      const response = await api.get(
        `${this.endpoint}/usuarios/${idUser}/download`,
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Certificado-${name.replaceAll(" ", "-")}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      throw new Error(error);
    }
  }

  async certificadoEnviarEmail(idUser) {
    const response = await api.get(
      `${this.endpoint}/usuarios/${idUser}/download?enviar-email=true`
    );

    return response.data;
  }

  async generateSendCertificado(users) {
    const response = await api.post(`${this.endpoint}/emitir-enviar`, users);
    return response.data;
  }
}
