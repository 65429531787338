/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import Authentication from "../../service/authentication.service";
import TableauService from "../../service/tableau.service";
import { userHasAccess, userProfiles } from "../../utils/permission-user";

import "./style.scss";

export default function DashboardExpo() {
  const [mensagem, setMensagem] = useState("");
  const [expositores, setExpositores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expositorSelecionado, setExpositorSelecionado] = useState();

  function initViz(
    idExpositor = null,
    ticketData = null,
    expositoresData = null
  ) {
    if (!expositoresData) {
      expositoresData = expositores;
    }

    setTimeout(() => {
      setLoading(false);

      // const expositorData = expositoresData.find(
      //   (item) => item.value === idExpositor
      // );

      // const permissoes = Authentication._user.expositoresPermissoes.find(
      //   (item) => item.id === idExpositor
      // );

      var containerDiv = document.getElementById("vizContainer");

      let url = "";
      if (userHasAccess([userProfiles.administrador])) {
        //url = `https://ts.exporevestir.digital/trusted/${ticketData}/views/ExpoRevestirv2_0/01-PainelPrincipal-Admin/cb69ea6f-4d57-47bf-b3bd-ea3611a815b5/dd56f5e1-4afa-4d74-8df2-36365aa2279d?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link`;
        url = `https://ts.exporevestir.digital/trusted/${ticketData}/views/ExpoRevestirv2_3/01-Anfacer-Cadastrados?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link`
      }

      // else if (permissoes.coletorDeDadosDigital) {
      //   url = `https://ts.exporevestir.digital/trusted/${ticketData}/views/ExpoRevestir-PainelExpositoresv1_0/02-PainelExpositorExpositorcompleto?&Nome%20Fantasia=${encodeURIComponent(
      //     expositorData.label
      //   )}&:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:dataDetails=no&:showDataDetails=false&:alerts=no&:embed=yes&:showAskData=false&:showShareOptions=false&:tooltip=no`;
      // } else {
      //   url = `https://ts.exporevestir.digital/trusted/${ticketData}/views/ExpoRevestir-PainelExpositoresv1_0/03-PainelExpositorExpositorsimples?&Nome%20Fantasia=${encodeURIComponent(
      //     expositorData.label
      //   )}&:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:dataDetails=no&:showDataDetails=false&:alerts=no&:embed=yes&:showAskData=false&:showShareOptions=false&:tooltip=no`;
      // }

      var options = {
        hideTabs: true,
      };

      if (containerDiv) {
        // Necessário para carregar o viz
        // eslint-disable-next-line no-unused-vars
        var viz = new tableau.Viz(containerDiv, url, options);
      }
    }, 300);
  }

  useEffect(() => {
    const user = Authentication._user;

    if (user?.expositoresNomeFantasia.length) {
      const data = user.expositoresNomeFantasia.map((item) => {
        return {
          label: item.nome,
          value: item.id,
        };
      });

      setExpositores(data);
      setExpositorSelecionado(data[0].value);
      initDash(data[0].value, data);
    } else {
      initDash();
    }
  }, []);

  async function initDash(expositorSelecionado, expositoresData) {
    const tableauService = new TableauService();
    const ticket = await tableauService.getTableau();

    if (
      ticket !== -1 &&
      (expositorSelecionado || userHasAccess([userProfiles.administrador]))
    ) {
      var button = document.createElement("button");
      button.innerHTML = "click me";
      button.onclick = function () {
        // eslint-disable-next-line no-undef
        initViz(expositorSelecionado, ticket, expositoresData);
      };
      button.click();
    } else {
      setMensagem("-1");
    }
  }

  return (
    <>
      {!loading ? (
        <div className="p-grid">
          <div className="p-col-12">
            <div className="card">
              <Dropdown
                options={expositores}
                value={expositorSelecionado}
                placeholder="Expositores"
                onChange={(e) => {
                  setLoading(true);
                  setExpositorSelecionado(e.target.value);
                  initDash(e.target.value);
                }}
                style={{ display: expositores.length < 2 ? "none" : "block" }}
              />
              {mensagem && <div hidden={true}>Tableau -1</div>}
              <div
                id="vizContainer"
                style={{ width: 1000, height: 1000 }}
              ></div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}