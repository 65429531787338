import React, { useEffect } from "react";
import Authentication from "../../../service/authentication.service";
import history from "../../../service/history";

export default function Logout() {
  useEffect(() => {
    Authentication.logout();

    history.push("/entrar");
  }, []);

  return <div></div>;
}
