import * as Yup from "yup";
import * as messages from "./messages";

export let failedValidation = false;

export const productSchema = Yup.object().shape({
  idiomas: Yup.array().of(
    Yup.object().shape({
      nome: Yup.string()
        .max(50, "O máximo de caracteres permitido é 50")
        .test("nome", messages.REQUIRED, function (value) {
          if (this && this.parent) {
            if (this.parent.idIdioma === 1) {
              failedValidation = value;
              return value;
            } else if (!this.parent.nome && this.parent.descricao) {
              failedValidation = false;
              return false;
            }
          }
          return true;
        }),
      descricao: Yup.string()
        .max(266, "O máximo de caracteres permitido é 266")
        .test("descricao", messages.REQUIRED, function (value) {
          if (this && this.parent) {
            if (this.parent.idIdioma === 1) {
              return value;
            } else if (this.parent.nome && !this.parent.descricao) {
              failedValidation = false;
              return false;
            }
          }
          return true;
        }),
    })
  ),
});
