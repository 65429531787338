/* eslint-disable react-hooks/exhaustive-deps */
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AlertMenssage from "../../components/AlertMenssage";
import ButtonSave from "../../components/ButtonSave";
import CursoService from "../../service/curso.service";
import QueryOptions from "../../utils/query-options";
import "./styles.scss";

export default function CursoList() {
  const history = useHistory();

  // Paginação
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);
  const [first, setFirst] = useState(1);
  const [last, setLast] = useState(0);

  const [loading, setLoading] = useState(false);
  const [cursos, setCursos] = useState([]);

  // Services
  const _cursoService = new CursoService();

  useEffect(() => {
    async function init() {
      setLoading(true);

      const queryOptions = new QueryOptions([["page", page]]);
      const data = await _cursoService.list(queryOptions, true);

      setCursos(data.items);
      setMeta(data.meta);
      setLast(data.items.length);
      setLoading(false);
    }

    init();
  }, [page]);

  const handleChangePage = (event) => {
    let lastAux = event.first + cursos.length;
    if (lastAux > meta.totalItems) {
      lastAux = meta.totalItems;
    }

    setFirst(event.first + 1);
    setLast(lastAux);
    setPage(event.page + 1);
  };

  const statusBodyTable = (event) => {
    return (
      <span className={`status ${event.disabled ? "desativado" : "ativado"}`}>
        {event.disabled ? "Desativado" : "Ativado"}
      </span>
    );
  };

  const actionBodyTable = (event) => {
    return (
      <div className="icons">
        <i
          className="pi pi-pencil"
          title="Editar"
          onClick={() => history.push(`/cursos/editar/${event.id}`)}
        ></i>
        <i
          className="pi pi-list"
          title="Módulos"
          onClick={() => history.push(`/cursos/modulos/${event.id}`)}
        ></i>
      </div>
    );
  };

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <div className="p-grid">
            <div className="p-col-8 p-lg-8 p-md-8 p-sm-5">
              <h1 className="h1-container">Cursos</h1>
            </div>
          </div>

          <hr className="hr-header" />

          <div className="datatable-paginator-demo">
            <div className="btn-add">
              <ButtonSave
                label="Adicionar"
                onClick={() => history.push("/cursos/adicionar")}
              />
            </div>

            <div className="alert-menssage">
              <AlertMenssage to="top" />
            </div>
            <div className="content-section implementation">
              <DataTable
                value={cursos}
                lazy
                first={first}
                rows={meta.perPage}
                paginator={meta.totalPages > 1}
                loading={loading}
                totalRecords={meta.totalItems}
                onPage={handleChangePage}
                emptyMessage="Sem registros"
                currentPageReportTemplate={`${first} a ${last} de ${meta.totalItems}`}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                dataKey="id"
              >
                <Column
                  field={`idiomas.${[0]}.titulo`}
                  header="Título"
                  headerStyle={{ width: "20rem" }}
                ></Column>
                <Column
                  field="instrutor.nome"
                  header="Instrutor"
                  headerStyle={{ width: "15rem" }}
                ></Column>
                <Column
                  field="expositor.nomeFantasia"
                  header="Expositor"
                  headerStyle={{ width: "15rem" }}
                ></Column>
                <Column
                  field="dataCriacao"
                  header="Data criação"
                  headerStyle={{ width: "10rem" }}
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  headerStyle={{ width: "10rem" }}
                  body={statusBodyTable}
                ></Column>
                <Column
                  field="actions"
                  header="Ações"
                  headerStyle={{ width: "8rem" }}
                  body={actionBodyTable}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
