/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams } from "react-router";
import ProductService from "../../service/products.service";
import QueryOptions from "../../utils/query-options";
import "./App.css";
import { useHistory } from "react-router";
import EditionService from "../../service/edition.service";

function ProdutosListagem({
  busca,
  setLimparFiltros,
  setProdutoListagemLength,
  produtoListagem,
  setProdutoListagem,
}) {
  const { idExpositor } = useParams();
  const history = useHistory();
  const [time] = useState(new Date().getTime());
  const [loadingInfo, setLoadingInfo] = useState(true);

  const _produtosService = new ProductService();

  useEffect(() => {
    async function init() {
      const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
      const _edicaoService = new EditionService();

      const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
        queryOptionsEdicaoAtiva
      );

      const queryOptions = new QueryOptions([
        ["edicao", edicaoAtual.id],
        ["sem_paginacao"],
      ]);

      if (busca) {
        queryOptions.queryKvs.push(["query", busca]);
        setLimparFiltros(true);
      } else {
        setLimparFiltros(false);
      }

      const produtosResponse = await _produtosService.listSub(
        idExpositor,
        queryOptions,
        true
      );

      setProdutoListagem(produtosResponse);
      setProdutoListagemLength(produtosResponse);
      setLoadingInfo(false);
    }

    init();
  }, [idExpositor, busca]);

  async function handleDelete(idProduto) {
    await _produtosService.delete(idProduto).then((response) => {
      const novoValues = produtoListagem.filter(
        (item) => item.id !== idProduto
      );
      setProdutoListagem(novoValues);
    });
    history.push(`/expositores/editar/${idExpositor}/produtos?acao=deletado`);
  }

  async function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(produtoListagem);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setProdutoListagem(items);

    for (let index = 0; index < items.length; index++) {
      items[index].order = index;
    }

    items.forEach(async (item) => {
      await _produtosService.update(item);
    });
  }

  return (
    <div className="App" hidden={loadingInfo}>
      <header className="App-header">
        {produtoListagem?.length ? (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="produtos">
              {(provided) => (
                <ul
                  className="produtos"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {produtoListagem.map((produto, index) => {
                    return (
                      <Draggable
                        key={produto.id.toString()}
                        draggableId={produto.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="p-grid"
                          >
                            <div className="produtos-thumb p-col-12 p-md-12 p-lg-3">
                              <img
                                src={`${process.env.REACT_APP_API_URL}produto-imagens/${produto?.imagens[0]?.id}/display?${time}`}
                                alt="imagem"
                              />
                            </div>

                            <div className="produtos-info p-col-12 p-md-12 p-lg-9 p-grid">
                              <div className="p-col-12 p-md-12 p-lg-12">
                                <div className="p-col-12 p-md-12 p-lg-12">
                                  <h1>{produto?.idiomas[0]?.nome}</h1>
                                  <p>{produto?.idiomas[0]?.descricao}</p>
                                </div>

                                <div className="produtos-info-botoes p-col-12 p-md-12 p-lg-12">
                                  <button
                                    className="produtos-info-ver-produto"
                                    onClick={() =>
                                      history.push(
                                        `/expositores/${idExpositor}/produtos/editar/${produto?.id}`
                                      )
                                    }
                                  >
                                    Ver produto
                                  </button>

                                  <button
                                    className="produtos-info-deletar"
                                    onClick={() => handleDelete(produto.id)}
                                  >
                                    Excluir
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div className="container-sem-cadastro">
            <div className="container-mensagem-sem-cadastro">
              <h2 className="mensagem-produto-sem-cadastro">
                Ainda não existe nenhum produto cadastrado
              </h2>
            </div>
          </div>
        )}
      </header>
    </div>
  );
}

export default ProdutosListagem;
