import { Form, Formik } from "formik";
import "primeflex/primeflex.css";
import { InputSwitch } from "primereact/inputswitch";
import { PickList } from "primereact/picklist";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AlertMenssage from "../../../components/AlertMenssage";
import ButtonSave from "../../../components/ButtonSave";
import CalendarInput from "../../../components/CalendarInput";
import ConfirmationDialog from "../../../components/dialogs/confirmation-dialog";
import TextInput from "../../../components/TextInput";
import AlertService from "../../../service/alert.service";
import EditionService from "../../../service/edition.service";
import ExpositoresService from "../../../service/expositores.service";
import { editionSchema } from "../../../validations/edition.validation";
import QueryOptions from "../../../utils/query-options";
import "./styles.scss";
import { userHasAccess, userProfiles } from "../../../utils/permission-user";

export default function EditionForm() {
  const { id } = useParams();
  const history = useHistory();
  const [action, setAction] = useState("edit");
  const [loading, setLoading] = useState(false);
  const [loadingDuplicar, setLoadingDuplicar] = useState(false);
  const [EdicaoAtiva, setEdicaoAtiva] = useState(true);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [expositores, setExpositores] = useState([]);
  const [expositoresSelecionados, setExpositoresSelecionados] = useState([]);

  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);
  const [
    displayConfirmationDialogEdicaoAtiva,
    setDisplayConfirmationDialogEdicaoAtiva,
  ] = useState(false);
  const [displayDuplicateDialog, setDisplayDuplicateDialog] = useState(false);

  const _editionService = new EditionService();

  const [edition, setEdition] = useState({
    nome: "",
    dataInicio: "",
    dataFim: "",
    expositores: [],
  });

  useEffect(() => {
    async function getEdition(expositoresData) {
      const _editionService = new EditionService();
      const edition = await _editionService.read(id);
      setEdition(edition);
      setEdicaoAtiva(edition.disabled);

      if (expositoresData.length) {
        if (edition.expositores.length) {
          const sourceData = expositoresData.filter(
            (item) => !edition.expositores.includes(item.id)
          );
          const targetData = expositoresData.filter((item) =>
            edition.expositores.includes(item.id)
          );

          setExpositores(sourceData);
          setExpositoresSelecionados(targetData);
        } else {
          setExpositores(expositoresData);
        }
      }
      setLoadingInfo(false);
    }

    async function init() {
      const _expositorService = new ExpositoresService();
      const expositoresData = await _expositorService.list(
        new QueryOptions([["per_page", 10000]])
      );

      if (id) {
        getEdition(expositoresData);
      } else {
        setAction("create");
        setExpositores(expositoresData);
        setLoadingInfo(false);
      }
    }
    init();
  }, [id]);

  async function createEdition(values) {
    setLoading(true);

    values.expositores = expositoresSelecionados.length
      ? expositoresSelecionados
      : [];

    if (action === "create") {
      await _editionService
        .create(values)
        .then(() => {
          history.push("/edicoes?acao=criado");
        })
        .catch(({ response }) => {
          AlertService.warn(response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await _editionService
        .update(values)
        .then(() => {
          AlertService.success("A edição foi atualizada com sucesso");
        })
        .catch(({ response }) => AlertService.warn(response.data.msg))
        .finally(() => {
          setLoading(false);
        });
    }
  }

  async function ativarEdicao() {
    await _editionService
      .ativarEdicao(id)
      .then(() => {
        window.location.reload();
      })
      .catch(({ response }) => AlertService.warn(response.data.msg))
      .finally(() => {
        setLoading(false);
      });
  }

  async function handleDelete() {
    await _editionService.delete(id).then(() => {
      history.push("/edicoes?acao=deletado");
      setDisplayConfirmationDialog(false);
    });
  }

  const itemTemplate = (item) => {
    return (
      <div className="expositor-item-lista">
        <div className="imagem">
          <img
            src={`${process.env.REACT_APP_API_URL}expositores/${item.id}/logotipos/display`}
            onError={(e) =>
              (e.target.src = require("../../../assets/images/user.svg"))
            }
            alt={item.name}
          />
        </div>
        <div>
          <h5>{item.label}</h5>
          <span className="product-category">
            <strong>CNPJ: </strong>
            {item.cnpj}
          </span>
        </div>
      </div>
    );
  };

  const onChange = (event) => {
    setExpositores(event.source);
    setExpositoresSelecionados(event.target);
  };

  async function duplicarEdicao() {
    setLoadingDuplicar(true);

    await _editionService
      .duplicarEdicao(id)
      .then((response) => {
        setLoadingDuplicar(false);
        setDisplayDuplicateDialog(false);
        history.push(`/edicoes/editar/${response.oret.id_edicao}`);
      })
      .catch(({ response }) => AlertService.warn(response.data.msg));
  }

  return (
    <div className="p-grid">
      <ConfirmationDialog
        header="Excluir essa edição vai apagar todos os dados salvos e desvincular os expositores."
        action="Deseja realmente excluir?"
        display={displayConfirmationDialog}
        onClose={() => setDisplayConfirmationDialog(false)}
        onConfirm={handleDelete}
      />

      <ConfirmationDialog
        header="Ao ativar essa edição, todos os elementos relacionados a ela poderão ser modificados e a edição atualmente ativa será desativada"
        action="Deseja realmente ativar?"
        display={displayConfirmationDialogEdicaoAtiva}
        onClose={() => setDisplayConfirmationDialogEdicaoAtiva(false)}
        onConfirm={ativarEdicao}
      />

      <ConfirmationDialog
        header="Duplicar edição"
        action="Você tem certeza de que deseja Duplicar essa edição? A edição duplicada ficará ativa e todos os usuários Expositor e Visitante perderão o acesso. Novos cadastros serão necessários para acessar o Admin e a Plataforma."
        display={displayDuplicateDialog}
        onClose={() => setDisplayDuplicateDialog(false)}
        onConfirm={duplicarEdicao}
        loading={loadingDuplicar}
      />

      {loadingInfo && (
        <div className="loading-info-container">
          <lottie-player
            src="https://assets4.lottiefiles.com/packages/lf20_58vkk48j.json"
            background="transparent"
            speed="1"
            style={{ width: "500px" }}
            loop
            autoplay
          />
        </div>
      )}

      <div className="p-col-12" hidden={loadingInfo}>
        <div className="card">
          <div className="itens-hr">
            <h1>Edição</h1>
            {action === "edit" && (
              <div className="switch-container">
                <label>Ativar edição</label>
                <InputSwitch
                  checked={!EdicaoAtiva}
                  onChange={(e) => {
                    e.preventDefault();
                    setDisplayConfirmationDialogEdicaoAtiva(true);
                  }}
                  disabled={EdicaoAtiva ? false : true}
                />
              </div>
            )}
          </div>

          <hr className="hr-header" />
          <Formik
            enableReinitialize={true}
            validationSchema={editionSchema}
            initialValues={edition}
            onSubmit={(values) => {
              createEdition(values);
            }}
          >
            {(props) => {
              return (
                <Form autoComplete="off">
                  <div className="p-grid" style={{ paddingTop: "10px" }}>
                    <div
                      style={{ display: "flex", flexDirection: "column" }}
                      className="p-col-12 p-md-4 p-lg-3"
                    >
                      <label>
                        Nome da edição <span className="required-hash">*</span>
                      </label>
                      <TextInput name="nome" className="input" />
                    </div>

                    <div
                      style={{ display: "flex", flexDirection: "column" }}
                      className="p-col-12 p-md-3 p-lg-2"
                    >
                      <label>
                        Data de início <span className="required-hash">*</span>
                      </label>
                      <CalendarInput
                        name="dataInicio"
                        className="input"
                        selected={props.values?.dataInicio}
                        onChange={(e) => {
                          props.setFieldValue("dataInicio", e);
                        }}
                        maxDate={props.values?.dataFim}
                      />
                    </div>

                    <div
                      style={{ display: "flex", flexDirection: "column" }}
                      className="p-col-12 p-md-2 p-lg-2"
                    >
                      <label>
                        Data de fim <span className="required-hash">*</span>
                      </label>
                      <CalendarInput
                        name="dataFim"
                        className="input"
                        selected={props.values?.dataFim}
                        onChange={(e) => {
                          props.setFieldValue("dataFim", e);
                        }}
                        minDate={props.values?.dataInicio}
                      />
                    </div>
                  </div>

                  <div className="p-grid m-t-20 m-b-20">
                    <div className="p-col-12">
                      <label className="label-list">
                        Atribuir expositores à edição
                      </label>
                    </div>
                    <div className="p-col-12">
                      <div className="picklist-demo">
                        <PickList
                          source={expositores}
                          target={expositoresSelecionados}
                          itemTemplate={itemTemplate}
                          sourceHeader="Expositores"
                          targetHeader="Selecionados"
                          showSourceControls={false}
                          showTargetControls={false}
                          sourceStyle={{ width: "350px", height: "350px" }}
                          targetStyle={{ width: "350px", height: "350px" }}
                          onChange={onChange}
                        ></PickList>
                      </div>
                    </div>
                  </div>

                  <div className="btns-inline">
                    <div className="btn-medium">
                      <ButtonSave
                        label={action === "create" ? "Adicionar" : "Atualizar"}
                        loading={loading}
                        type="submit"
                        onClick={() => {
                          if (Object.keys(props.errors).length) {
                            AlertService.warn(
                              "Verifique se todos os campos obrigatórios foram preenchidos corretamente"
                            );
                          }
                        }}
                      />
                    </div>

                    {action === "edit" &&
                      userHasAccess([userProfiles.administrador]) && (
                        <div className="btn-medium">
                          <button
                            className="button-duplicar"
                            type="button"
                            onClick={() => {
                              setDisplayDuplicateDialog(true);
                            }}
                          >
                            Duplicar
                          </button>
                        </div>
                      )}

                    {action === "edit" && (
                      <div className="btn-medium">
                        <button
                          className="button-delete"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setDisplayConfirmationDialog(true);
                          }}
                        >
                          Excluir
                        </button>
                      </div>
                    )}

                    <div className="btn-medium">
                      <button
                        className="button-cancel"
                        type="button"
                        onClick={() => {
                          history.push(`/edicoes`);
                        }}
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <AlertMenssage />
        </div>
      </div>
    </div>
  );
}
