import * as Yup from "yup";
import * as messages from "./messages";

export let failedValidation = false;

export const cursoInstrutorSchema = Yup.object().shape({
  nome: Yup.string().required(messages.REQUIRED).nullable(),
  idiomas: Yup.array().of(
    Yup.object().shape({
      biografia: Yup.string().test(
        "biografia",
        messages.REQUIRED,
        function (value) {
          if (this && this.parent) {
            if (this.parent.idIdioma === 1) {
              failedValidation = value;
              return value;
            } else if (!this.parent.biografia && this.parent.link) {
              failedValidation = false;
              return false;
            }
          }
          return true;
        }
      ),
    })
  ),
});
