/* eslint-disable react-hooks/exhaustive-deps */
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import AlertMenssage from "../../components/AlertMenssage";
import ListTableLazy from "../../components/ListTableLazy";
import AlertService from "../../service/alert.service";
import UserService from "../../service/user.service";
import QueryOptions from "../../utils/query-options";

export default function Users({ location }) {
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);
  const [first, setFirst] = useState(0);
  const [busca, setBusca] = useState("");
  const [existeBusca, setExisteBusca] = useState(false);
  const [buscaUltima, setBuscaUltima] = useState("");
  const [limparFiltros, setLimparFiltros] = useState(false);
  const _userService = new UserService();

  const columns = [
    { field: "name", header: "Nome" },
    { field: "login", header: "E-mail" },
    { field: "profile.profile", header: "Tipo de perfil" },
    { field: "statusStr", header: "Status" },
  ];

  useEffect(() => {
    async function listUser() {
      if (!existeBusca) {
        const queryOptions = new QueryOptions([
          [
            "fields",
            "id_usuario, login, nome, pessoa, disabled, usuario_perfil",
          ],
          ["page", page],
        ]);

        setLoading(true);

        let data = await _userService.list(queryOptions, true);
        setLoading(false);
        setUsers(data.items);
        setMeta(data.meta);
      }
    }

    listUser();
  }, [page, limparFiltros]);

  useEffect(() => {
    const queryStringObj = queryString.parse(location.search);
    if (queryStringObj) {
      if (queryStringObj.acao === "criado") {
        AlertService.success("Usuário adicionado com sucesso");
      } else if (queryStringObj.acao === "deletado") {
        AlertService.success("Usuário deletado com sucesso");
      }
    }
  }, [location]);

  async function trazerFiltragem() {
    const queryOptions = new QueryOptions([
      ["fields", "id_usuario, login, nome, pessoa, disabled, usuario_perfil"],
      ["page", page],
    ]);

    if (busca.length) {
      let pageAtual = 1;
      setExisteBusca(true);

      if (buscaUltima === busca) {
        pageAtual = page;
      } else {
        setBuscaUltima(busca);
      }

      queryOptions.queryKvs.push(["query", busca], ["page", pageAtual]);

      setLimparFiltros(true);
    } else {
      setLimparFiltros(false);
      setBuscaUltima("");
      setExisteBusca(false);
    }

    let data = await _userService.list(queryOptions, true);
    setLoading(false);
    setUsers(data.items);
    setMeta(data.meta);
  }

  function handleChangePage(event) {
    setFirst(event.first);
    setPage(event.page + 1);
  }

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card">
            <div className="p-grid">
              <div className="p-col-8 p-lg-8 p-md-8 p-sm-5">
                <h1 className="h1-container">Usuários</h1>
              </div>

              <div
                className={
                  existeBusca
                    ? "p-col-12 p-lg-3 p-md-2 p-sm-2"
                    : "p-col-12 p-lg-4 p-md-4 p-sm-7"
                }
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    trazerFiltragem();
                  }}
                  className="container-pesquisa"
                >
                  <div className="text-input-filtragem container-usuario-filtragem">
                    <input
                      type="text"
                      value={busca}
                      onChange={(ev) => setBusca(ev.target.value)}
                      placeholder="Busca por nome, email ou CPF"
                    />
                  </div>
                  <div>
                    <button className="btn-filtragem" type="submit">
                      Buscar
                    </button>
                  </div>
                </form>
              </div>

              {limparFiltros && (
                <div className="p-col-12 p-lg-1 p-md-1 p-sm-1">
                  <button
                    onClick={() => {
                      setBusca("");
                      setLimparFiltros(false);
                      setExisteBusca(false);
                    }}
                    className="limpar-filtros-button"
                  >
                    <span>Limpar filtros</span>
                  </button>
                </div>
              )}
            </div>

            <hr className="hr-header" />
            <AlertMenssage />
            <ListTableLazy
              cols={columns}
              values={users}
              meta={meta}
              firstIdx={first}
              loading={loading}
              endpoint="usuarios"
              onChangePage={handleChangePage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
