import AddressTypeSerializer from "./address-type.serializer";
import EntityTypeSerializer from "./entity-type.serializer";
import PaisSerializer from "./pais.serializer";

export default class AddressSerializer {
  constructor() {
    this.addressTypeSerializer = new AddressTypeSerializer();
    this.entityTypeSerializer = new EntityTypeSerializer();
    this.paisSerializer = new PaisSerializer();
  }

  fromJson(json) {
    const address = {};
    Object.assign(
      address,
      json.id_endereco && { id: json.id_endereco },
      json.logradouro && { address: json.logradouro },
      json.logradouro_numero && { number: json.logradouro_numero },
      json.complemento && { complement: json.complemento },
      json.bairro && { neighborhood: json.bairro },
      json.id_pais && {
        idCountry: {
          label: json.pais,
          value: json.id_pais,
          id: json.id_pais,
        },
      },

      json.cidade_exterior && {
        foreignCity: json.cidade_exterior,
      },
      json.estado_exterior && {
        foreignState: json.estado_exterior,
      },

      json.cep && { zipCode: json.cep },
      { idCity: json.id_cidade || "" },
      { idState: json.id_estado || "" }
    );

    return address;
  }

  toJson(address) {
    const addressToJson = {};
    Object.assign(
      addressToJson,
      address.address && { logradouro: address.address },
      address.number && { logradouro_numero: address.number },
      address.complement && { complemento: address.complement },
      address.neighborhood && { bairro: address.neighborhood },
      address.zipCode && { cep: address.zipCode.replace("-", "") },

      address.foreignCity && {
        cidade_exterior: address.foreignCity,
      },
      address.foreignState && {
        estado_exterior: address.foreignState,
      }
    );

    if (
      typeof address.idState === "object" &&
      typeof address.idCity === "object"
    ) {
      addressToJson.id_cidade = address.idCity.idCidade;
      addressToJson.id_estado = address.idState.idEstado;
    } else if (
      typeof address.idState === "number" &&
      typeof address.idCity === "number"
    ) {
      addressToJson.id_cidade = address.idCity;
      addressToJson.id_estado = address.idState;
    }

    return addressToJson;
  }
}
