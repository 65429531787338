export function verificarUrlYoutube(url) {
  url = url.split("/");

  if (
    url.length === 5 &&
    url[0] === "https:" &&
    url[1] === "" &&
    url[2] === "www.youtube.com" &&
    url[3] === "embed" &&
    typeof url[4] === "string"
  ) {
    return true;
  } else {
    return false;
  }
}
