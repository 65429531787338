import ProdutoTiposIdiomasSerializer from "./produto-tipo-idiomas.serializer";

export default class ProductsTypeSerializer {
  constructor() {
    this.produtoTiposIdiomasSerializer = new ProdutoTiposIdiomasSerializer();
  }

  fromJson(json) {
    const productType = {};

    Object.assign(
      productType,
      json.id_produto_tipo && { value: json.id_produto_tipo },
      json.id_produto_tipo && { id: json.id_produto_tipo },
      json.idiomas && { label: json.idiomas[0].tipo },
      json.idiomas && {
        idiomas: json.idiomas.map((item) =>
          this.produtoTiposIdiomasSerializer.fromJson(item)
        ),
      }
    );

    return productType;
  }

  toJson(produtoTipos) {
    const expositorProductType = {};

    Object.assign(
      expositorProductType,
      produtoTipos.id_produto_tipo && { value: produtoTipos.id_produto_tipo },
      produtoTipos.idiomas && {
        idiomas: produtoTipos.idiomas.map((item) =>
          this.produtoTiposIdiomasSerializer.toJson(item)
        ),
      }
    );

    return expositorProductType;
  }
}
