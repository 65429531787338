import SexoIdiomasSerializer from "./sexo-idiomas.serializer";

export default class SexoSerializer {
  constructor() {
    this.sexoIdiomasSerializer = new SexoIdiomasSerializer();
  }

  fromJson(json) {
    const sexoSerializer = {
      idiomas: json.idiomas.map((item) =>
        this.sexoIdiomasSerializer.fromJson(item)
      ),
    };

    return sexoSerializer.idiomas[0];
  }
}
