export default class EventoBannerIdiomaSerializer {
  fromJson(json) {
    const eventoBannerIdioma = {};

    Object.assign(
      eventoBannerIdioma,
      json.link
        ? {
            link: json.link,
          }
        : {
            link: "",
          },

      json.id_idioma && {
        idIdioma: json.id_idioma,
      },
      json.filename_mobile && {
        filenameMobile: json.filename_mobile,
      },
      json.id_evento_banner && {
        idEventoBanner: json.id_evento_banner,
      },
      json.filename && {
        filename: json.filename,
      },
      json.id_evento_banner_idioma && {
        idEventoBannerIdioma: json.id_evento_banner_idioma,
      }
    );

    return eventoBannerIdioma;
  }

  toJson(eventoBannerIdioma) {
    const eventoBannerIdiomaToJson = {};

    Object.assign(
      eventoBannerIdiomaToJson,
      {
        link: eventoBannerIdioma.link,
      },
      eventoBannerIdioma.idIdioma && {
        id_idioma: eventoBannerIdioma.idIdioma,
      },
      eventoBannerIdioma.idEventoBannerIdioma && {
        id_evento_banner_idioma: eventoBannerIdioma.idEventoBannerIdioma,
      }
    );

    return eventoBannerIdiomaToJson;
  }
}
