import * as Yup from "yup";

export let failedValidationEvent = false;

export const SobreExpositorSchema = Yup.object().shape({
  idiomas: Yup.array().of(
    Yup.object().shape({
      descricao: Yup.string().max(
        360,
        "A descrição não pode passar de 360 caracteres"
      ),
    })
  ),
});
