import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import LogoExporevestir from "../../assets/images/LogoSvgNobgWhite.svg"
import Authentication from "../../service/authentication.service";

export class AppTopbar extends Component {
  container = React.createRef();

  state = {
    userMenu: false,
  };

  static defaultProps = {
    onToggleMenu: null,
  };

  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleUserMenu = () => {
    this.setState({ userMenu: !this.state.userMenu });
  };

  handleClickOutside = (event) => {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      this.setState({
        userMenu: false,
      });
    }
  };

  render() {
    return (
      <div className="layout-topbar clearfix" ref={this.container}>
        <a href="/" className="layout-topbar-logo">
          <img src={LogoExporevestir} alt="Home"></img>
        </a>
        <button
          className="p-link layout-menu-button"
          onClick={this.props.onToggleMenu}
        >
          <span className="pi pi-bars" />
        </button>
        <div className="layout-topbar-icons">
          <ul className="nav navbar">
            {this.props.reportsCount > 0 && (
              <li style={{ paddingRight: "15px" }}>
                <button
                  className="p-link"
                  onClick={() => this.props.onShowReport()}
                >
                  <span className="layout-topbar-item-text">Notificações</span>
                  <span
                    className="layout-topbar-icon pi pi-download"
                    style={{ fontSize: "20px", paddingRight: "5px" }}
                  />
                  <span className="layout-topbar-badge">
                    {this.props.reportsCount}
                  </span>
                </button>
              </li>
            )}
            <li
              className={`user-menu ${this.state.userMenu ? "active" : ""}`}
              onClick={this.handleUserMenu}
            >
              <div>{this.props.nameUser}</div>
              <div className="box-profile">
                <img src={this.props.imageProfile} alt="Foto perfil" />
              </div>
              <ul className="dropdown-menu">
                <li className="user-header">
                  <div className="user-header-profile">
                    <img src={this.props.imageProfile} alt="Foto perfil" />
                  </div>
                  <p>{this.props.nameUser}</p>
                </li>
                <li className="user-footer">
                  <Link to={`/perfil/${Authentication._user?.id}`}>
                    <div className="item">Perfil</div>
                  </Link>
                  <button className="logout" onClick={this.props.handleLogout}>
                    Sair
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
