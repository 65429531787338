export default class ProductsTypeSerializer {
  fromJson(json) {
    const expositorProductType = {};

    Object.assign(
      expositorProductType,
      json.id_produto_tipo && { value: json.id_produto_tipo },
      json.idiomas && { label: json.idiomas[0].tipo }
    );

    return expositorProductType;
  }
}
