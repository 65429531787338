import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router";
import { Dialog } from "primereact/dialog";
import ButtonSave from "../../../components/ButtonSave";
import ProductsImagesService from "../../../service/products-images.service";

import { FieldArray, Form, Formik } from "formik";
import TextInput from "../../../components/TextInput";
import TextareaInput from "../../../components/TextareaInput";
import ConfirmationDialog from "../../../components/dialogs/confirmation-dialog";
import { productImageSchema } from "../../../validations/products-image.validations";
import ordernarItems from "../../../utils/ordernar-items";
import SetaEsquerda from "../../../assets/icons/LeftCircle.svg";
import SetaDireita from "../../../assets/icons/RightCircle.svg";

import "./styles.scss";
import AlertDialog from "../../../components/dialogs/alert-dialog";
import AlertService from "../../../service/alert.service";

const productImageLanguageFields = {
  nome: "",
  descricao: "",
  idIdioma: "",
};

export default function ProductsImages({ languages, width, height }) {
  const { id: idProduto } = useParams();
  const [displayDialog, setDisplayDialog] = useState(false);
  const [imagens, setImagens] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageCurrentEdit, setImageCurrentEdit] = useState({});
  const [currentLanguage, setCurrentLanguage] = useState(1);
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);
  const [displayDialogImagem, setDisplayDialogImagem] = useState(false);
  const [productImage, setProductImage] = useState({
    idiomas: [productImageLanguageFields],
  });

  const _productsImagesService = new ProductsImagesService();

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 150,
    height: 150,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    getFilesFromEvent: async (event) => {
      const files = event.target.files || event.dataTransfer.files;
      const promises = [];
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const promise = new Promise((resolve, reject) => {
          const image = new Image();
          let url = "";
          image.onload = function () {
            file.width = image.width;
            file.height = image.height;
            resolve(file);
          };
          url = URL.createObjectURL(file);
          image.src = url;
        });
        promises.push(promise);
      }

      const data = await Promise.all(promises);

      if (width && height) {
        if (data[0].width === width && data[0].height === height) {
          setFiles(
            data.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
        } else {
          setDisplayDialogImagem(true);
        }
      } else {
        setFiles(
          data.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      }

      return data;
    },
    maxFiles: 1,
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="upload" />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  function moverDireita(index) {
    const data = [...imagens];
    const dataSplice = data.splice(index, 1);
    data.splice(index + 1, 0, dataSplice[0]);

    setImagens(data);
  }

  function moverEsquerda(index) {
    const data = [...imagens];
    const dataSplice = data.splice(index, 1);
    data.splice(index - 1, 0, dataSplice[0]);

    setImagens(data);
  }

  function onOrdenacao() {
    const data = imagens.map((item, index) => {
      return {
        id_produto_imagem: item.id,
        order: index + 1,
      };
    });

    _productsImagesService
      .updateOrdem({ ordenacao: data })
      .then((response) => {
        AlertService.success("Ordenamento de imagens atualizado.");
      })
      .catch(({ response }) => {
        AlertService.warn(response.data.msg);
      });
  }

  useEffect(
    (_productsImagesService = new ProductsImagesService()) => {
      async function init() {
        const response = await _productsImagesService.listSub(idProduto);

        const data = ordernarItems(response, "order");

        setImagens(data);
      }

      init();
    },
    [idProduto]
  );

  async function editarImagem(imagem) {
    let idiomas = [];
    languages.forEach((element) => {
      idiomas.push({
        ...productImageLanguageFields,
        idIdioma: element.id,
      });
    });

    const idIdiomas = [];
    imagem.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

    const idiomasFaltantes = [];
    idiomas.forEach((item) => {
      if (!idIdiomas.includes(item.idIdioma)) {
        idiomasFaltantes.push(item);
      }
    });

    const idiomasFields = [...imagem.idiomas, ...idiomasFaltantes];

    setProductImage({ idiomas: idiomasFields });

    setImageCurrentEdit(imagem);
    setDisplayDialog(true);
  }

  async function onUpload() {
    setLoading(true);
    const arquivos = await _productsImagesService.uploadList(idProduto, files);
    setImagens([...imagens, ...arquivos]);
    setFiles([]);
    setLoading(false);
  }

  async function updateProdutImage(data) {
    data.idiomas = data.idiomas.filter((item) => item.nome && item.descricao);

    if (data.idiomas.length) {
      const response = await _productsImagesService.updateLanguage({
        id: imageCurrentEdit.id,
        ...data,
      });

      setImagens([...imagens, response]);

      const responseImages = await _productsImagesService.listSub(idProduto);
      setImagens(responseImages);
    }

    setDisplayDialog(false);
    setProductImage({ idiomas: [productImageLanguageFields] });
  }

  async function handleDelete() {
    await _productsImagesService.deleteImage(imageCurrentEdit.id);

    const data = imagens.filter((item) => item.id !== imageCurrentEdit.id);

    setImagens(data);

    setDisplayConfirmationDialog(false);
  }

  return (
    <div>
      <ConfirmationDialog
        header="Excluir a imagem vai apagá-la do sistema."
        action="Deseja realmente excluir?"
        display={displayConfirmationDialog}
        onClose={() => setDisplayConfirmationDialog(false)}
        onConfirm={handleDelete}
      />

      <AlertDialog
        display={displayDialogImagem}
        textList={[
          "Dimensão da imagem precisa ser:",
          `Largura: ${width}px`,
          `Altura: ${height}px`,
        ]}
        onClose={() => setDisplayDialogImagem(false)}
      />

      <Dialog
        header="Editar"
        visible={displayDialog}
        style={{ width: "50vw" }}
        onHide={() => setDisplayDialog(false)}
      >
        <div className="dialog-image">
          <img
            src={
              imageCurrentEdit.id &&
              `${process.env.REACT_APP_API_URL}produto-imagens/${imageCurrentEdit.id}/display`
            }
            alt="Card"
          />
        </div>
        <Formik
          enableReinitialize={true}
          validationSchema={productImageSchema}
          initialValues={productImage}
          onSubmit={(values) => {
            updateProdutImage(values);
          }}
        >
          {(props) => {
            return (
              <Form autoComplete="off">
                <div className="p-grid" style={{ paddingLeft: "14px" }}>
                  {languages.map((item, index) => (
                    <div key={index}>
                      <button
                        className={
                          item.id === currentLanguage
                            ? "p-button-dash"
                            : "p-button-teste"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentLanguage(item.id);
                        }}
                      >
                        {item.nome}
                      </button>
                    </div>
                  ))}
                </div>

                <div className="p-grid">
                  <FieldArray
                    name="idiomas"
                    render={(arrayHelpers) => (
                      <div className="p-col-12">
                        {props?.values?.idiomas?.map((item, index) => (
                          <div
                            key={index}
                            hidden={!(item.idIdioma === currentLanguage)}
                          >
                            <TextInput
                              label="Nome da foto"
                              name={`idiomas[${index}].nome`}
                              quantidadeCaracteres={120}
                              classes="p-col-12"
                              className="input"
                            />

                            <TextareaInput
                              label="Legenda da foto"
                              name={`idiomas[${index}].descricao`}
                              quantidadeCaracteres={120}
                              classes="p-col-12"
                              className="input"
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </div>

                <div className="btns-inline">
                  <div className="btn-medium" style={{ paddingLeft: "7px" }}>
                    <ButtonSave
                      label="Atualizar"
                      loading={loading}
                      type="submit"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
      <section className="container">
        <div
          className="box-upload"
          {...getRootProps({ className: "dropzone" })}
        >
          <input {...getInputProps()} />

          <p>Arraste e solte a imagem aqui ou clique para selecionar.</p>
        </div>

        <aside style={thumbsContainer}>{thumbs}</aside>

        {files.length ? (
          <ButtonSave
            label="Adicionar Imagem"
            loading={loading}
            disabled={!files.length}
            onClick={onUpload}
          />
        ) : null}

        <div
          className="m-t-20"
          style={{ display: "flex", gap: 20, flexWrap: "wrap" }}
        >
          {imagens.map((imagem, index) => (
            <div className="image-card" key={index}>
              <div className="image">
                <img
                  src={`${process.env.REACT_APP_API_URL}produto-imagens/${imagem.id}/display`}
                  alt="Card"
                />
              </div>

              <div className="content">
                <div className="title">{imagem.nome}</div>
                <div className="desc">{imagem.descricao}</div>
              </div>

              <div className="image-card-actions">
                <div className="image-interacao-card">
                  <i
                    className="pi pi-pencil"
                    id="edit-pencil"
                    onClick={() => editarImagem(imagem)}
                  ></i>
                  <i
                    className="pi pi-trash"
                    id="edit-trash"
                    onClick={() => {
                      setImageCurrentEdit(imagem);
                      setDisplayConfirmationDialog(true);
                    }}
                  ></i>

                  {imagens[0].id !== imagem.id && (
                    <img
                      src={SetaEsquerda}
                      alt="seta esquerda"
                      type="button"
                      onClick={() => {
                        moverEsquerda(index);
                      }}
                      style={{
                        cursor: "pointer",
                        width: "27px",
                        margin: "0.2% 0% 5% 6%",
                      }}
                    />
                  )}

                  {imagens[imagens.length - 1].id !== imagem.id && (
                    <img
                      src={SetaDireita}
                      alt="seta direita"
                      type="button"
                      onClick={() => {
                        moverDireita(index);
                      }}
                      style={{
                        cursor: "pointer",
                        width: "27px",
                        margin: "0.2% 0% 5% 6%",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="botao-salvar-ordenamento">
          <button
            type="button"
            className="button-save"
            onClick={() => {
              onOrdenacao();
            }}
          >
            Salvar Ordenamento
          </button>
        </div>
      </section>
    </div>
  );
}
