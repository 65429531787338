export default class AvertisingdAreaSerializer {
  fromJson(json) {
    const avertisingdArea = {
      id: json.id_publicidade_area,
      area: json.area,
      label: json.area,
      value: json.id_publicidade_area,
    };

    return avertisingdArea;
  }

  toJson(avertisingdArea) {
    return {
      id_publicidade_area: avertisingdArea.id,
      area: avertisingdArea.area,
    };
  }
}
