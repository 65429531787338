/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TextareaInput from "./TextareaInput";
import TextInput from "../components/TextInput";
import imageVideo from "../assets/images/video_container_default.png";

export function VideoContainer({ index, formValue }) {
  const [videoValido, setVideoValido] = useState(false);
  const [videoSobreExpositorUrl, setVideoSobreExpositorUrl] = useState();

  function verificarUrlYoutubeValida(url) {
    var id = "";
    url = url
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

    if (url[2] !== undefined) {
      id = url[2].split(/[^0-9a-z_-]/i);
      id = id[0];
      setVideoValido(true);
      setVideoSobreExpositorUrl(`https://www.youtube.com/embed/${id}`);
      return true;
    } else {
      return false;
    }
  }

  function verificarUrlVimeoValida(url) {
    var id = url.split("/").slice(-1)[0].split("?")[0];

    if (typeof url === "string") {
      setVideoValido(true);
      setVideoSobreExpositorUrl(`https://player.vimeo.com/video/${id}`);
      return true;
    } else {
      return false;
    }
  }

  function verificarUrlVideoValida(url) {
    if (verificarUrlYoutubeValida(url)) {
      return true;
    } else if (verificarUrlVimeoValida(url)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (formValue.idiomas[index].linkVideo) {
      verificarUrlVideoValida(formValue.idiomas[index].linkVideo);
    }
  }, [formValue.idiomas, index]);

  return (
    <div className="background-container">
      <div className="expositor-video-container p-grid">
        <div className="p-xl-6 p-lg-6 p-md-6">
          <div style={{ height: "100%" }}>
            <TextInput
              name={`idiomas[${index}].linkVideo`}
              classes="descricao-publicidade"
              placeholder="Insira o link do seu vídeo introdutório do vimeo ou do youtube"
              className="input"
              onKeyUp={(e) => {
                formValue.idiomas[index].linkVideo = e.target.value;
                verificarUrlVideoValida(formValue.idiomas[index].linkVideo);
              }}
              style={{ borderRadius: "0px" }}
            />
            <div style={{ height: "100%" }}>
              {videoValido ? (
                <iframe
                  width="100%"
                  height="85%"
                  src={videoSobreExpositorUrl}
                  title="Apresentação do expositor"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              ) : (
                <div>
                  <img
                    src={imageVideo}
                    alt="Imagem de um vídeo"
                    style={{ width: "100%" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="video-text-container p-xl-6 p-lg-6 p-md-6">
          <div className="container-info">
            <h1
              style={{
                textAlign: "left",
                textAlignLast: "left",
              }}
            >
              <div>
                <TextareaInput
                  placeholder="Digite o título do seu vídeo introdutório"
                  name={`idiomas[${index}].tituloVideo`}
                  maxlength="40"
                  classes="descricao-publicidade"
                  className="input"
                  style={{
                    fontFamily: "Roboto Slab, serif",
                    fontSize: "24px",
                    lineHeight: "140%",
                    border: "none",
                    height: "none",
                    overflow: "none",
                    color: "#212529",
                  }}
                />
              </div>
            </h1>

            <div>
              <TextareaInput
                placeholder="Digite a descrição do seu vídeo introdutório"
                maxlength="360"
                name={`idiomas[${index}].descricaoVideo`}
                classes="descricao-publicidade"
                className="input"
                style={{
                  fontFamily: "Raleway, sans-serif",
                  fontSize: "1.5em",
                  lineHeight: "32px",
                  border: "none",
                  color: "black",
                  padding: "10px",
                  minHeight: "250px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
