import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import EventoDoDia from "./EventoDoDiaCard";
import EventService from "../service/event.service";
import { languageEventFields } from "../pages/Events/EventForm";
import AlertService from "../service/alert.service";
import { useHistory, useParams } from "react-router";
import { Button } from "primereact/button";

export default function ModalEventosDestaques({
  modalAberto,
  setModalAberto,
  setEventoConfirmado,
  formValues,
  languages,
  setEvent,
  setLoading,
  action,
  eventosDestaquesDoDia,
  quantidadeEventosDestaquesDoDia,
}) {
  const history = useHistory();
  const { id } = useParams();
  const [eventoJaEDestaque, setEventoJaEDestaque] = useState(false);
  const [evento, setEvento] = useState();

  useEffect(() => {
    setEventoJaEDestaque(null);

    eventosDestaquesDoDia.forEach((item) => {
      if (item.id === parseInt(id)) {
        setEventoJaEDestaque(true);
        setEvento(item);
      }
    });
  }, [eventosDestaquesDoDia, id]);

  return (
    <>
      <Dialog
        className="dialog-eventos-destaques"
        header={"Atenção"}
        visible={modalAberto}
        style={
          quantidadeEventosDestaquesDoDia <= 2
            ? { width: "60vw" }
            : { width: "60vw", overflow: "scroll", height: "98vh" }
        }
        onHide={() => {
          setModalAberto(false);
        }}
      >
        <div style={{ padding: "18px" }}>
          <div>
            {eventoJaEDestaque ? (
              <p>
                Você está mexendo no evento "{evento.idiomas[0].titulo}", que é
                um evento destaque do dia{" "}
                {eventosDestaquesDoDia[0]?.dataInicioStr} na plataforma da Expo
                Revestir. Caso você remova o destaque desse evento e no dia{" "}
                {eventosDestaquesDoDia[0]?.dataInicioStr} haja outro evento
                marcado como destaque, haverá uma substituição desse evento por
                outro.
              </p>
            ) : (
              <p>
                Já existem {quantidadeEventosDestaquesDoDia} eventos destaques
                no dia {eventosDestaquesDoDia[0]?.dataInicioStr}. A plataforma
                só pode exibir {quantidadeEventosDestaquesDoDia} eventos por vez
                e em ordem cronológica, sendo assim, os eventos que acontecem
                primeiro que aparecerão na plataforma.{" "}
                <i>
                  Deseja transformar "{formValues?.idiomas[0]?.titulo}" em um
                  evento destaque do dia?
                </i>
              </p>
            )}
          </div>
        </div>

        <div style={{ marginBottom: "30px" }}>
          <EventoDoDia eventosDestaquesDoDia={eventosDestaquesDoDia} />
        </div>

        <hr />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              minWidth: "288px",
            }}
          >
            <Button
              label="Cancelar"
              onClick={() => {
                setModalAberto(false);
                setEventoConfirmado(false);
                return;
              }}
            />

            <Button
              label="Confirmar"
              onClick={async () => {
                setLoading(true);
                setModalAberto(false);
                setEventoConfirmado(true);
                const _eventService = new EventService();

                const data = formValues;
                data.idiomas = data.idiomas.filter(
                  (idioma) => idioma.titulo && idioma.descricao
                );

                if (action === "create") {
                  await _eventService
                    .create(data)
                    .then((response) => {
                      history.push(
                        `/eventos/editar/${response.id}?acao=criado`
                      );
                    })
                    .catch(({ response }) => {
                      AlertService.warn(response.data.msg);
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                } else {
                  await _eventService
                    .update(formValues)
                    .then(async (response) => {
                      let idiomas = [];
                      languages.forEach((element) => {
                        idiomas.push({
                          ...languageEventFields,
                          idIdioma: element.id,
                        });
                      });

                      const idIdiomas = [];
                      response.idiomas.forEach((item) =>
                        idIdiomas.push(item.idIdioma)
                      );

                      const idiomasFaltantes = [];
                      idiomas.forEach((item) => {
                        if (!idIdiomas.includes(item.idIdioma)) {
                          idiomasFaltantes.push(item);
                        }
                      });

                      const allLanguages = [
                        ...response.idiomas,
                        ...idiomasFaltantes,
                      ].sort((v1, v2) => v1.idIdioma - v2.idIdioma);

                      setEvent({ ...response, idiomas: allLanguages });
                    })
                    .catch(({ response }) =>
                      AlertService.warn(response.data.msg)
                    )
                    .finally(() => {
                      setEventoConfirmado(false);
                      setLoading(false);
                    });
                }
              }}
              className="p-button-danger"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}
