import React from "react";

export default function ProporcaoImagemDialogo({
  isJustDesktop,
  widthDesktop,
  heightDesktop,
  isJustMobile,
  widthMobile,
  heightMobile,
  desktopEMobile,
  desktopEMobileWidth,
  desktopEMobileHeight,
}) {
  return (
    <>
      {isJustDesktop && (
        <div>
          <span>
            Dimensões da imagem desktop: {widthDesktop}x{heightDesktop}px
          </span>
        </div>
      )}

      {isJustMobile && (
        <div>
          <span>
            Dimensões da imagem mobile: {widthMobile}x{heightMobile}px
          </span>
        </div>
      )}

      {desktopEMobile && (
        <div>
          <span>
          Dimensões da imagem: {desktopEMobileWidth}x{desktopEMobileHeight}px
          </span>
        </div>
      )}
    </>
  );
}
