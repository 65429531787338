import React from "react";
import { Switch } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import Advsertising from "../pages/Advertising";
import AdvertisingForm from "../pages/Advertising/AdvertisingForm";
import ChangePassword from "../pages/authentication/ChangePassword";
import Forgot from "../pages/authentication/Forgot";
import Login from "../pages/authentication/Login";
import Logout from "../pages/authentication/Logout";
import DashboardExpo from "../pages/Dashboard";
import Edition from "../pages/Edition";
import EditionForm from "../pages/Edition/EditionForm";
import Events from "../pages/Events";
import Expositores from "../pages/Expositores";
import ExpositorForm from "../pages/Expositores/ExpositorForm";
import MapaInterativo from "../pages/MapaInterativo";
import MapainterativoForm from "../pages/MapaInterativo/MapaInterativoForm";
import Products from "../pages/Products";
import ProductsForm from "../pages/Products/ProductsForm";
import ProductsImages from "../pages/Products/ProductsImages";
import EventForm from "../pages/Events/EventForm";
import ExpositorEvent from "../pages/Events/ExpositorEvent";
import ExpositorEventForm from "../pages/Events/ExpositorEventForm";
import ExhibitorVideos from "../pages/Videos";
import ExhibitorVideosForm from "../pages/Videos/VideosForm";
import Users from "../pages/Users";
import UserForm from "../pages/Users/UserForm";
import CatalogoForm from "../pages/Catalogo/CatalogoForm";
import Route from "./Route";
import Catalogos from "../pages/Catalogo";
import SobreExpositor from "../pages/SobreExpositor";
import BannerExpositorForm from "../pages/BannerExpositor/BannerExpositorForm/index";
import BannerExpositor from "../pages/BannerExpositor";
import UsuarioLog from "../pages/Users/UsuarioLog";
import Segmentos from "../pages/Segmentos";
import SegmentosForm from "../pages/Segmentos/SegmentosForm";
import ProdutoTipos from "../pages/ProdutoTipos";
import ProdutoTiposForm from "../pages/ProdutoTipos/ProdutoTiposForm";
import CamposCustomizados from "../pages/CamposCustomizados";
import CampoCustomizadoItens from "../pages/CamposCustomizados/Itens";
import HomeForm from "../pages/CamposCustomizados/Itens/HomeForm";
import NavBarForm from "../pages/CamposCustomizados/Itens/NavBarForm";
import FAQForm from "../pages/CamposCustomizados/Itens/FAQForm";
import Certificados from "../pages/Certificados";
import CursoList from "../pages/Curso/CursoList";
import CursoForm from "../pages/Curso/CursoForm";
import CursoInstrutorForm from "../pages/Curso/CursoInstrutorForm";
import CursoInstrutorList from "../pages/Curso/CursoInstrutorList";
import CursoModuloList from "../pages/Curso/CursoModuloList";
import CursoModuloForm from "../pages/Curso/CursoModuloForm";
import CursoModuloAulaList from "../pages/Curso/CursoModuloAulaList";
import CursoModuloAulaForm from "../pages/Curso/CursoModuloAulaForm";
import ConfiguracaoPerfilList from "../pages/Configuracao/ConfiguracaoPerfil/ConfiguracaoPerfilList";
import ConfiguracaoPerfilForm from "../pages/Configuracao/ConfiguracaoPerfil/ConfiguracaoPerfilForm";
import CursoRelatorio from "../pages/Curso/CursoRelatorio";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/entrar" component={Login} />
      <Route exact path="/recuperar-senha" component={Forgot} />
      <Route exact isPrivate path="/mudar-senha" component={ChangePassword} />
      <Route exact isPrivate path="/sair" component={Logout} />
      <Route exact isPrivate path="/" component={DashboardExpo} />
      <Route
        exact
        isPrivate
        path="/painel-controle"
        component={DashboardExpo}
      />
      <Route exact isPrivate path="/" component={Dashboard} />
      <Route exact isPrivate path="/usuarios" component={Users} />
      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/usuarios"
        component={Users}
      />
      <Route exact isPrivate path="/usuarios/adicionar" component={UserForm} />
      <Route exact isPrivate path="/usuarios/editar/:id" component={UserForm} />
      <Route exact isPrivate path="/usuarios/:id/logs" component={UsuarioLog} />
      <Route exact isPrivate path="/perfil/:id" component={UserForm} />
      <Route exact isPrivate path="/expositores" component={Expositores} />
      <Route
        exact
        isPrivate
        path="/expositores/adicionar"
        component={ExpositorForm}
      />
      <Route
        exact
        isPrivate
        path="/expositores/editar/:id"
        component={ExpositorForm}
      />
      <Route exact isPrivate path="/edicoes" component={Edition} />
      <Route
        exact
        isPrivate
        path="/edicoes/adicionar"
        component={EditionForm}
      />
      <Route
        exact
        isPrivate
        path="/edicoes/editar/:id"
        component={EditionForm}
      />

      <Route
        exact
        isPrivate
        path="/expositores/editar/:idExpositor/produtos"
        component={Products}
      />
      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/produtos/adicionar"
        component={ProductsForm}
      />
      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/produtos/editar/:id"
        component={ProductsForm}
      />
      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/produtos/editar/:id/imagens"
        component={ProductsImages}
      />
      <Route exact isPrivate path="/edicoes" component={Edition} />
      <Route
        exact
        isPrivate
        path="/edicoes/adicionar"
        component={EditionForm}
      />
      <Route
        exact
        isPrivate
        path="/edicoes/editar/:id"
        component={EditionForm}
      />
      <Route
        exact
        isPrivate
        path="/expositores/editar/:idExpositor/produtos"
        component={Products}
      />
      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/produtos/adicionar"
        component={ProductsForm}
      />
      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/produtos/editar/:id"
        component={ProductsForm}
      />
      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/produtos/editar/:id/imagens"
        component={ProductsImages}
      />
      <Route exact isPrivate path="/eventos-oficiais" component={Events} />
      <Route
        exact
        isPrivate
        path="/expositores/editar/:idExpositor/eventos-expositor"
        component={ExpositorEvent}
      />
      <Route
        exact
        isPrivate
        path="/expositores/editar/:idExpositor/eventos-expositor"
        component={ExpositorEvent}
      />
      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/eventos-expositor/adicionar"
        component={ExpositorEventForm}
      />
      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/eventos-expositor/editar/:id"
        component={ExpositorEventForm}
      />
      <Route exact isPrivate path="/eventos/adicionar" component={EventForm} />
      <Route exact isPrivate path="/publicidades" component={Advsertising} />
      <Route
        exact
        isPrivate
        path="/publicidades/adicionar"
        component={AdvertisingForm}
      />
      <Route
        exact
        isPrivate
        path="/publicidades/editar/:id"
        component={AdvertisingForm}
      />
      <Route exact isPrivate path="/eventos/editar/:id" component={EventForm} />
      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/videos"
        component={ExhibitorVideos}
      />
      <Route
        exact
        isPrivate
        path="/mapas-interativos"
        component={MapaInterativo}
      />
      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/videos/adicionar"
        component={ExhibitorVideosForm}
      />
      <Route
        exact
        isPrivate
        path="/mapas-interativos/adicionar"
        component={MapainterativoForm}
      />
      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/videos/editar/:id"
        component={ExhibitorVideosForm}
      />
      <Route
        exact
        isPrivate
        path="/mapas-interativos/editar/:id"
        component={MapainterativoForm}
      />
      <Route
        exact
        isPrivate
        path="/expositores/catalogos/editar"
        component={CatalogoForm}
      />
      <Route
        exact
        isPrivate
        path="/campos-customizados"
        component={CamposCustomizados}
      />

      <Route
        exact
        isPrivate
        path="/campos-customizados/form/navbar"
        component={NavBarForm}
      />

      <Route
        exact
        isPrivate
        path="/campos-customizados/form/home"
        component={HomeForm}
      />

      <Route
        exact
        isPrivate
        path="/campos-customizados/form/faq"
        component={FAQForm}
      />

      <Route
        exact
        isPrivate
        path="/campos-customizados/:id/itens"
        component={CampoCustomizadoItens}
      />

      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/catalogos/editar/:id"
        component={CatalogoForm}
      />
      <Route
        exact
        isPrivate
        path="/expositores/editar/:idExpositor/catalogos"
        component={Catalogos}
      />
      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/catalogos/adicionar"
        component={CatalogoForm}
      />
      <Route
        exact
        isPrivate
        path="/expositores/editar/:idExpositor/banner-expositor"
        component={BannerExpositor}
      />
      <Route
        exact
        isPrivate
        path="/expositores/editar/:idExpositor/sobre"
        component={SobreExpositor}
      />
      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/banner-expositor/editar/:id"
        component={BannerExpositorForm}
      />
      <Route
        exact
        isPrivate
        path="/expositores/:idExpositor/banner-expositor/adicionar"
        component={BannerExpositorForm}
      />

      <Route exact isPrivate path="/segmentos" component={Segmentos} />
      <Route
        exact
        isPrivate
        path="/segmentos/adicionar"
        component={SegmentosForm}
      />
      <Route
        exact
        isPrivate
        path="/segmentos/editar/:id"
        component={SegmentosForm}
      />

      <Route exact isPrivate path="/produto-tipos" component={ProdutoTipos} />
      <Route
        exact
        isPrivate
        path="/produto-tipos/adicionar"
        component={ProdutoTiposForm}
      />
      <Route
        exact
        isPrivate
        path="/produto-tipos/editar/:id"
        component={ProdutoTiposForm}
      />

      <Route exact isPrivate path="/certificados" component={Certificados} />

      <Route exact isPrivate path="/cursos/lista" component={CursoList} />
      <Route exact isPrivate path="/cursos/adicionar" component={CursoForm} />
      <Route exact isPrivate path="/cursos/editar/:id" component={CursoForm} />
      <Route exact isPrivate path="/cursos/modulos/:idCurso" component={CursoModuloList} />
      <Route exact isPrivate path="/cursos/modulos/:idCurso/adicionar" component={CursoModuloForm} />
      <Route exact isPrivate path="/cursos/modulos/:idCurso/editar/:id" component={CursoModuloForm} />
      <Route exact isPrivate path="/cursos/modulos/:idCursoModulo/aulas" component={CursoModuloAulaList} />
      <Route exact isPrivate path="/cursos/modulos/:idCursoModulo/aulas/adicionar" component={CursoModuloAulaForm} />
      <Route exact isPrivate path="/cursos/modulos/:idCursoModulo/aulas/editar/:id" component={CursoModuloAulaForm} />
      <Route exact isPrivate path="/cursos/instrutores" component={CursoInstrutorList} />
      <Route exact isPrivate path="/cursos/instrutores/adicionar" component={CursoInstrutorForm} />
      <Route exact isPrivate path="/cursos/instrutores/editar/:id" component={CursoInstrutorForm} />
      <Route exact isPrivate path="/cursos/relatorio" component={CursoRelatorio} />

      <Route exact isPrivate path="/configuracoes/perfis" component={ConfiguracaoPerfilList} />
      <Route exact isPrivate path="/configuracoes/perfis/adicionar" component={ConfiguracaoPerfilForm} />
      <Route exact isPrivate path="/configuracoes/perfis/editar/:id" component={ConfiguracaoPerfilForm} />

      <Route exact path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  );
}
