import React, { useEffect, useState } from "react";
import DashboardService from "../service/dashboard.service";

export default function Dashboard() {
  const [data, setData] = useState({});

  useEffect(() => {
    const init = async () => {
      const dashboardService = new DashboardService();
      const data = await dashboardService.getTotalizador();
      setData(data);
    };

    init();
  }, []);

  return (
    <div className="p-grid p-fluid dashboard">
      <div className="p-col-12 p-lg-12">
        <h2>Pedido Adiantamento</h2>
      </div>
      <div className="p-col-12 p-lg-4">
        <div className="card summary card-custom">
          <span className="title">Pendentes</span>
          <span className="count purchases">
            {data?.adiantamento?.pendente || 0}
          </span>
        </div>
      </div>
      <div className="p-col-12 p-lg-4">
        <div className="card summary card-custom">
          <span className="title">Autorizados</span>
          <span className="count visitors">
            {data?.adiantamento?.autorizado || 0}
          </span>
        </div>
      </div>
      <div className="p-col-12 p-lg-4">
        <div className="card summary card-custom">
          <span className="title">Negados</span>
          <span className="count revenue">
            {data?.adiantamento?.rejeitado || 0}
          </span>
        </div>
      </div>
      <div className="p-col-12 p-lg-12">
        <h2>Autorizações Pagamento</h2>
      </div>
      <div className="p-col-12 p-lg-4">
        <div className="card summary card-custom">
          <span className="title">Pendentes</span>
          <span className="count purchases">
            {data?.autorizacao?.pendente || 0}
          </span>
        </div>
      </div>
      <div className="p-col-12 p-lg-4">
        <div className="card summary card-custom">
          <span className="title">Autorizados</span>
          <span className="count visitors">
            {data?.autorizacao?.autorizado || 0}
          </span>
        </div>
      </div>
      <div className="p-col-12 p-lg-4">
        <div className="card summary card-custom">
          <span className="title">Negados</span>
          <span className="count revenue">
            {data?.autorizacao?.rejeitado || 0}
          </span>
        </div>
      </div>
    </div>
  );
}
