import React, { useState } from "react";

const initialState = {
  user: {},
};

export const AppContext = React.createContext(initialState);

export default function Store({ children }) {
  const [state, setState] = useState(initialState);

  function updateUser(value) {
    setState({ ...state, user: value });
  }

  return (
    <AppContext.Provider
      value={{
        user: state.user,
        setUser: (value) => updateUser(value),
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
