import * as Yup from "yup";
import * as messages from "./messages";

export let failedValidation = false;

export const videosSchema = Yup.object().shape({
  idiomas: Yup.array().of(
    Yup.object().shape({
      titulo: Yup.string()
        .max(50, "Você alcançou 50 caracteres, que é o número máximo permitido")
        .test("titulo", messages.REQUIRED, function (value) {
          if (this && this.parent) {
            if (this.parent.idIdioma === 1) {
              failedValidation = value;
              return value;
            } else if (!this.parent.titulo && this.parent.link) {
              failedValidation = false;
              return false;
            } else if (!this.parent.titulo && this.parent.descricao) {
              failedValidation = false;
              return false;
            }
          }
          return true;
        }),
      descricao: Yup.string()
        .max(
          250,
          "(Você alcançou 250 caracteres, que é o número máximo permitido"
        )
        .test("link", messages.REQUIRED, function (value) {
          if (this && this.parent) {
            if (this.parent.idIdioma === 1) {
              return value;
            } else if (!this.parent.descricao && this.parent.titulo) {
              failedValidation = false;
              return false;
            } else if (!this.parent.descricao && this.parent.link) {
              failedValidation = false;
              return false;
            }
          }
          return true;
        }),
      link: Yup.string().test("titulo", messages.REQUIRED, function (value) {
        if (this && this.parent) {
          if (this.parent.idIdioma === 1) {
            failedValidation = value;
            return value;
          } else if (!this.parent.link && this.parent.titulo) {
            failedValidation = false;
            return false;
          } else if (!this.parent.link && this.parent.descricao) {
            failedValidation = false;
            return false;
          }
        }
        return true;
      }),
    })
  ),
});
