/* eslint-disable react-hooks/exhaustive-deps */
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import AlertMenssage from "../../../components/AlertMenssage";
import ButtonSave from "../../../components/ButtonSave";
import CalendarInput from "../../../components/CalendarInput";
import ConfirmationDialog from "../../../components/dialogs/confirmation-dialog";
import DropdownInput from "../../../components/DropdownInput";
import ExpositorMenu from "../../../components/ExpositorMenu";
import ProporcaoImagemDialogo from "../../../components/ProporcaoImagemDialogo";
import TextareaInput from "../../../components/TextareaInput";
import TextInput from "../../../components/TextInput";
import AlertService from "../../../service/alert.service";
import EditionService from "../../../service/edition.service";
import EventService from "../../../service/event.service";
import ExpositoresService from "../../../service/expositores.service";
import LanguagesService from "../../../service/language.service";
import QueryOptions from "../../../utils/query-options";
import {
  eventoSchemaExpositor,
  failedValidationEventExpositor,
} from "../../../validations/event-expositor.validation";
import { languageFields } from "../../Products/ProductsForm/idioma";
import EventsImages from "../EventImage/index";
import "./style.scss";

export const languageEventFields = {
  titulo: "",
  descricao: "",
  idIdioma: "",
};

export default function ExpositorEventForm() {
  const { id, idExpositor } = useParams();
  const [action, setAction] = useState("create");
  const [currentLanguage, setCurrentLanguage] = useState(1);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [languages, setLanguages] = useState([]);
  const [editions, setEditions] = useState([]);
  const [loadingInfo, setLoadingInfo] = useState(true);

  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);
  const [edicaoAtiva, setEdicaoAtiva] = useState(false);
  const [event, setEvent] = useState();
  const [expositor, setExpositor] = useState();
  const _eventService = new EventService();

  useEffect(
    (_eventService = new EventService(), event) => {
      async function getEvent(languages) {
        setAction("edit");
        const event = await _eventService.read(id);
        let idiomas = [];
        languages.forEach((element) => {
          idiomas.push({
            ...languageFields,
            idIdioma: element.id,
          });
        });

        const idIdiomas = [];
        event.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

        const idiomasFaltantes = [];
        idiomas.forEach((item) => {
          if (!idIdiomas.includes(item.idIdioma)) {
            idiomasFaltantes.push(item);
          }
        });

        const allLanguages = [...event.idiomas, ...idiomasFaltantes].sort(
          (v1, v2) => v1.idIdioma - v2.idIdioma
        );

        setEvent({ ...event, idiomas: allLanguages });
        setLoadingInfo(false);
      }

      async function init() {
        const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
        const _edicaoService = new EditionService();
        const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
          queryOptionsEdicaoAtiva
        );

        const _expositoresService = new ExpositoresService();
        const expositor = await _expositoresService.read(idExpositor);
        setExpositor(expositor);

        const _languagesService = new LanguagesService();
        const languages = await _languagesService.list();

        let idiomas = [];
        languages.forEach((element) => {
          idiomas.push({
            ...languageEventFields,
            idIdioma: element.id,
          });
        });

        const eventFields = {
          dataInicio: "",
          dataFim: "",
          urlVideo: "",
          eventoCriadoPeloExpositor: true,
          idEdicao: edicaoAtual.id,
          idExpositor: idExpositor,
          idiomas: [languageEventFields],
        };

        setEvent({ ...eventFields, idiomas });

        const _editionService = new EditionService();
        const editions = await _editionService.list();
        setEditions(editions);
        editions.forEach((element) => {
          if (!element.disabled) {
            setEdicaoAtiva(element.value);
          }
        });

        setLanguages(languages);
        if (id) {
          getEvent(languages);
        } else {
          setAction("create");
          setLoadingInfo(false);
        }

        setLoading(false);
      }
      init();
    },
    [id, edicaoAtiva]
  );

  async function createEvent(values) {
    setLoading(true);
    const data = { ...values };
    data.idiomas = data.idiomas.filter(
      (idioma) => idioma.titulo && idioma.descricao
    );
    if (action === "create") {
      data.idExpositor = idExpositor;

      await _eventService
        .create(data)
        .then((response) => {
          AlertService.success("Evento criado com sucesso");
          history.push(
            `/expositores/${idExpositor}/eventos-expositor/editar/${response.id}`
          );
        })
        .catch(({ response }) => {
          AlertService.warn(response.data.msg);
          setLoading(false);
        });
    } else {
      await _eventService
        .update(data)
        .then((response) => {
          let idiomas = [];
          languages.forEach((element) => {
            idiomas.push({
              ...languageEventFields,
              idIdioma: element.id,
            });
          });

          const idIdiomas = [];
          response.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

          const idiomasFaltantes = [];
          idiomas.forEach((item) => {
            if (!idIdiomas.includes(item.idIdioma)) {
              idiomasFaltantes.push(item);
            }
          });

          const allLanguages = [...response.idiomas, ...idiomasFaltantes].sort(
            (v1, v2) => v1.idIdioma - v2.idIdioma
          );

          setEvent({ ...response, idiomas: allLanguages });
          AlertService.success("Evento atualizado com sucesso");
        })
        .catch(({ response }) => AlertService.warn(response.data.msg))
        .finally(() => {
          setLoading(false);
        });
    }
  }

  async function handleDelete() {
    await _eventService.delete(id);
    history.push(
      `/expositores/editar/${idExpositor}/eventos-expositor?acao=deletado`
    );
    setDisplayConfirmationDialog(false);
  }

  function selectLanguage(item) {
    setCurrentLanguage(item.id);
  }

  return (
    <div className="p-grid">
      <ConfirmationDialog
        header="Excluir esse item vai apagar todas as informações"
        action="Excluir mesmo assim?"
        display={displayConfirmationDialog}
        onClose={() => setDisplayConfirmationDialog(false)}
        onConfirm={handleDelete}
      />

      {loadingInfo && (
        <div className="loading-info-container">
          <lottie-player
            src="https://assets4.lottiefiles.com/packages/lf20_58vkk48j.json"
            background="transparent"
            speed="1"
            style={{ width: "500px" }}
            loop
            autoplay
          />
        </div>
      )}

      <div className="p-col-12" hidden={loadingInfo}>
        <ExpositorMenu activeItem={3} expositor={expositor} />

        <div className="card">
          <Formik
            enableReinitialize={true}
            validationSchema={eventoSchemaExpositor}
            initialValues={event}
            onSubmit={(values) => {
              createEvent(values);
            }}
          >
            {(props) => {
              return (
                <Form autoComplete="off">
                  <h1>
                    {action === "create"
                      ? "Adicionar um novo evento para o expositor"
                      : "Editar evento de um expositor"}
                  </h1>
                  <hr className="hr-header" />

                  <div className="p-grid" style={{ marginLeft: "0px" }}>
                    {languages.map((item, index) => (
                      <div key={index}>
                        <button
                          className={
                            item.id === currentLanguage
                              ? "p-button-dash"
                              : "p-button-teste"
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            selectLanguage(item);
                          }}
                        >
                          {item.nome}
                        </button>
                      </div>
                    ))}
                  </div>

                  <div className="p-grid">
                    <FieldArray
                      name="idiomas"
                      render={(arrayHelpers) => (
                        <div className="p-col-12">
                          {props?.values?.idiomas?.map((item, index) => (
                            <div
                              key={index}
                              hidden={!(item.idIdioma === currentLanguage)}
                              style={{ marginLeft: "-5px", marginTop: "16px" }}
                            >
                              <TextInput
                                name={`idiomas[${index}].titulo`}
                                classes="p-col-12 p-md-12 p-lg-8"
                                className="input"
                                label="Título"
                                required={
                                  index === 0 || (item.descricao && index > 0)
                                }
                                maxLength={50}
                                quantidadeCaracteres={50}
                              />

                              <TextareaInput
                                name={`idiomas[${index}].descricao`}
                                classes="p-col-12 p-md-12 p-lg-8"
                                className="input"
                                label="Descrição"
                                required={
                                  index === 0 || (item.titulo && index > 0)
                                }
                                maxLength={160}
                                quantidadeCaracteres={160}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    />

                    <div
                      style={{ display: "flex", flexDirection: "column" }}
                      className="p-col-12 p-md-4 p-lg-2"
                    >
                      <label>
                        Data do início <span className="required-hash">*</span>
                      </label>
                      <CalendarInput
                        name="dataInicio"
                        className="input"
                        selected={props.values?.dataInicio}
                        onChange={(e) => {
                          props.setFieldValue("dataInicio", e);
                        }}
                        maxDate={props.values?.dataFim}
                      />
                    </div>

                    <div
                      style={{ display: "flex", flexDirection: "column" }}
                      className="p-col-12 p-md-4 p-lg-2"
                    >
                      <label>
                        Data do fim <span className="required-hash">*</span>
                      </label>
                      <CalendarInput
                        name="dataFim"
                        className="input"
                        selected={props.values?.dataFim}
                        onChange={(e) => {
                          props.setFieldValue("dataFim", e);
                        }}
                        minDate={props.values?.dataInicio}
                      />
                    </div>

                    <div
                      style={{ display: "flex", flexDirection: "column" }}
                      className="p-col-12 p-md-4 p-lg-2"
                    >
                      <label>
                        Url do vídeo <span className="required-hash">*</span>
                      </label>
                      <TextInput name="urlVideo" className="input" />
                    </div>

                    <div
                      style={{ display: "flex", flexDirection: "column" }}
                      className="p-col-12 p-md-4 p-lg-2"
                    >
                      <label>
                        Edição <span className="required-hash">*</span>
                      </label>
                      <DropdownInput
                        name="idEdicao"
                        options={editions}
                        value={edicaoAtiva}
                        disabled={true}
                      />
                    </div>

                    {action === "edit" && (
                      <div className="p-col-12 p-md-4 p-lg-8">
                        <h3>Imagem do evento</h3>
                        <ProporcaoImagemDialogo
                          desktopEMobile={true}
                          desktopEMobileWidth={300}
                          desktopEMobileHeight={300}
                        />
                        <hr />
                        <EventsImages
                          languages={languages}
                          width={300}
                          height={300}
                          form={props}
                        />
                      </div>
                    )}
                  </div>

                  <div className="btns-inline" style={{ marginTop: "14px" }}>
                    <div className="btn-medium">
                      <ButtonSave
                        label={action === "create" ? "Adicionar" : "Atualizar"}
                        loading={loading}
                        type="submit"
                        onClick={() => {
                          if (!failedValidationEventExpositor) {
                            AlertService.warn(
                              "Verifique se os campos obrigatórios de todos os idiomas foram preenchidos"
                            );
                          }
                          if (Object.keys(props.errors).length) {
                            AlertService.warn(
                              "Verifique se todos os campos obrigatórios foram preenchidos corretamente"
                            );
                          }
                        }}
                      />
                    </div>

                    <div className="btn-medium">
                      {action === "edit" && (
                        <button
                          className="button-delete"
                          label="Excluir"
                          styles="p-button-secondary"
                          onClick={(e) => {
                            e.preventDefault();
                            setDisplayConfirmationDialog(true);
                          }}
                        >
                          Excluir
                        </button>
                      )}
                    </div>

                    <div className="btn-medium">
                      <button
                        className="button-cancel"
                        onClick={() => {
                          history.push(
                            `/expositores/editar/${idExpositor}/eventos-expositor`
                          );
                        }}
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <AlertMenssage />
        </div>
      </div>
    </div>
  );
}
