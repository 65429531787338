import PhoneTypeSerializer from "./phone-type.serializer";
import EntityTypeSerializer from "./entity-type.serializer";
import unMask from "../../utils/unmask";

export default class PhoneSerialier {
  constructor() {
    this.phoneTypeSerializer = new PhoneTypeSerializer();
    this.entityType = new EntityTypeSerializer();
  }
  fromJson(json) {
    const phone = {};
    Object.assign(
      phone,
      json.id_telefone && { id: json.id_telefone },
      json.ddi && { ddi: json.ddi },
      json.ddd && { ddd: json.ddd },
      json.telefone && { phone: json.telefone },
      json.ramal && { branch: json.ramal },
      json.telefone_tipo && {
        phoneType: this.phoneTypeSerializer.fromJson(json.telefone_tipo),
        type: json.telefone_tipo.id_telefone_tipo,
      },
      json.id_entidade && { idEntity: json.id_entidade },
      json.entidade && { entity: this.EntityTypeSerializer(json.entidade) }
    );

    return phone;
  }

  toJson(phone) {

    const phoneToJson = {};
    Object.assign(
      phoneToJson,
      phone.id && { id_telefone: phone.id },
      phone.ddi &&
        unMask(phone.ddi) && {
          ddi: unMask(phone.ddi),
        },

      phone.phone &&
        unMask(phone.phone) && {
          telefone: unMask(phone.phone),
        },
      phone.branch && { ramal: phone.branch },
      phone.type && { id_telefone_tipo: phone.type },
      phone.ddd &&
        unMask(phone.ddd) && {
          ddd: unMask(phone.ddd),
        }
    );

    return phoneToJson;
  }
}
