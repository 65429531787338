export default class PerfilEmpresaIdiomasSerializer {
  fromJson(json) {
    const perfilEmpresaIdioma = {};

    Object.assign(
      perfilEmpresaIdioma,
      json.id_perfil_empresa && {
        idPerfilEmpresa: json.id_perfil_empresa,
        value: json.id_perfil_empresa,
      },
      json.perfil && { perfil: json.perfil, label: json.perfil }
    );

    return perfilEmpresaIdioma;
  }
}
