import React, { Component } from "react";
import PhoneTypeService from "../../service/phone-type.service";

import TextInput from "../TextInput";
import DropdownInput from "../DropdownInput";

export const telefoneFields = {
  ddi: "",
  ddd: "",
  phone: "",
  type: "",
  branch: "",
};

export default class TelefoneForm extends Component {
  constructor(props) {
    super(props);
    this._phoneTypeService = new PhoneTypeService();
    this.state = {
      typeItemsSelect: [],
    };
  }

  async componentDidMount() {
    const types = await this._phoneTypeService.list();
    let typeItemsSelect = [];

    types.map((type) =>
      typeItemsSelect.push({
        label: type.type,
        value: type.id,
      })
    );
    this.setState({ typeItemsSelect });
    if (this.props.phones[this.props.index].phoneType) {
      this.props.formikProps.setFieldValue(
        `${this.props.parent}${this.props.index}.type`,
        parseInt(this.props.phones[this.props.index].phoneType.id)
      );
    }
  }

  render() {
    const { index, arrayHelpers, parent } = this.props;
    const phoneType = this.props.arrayHelpers.form.values.phones[index].type;
    const campoObrigatorio =
      this?.props?.formikProps?.values.phones[index]?.ddi === "55_";

    return (
      <div className="p-grid">
        <div className="p-col-12 p-lg-3 p-md-4 p-sm-3">
          <label>
            Tipo{" "}
            {this.props.requiredHashInFields && (
              <span className="required-hash"> *</span>
            )}
          </label>
          <DropdownInput
            placeholder="Selecione"
            name={`${parent}${index}.type`}
            options={this.state.typeItemsSelect}
            disabled={this.props.disabledForm}
          />
        </div>

        <div className="p-col-12 p-lg-2 p-md-7 p-sm-6">
          <label>
            DDI{" "}
            {this.props.requiredHashInFields && (
              <span className="required-hash">*</span>
            )}
          </label>
          <TextInput
            name={`${parent}${index}.ddi`}
            disabled={this.props.disabledForm}
            className="input"
            mask="999"
          />
        </div>

        <div className="p-col-12 p-lg-2 p-md-7 p-sm-6">
          <label>
            DDD {campoObrigatorio && <span className="required-hash">*</span>}
          </label>
          <TextInput
            name={`${parent}${index}.ddd`}
            mask="99"
            disabled={this.props.disabledForm}
            className="input"
          />
        </div>

        <div className="p-col-12 p-lg-3 p-md-5 p-sm-8">
          <label>
            Telefone{" "}
            {this.props.requiredHashInFields && (
              <span className="required-hash">*</span>
            )}
          </label>
          <TextInput
            name={`${parent}${index}.phone`}
            mask={phoneType === 2 ? "99999-9999" : "9999-9999"}
            disabled={this.props.disabledForm}
            className="input"
          />
        </div>

        <div className="p-col-12 p-lg-3 p-md-6 p-sm-9">
          <TextInput
            label="Ramal"
            name={`${parent}${index}.branch`}
            disabled={this.props.disabledForm}
            className="input"
          />
        </div>
        {!this.props.removeBtn && this.props.index > 0 ? (
          <div
            className="p-col-12 p-lg-1 p-md-1 p-sm-6 icon-input-space"
            style={{ paddingTop: 14 }}
          >
            <i className="trashIcon" onClick={() => arrayHelpers.remove(index)}>
              <img
                src={require("../../assets/icons/deleteIconColor.svg")}
                alt="Imagem de um lixeiro; clique nessa imagem para excluir o endereço"
              />
            </i>
          </div>
        ) : null}
      </div>
    );
  }
}
