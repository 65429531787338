import React from "react";
import { Messages } from "primereact/messages";
import { Message } from "primereact/message";
import { animateScroll } from "react-scroll";

export default function AlertMenssage({ to = null }) {
  return (
    <Messages
      ref={(el) => {
        Message.messages = el;
        if (el && el.state && el.state.messages.length) {
          if (to === "top") {
            animateScroll.scrollToTop();
          } else {
            animateScroll.scrollToBottom();
          }
        }
      }}
    ></Messages>
  );
}
