import React, { useState } from "react";
import { format } from "date-fns";

export default function EventoDoDia({ eventosDestaquesDoDia }) {
  const [modalAberto, setModalAberto] = useState(false);
  const [time] = useState(new Date().getTime());

  return (
    <>
      <div className="sessao-eventos-do-dia">
        <div className="container">
          <div className="eventos-do-dia-title-container"></div>

          <div style={{ textAlign: "center" }}>
            <h1>
              Eventos destaques do dia {eventosDestaquesDoDia[0]?.dataInicioStr}
            </h1>
            <hr />
          </div>
          <div
            className="p-grid"
            style={{ flexWrap: "wrap", justifyContent: "space-around" }}
          >
            {eventosDestaquesDoDia?.map((evento) => (
              <>
                <div
                  style={{
                    display: "flex",
                    maxWidth: "45%",
                    marginTop: "20px",
                    width: "45%",
                    backgroundColor: "#000",
                    color: "white",
                    border: "1px solid #009974",
                    borderRadius: "2px",
                  }}
                >
                  <div
                    className="card-eventos"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setModalAberto(!modalAberto);
                    }}
                  >
                    <div>
                      <div>
                        <div
                          style={{
                            width: "100%",
                            height: "280px",
                            backgroundSize: "cover",
                            borderTopRightRadius: "2px",
                            borderTopLeftRadius: "2px",
                            backgroundRepeat: "no-repeat",
                            backgroundImage: `url(${process.env.REACT_APP_API_URL}eventos/${evento?.filename}/display?${time})`,
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: "0px 20px 0px 20px",
                      }}
                    >
                      <div>
                        <h3 style={{ minHeight: "100px" }}>
                          {" "}
                          {evento?.idiomas[0].titulo}
                        </h3>
                      </div>

                      <p>
                        {format(evento?.dataInicio, "dd")} de{" "}
                        {format(evento?.dataInicio, "MMMM")} às{" "}
                        {format(evento?.dataInicio, "H:mm")}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
