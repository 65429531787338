import CampoCustomizadoItemIdiomaSerializer from "./campo-customizado-item-idioma.serializer";
import CampoCustomizadoItemIndividualSerializer from "./campo-customizado-item-individual.serializer";

export default class CampoCustomizadoItemSerializer {
  constructor() {
    this.campoCustomizadoIdiomasSerializer =
      new CampoCustomizadoItemIdiomaSerializer();
    this.campoCustomizadoIndividualSerializer =
      new CampoCustomizadoItemIndividualSerializer();
  }

  fromJson(json) {
    const campoCustomizadoItemFromJson = {};

    Object.assign(
      campoCustomizadoItemFromJson,
      json.id_campo_customizado && {
        idCampoCustomizado: json.id_campo_customizado,
      },
      json.id_campo_item_customizado && {
        idCampoItemCustomizado: json.id_campo_item_customizado,
      },
      json.idiomas
        ? {
            idiomas: json.idiomas.map((item) =>
              this.campoCustomizadoIdiomasSerializer.fromJson(item)
            ),
          }
        : { idiomas: [] }
    );

    return campoCustomizadoItemFromJson;
  }

  toJson(campoCustomizado) {
    const campoCustomizadoToJson = {};

    Object.assign(
      campoCustomizadoToJson,
      campoCustomizado.dados && {
        dados: campoCustomizado.dados.map((item) =>
          this.campoCustomizadoIndividualSerializer.toJson(item)
        ),
      }
    );

    return campoCustomizadoToJson;
  }
}
