import React, { useState } from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router";
import * as Yup from "yup";

import UserService from "../../../service/user.service";

import TextInput from "../../../components/TextInput";
// import bgLogo from "../../../assets/images/bg-login.svg";

import * as messages from "../../../validations/messages";

export default function Forgot() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const userService = new UserService();

  async function onSubmit(data) {
    setLoading(true);

    userService
      .forgot(data)
      .then((response) => {
        setEmail(data.email);
        setError(false);
        setLoading(false);
      })
      .catch(({ response }) => {
        setEmail("");
        setError(true);
        setLoading(false);
      });
  }

  return (
    <div id="page-login-background">
      <div id="page-login">
        <div className="container">
          <div className="p-grid">
            <div className="p-xl-6 p-lg-6 p-md-6 p-sm-12 p-xs-12">
              <div className="flex-logo-box">
                {/* <img src={bgLogo} alt="logo da expo revestir" /> */}
              </div>
            </div>
            <div className="p-xl-1 p-lg-1 p-md-1 p-sm-1 p-xs-1"></div>
            <div
              className="p-xl-4 p-lg-4 p-md-4 p-sm-10 p-xs-10"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="row-right">
                <div div className="flex-box-login">
                  <div className="box-login">
                    <div className="boas-vindas">
                      <h1 className="title-boas-vindas">Esqueceu sua senha?</h1>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        marginBottom: 20,
                        fontFamily: "Raleway",
                        fontSize: "17px",
                        padding: 20,
                        color: "#000",
                      }}
                    >
                      {" "}
                      Digite seu email cadastrado para receber instruções de
                      recuperação de senha.
                    </div>

                    {error && (
                      <div
                        style={{
                          textAlign: "center",
                          marginBottom: 20,
                          fontFamily: "Raleway",
                          color: "red",
                        }}
                      >
                        Por favor, tente novamente.
                      </div>
                    )}

                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={Yup.object({
                        email: Yup.string()
                          .email(messages.EMAIL)
                          .required(messages.REQUIRED),
                      })}
                      onSubmit={(values) => {
                        onSubmit(values);
                      }}
                    >
                      <Form
                        className="form"
                        autoComplete="off"
                        style={{ maxWidth: "70%" }}
                      >
                        <div className="box">
                          <TextInput
                            name="email"
                            placeholder="E-mail"
                            className="box-input"
                          />
                        </div>

                        <button
                          loading={loading}
                          type="submit"
                          styles="w-all"
                          className="button-enter"
                          style={{ marginTop: "20px" }}
                        >
                          {" "}
                          Enviar
                        </button>

                        {email && (
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: 20,
                              color: "#000",
                            }}
                          >
                            Caso o email <strong>{email}</strong> esteja
                            cadastrado no sistema, uma mensagem será enviada ao
                            endereço informado com instruções para recuperação
                            da senha.
                          </div>
                        )}
                        <div
                          className="text-link"
                          onClick={() => history.push("/entrar")}
                        >
                          Voltar para login
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-xl-1 p-lg-1 p-md-1 p-sm-1 p-xs-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
