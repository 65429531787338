import CampoCustomizadoItemIdiomaSerializer from "./campo-customizado-item-idioma.serializer";

export default class CampoCustomizadoItemIndividualSerializer {
  constructor() {
    this.CampoCustomizadoItemIdiomaSerializer =
      new CampoCustomizadoItemIdiomaSerializer();
  }

  toJson(campoCustomizadoIndividual) {
    const campoCustomizadoIndividualToJson = {};

    Object.assign(
      campoCustomizadoIndividualToJson,
      campoCustomizadoIndividual.idCampoCustomizado && {
        id_campo_customizado: campoCustomizadoIndividual.idCampoCustomizado,
      },
      campoCustomizadoIndividual.idCampoItemCustomizado && {
        id_campo_item_customizado:
          campoCustomizadoIndividual.idCampoItemCustomizado,
      },
      campoCustomizadoIndividual.idiomas && {
        idiomas: campoCustomizadoIndividual.idiomas.map((item) =>
          this.CampoCustomizadoItemIdiomaSerializer.toJson(item)
        ),
      }
    );

    return campoCustomizadoIndividualToJson;
  }
}
