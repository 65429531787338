import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

export default function BoxUploadNote({
  file,
  setFile,
  loading,
  nameFile,
  onDownload,
  index,
  isList = true,
  styles = null,
  acceptList = "application/pdf,image/x-png,image/gif,image/jpeg",
  isObject = false,
}) {
  const [description, setDescription] = useState(
    "Clique ou arraste sua imagem aqui."
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length) {
        if (isObject) {
          setFile(acceptedFiles);
        } else if (isList) {
          setFile(`documents.${index}`, acceptedFiles);
        } else {
          setFile(`document`, acceptedFiles);
        }
      } else {
        setDescription('Apenas arquivos com a extenção ".jpeg"');
      }
    },
    [setFile, index, isList, isObject]
  );

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: acceptList,
      disabled: loading,
    });
  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  let box = (
    <div
      {...getRootProps()}
      className="dropzone upload-nota"
      style={{ ...styles }}
    >
      <input id="input-imagem" {...getInputProps()} />
      {!file ? (
        <>
          {isDragActive ? <span>Solte aqui</span> : <span>{description}</span>}
        </>
      ) : (
        <>{files}</>
      )}
    </div>
  );

  if (nameFile) {
    box = (
      <div className="actions" style={{ marginTop: 5 }}>
        <span className="link" onClick={() => onDownload(nameFile)}>
          {nameFile}
        </span>
      </div>
    );
  }

  return <div key={index}>{box}</div>;
}
