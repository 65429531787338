export default class CargoIdiomasSerializer {
  fromJson(json) {
    const cargoIdiomasSerializer = {};

    Object.assign(
      cargoIdiomasSerializer,
      json.id_usuario_cargo && {
        idUsuarioCargo: json.id_usuario_cargo,
        value: json.id_usuario_cargo,
      },
      json.cargo && { cargo: json.cargo, label: json.cargo }
    );

    return cargoIdiomasSerializer;
  }
}
