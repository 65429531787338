import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import ButtonSave from "../../../components/ButtonSave";
import AlertDialog from "../../../components/dialogs/alert-dialog";
import ImageDefault from "../../../components/ImageDefault";
import EventoImagemService from "../../../service/evento-imagem.service";

export default function EventLogoImage({
  index,
  arquivos,
  setArquivos,
  form,
  idEventoLogotipo,
  width,
  height,
  item,
  atualizarImagens,
}) {
  const [displayDialog, setDisplayDialog] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayDialogInvalidImage, setDisplayDialogInvalidImage] = useState();

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 150,
    height: 150,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    getFilesFromEvent: async (event) => {
      const files = event.target.files || event.dataTransfer.files;
      const promises = [];

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const promise = new Promise((resolve, reject) => {
          const image = new Image();
          let url = "";
          image.onload = function () {
            file.width = image.width;
            file.height = image.height;
            resolve(file);
          };
          url = URL.createObjectURL(file);
          image.src = url;
        });
        promises.push(promise);
      }

      const data = await Promise.all(promises);

      if (width && height) {
        if (data[0].width === width && data[0].height === height) {
          data.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );
          const arqs = [...arquivos];
          arqs.splice(index, 1, { id: idEventoLogotipo, index, file: data[0] });
          setArquivos(arqs);
        } else {
          setDisplayDialogInvalidImage(true);
        }
      } else {
        data.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

        const arqs = [...arquivos];
        arqs.splice(index, 1, { id: idEventoLogotipo, index, file: data[0] });
        setArquivos(arqs);
      }

      return data;
    },
  });

  const thumbs = arquivos.length > index && arquivos[index]?.file && (
    <div style={thumb} key={arquivos[index].file.name}>
      <div style={thumbInner}>
        <img src={arquivos[index].file.preview} style={img} alt="upload" />
      </div>
    </div>
  );

  async function onUpload() {
    const _eventoService = new EventoImagemService();
    setLoading(true);
    await _eventoService.EventoLogoImagem(idEventoLogotipo, files).then(() => {
      document.getElementById("image-card").style.display = "flex";
    });

    setFiles([]);
    setLoading(false);
  }

  return (
    <div>
      <Dialog
        header="Editar"
        visible={displayDialog}
        style={{ width: "50vw" }}
        onHide={() => setDisplayDialog(false)}
        dismissableMask
      ></Dialog>

      <AlertDialog
        display={displayDialogInvalidImage}
        textList={[
          "Dimensão da imagem precisa ser:",
          `Largura ${width}px`,
          `Altura ${height}px`,
        ]}
        onClose={() => setDisplayDialogInvalidImage(false)}
      />

      <section className="container">
        <div
          className="box-upload"
          {...getRootProps({ className: "dropzone" })}
        >
          <input {...getInputProps()} />
          <p>Arraste e solte a imagem aqui ou clique para selecionar.</p>
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>

        {files.length ? (
          <ButtonSave
            label="Adicionar Imagens"
            loading={loading}
            disabled={!files.length}
            onClick={onUpload}
          />
        ) : null}

        {item?.image_filename && (
          <div
            className="m-t-20"
            id="image-card"
            style={{
              display: "flex",
              gap: 20,
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <div className="image-card" style={{ marginBottom: "16px" }}>
              <div className="image">
                <ImageDefault
                  urlImage={`${process.env.REACT_APP_API_URL}eventos/logotipos/${idEventoLogotipo}/display?${atualizarImagens}`}
                />
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
