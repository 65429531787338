import * as Yup from "yup";
import * as messages from "./messages";
import "./customs.validation";

function validaPais(pais, campo) {
  if (pais?.id === 1) {
    return (
      campo !== "" &&
      campo !== null &&
      campo !== undefined &&
      campo !== "________" &&
      campo !== "_____-___"
    );
  } else {
    return true;
  }
}

export const addressSchema = Yup.object().shape({
  idCountry: Yup.object().required(messages.REQUIRED),
  foreignCity: Yup.string().test(
    "foreignCity",
    messages.REQUIRED,
    function (value) {
      if (this.parent?.idCountry?.id !== 1) {
        return value;
      } else {
        return true;
      }
    }
  ),
  foreignState: Yup.string().test(
    "foreignState",
    messages.REQUIRED,
    function (value) {
      if (this.parent?.idCountry?.id !== 1) {
        return value;
      } else {
        return true;
      }
    }
  ),

  zipCode: Yup.string().test("zipCode", messages.REQUIRED, function (value) {
    return validaPais(this.parent.idCountry, value);
  }),

  idState: Yup.string().test("idState", messages.REQUIRED, function (value) {
    if (this.parent?.idCountry?.id === 1) {
      return value;
    } else {
      return true;
    }
  }),
  idCity: Yup.string().test("idCity", messages.REQUIRED, function (value) {
    if (this.parent?.idCountry?.id === 1) {
      return value;
    } else {
      return true;
    }
  }),

  address: Yup.string().required(messages.REQUIRED),

  neighborhood: Yup.string().required(messages.REQUIRED),

  number: Yup.string().required(messages.REQUIRED),
});
