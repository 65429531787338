import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import CampoCustomizado from "../../service/campo-customizado.service";

import "./styles.scss";

export default function CamposCustomizados() {
  const [camposCustomizados, setCamposCustomizados] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function init() {
      const _campoCustomizadoService = new CampoCustomizado();
      const campoCustomizadoResponse = await _campoCustomizadoService.list();
      setCamposCustomizados(campoCustomizadoResponse);
    }

    init();
  }, []);

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <div className="p-grid">
            <div className="p-col-9 p-lg-9 p-md-9 p-sm-9">
              <h1 className="h1-container">Campos Customizados</h1>
            </div>
          </div>
          <hr className="hr-header" />

          <div className="p-grid">
            {camposCustomizados.map((campo) => (
              <div
                key={campo.idCampoCustomizado}
                className="p-col-3 p-lg-3 p-md-3 p-sm-3 campo-card-container"
                onClick={() =>
                  history.push(`/campos-customizados/form/${campo.campo}`)
                }
              >
                <span>{campo.campo}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
