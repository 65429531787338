import ProductsLanguagesImagesSerializer from "./products-languages-images.serializer";

export default class ProductsImagesSerializer {
  constructor() {
    this.productsLanguageImages = new ProductsLanguagesImagesSerializer();
  }

  fromJson(json) {
    const productsImage = {};

    Object.assign(
      productsImage,
      json.id_produto_imagem && { id: json.id_produto_imagem },
      json.id_produto && { idProduto: json.id_produto },
      json.image_filename && { imageFile: json.image_filename },
      json.idiomas
        ? {
            idiomas: json.idiomas.map((item) =>
              this.productsLanguageImages.fromJson(item)
            ),
          }
        : { idiomas: [] }
    );

    if (productsImage?.idiomas.length) {
      productsImage.nome = productsImage.idiomas[0].nome;
      productsImage.descricao = productsImage.idiomas[0].descricao;
    }

    return productsImage;
  }

  toJson(productImage) {
    const productImagesJson = {};

    Object.assign(
      productImagesJson,
      productImage.idiomas && {
        idiomas: productImage.idiomas.map((item) =>
          this.productsLanguageImages.toJson(item)
        ),
      },
      productImage.imageFile && { image_filename: productImage.imageFile },
    );

    return productImagesJson;
  }

  toFormData(files) {
    let formData = new FormData();
    files.forEach((item) => formData.append("files", item));

    return formData;
  }
}
