export default class MetaSerializer {
  fromJson(json) {
    const _meta = {};
    _meta.page = json.page;
    _meta.perPage = json.per_page;
    _meta.totalItems = json.total_items;
    _meta.totalPages = json.total_pages;

    return _meta;
  }
}
