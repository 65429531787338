import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import AlertMenssage from "../../../components/AlertMenssage";
import ButtonSave from "../../../components/ButtonSave";
import ConfirmationDialog from "../../../components/dialogs/confirmation-dialog";
import ExpositorMenu from "../../../components/ExpositorMenu";
import TextareaInput from "../../../components/TextareaInput";
import TextInput from "../../../components/TextInput";
import AlertService from "../../../service/alert.service";
import EditionService from "../../../service/edition.service";
import ExpositoresService from "../../../service/expositores.service";
import LanguagesService from "../../../service/language.service";
import ModerationVideoService from "../../../service/video-moderation.service";
import QueryOptions from "../../../utils/query-options";
import {
  failedValidation,
  videosSchema,
} from "../../../validations/videos.validation";

export const languageVideosFields = {
  titulo: "",
  descricao: "",
  link: "",
  idIdioma: "",
};

export default function ExhibitorVideosForm({ location }) {
  const { idExpositor, id } = useParams();
  const [action, setAction] = useState("create");
  const [currentLanguage, setCurrentLanguage] = useState(1);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);
  const [videos, setVideos] = useState();
  const [expositor, setExpositor] = useState();
  const _videoModerationService = new ModerationVideoService();
  const [loadingInfo, setLoadingInfo] = useState(true);

  useEffect(
    (_videoModerationService = new ModerationVideoService(), videos) => {
      async function getVideos(languages) {
        setAction("edit");
        const videos = await _videoModerationService.read(id);
        let idiomas = [];
        languages.forEach((element) => {
          idiomas.push({
            ...languageVideosFields,
            idIdioma: element.id,
          });
        });

        const idIdiomas = [];
        videos.idiomas.forEach((item) => idIdiomas.push(item.idIdioma));

        const idiomasFaltantes = [];
        idiomas.forEach((item) => {
          if (!idIdiomas.includes(item.idIdioma)) {
            idiomasFaltantes.push(item);
          }
        });

        const allLanguages = [...videos.idiomas, ...idiomasFaltantes].sort(
          (v1, v2) => v1.idIdioma - v2.idIdioma
        );

        setVideos({ ...videos, idiomas: allLanguages });
        setLoadingInfo(false);
      }

      async function init() {
        const queryOptionsEdicaoAtiva = new QueryOptions([["ativa", "ativa"]]);
        const _edicaoService = new EditionService();
        const edicaoAtual = await _edicaoService.carregarEdicaoAtual(
          queryOptionsEdicaoAtiva
        );
        const _expositoresService = new ExpositoresService();
        const expositor = await _expositoresService.read(idExpositor);
        setExpositor(expositor);

        const _languagesService = new LanguagesService();
        const languages = await _languagesService.list();

        let idiomas = [];
        languages.forEach((element) => {
          idiomas.push({
            ...languageVideosFields,
            idIdioma: element.id,
          });
        });

        const videoFields = {
          idExpositor: idExpositor,
          idiomas: [languageVideosFields],
          edicaoAtual: edicaoAtual?.id,
        };

        setVideos({ ...videoFields, idiomas });
        setLanguages(languages);
        if (id) {
          getVideos(languages);
        } else {
          setAction("create");
          setLoadingInfo(false);
        }
      }
      init();
    },
    [id, idExpositor]
  );

  async function createVideos(values) {
    setLoading(true);
    const data = { ...values };
    data.idiomas = data.idiomas.filter(
      (idioma) => idioma.titulo && idioma.descricao && idioma.link
    );

    if (action === "create") {
      data.parentId = idExpositor;

      await _videoModerationService
        .createSub(data)
        .then((response) => {
          history.push(`/expositores/${idExpositor}/videos?acao=criado`);
        })
        .catch(({ response }) => {
          AlertService.warn(response.data.msg);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const data = { ...values };
      data.idiomas = data.idiomas.filter(
        (idioma) => idioma.titulo && idioma.descricao && idioma.link
      );

      await _videoModerationService
        .update(data)
        .then(() => {
          AlertService.success("Vídeo atualizado com sucesso");
        })
        .catch(({ response }) => AlertService.warn(response.data.msg))
        .finally(() => {
          setLoading(false);
        });
    }
  }
  function selectLanguage(item) {
    setCurrentLanguage(item.id);
  }

  async function handleDelete() {
    await _videoModerationService.delete(id);
    history.push(`/expositores/${idExpositor}/videos?acao=deletado`);
    setDisplayConfirmationDialog(false);
  }

  return (
    <div className="p-grid">
      <ConfirmationDialog
        header="Excluir esse item vai apagar todas as informações sobre o vídeo"
        action="Excluir mesmo assim?"
        display={displayConfirmationDialog}
        onClose={() => setDisplayConfirmationDialog(false)}
        onConfirm={handleDelete}
      />

      {loadingInfo && (
        <div className="loading-info-container">
          <lottie-player
            src="https://assets4.lottiefiles.com/packages/lf20_58vkk48j.json"
            background="transparent"
            speed="1"
            style={{ width: "500px" }}
            loop
            autoplay
          />
        </div>
      )}

      <div className="p-col-12" hidden={loadingInfo}>
        <ExpositorMenu activeItem={4} expositor={expositor} />

        <div className="card">
          <Formik
            enableReinitialize={true}
            initialValues={videos}
            validationSchema={videosSchema}
            onSubmit={(values) => {
              createVideos(values);
            }}
          >
            {(props) => {
              return (
                <Form autoComplete="off">
                  <div className="wrapper-form">
                    <h1>Adicionar um novo vídeo</h1>
                    <hr style={{ marginBottom: "22px" }} />
                    <div
                      className="p-grid"
                      style={{ margin: "0px 0px 15px 0px" }}
                    >
                      {languages.map((item, index) => (
                        <div key={index}>
                          <button
                            className={
                              item.id === currentLanguage
                                ? "p-button-dash"
                                : "p-button-teste"
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              selectLanguage(item);
                            }}
                          >
                            {item.nome}
                          </button>
                        </div>
                      ))}
                    </div>
                    <div className="p-grid">
                      <FieldArray
                        name="idiomas"
                        render={(arrayHelpers) => (
                          <div className="p-col-12" style={{ padding: "0px" }}>
                            {props?.values?.idiomas?.map((item, index) => (
                              <div
                                key={index}
                                hidden={!(item.idIdioma === currentLanguage)}
                              >
                                <TextInput
                                  label="Titulo"
                                  name={`idiomas[${index}].titulo`}
                                  classes="p-col-12 p-md-6 p-lg-6"
                                  className="input"
                                  maxLength={50}
                                  quantidadeCaracteres={50}
                                  required={true}
                                />

                                <TextareaInput
                                  label="Descrição"
                                  name={`idiomas[${index}].descricao`}
                                  classes="p-col-12 p-md-4 p-lg-6"
                                  className="input"
                                  maxLength={250}
                                  quantidadeCaracteres={250}
                                  required={true}
                                />

                                <TextInput
                                  label="Link do vídeo"
                                  name={`idiomas[${index}].link`}
                                  classes="p-col-12 p-md-4 p-lg-6"
                                  className="input"
                                  required={true}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      />
                    </div>
                    <div className="btns-inline">
                      <div className="btn-medium">
                        <ButtonSave
                          label={
                            action === "create" ? "Adicionar" : "Atualizar"
                          }
                          loading={loading}
                          type="submit"
                          onClick={() => {
                            if (!failedValidation) {
                              AlertService.warn(
                                "Verifique se os campos obrigatórios de todos os idiomas foram preenchidos"
                              );
                            }
                            if (Object.keys(props.errors).length) {
                              AlertService.warn(
                                "Verifique se todos os campos obrigatórios foram preenchidos corretamente"
                              );
                            }
                          }}
                        />
                      </div>

                      {action === "edit" && (
                        <div className="btn-medium">
                          <button
                            className="button-delete"
                            styles="p-button-secondary"
                            onClick={(e) => {
                              e.preventDefault();
                              setDisplayConfirmationDialog(true);
                            }}
                          >
                            Excluir
                          </button>
                        </div>
                      )}

                      <div className="btn-medium">
                        <button
                          className="button-cancel"
                          onClick={() => {
                            history.push(`/expositores/${idExpositor}/videos`);
                          }}
                        >
                          Voltar
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <AlertMenssage />
        </div>
      </div>
    </div>
  );
}
