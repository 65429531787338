import { Dialog } from "primereact/dialog";
import PropTypes from "prop-types";
import React from "react";

LoadDialog.propTypes = {
  header: PropTypes.string.isRequired,
  display: PropTypes.bool.isRequired,
  onClose: PropTypes.any.isRequired,
};

export default function LoadDialog({ display, onClose, header }) {
  return (
    <Dialog
      visible={display}
      dismissableMask={true}
      style={{ width: "400px" }}
      onHide={() => onClose()}
      closable={false}
    >
      <div style={{ textAlign: "center" }}>{header}</div>
      <div className="loading-info-container" style={{ padding: "unset" }}>
        <lottie-player
          src="https://assets4.lottiefiles.com/packages/lf20_58vkk48j.json"
          background="transparent"
          speed="1"
          style={{ width: "500px" }}
          loop
          autoplay
        />
      </div>
    </Dialog>
  );
}
