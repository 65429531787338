export default class AvertisingdLanguageSerializer {
  fromJson(json) {
    const avertisingdImage = {};
    Object.assign(
      avertisingdImage,
      json.filename && { imageFilename: json.filename },
      json.filename_mobile && { imageFilenameMobile: json.filename_mobile },
      json.titulo && { titulo: json.titulo },
      json.link && { link: json.link },
      json.id_publicidade_idioma && {
        idPublicidadeIdioma: json.id_publicidade_idioma,
      },
      json.id_idioma && { idIdioma: json.idioma.id_idioma },
      json.descricao && { descricao: json.descricao },
      json.filename_home && { filenameHome: json.filename_home },
      json.filename_home_mobile && {
        filenameHomeMobile: json.filename_home_mobile,
      }
    );

    return avertisingdImage;
  }

  toJson(avertisingdImage) {
    const avertisingdImageJson = {};
    Object.assign(
      avertisingdImageJson,
      avertisingdImage.idIdioma && { id_idioma: avertisingdImage.idIdioma },
      avertisingdImage.imageFile && {
        image_filename: avertisingdImage.imageFile,
      },
      avertisingdImage.idPublicidadeIdioma && {
        id_publicidade_idioma: avertisingdImage.idPublicidadeIdioma,
      },
      avertisingdImage.titulo && { titulo: avertisingdImage.titulo },
      avertisingdImage.link ? { link: avertisingdImage.link } : { link: null },
      avertisingdImage.descricao
        ? { descricao: avertisingdImage.descricao }
        : { descricao: null }
    );

    return avertisingdImageJson;
  }

  toFormData(file) {
    let formData = new FormData();
    formData.append("file", file[0]);

    return formData;
  }
}
