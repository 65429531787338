export default class ProdutoTiposIdiomasSerializer {
  fromJson(json) {
    const produtoTiposIdiomas = {
      tipo: json.tipo,
      idIdioma: json.idioma.id_idioma,
    };

    return produtoTiposIdiomas;
  }

  toJson(produtoTiposIdiomas) {
    return {
      id_idioma: produtoTiposIdiomas.idIdioma,
      tipo: produtoTiposIdiomas.tipo,
    };
  }
}
