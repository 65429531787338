import { ptBR } from "date-fns/locale";
import { Form, Formik } from "formik";
import { Accordion, AccordionTab } from "primereact/accordion";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ButtonSave from "../../../components/ButtonSave";
import DropdownInput from "../../../components/DropdownInput";
import LogService from "../../../service/log.service";
import { METODOS } from "../../../utils/constant";

UsuarioLogFilter.propTypes = {
  setFilter: PropTypes.isRequired,
  loading: PropTypes.bool,
};

export default function UsuarioLogFilter({ setFilter, loading = false }) {
  registerLocale("ptBR", ptBR);
  const [metodos, setMetodos] = useState([]);
  const [endpoints, setEndpoints] = useState([]);

  useEffect(() => {
    async function init() {
      const _logService = new LogService();
      const filtrosOpcoes = await _logService.logFiltros();

      setEndpoints(
        filtrosOpcoes.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
      );

      setMetodos(
        METODOS.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
      );
    }

    init();
  }, []);

  function onFilter(values) {
    const data = {};

    Object.assign(
      data,
      values.data_entrada_inicio && {
        data_entrada_inicio: new Date(values.data_entrada_inicio).toISOString(),
      },
      values.data_entrada_fim && {
        data_entrada_fim: new Date(values.data_entrada_fim).toISOString(),
      },
      values.methods && { methods: values.methods },
      values.endpoint && { endpoint: values.endpoint }
    );

    setFilter(data);
  }

  return (
    <Accordion className="m-b-10">
      <AccordionTab header="Filtros">
        <Formik
          enableReinitialize={true}
          initialValues={{}}
          onSubmit={onFilter}
        >
          {(props) => {
            return (
              <Form className="form" autocomplete="off">
                <div className="p-grid">
                  <DropdownInput
                    classes="p-col-12 p-lg-3"
                    label="Método"
                    placeholder="Selecione"
                    name="methods"
                    options={metodos}
                    filter={true}
                  />
                  <DropdownInput
                    classes="p-col-12 p-lg-5"
                    label="Tipos"
                    placeholder="Selecione"
                    name="endpoint"
                    options={endpoints}
                    filter={true}
                  />
                </div>
                <div className="p-grid" style={{ marginLeft: 0 }}>
                  <ButtonSave label="Filtrar" loading={loading} type="submit" />

                  <div style={{ marginLeft: 15 }}>
                    <ButtonSave
                      label="Limpar"
                      styles="p-button-secondary"
                      loading={loading}
                      onClick={() => {
                        props.resetForm();
                        setFilter({});
                      }}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </AccordionTab>
    </Accordion>
  );
}
