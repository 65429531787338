export default class EventLanguageSerializer {
  fromJson(json) {
    const eventLanguage = {};

    Object.assign(
      eventLanguage,
      json.titulo && { titulo: json.titulo },
      json.descricao && { descricao: json.descricao },
      json.id_idioma && { idIdioma: json.id_idioma },
      json.texto_descritivo && { textoDescritivo: json.texto_descritivo }
    );

    return eventLanguage;
  }

  toJson(eventLanguage) {
    return {
      titulo: eventLanguage.titulo,
      descricao: eventLanguage.descricao,
      id_idioma: eventLanguage.idIdioma,
      texto_descritivo: eventLanguage.textoDescritivo,
    };
  }
}
