import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Checkbox } from "primereact/checkbox";
import Authentication from "../../../service/authentication.service";
import TextInput from "../../../components/TextInput";
import "./styles.scss";
import { AppContext } from "../../../store";
import iconLoginEmail from "../../../assets/icons/email.svg";
import iconLoginPassword from "../../../assets/icons/lock.svg";
import EditionService from "../../../service/edition.service";
// import { format } from "date-fns";
// import { ptBR } from "date-fns/locale";

export default function Login() {
  const [userLogin, setUserLogin] = useState("");
  const history = useHistory();
  const [hasError, setHasError] = useState("");
  const [errorMenssage, setErrorMenssage] = useState("");
  const [loading, setloading] = useState(false);
  const [isRememberLogin, setIsRememberLogin] = useState(false);
  const { setUser } = useContext(AppContext);
  /* eslint-disable-next-line no-unused-vars */
  const [edicaoAtiva, setEdicaoAtiva] = useState();

  function saveLogin(remember, login) {
    if (remember) {
      localStorage.setItem("userLogin", login);
      localStorage.setItem("rememberUserLogin", true);
    } else {
      localStorage.removeItem("userLogin");
      localStorage.removeItem("rememberUserLogin");
    }
  }

  async function handleSignIn(data) {
    setErrorMenssage("");

    try {
      setloading(true);
      await new Authentication().singIn(data.user, data.password);

      if (
        Authentication._user.profile &&
        Authentication._user.profile.id === 4 // Não trocar deve ser sempre 4
      ) {
        throw new Error("Acesso negado.");
      }

      setUser(Authentication._user);
      saveLogin(isRememberLogin, data.user);
      setHasError(false);
      history.push("/painel-controle");
    } catch (error) {
      setloading(false);

      if (error.response && error.response.data) {
        setHasError(true);
        setErrorMenssage(error.response.data.msg);
      } else {
        setErrorMenssage(
          error.message || "Ocorreu um erro ao tentar realizar o login."
        );
      }
    }
  }

  const errorMessage = (
    <div className="error-menssage">
      Login ou Senha Incorreto. Tente Novamente
    </div>
  );

  useEffect(() => {
    if (localStorage.getItem("rememberUserLogin")) {
      setUserLogin(localStorage.getItem("userLogin"));
      setIsRememberLogin(true);
    }

    async function init() {
      const _editionService = new EditionService();
      const edicaoAtivaResponse = await _editionService.carregarEdicaoAtiva();

      setEdicaoAtiva(edicaoAtivaResponse);
    }

    init();
  }, []);

  return (
    <div id="page-login-background">
      <div id="page-login">
        <div className="container">
          <div className="p-grid">
            <div className="p-xl-6 p-lg-6 p-md-6 p-sm-12 p-xs-12">
              <div className="flex-logo-box">
                {/* {edicaoAtiva && (
                  <div className="container-info-visitante">
                    <p>
                      {format(
                        new Date(
                          new Date(edicaoAtiva?.data_inicio).valueOf() +
                            new Date(
                              edicaoAtiva?.data_inicio
                            ).getTimezoneOffset() *
                              60 *
                              1000
                        ),
                        "d"
                      )}
                      –{" "}
                      {format(
                        new Date(
                          new Date(edicaoAtiva?.data_fim).valueOf() +
                            new Date(
                              edicaoAtiva?.data_fim
                            ).getTimezoneOffset() *
                              60 *
                              1000
                        ),
                        "d"
                      )}
                      <br />
                      <span className="mes-edicao">
                        {format(new Date(edicaoAtiva?.data_inicio), "MMMM", {
                          locale: ptBR,
                        })}
                      </span>
                      <br />
                      2024
                    </p>

                    <br />
                    <br />

                    <p>
                      SÃO <br /> PAULO <br />
                      BR
                    </p>
                  </div>
                )} */}
              </div>
            </div>
            <div className="p-xl-1 p-lg-1 p-md-1 p-sm-1 p-xs-1"></div>
            <div
              className="p-xl-4 p-lg-4 p-md-4 p-sm-10 p-xs-10"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="row-right">
                <div className="flex-box-login">
                  <div className="box-login">
                    <div className="boas-vindas">
                      <h1 className="title-boas-vindas">Conecte-se</h1>
                    </div>

                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        user: userLogin,
                        password: "",
                      }}
                      validationSchema={Yup.object({
                        user: Yup.string().required("*Obrigatório"),
                        password: Yup.string().required("*Obrigatório"),
                      })}
                      onSubmit={(values) => {
                        handleSignIn(values);
                      }}
                    >
                      <Form className="form" autoComplete="off">
                        <div className="box">
                          <TextInput
                            iconleft={iconLoginEmail}
                            className="box-input"
                            name="user"
                            placeholder="E-mail"
                          />

                          <div id="box-input-password">
                            <TextInput
                              iconleft={iconLoginPassword}
                              className="box-input"
                              name="password"
                              placeholder="Senha"
                              type="password"
                            />
                          </div>
                          <div className="check-login">
                            <Checkbox
                              inputId="checkLogin"
                              onChange={() =>
                                setIsRememberLogin(!isRememberLogin)
                              }
                              checked={isRememberLogin}
                            />
                            <label htmlFor="checkLogin" className="text">
                              Mantenha-me conectado
                            </label>
                          </div>
                        </div>

                        <button
                          loading={loading.true}
                          type="submit"
                          styles="w-all"
                          className="button-enter"
                        >
                          Entrar
                        </button>

                        <div
                          className="text-link"
                          onClick={() => history.push("/recuperar-senha")}
                        >
                          Esqueceu a senha? Resetar senha
                        </div>

                        {hasError ? errorMessage : null}
                        {errorMenssage && (
                          <div className="error-menssage">{errorMenssage}</div>
                        )}
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-xl-1 p-lg-1 p-md-1 p-sm-1 p-xs-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
