/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ExpositoresService from "../../service/expositores.service";
import QueryOptions from "../../utils/query-options";
import ListTableLazy from "../../components/ListTableLazy";
import queryString from "query-string";
import AlertService from "../../service/alert.service";
import AlertMenssage from "../../components/AlertMenssage";

export default function Expositores({ location }) {
  const [expositores, setExpositores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);
  const [first, setFirst] = useState(0);
  const [busca, setBusca] = useState("");
  const [buscaUltima, setBuscaUltima] = useState("");
  const [limparFiltros, setLimparFiltros] = useState(false);

  const columns = [
    { field: "nomeFantasia", header: "Nome fantasia" },
    { field: "razaoSocial", header: "Razão social" },
    { field: "cnpj", header: "CNPJ" },
    { field: "statusStr", header: "Status" },
  ];

  useEffect(() => {
    async function listExpositores() {
      const _expositoresService = new ExpositoresService();
      const queryOptions = new QueryOptions([
        [
          "fields",
          "razao_social, nome_fantasia, image_filename, cnpj, insc_estadual, id_expositor, disabled",
        ],
        ["page", page],
      ]);

      if (busca.length) {
        let pageAtual = 1;

        if (buscaUltima === busca) {
          pageAtual = page;
        } else {
          setBuscaUltima(busca);
        }

        queryOptions.queryKvs.push(["query", busca], ["page", pageAtual]);
        setLimparFiltros(true);
      } else {
        setLimparFiltros(false);
        setBuscaUltima("");
      }

      setLoading(true);
      const data = await _expositoresService.list(queryOptions, true);
      setLoading(false);
      setExpositores(data.items);
      setMeta(data.meta);
    }
    listExpositores();
  }, [page, busca]);

  useEffect(() => {
    const queryStringObj = queryString.parse(location.search);

    if (queryStringObj) {
      if (queryStringObj.acao === "criado") {
        AlertService.success("Expositor adicionado com sucesso");
      } else if (queryStringObj.acao === "deletado") {
        AlertService.success("Expositor deletado com sucesso");
      }
    }
  }, [location]);

  function handleChangePage(event) {
    setFirst(event.first);
    setPage(event.page + 1);
  }

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <div className="p-grid">
            <div className="p-col-9 p-lg-9 p-md-9 p-sm-9">
              <h1 className="h1-container">Expositores</h1>
            </div>

            <div
              className={
                busca.length
                  ? "p-col-12 p-lg-2 p-md-2 p-sm-2"
                  : "p-col-12 p-lg-3 p-md-3 p-sm-3"
              }
            >
              <div className="text-input-filtragem">
                <input
                  type="text"
                  value={busca}
                  onChange={(ev) => setBusca(ev.target.value)}
                  placeholder="Busca por nome fantasia, razão social ou CNPJ"
                />
              </div>
            </div>

            {limparFiltros && (
              <div className="p-col-12 p-lg-1 p-md-1 p-sm-1">
                <button
                  onClick={() => {
                    setBusca("");
                    setLimparFiltros(false);
                  }}
                  className="limpar-filtros-button"
                >
                  <span>Limpar filtros</span>
                </button>
              </div>
            )}
          </div>

          <hr className="hr-header" />
          <AlertMenssage />
          <ListTableLazy
            cols={columns}
            values={expositores}
            firstIdx={first}
            meta={meta}
            loading={loading}
            endpoint="expositores"
            onChangePage={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
}
