/* eslint-disable react-hooks/exhaustive-deps */
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AlertMenssage from "../../../components/AlertMenssage";
import ButtonSave from "../../../components/ButtonSave";
import UserProfileService from "../../../service/user-profile.service";
import QueryOptions from "../../../utils/query-options";

export default function ConfiguracaoPerfilList() {
  const history = useHistory();

  // Paginação
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(0);
  const [first, setFirst] = useState(1);
  const [last, setLast] = useState(0);

  const [loading, setLoading] = useState(false);
  const [perfis, setPerfis] = useState([]);

  // Services
  const _perfilService = new UserProfileService();

  useEffect(() => {
    async function init() {
      setLoading(true);

      const queryOptions = new QueryOptions([["page", page]]);
      const data = await _perfilService.list(queryOptions, true);

      const perfilFilter = [];
      data.items.forEach((item) => {
        if (item.id > 3) {
          perfilFilter.push({ ...item });
        }
      });

      data.items = [...perfilFilter];

      setPerfis(data.items);
      setMeta(data.meta);
      setLast(data.items.length);
      setLoading(false);
    }

    init();
  }, [page]);

  const handleChangePage = (event) => {
    let lastAux = event.first + perfis.length;
    if (lastAux > meta.totalItems) {
      lastAux = meta.totalItems;
    }

    setFirst(event.first + 1);
    setLast(lastAux);
    setPage(event.page + 1);
  };

  const statusBodyTable = (event) => {
    return (
      <span className={`status ${event.disabled ? "desativado" : "ativado"}`}>
        {event.disabled ? "Desativado" : "Ativado"}
      </span>
    );
  };

  const actionBodyTable = (event) => {
    if (event.id > 3) {
      return (
        <div className="icons">
          <i
            className="pi pi-pencil"
            title="Editar"
            onClick={() =>
              history.push(`/configuracoes/perfis/editar/${event.id}`)
            }
          ></i>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <div className="p-grid">
            <div className="p-col-8 p-lg-8 p-md-8 p-sm-5">
              <h1 className="h1-container">Perfis</h1>
            </div>
          </div>

          <hr className="hr-header" />

          <div className="datatable-paginator-demo">
            <div className="btn-add">
              <ButtonSave
                label="Adicionar"
                onClick={() => history.push("/configuracoes/perfis/adicionar")}
              />
            </div>

            <div className="alert-menssage">
              <AlertMenssage to="top" />
            </div>
            <div className="content-section implementation">
              <DataTable
                value={perfis}
                lazy
                first={first}
                rows={meta.perPage}
                paginator={meta.totalPages > 1}
                loading={loading}
                totalRecords={meta.totalItems}
                onPage={handleChangePage}
                emptyMessage="Sem registros"
                currentPageReportTemplate={`${first} a ${last} de ${meta.totalItems}`}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                dataKey="id"
              >
                <Column field="label" header="Título"></Column>
                <Column
                  field="status"
                  header="Status"
                  headerStyle={{ width: "10rem" }}
                  body={statusBodyTable}
                ></Column>
                <Column
                  field="actions"
                  header="Ações"
                  headerStyle={{ width: "5rem" }}
                  body={actionBodyTable}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
