import React from "react";
import PropTypes from "prop-types";
import { TabMenu } from "primereact/tabmenu";
import { useHistory, useParams } from "react-router-dom";
import { userHasAccess, userProfiles } from "../utils/permission-user";
import Authentication from "../service/authentication.service";

ExpositorMenu.propTypes = {
  activeItem: PropTypes.any,
  expositor: PropTypes.any,
};

export default function ExpositorMenu({ activeItem, expositor }) {
  const history = useHistory();
  const { idExpositor } = useParams();
  const usuarioLogado = Authentication._user;

  const items = [
    { label: "Dados", action: "" },
    { label: "Produtos", action: `produtos` },
    { label: "Sobre", action: "sobre" },
  ];

  if (userHasAccess([userProfiles.expositor])) {
    const permissoes = usuarioLogado.expositoresPermissoes.find(
      (item) => item.id === expositor?.id
    );

    if (permissoes) {
      if (permissoes.moduloGaleriaVideos) {
        items[4] = { label: "Vídeos", action: `videos` };
      }

      if (permissoes?.bannerRotativos) {
        items[6] = { label: "Banner do expositor", action: "banner-expositor" };
      }

      if (permissoes?.modulosCatalogos) {
        items[5] = { label: "Catálogos", action: "catalogos" };
      }

      if (permissoes?.modulosEventos) {
        items[3] = {
          label: "Eventos do expositor",
          action: `eventos-expositor`,
        };
      }
    }
  } else if (userHasAccess([userProfiles.administrador])) {
    items.push(
      { label: "Eventos do expositor", action: `eventos-expositor` },
      { label: "Vídeos", action: `videos` },
      { label: "Catálogos", action: "catalogos" },
      { label: "Banner do expositor", action: "banner-expositor" }
    );
  }

  function handleChange({ value }) {
    if (expositor) {
      if (value.action === "produtos") {
        if (expositor.id) {
          history.push(`/expositores/editar/${expositor.id}/${value.action}`);
        } else if (typeof expositor === "string") {
          history.push(`/expositores/editar/${idExpositor}/${value.action}`);
        }
      } else if (value.action === "") {
        if (typeof expositor === "string") {
          history.push(`/expositores/editar/${idExpositor}`);
        }
        if (expositor.id) {
          history.push(`/expositores/editar/${expositor.id}`);
        }
      } else if (value.action === "eventos-expositor") {
        if (expositor.id) {
          history.push(`/expositores/editar/${expositor.id}/${value.action}`);
        } else if (typeof expositor === "string") {
          history.push(`/expositores/editar/${idExpositor}/${value.action}`);
        }
      } else if (value.action === "videos") {
        if (expositor.id) {
          history.push(`/expositores/${expositor.id}/${value.action}`);
        } else if (typeof expositor === "string") {
          history.push(`/expositores/${idExpositor}/${value.action}`);
        }
      } else if (value.action === "catalogos") {
        if (expositor.id) {
          history.push(`/expositores/editar/${expositor.id}/${value.action}`);
        } else if (typeof expositor === "string") {
          history.push(`/expositores/editar/${idExpositor}/${value.action}`);
        }
      } else if (value.action === "sobre") {
        if (expositor.id) {
          history.push(`/expositores/editar/${expositor.id}/${value.action}`);
        } else if (typeof expositor === "string") {
          history.push(`/expositores/editar/${idExpositor}/${value.action}`);
        }
      } else if (value.action === "banner-expositor") {
        if (expositor.id) {
          history.push(`/expositores/editar/${expositor.id}/${value.action}`);
        } else if (typeof expositor === "string") {
          history.push(`/expositores/editar/${idExpositor}/${value.action}`);
        }
      }
    }
  }

  return (
    <>
      <TabMenu
        model={items}
        activeItem={items[activeItem]}
        onTabChange={(e) => {
          handleChange(e);
        }}
      />
    </>
  );
}
