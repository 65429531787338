export default class RedesSociaisTiposSerializer {
  fromJson(json) {
    const redesSociaisTipos = {};
    Object.assign(
      redesSociaisTipos,
      json.id_rede_social_tipo && {
        id_rede_social_tipo: json.id_rede_social_tipo,
        value: json.id_rede_social_tipo,
      },
      json.link && { link: json.link },
      json.tipo && { label: json.tipo.tipo },
      json.id_rede_social && { id_rede_social: json.id_rede_social }
    );

    return redesSociaisTipos;
  }
}
